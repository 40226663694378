class ProtobufApi {
  private service: any;

  constructor(service: any) {
    this.service = service;
  }

  request<T>(method: any, args: any[]): Promise<T> {
    return new Promise((resolve, reject) => {
      this.service[method](...args, (err: any, res: any) => {
        if (err) {
          // TODO: Handle server errors
          console.log(err);
          return reject(err);
        }
        resolve(res.toObject());
      });
    });
  }

  stream(method: any, onData: any, onEnd: any, args: any[]): void {
    const streamObject = this.service[method](...args);
    streamObject.on('data', (data: any) => {
      onData(data);
    });

    streamObject.on('end', () => onEnd());

    streamObject.on('error', (e: any) => {
      console.log('error', e);
      throw e;
    });
  }
}

export default ProtobufApi;
