import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import Vue from 'vue';
import cloneDeep from 'lodash/cloneDeep';
import { to } from 'await-to-js';
import includes from 'lodash/includes';

import store from '@/store';
import { objectKeysToCamelCase } from '@/lib/objectKeysToCamelCase';
import { eventService } from './event.service';
import {
  FactoryEventMap,
  FactoryEvent,
  NewEventPayload,
  EventState,
  EventTemplates,
  EventRequestSource,
  DualEventApiType,
  isLive,
  isPrematch,
  GenerateTennisHandicapsPayload,
  CamelizedTemplates,
  BlockBettingPlace,
  ChangeTemplateRequestType,
  GetSingleLiveEventResponse,
  LiveEvent,
  PrematchEvent,
  BlockedBettingPlace,
  BlockUserPayload,
  UnblockBettingPlace,
} from './event.types';
import { MarketStore, marketService, Odd } from '@/modules/market';
import {
  NumberOfProdOddMessage,
  EgalOddsUpdateMessage,
  ValidatedDualEvent,
  DualEventsValidatedMessage,
  EventStartDateUpdated,
  CodeMessageData,
  MarketTemplateChangedMessage,
  EventEndMessage,
} from '@/modules/feed/feed.types';
import { CommonStore } from '../common';

import { SettlementStore } from '@/modules/settlement/settlement.store';
import { EventPublished } from '@/modules/event/eventPublished.entity';
import { SportFilterStore } from '@/modules/sportFilter/sportFilter.store';
import { SportSource } from '@/modules/sportFilter/sportFilter.types';
import { mapCurrentResult, mapCurrentPhase, mapPeriodResult } from '@/lib/mapStructData';

const initialState = () => ({
  events: {},
  activeId: null,
  selectedEvents: [],
  availableCompetitions: [],
  tempGeneratedOdds: {},
  tennisHandicaps: null,
  tempInputParams: {},
  activeEventInputParams: {},
  activeEventMaxBet: -1,
  activeLiveMaxAmountSc: -1,
  activeLiveMaxAmountWeb: -1,
  isGeneratingOdds: false,
  lastUpdatedEvent: '',
  liveEventAdded: '',
  eventEnded: '',
  eventToRemoveFromPrematch: '',
  liveLastChange: 0,
  requestSource: 'other' as EventRequestSource,
  matchDataEventId: null,
  defaultSportModel: 0,
  filterTriggerer: false,
});

const arrayToObject = (array: FactoryEvent[]) => {
  return array.reduce((obj: any, item: FactoryEvent) => {
    const key = eventService.formatEventId(item.intKey);
    // live & prematch baseMarkets come in some grpc structure that we need to parse to work
    // with normalized js structure
    item.basePrematchMarkets = eventService.pluckBaseMarkets({
      baseMarketsMap: item.basePrematchMarketsMap,
      isMarketSuspendedMap: item.marketsMap,
    });
    item.basePrematchMarketsMap = undefined;
    item.eventType = DualEventApiType.prematch;

    if (isLive(item)) {
      item.baseLiveMarkets = eventService.pluckLiveBaseMarkets({
        baseMarketsMap: item.baseLiveMarketsMap,
        isMarketSuspendedMap: item.marketsMap,
      });
      item.baseLiveMarketsMap = undefined;
      item.eventType = DualEventApiType.live;
      try {
        item.currentResultJson = mapCurrentResult(item.currentResult?.fieldsMap) as any;
        item.currentPhaseJson = mapCurrentPhase(item.currentPhase?.fieldsMap) as any;
        item.periodResultJson = mapPeriodResult(item.periodResult?.fieldsMap) as any;
        item.periodResult = undefined;
        item.currentPhase = undefined;
        item.currentResult = undefined;
      } catch (e) {
        console.error(e);
      }
    }
    obj[key] = item;
    return obj;
  }, {});
};

@Module({ dynamic: true, store, name: 'event', namespaced: true })
class Event extends VuexModule {
  events: FactoryEventMap = initialState().events;
  activeId: string | null = initialState().activeId;
  selectedEvents: FactoryEvent[] = initialState().selectedEvents;
  tempGeneratedOdds: any = initialState().tempGeneratedOdds;
  tennisHandicaps: any = initialState().tennisHandicaps;
  tempInputParams: any = initialState().tempInputParams;
  activeEventInputParams: any = initialState().activeEventInputParams;
  activeEventMaxBet: any = initialState().activeEventMaxBet;
  isGeneratingOdds: boolean = initialState().isGeneratingOdds;
  lastUpdatedEvent: string = initialState().lastUpdatedEvent;
  liveEventAdded: string = initialState().liveEventAdded;
  eventEnded: string = initialState().eventEnded;
  eventToRemoveFromPrematch: string = initialState().eventToRemoveFromPrematch;
  liveLastChange: number = initialState().liveLastChange;
  requestSource: EventRequestSource = initialState().requestSource;
  matchDataEventId: number | null = initialState().matchDataEventId;
  activeLiveMaxAmountSc: number | null = initialState().activeLiveMaxAmountSc;
  activeLiveMaxAmountWeb: number | null = initialState().activeLiveMaxAmountWeb;
  availableCompetitions: number[] = initialState().availableCompetitions;
  defaultSportModel: number = initialState().defaultSportModel;
  filterTriggerer: boolean = initialState().filterTriggerer;

  blockedUsersOnEvent: BlockUserPayload[] = [];
  blockedBettingPlacesOnEvent: BlockedBettingPlace[] = [];

  get hasActiveEvent() {
    return this.activeId !== null;
  }

  get active() {
    if (!this.activeId) return null;
    return this.events[this.activeId];
  }

  get eventsArray() {
    return Object.values(this.events);
  }

  get availableStates() {
    const a = eventService.bulkStateTansition(this.selectedEvents);
    if (a) {
      return [...(a.next || []), ...(a.prev || [])].map((a: any) => ({
        label: a.label,
        value: a.value,
      }));
    }
    return [];
  }

  get selectedSportId() {
    return eventService.getSportIdFromSelectedEvents(this.selectedEvents);
  }

  get isEventEditDisabled() {
    return eventService.isEventEditDisabled(this.active?.state, this.requestSource);
  }

  get canBulkSuspend() {
    return () => {
      let canSuspend = false;
      if (this.selectedEvents.length) {
        canSuspend = eventService.canBulkSuspend(this.selectedEvents);
      }
      return canSuspend;
    };
  }

  get nextBulkSuspendState() {
    return () => {
      const event = this.selectedEvents[0];
      if (!this.canBulkSuspend()) return null;
      if (isLive(event) && event.state === 'PUBLISHED') {
        return event.liveSuspended ? 'unsuspend' : 'suspend';
      } else if (isPrematch(event) && event.state === 'PUBLISHED') {
        return event.prematchSuspended ? 'unsuspend' : 'suspend';
      } else {
        return null;
      }
    };
  }

  @Mutation
  setEvents(events: FactoryEvent[]) {
    const sortedEvents = eventService.sortEvents(events);
    this.availableCompetitions = sortedEvents.map(e => e.competitionId);
    this.events = {
      ...arrayToObject(sortedEvents),
    };
  }

  @Mutation
  toggleFilterTriggerer() {
    this.filterTriggerer = !this.filterTriggerer;
  }

  @Mutation
  setSignleEventInputParams(inputParams: any) {
    this.activeEventInputParams = inputParams;
  }

  @Mutation
  setActiveEventMaxBet(maxBet: number) {
    this.activeEventMaxBet = maxBet;
  }

  @Mutation
  setLiveEventMaxBets({ live_max_amount_sc, live_max_amount_web }: GetSingleLiveEventResponse) {
    this.activeLiveMaxAmountSc = live_max_amount_sc;
    this.activeLiveMaxAmountWeb = live_max_amount_web;
  }

  @Mutation
  addToSelectedEvents(event: FactoryEvent) {
    this.selectedEvents.push(cloneDeep(event));
  }

  @Mutation
  setUpdateTableForLive() {
    this.liveLastChange = Date.now();
  }

  @Mutation
  removeSelectedEvent(event: FactoryEvent) {
    if (!event) return;
    this.selectedEvents = this.selectedEvents.filter(
      (e: FactoryEvent) => e.intKey !== event.intKey
    );
  }

  @Mutation
  resetStore(keyToExclude?: string) {
    const initial = initialState() as any;
    Object.keys(initial).forEach((key: any) => {
      if (keyToExclude && keyToExclude === key) {
        return;
      }
      this[key as keyof this] = initial[key];
    });
  }

  @Mutation
  clearEvents() {
    this.events = {};
  }

  @Mutation
  setLastUpdatedEvent(intKey: string | number) {
    this.lastUpdatedEvent = `${intKey}_${Date.now()}`;
  }

  @Mutation
  updateEvent({ intKey, params }: { intKey: number; params: Partial<FactoryEvent> }) {
    const eventAccessor = eventService.formatEventId(intKey);
    const event = this.events[eventAccessor];
    if (!event) return;
    const camelizeKeyValues = objectKeysToCamelCase(params) as CamelizedTemplates;
    Vue.set(this.events, eventAccessor, { ...event, ...camelizeKeyValues });
    this.lastUpdatedEvent = `${intKey}_${Date.now()}`;
  }

  @Mutation
  updateEventWithoutRender({ intKey, params }: { intKey: number; params: Partial<FactoryEvent> }) {
    const eventAccessor = eventService.formatEventId(intKey);
    const event = this.events[eventAccessor];
    if (!event) return;
    const camelizeKeyValues = objectKeysToCamelCase(params) as CamelizedTemplates;
    Vue.set(this.events, eventAccessor, { ...event, ...camelizeKeyValues });
  }

  @Mutation
  updateSelectedEvent({ intKey, params }: { intKey: number; params: Partial<FactoryEvent> }) {
    const index = this.selectedEvents.findIndex(event => event.intKey === intKey);
    if (index === undefined) return;
    const camelizeKeyValues = objectKeysToCamelCase(params) as CamelizedTemplates;
    Vue.set(this.selectedEvents, index, { ...this.selectedEvents[index], ...camelizeKeyValues });
  }

  @Mutation
  removeEventFromLive(intKey: number) {
    const eventAccessor = eventService.formatEventId(intKey);
    if (this.events[eventAccessor]) {
      this.events[eventAccessor].sentToSettlement = true;
    }
  }

  @Mutation
  updateEventBaseOdds({ intKey, params }: { intKey: number; params: Partial<FactoryEvent> }) {
    const eventAccessor = eventService.formatEventId(intKey);
    const event = this.events[eventAccessor];
    if (!event) return;
    Vue.set(this.events, eventAccessor, { ...event, ...params });
    this.lastUpdatedEvent = `${intKey}_${Date.now()}`;
  }

  @Mutation
  eventFinished(intKey: number) {
    this.eventEnded = `${intKey}_${Date.now()}`;
    const eventAccessor = eventService.formatEventId(intKey);
    if (this.events[eventAccessor]) {
      this.events[eventAccessor].state = 'COMPLETED';
    }
  }

  @Mutation
  onPublishedLiveEvent({ intKey, params }: { intKey: number; params: FactoryEvent }) {
    const eventAccessor = eventService.formatEventId(intKey);
    const event = this.events[eventAccessor];
    if (!event) {
      Vue.set(this.events, eventAccessor, params);
      this.liveEventAdded = `${intKey}_${Date.now()}`;
      return;
    }
    Vue.set(this.events, eventAccessor, { ...event, ...params });
    this.liveEventAdded = `${intKey}_${Date.now()}`;
  }

  @Mutation
  publishEvent({ intKey, params }: { intKey: number; params: FactoryEvent }) {
    const eventAccessor = eventService.formatEventId(intKey);
    const event = this.events[eventAccessor];
    if (!event) {
      Vue.set(this.events, eventAccessor, params);
      this.lastUpdatedEvent = `${intKey}_${Date.now()}`;
      return;
    }
    Vue.set(this.events, eventAccessor, { ...event, ...params });
    this.lastUpdatedEvent = `${intKey}_${Date.now()}`;
  }

  @Mutation
  setActiveEvent(eventKey: number) {
    this.activeId = eventService.formatEventId(eventKey);
  }

  @Mutation
  deselectEvent() {
    this.activeId = null;
  }

  @Mutation
  setMatchDataEvent(intKey: number) {
    this.matchDataEventId = intKey;
  }

  @Mutation
  deselectMatchDataEvent() {
    this.matchDataEventId = null;
  }

  @Mutation
  clearSelectedEvents() {
    this.selectedEvents = [];
  }

  @Mutation
  setEventState(event: ValidatedDualEvent) {
    if (!this.events[eventService.formatEventId(event.i_k)]) return;
    this.events[eventService.formatEventId(event.i_k)].state = 'VALIDATED';
    this.events[eventService.formatEventId(event.i_k)].landbaseCode = event.e_c;
    this.lastUpdatedEvent = this.lastUpdatedEvent = `${event.i_k}_${Date.now()}`;
  }

  // test next 3 mutations and see if u need to do this for base_live_markets when u get a chance
  @Mutation
  public updateBaseMarketOdd({ eventId, oddId, value }: any) {
    const event = this.events[`e_${eventId}`];
    if (!event || isLive(event)) return;
    event.basePrematchMarkets?.forEach((market: any) => {
      market.odds.forEach((odd: Odd) => {
        if (odd.outcome_id === oddId) {
          odd.prematch_prod_odd = value.prod_odd / 100;
          odd.prematch_real_odd =
            eventService.isManualTech(event) && !odd.prematch_real_odd
              ? value.real_odd
              : odd.prematch_real_odd;
        }
      });
    });
  }

  @Mutation
  private updateBaseMarketSuspend({ eventId, oddId, value }: any) {
    const event = this.events[`e_${eventId}`];
    if (!event) return;
    const baseMarkets = isLive(event) ? event.baseLiveMarkets : event.basePrematchMarkets;
    baseMarkets.forEach((market: any) => {
      market.odds.forEach((odd: any) => {
        if (odd.int_key === oddId) {
          odd.is_suspended = value;
        }
      });
    });
  }

  @Mutation
  private updateBaseMarket({ eventId, marketId, params }: any) {
    const event = this.events[`e_${eventId}`];
    if (!event) return;
    const baseMarkets = isLive(event) ? event.baseLiveMarkets : event.basePrematchMarkets;
    baseMarkets.forEach((market: any) => {
      if (market.id === marketId) {
        Object.keys(params).forEach((key: any) => {
          Vue.set(market, key, params[key]);
        });
      }
    });
  }

  @Mutation
  private setIsGeneratingOdds(isGeneratingOdds: boolean) {
    this.isGeneratingOdds = isGeneratingOdds;
  }

  @Mutation
  public setGeneratedOdds(tempOdds: any) {
    const arrayToObject = (array: any[], keyValue: string): object => {
      return array.reduce((obj: any, item: any) => {
        obj[`${item[keyValue]}`] = item;
        return obj;
      }, {});
    };
    const sortTempOdds = [];
    for (const key in tempOdds) {
      const currentOdds = tempOdds[key].odds;
      const mapOdds = [];
      for (const oddKey in currentOdds) {
        mapOdds.push({ ...currentOdds[oddKey], outcome_id: Number(oddKey) });
      }
      mapOdds.forEach((odd: Odd) => {
        // @ts-ignore
        odd.odd_value /= 100;
      });
      const sortedOdds = marketService.sortOddsByLimitOrOutcomeId(mapOdds);
      sortTempOdds.push({ ...tempOdds[key], market_id: Number(key), odds: sortedOdds });
    }
    this.tempGeneratedOdds = arrayToObject(sortTempOdds, 'market_id');
  }

  @Mutation
  setInputParams(inputParams: any) {
    this.tempInputParams = inputParams;
  }

  @Mutation
  setTennisHandicaps(handicaps: any) {
    this.tennisHandicaps = handicaps;
  }

  @Mutation
  addToAvailableCompetitions(val: number) {
    this.availableCompetitions.push(val);
  }

  @Mutation
  public setRequestSource(source: EventRequestSource) {
    this.requestSource = source;
  }

  @Mutation
  removeEventFromPrematch(intKeys: number[]) {
    intKeys.forEach(intKey => {
      const eventAccessor = eventService.formatEventId(intKey);
      if (this.events[eventAccessor]) {
        this.events[eventAccessor].prematchRemoved = true;
      }
      setTimeout(() => {
        this.eventToRemoveFromPrematch = eventAccessor;
      }, 0);
    });
  }

  @Mutation
  public clearInputParams() {
    this.tempInputParams = initialState().tempInputParams;
  }

  // only live base markets have egal odds
  @Mutation
  changeBaseMarketEgalOdd({
    eventAccessor,
    marketId,
    newOdds,
  }: {
    eventAccessor: string;
    marketId: number;
    newOdds: any[];
  }) {
    const event = this.events[eventAccessor];
    if (!isLive(event)) return;
    const baseMarkets = event.baseLiveMarkets.find((market: any) => market.id === marketId);
    baseMarkets && (baseMarkets.odds = newOdds);
  }

  @Mutation
  checkBaseMarketsAndUpdate(wsData: any) {
    const eventAccessor = eventService.formatEventId(wsData.e_i_k);
    const event = this.events[eventAccessor];
    if (!isLive(event)) return;
    if (event.baseLiveMarkets.length) return;
    const newBaseMarkets = eventService.groupPrematchBaseOddsByMarket(wsData.base_odds);
    event.baseLiveMarkets = newBaseMarkets;
    this.lastUpdatedEvent = `${wsData.e_i_k}_${Date.now()}`;
  }

  @Mutation
  updateactiveLiveMaxAmountSc(value: number | null) {
    this.activeLiveMaxAmountSc = value;
  }

  @Mutation
  setActiveLiveMaxAmountWeb(value: number | null) {
    this.activeLiveMaxAmountWeb = value;
  }

  @Mutation
  setSportModel(model: number | null) {
    this.defaultSportModel = !model ? 0 : model;
  }

  @Mutation
  setBlockedUsers(users: BlockUserPayload[]) {
    if (!users.length) return;

    users.forEach(user => {
      const blockedUser = {
        ...user,
      };
      if (!this.blockedUsersOnEvent.length) {
        this.blockedUsersOnEvent.push(blockedUser);
        return;
      }
      const foundUser = this.blockedUsersOnEvent.find(usr => usr.userId === user.userId);
      if (foundUser) return;
      this.blockedUsersOnEvent.push(blockedUser);
    });
  }

  @Mutation
  setBlockedBettingPlaces(bettingPlaces: BlockedBettingPlace[]) {
    if (!bettingPlaces.length) return;

    bettingPlaces.forEach(bettingPlace => {
      if (!this.blockedBettingPlacesOnEvent.length) {
        this.blockedBettingPlacesOnEvent.push(bettingPlace);
        return;
      }
      const foundBP = this.blockedBettingPlacesOnEvent.find(
        bp => bp.bettingPlaceId === bettingPlace.bettingPlaceId
      );
      if (foundBP) return;
      this.blockedBettingPlacesOnEvent.push(bettingPlace);
    });
  }

  @Mutation
  removeBlockedUser(id: number) {
    if (!id) return;
    const foundIndex = this.blockedUsersOnEvent.findIndex(usr => usr.userId === id);
    this.blockedUsersOnEvent.splice(foundIndex, 1);
  }

  @Mutation
  removeBettingPlace(id: number) {
    if (!id) return;
    const foundIndex = this.blockedBettingPlacesOnEvent.findIndex(
      place => place.bettingPlaceId === id
    );
    this.blockedBettingPlacesOnEvent.splice(foundIndex, 1);
  }

  @Mutation
  clearStateBlockedByEvent() {
    this.blockedUsersOnEvent = [];
    this.blockedBettingPlacesOnEvent = [];
  }

  // on change base_markets handler
  @Action
  onBaseMarketChange(data: any) {
    const newBaseMarkets = eventService.groupPrematchBaseOddsByMarket(data.base_odds);
    this.updateEventBaseOdds({
      intKey: data.e_i_k,
      params: { basePrematchMarkets: newBaseMarkets },
    });
  }

  @Action
  onLiveBaseMarketChange(data: any) {
    const newBaseMarkets = eventService.groupPrematchBaseOddsByMarket(data.base_odds);
    this.updateEventBaseOdds({
      intKey: data.e_i_k,
      params: { baseLiveMarkets: newBaseMarkets },
    });
  }

  @Action
  onLiveEventPublished(data: any) {
    if (window.location.pathname.includes('live') && data.lo) {
      const mappedData = new EventPublished(data) as any;
      const hasCompetition = includes(this.availableCompetitions, mappedData.competitionId);
      if (!hasCompetition) {
        this.addToAvailableCompetitions(mappedData.competitionId);
        SportFilterStore.loadData(SportSource.factory);
      }

      mappedData.baseLiveMarkets = eventService.pluckLiveMarketsOnPublish({
        pluckedMakrets: mappedData.baseLiveMarkets,
        isMarketSuspendedMap: mappedData.marketsMap || [],
      });
      this.onPublishedLiveEvent({ intKey: mappedData.intKey, params: { ...mappedData } });
      this.toggleFilterTriggerer();
    }
  }

  @Action
  onPrematchEventPublished(data: any) {
    if (window.location.pathname.includes('dual')) {
      const mappedData = new EventPublished(data) as any;
      const hasCompetition = includes(this.availableCompetitions, mappedData.competitionId);
      if (!hasCompetition) {
        this.addToAvailableCompetitions(mappedData.competitionId);
        SportFilterStore.loadData(SportSource.factory);
      }
      mappedData.basePrematchMarkets = eventService.groupPrematchOddsByMarket({
        odds: mappedData.basePrematchMarkets,
        // check with Kris suspended markets when event is published
        suspendedMarkets: [],
      });

      this.publishEvent({ intKey: mappedData.intKey, params: { ...mappedData } });
      this.toggleFilterTriggerer();
    }
  }

  @Action
  async getEvents() {
    CommonStore.setIsFetching(true);
    const results = await eventService.getEvents();
    this.setEvents(results);
    CommonStore.setIsFetching(false);
  }

  @Action
  async getPrematch() {
    CommonStore.setIsFetching(true);
    const results = await eventService.getPrematch();
    this.setEvents(results);
    CommonStore.setIsFetching(false);
  }

  @Action
  async getMagazineEvents() {
    CommonStore.setIsFetching(true);
    const results = await eventService.getMagazineEvents();
    this.setEvents(results);
    CommonStore.setIsFetching(false);
  }

  @Action
  async getLive(date: string) {
    CommonStore.setIsFetching(true);
    const results = await eventService.getLive(date);
    this.setEvents(results);
    CommonStore.setIsFetching(false);
  }

  @Action
  async getLiveNonBooked() {
    const liveResults = await eventService.getLive();
    const prematchResults = await eventService.getPrematch();
    const nonBookedPrematchEvents = eventService.getNonBookedPrematchEvents(
      liveResults,
      prematchResults
    );
    this.setEvents(nonBookedPrematchEvents);
  }

  @Action
  async getSinglePrematchEvent() {
    if (!this.active) return;
    const [err, res] = await to(eventService.getSinglePrematchEvent(this.active?.intKey));
    if (err) return Promise.reject(err);
    this.setSignleEventInputParams((res as any).input_parameters);
    this.setActiveEventMaxBet((res as any).prematch_max_amount);
    this.setSportModel((res as any).model);
    this.setBlockedUsers(eventService.mapBlockedUsers((res as any).blocked_users));
    this.setBlockedBettingPlaces(eventService.mapBettingPlaces((res as any).blocked_bp));
    return Promise.resolve();
  }

  @Action
  async getSingleLiveEvent() {
    if (!this.active) return;
    const [err, res] = await to(eventService.getSingleLiveEvent(this.active?.intKey));
    if (err) return Promise.reject(err);
    this.setLiveEventMaxBets(res as GetSingleLiveEventResponse);
    return Promise.resolve();
  }

  @Action
  selectRow({ event, selected }: { event: FactoryEvent; selected: boolean }) {
    if (selected) {
      this.addToSelectedEvents(event);
    } else {
      this.removeSelectedEvent(event);
    }
  }

  @Action
  async updateTemplate({ intKey, value, key }: { intKey: number; key: any; value: number }) {
    const event = this.events[`e_${intKey}`];
    if (!event) return;
    const [err] = await to(eventService.updateTemplate(event, key, value, this.requestSource));
    if (!err) {
      this.updateEvent({ intKey, params: { [key]: value } });
    }
  }

  @Action
  createEvent(formData: NewEventPayload) {
    return eventService.createEvent(formData);
  }

  @Action
  async generateOddsByInputParams(formData: object) {
    if (!this.active) return;
    this.setIsGeneratingOdds(true);
    const eventId = this.active.intKey;
    this.setInputParams(formData);
    const [err, response] = await to(
      eventService.generateOddsByInputParams(eventId, {
        ...formData,
        source: this.requestSource,
        model: this.defaultSportModel,
      })
    );
    this.setIsGeneratingOdds(false);
    if (err) return Promise.reject(err);
    return Promise.resolve(response);
  }

  @Action
  async generateTennisOdds(payload: object) {
    if (!this.active) return;
    this.setIsGeneratingOdds(true);
    const eventId = this.active.intKey;
    const [err, response] = await to(eventService.generateTennisOdds(eventId, payload));
    this.setIsGeneratingOdds(false);
    if (err) return Promise.reject(err);
    return Promise.resolve(response);
  }

  @Action
  async getTennisHandicaps(payload: GenerateTennisHandicapsPayload) {
    if (!this.activeId) return;
    const id = Number(this.activeId.slice(2));
    const [err, res] = await to(eventService.getTennisHandicaps(id, payload));
    if (err) return Promise.reject(err);
    this.setTennisHandicaps((res as any).handicaps);
    return Promise.resolve();
  }

  @Action
  async changeBulkState(state: EventState) {
    const events = this.selectedEvents.map((e: FactoryEvent) => e.intKey);
    const source = this.requestSource === 'LIVE' ? 'live' : 'prematch';
    const [err, res] = await to(eventService.changeState(events, state, source, null));
    if (err) return Promise.reject(err);
    this.selectedEvents.forEach((event: FactoryEvent) => {
      if (!res) {
        this.updateEvent({ intKey: event.intKey, params: { state } });
      }
      if (res && !res.includes(event.intKey)) {
        this.updateEvent({ intKey: event.intKey, params: { state } });
      }
    });
    return Promise.resolve();
  }

  @Action
  async onEventsValidated(message: DualEventsValidatedMessage) {
    const events = Object.values(message);
    events.forEach((event: ValidatedDualEvent) => {
      this.setEventState(event);
    });
  }

  @Action
  async onEventsPublished() {
    // const events = Object.values(message);
    // events.forEach((event: ValidatedDualEvent) => {
    //   this.setEventState(event);
    // });
    // message: DualEventsValidatedMessage is needed as prop
    return;
  }

  @Action
  async setAutoOddChange({ eventId, nextValue }: { eventId: number; nextValue: boolean }) {
    const [err] = await to(eventService.setAutoOddChange(eventId, nextValue, this.requestSource));
    if (err) return Promise.reject(err);
    this.updateEvent({ intKey: eventId, params: { tech: nextValue ? 'A' : 'M' } });
    return Promise.resolve();
  }

  @Action
  async bulkChangeTemplates({
    formData,
    event,
    templatesType,
  }: {
    formData: EventTemplates;
    event?: FactoryEvent;
    templatesType: ChangeTemplateRequestType;
  }) {
    const selectedEvents = event ? [event] : this.selectedEvents;
    const [err] = await to(
      eventService.bulkChangeTemplates(selectedEvents, formData, this.requestSource, templatesType)
    );
    if (err) return Promise.reject(err);
    this.updateEventsTemplates({ selectedEvents, templates: formData });
    return Promise.resolve();
  }

  @Action
  private updateEventsTemplates({ selectedEvents, templates }: any) {
    selectedEvents.forEach((event: FactoryEvent) => {
      this.updateEvent({ intKey: event.intKey, params: templates });
      this.updateSelectedEvent({ intKey: event.intKey, params: templates });
    });
  }

  @Action
  async updateNote({
    intKey,
    note,
    eventType,
  }: {
    intKey: number;
    note: string;
    eventType: DualEventApiType;
  }) {
    const [err] = await to(eventService.updateNote(intKey, note, eventType));
    if (err) return Promise.reject(err);
    return Promise.resolve();
  }

  @Action
  updateOperatorNote(wsData: any) {
    const event = this.events[`e_${wsData.e_i_k}`];
    if (!event) return;
    this.updateEvent({
      intKey: wsData.e_i_k,
      params: {
        operatorNotesList: [
          //@ts-ignore-next-line
          ...this.events[`e_${wsData.e_i_k}`].operatorNotesList,
          { note: wsData.n, createdAt: new Date().toString(), id: '', userId: 0 },
        ], // should i do this at all?
      },
    });
  }

  @Action
  updateGameNote(wsData: any) {
    this.updateEvent({ intKey: wsData.e_i_k, params: { note: wsData.n } });
    SettlementStore.onUpdateEventNote(wsData);
  }

  @Action
  async addOperatorNote({ note, eventType }: { note: string; eventType: DualEventApiType }) {
    if (!this.active) return;
    const [err] = await to(
      eventService.addOperatorNote(this.active.intKey, note, this.requestSource, eventType)
    );
    if (err) return Promise.reject(err);
  }

  @Action
  async updateStart({
    intKey,
    date,
    stage,
    settle,
  }: {
    intKey: number;
    date: string;
    stage: string;
    settle: boolean;
  }) {
    const eventType = this.requestSource === 'LIVE' ? 'live' : 'prematch';
    const [err] = await to(
      eventService.updateStart(intKey, date, eventType as DualEventApiType, stage, settle)
    );
    if (err) return Promise.reject(err);
    this.updateEvent({ intKey, params: { start: date } });
  }

  @Action
  async updateProdOdd({
    oddInt,
    value,
    eventIntKey,
  }: {
    oddInt: number;
    value: number;
    eventIntKey: number;
  }) {
    const event = this.events[eventService.formatEventId(eventIntKey)];
    const [err] = await to(
      eventService.updateProdOdd(oddInt, value, this.requestSource, event.eventType, eventIntKey)
    );
    if (err) return Promise.reject(err);
    this.updateBaseMarketOdd({ eventIntKey, oddInt, value });
    this.updateEvent({ intKey: eventIntKey, params: { manualChanged: true } });
    MarketStore.updateOdd({
      id: oddInt,
      params: { manual_changed: true, prematch_prod_odd: value },
    });
  }

  @Action
  async suspendLiveEvent(eventIntKey: number) {
    const event = this.events[eventService.formatEventId(eventIntKey)] as LiveEvent;
    const nextValue = !event.liveSuspended;
    const [err] = await to(eventService.suspendEvent([eventIntKey], nextValue, true));
    if (err) return Promise.reject(err);
    this.updateEvent({ intKey: eventIntKey, params: { liveSuspended: nextValue } });
    return Promise.resolve();
  }

  @Action
  async suspendPrematchEvent(intKey: number) {
    const key = eventService.formatEventId(intKey);
    const event = this.events[key] as PrematchEvent;
    const nextValue = !event.prematchSuspended;
    const [err] = await to(eventService.suspendEvent([intKey], nextValue, false));
    if (err) return Promise.reject(err);
    this.updateEvent({ intKey, params: { prematchSuspended: nextValue } });
    return Promise.resolve();
  }

  @Action
  async suspendSelected() {
    const event = this.selectedEvents[0];
    const req = isPrematch(event) ? this.suspendSelectedPrematch : this.suspendSelectedLive;
    const [err] = await to(req());
    if (err) return Promise.reject(err);
    this.clearSelectedEvents();
    return Promise.resolve();
  }

  @Action
  async suspendEvent(eventId: number) {
    const key = eventService.formatEventId(eventId);
    const event = this.events[key];
    const suspendRequest = isPrematch(event) ? this.suspendPrematchEvent : this.suspendLiveEvent;
    const [err] = await to(suspendRequest(eventId));
    if (err) return Promise.reject(err);
    return Promise.resolve();
  }

  @Action
  async suspendMarket(payload: {
    eventId: number;
    marketId: number;
    nextValue: boolean;
    isLive: boolean;
  }) {
    const { eventId, marketId, nextValue, isLive } = payload;
    const [err] = await to(eventService.suspendMarket(eventId, marketId, nextValue, isLive));
    if (err) return Promise.reject(err);
    this.updateBaseMarket({ eventId, marketId, params: { is_suspended: nextValue } });
  }

  @Action
  async suspendOdd(payload: {
    eventId: number;
    oddId: number;
    nextValue: boolean;
    isLive: boolean;
  }) {
    const { eventId, oddId, nextValue, isLive } = payload;
    const [err] = await to(eventService.suspendOdd(oddId, eventId, nextValue, isLive));
    if (err) return Promise.reject(err);
    this.updateBaseMarketSuspend({ eventId, oddId, value: nextValue });
  }

  @Action
  async setOddMaxBet({
    oddId,
    eventIntKey,
    value,
  }: {
    oddId: number;
    eventIntKey: number;
    value: number;
  }) {
    const [err] = await to(eventService.setOddPrematchMaxBet(oddId, eventIntKey, value));
    if (err) return Promise.reject(err);
    MarketStore.updateOdd({ id: oddId, params: { prematch_max_amount: value } });
    return Promise.resolve();
  }

  @Action
  async setOddLiveMaxAmountSc({
    oddIntKey,
    eventIntKey,
    value,
  }: {
    oddIntKey: number;
    eventIntKey: number;
    value: number | null;
  }) {
    const [err] = await to(eventService.setOddLiveMaxAmountSc({ oddIntKey, eventIntKey, value }));
    if (err) return Promise.reject(err);
    MarketStore.updateOdd({
      id: oddIntKey,
      params: { live_max_amount_sc: value },
    });
    return Promise.resolve();
  }

  @Action
  async setOddLiveMaxAmountWeb({
    oddIntKey,
    eventIntKey,
    value,
  }: {
    oddIntKey: number;
    eventIntKey: number;
    value: number | null;
  }) {
    const [err] = await to(eventService.setOddLiveMaxAmountWeb({ oddIntKey, eventIntKey, value }));
    if (err) return Promise.reject(err);
    MarketStore.updateOdd({
      id: oddIntKey,
      params: { live_max_amount_web: value },
    });
    return Promise.resolve();
  }

  @Action
  async setMarketMaxBet(payload: { eventId: number; marketId: number; value: number }) {
    const { eventId, marketId, value } = payload;
    const request = eventService.setMarketPrematchMaxBet;
    const [err] = await to(request(eventId, marketId, value));
    if (err) return Promise.reject(err);
    MarketStore.updateMarket({ id: marketId, params: { prematch_max_amount: value } });
    return Promise.resolve();
  }

  @Action
  async setMarketLiveMaxBetSc(payload: { eventIntKey: number; marketId: number; value: number }) {
    const { eventIntKey, marketId, value } = payload;
    const [err] = await to(eventService.setMarketLiveMaxBetSc(eventIntKey, marketId, value));
    if (err) return Promise.reject(err);
    MarketStore.updateMarket({ id: marketId, params: { live_max_amount_sc: value } });
    return Promise.resolve();
  }

  @Action
  async setMarketLiveMaxBetWeb(payload: { eventIntKey: number; marketId: number; value: number }) {
    const { eventIntKey, marketId, value } = payload;
    const [err] = await to(eventService.setMarketLiveMaxBetWeb(eventIntKey, marketId, value));
    if (err) return Promise.reject(err);
    MarketStore.updateMarket({ id: marketId, params: { live_max_amount_web: value } });
    return Promise.resolve();
  }

  @Action
  async setPrematchMaxAmount(payload: { newValue: number; eventId: number }) {
    const { eventId, newValue } = payload;
    const [err] = await to(eventService.setPrematchMaxAmount({ eventId, value: newValue }));
    if (err) return Promise.reject(err);
    this.setActiveEventMaxBet(newValue);
    Promise.resolve();
  }

  @Action
  async setLiveMaxAmountWeb({ value, eventIntKey }: { value: number; eventIntKey: number }) {
    const [err] = await to(eventService.setLiveMaxAmountWeb({ eventIntKey, value }));
    if (err) return Promise.reject(err);
    this.updateEvent({ intKey: eventIntKey, params: { liveMaxAmountWeb: value } });
    this.setActiveLiveMaxAmountWeb(value);
    return Promise.resolve();
  }

  @Action
  async setLiveMaxAmountSc({ value, eventIntKey }: { value: number; eventIntKey: number }) {
    const [err] = await to(eventService.setLiveMaxAmountSc({ eventIntKey, value }));
    if (err) return Promise.reject(err);
    this.updateEvent({ intKey: eventIntKey, params: { liveMaxAmountSc: value } });
    this.updateactiveLiveMaxAmountSc(value);

    return Promise.resolve();
  }

  @Action
  async toggleLiveSettlement(intKey: number) {
    const event = this.events[eventService.formatEventId(intKey)];
    if (!event || !isLive(event)) return;
    const nextValue = !event.settlementLiveStop;
    const [err] = await to(eventService.toggleLiveSettlement(intKey, nextValue));
    if (err) return;
    this.updateEvent({
      intKey,
      params: { settlementLiveStop: nextValue },
    });
  }

  @Action
  onEventCodeChange(data: CodeMessageData) {
    this.updateEvent({ intKey: data.i_k, params: { landbaseCode: data.c } });
  }

  @Action
  onEventTimeChange(data: EventStartDateUpdated) {
    this.updateEvent({ intKey: data.i_k, params: { start: data.sta } });
  }

  @Action
  onNumOfProdOddChange(data: NumberOfProdOddMessage) {
    this.updateEvent({
      intKey: data.e_id,
      params: { numOfProdOddPrematch: data.o_p, numOfProdOddMng: data.o_m },
    });
  }

  @Action
  onTemplateOddsChange(data: MarketTemplateChangedMessage) {
    this.updateEvent({
      intKey: data.e_id,
      params: { templateOddsNum: data.n_o },
    });
  }

  @Action
  onMarketTemplateChange(data: MarketTemplateChangedMessage) {
    this.updateEvent({
      intKey: data.e_id,
      params: {
        prematchApprovalTemplateId: data.t_i,
        templateOddsNum: data.n_o,
      },
    });
  }

  @Action
  private async suspendSelectedLive() {
    const event = this.selectedEvents[0] as LiveEvent;
    const nextValue = !event.liveSuspended;
    const eventIds = this.selectedEvents.map(event => event.intKey);
    const [err] = await to(eventService.suspendEvent(eventIds, nextValue, true));
    if (err) return Promise.reject(err);
    eventIds.forEach(eventId => {
      this.updateEvent({ intKey: eventId, params: { liveSuspended: nextValue } });
    });
    return Promise.resolve();
  }

  @Action
  private async suspendSelectedPrematch() {
    const event = this.selectedEvents[0] as PrematchEvent;
    const nextValue = !event.prematchSuspended;
    const eventIds = this.selectedEvents.map(event => event.intKey);
    const [err] = await to(eventService.suspendEvent(eventIds, nextValue, false));
    if (err) return Promise.reject(err);
    eventIds.forEach(intKey => {
      this.updateEvent({ intKey, params: { prematchSuspended: nextValue } });
    });
    return Promise.resolve();
  }

  @Action
  onEgalOddsUpdate(message: EgalOddsUpdateMessage) {
    const eventAccessor = eventService.formatEventId(message.event_int_key);
    if (!this.events[eventAccessor]) return;
    const mappedOdds = eventService.mapEgalOddsBaseMarketWSMessage(message.odds);
    this.changeBaseMarketEgalOdd({
      eventAccessor: eventAccessor,
      marketId: message.market_id,
      newOdds: mappedOdds,
    });
  }

  @Action
  onEventEnd(message: EventEndMessage) {
    this.eventFinished(message.e_i_k);
    SettlementStore.eventEnd(message.e_i_k);
    this.updateEvent({
      intKey: message.e_i_k,
      params: { liveStatus: message.e_l_s as any },
    });
  }

  @Action
  async blockBettingPlace(payload: BlockBettingPlace) {
    const [err] = await to(eventService.blockBettingPlace(payload));
    if (err) return Promise.reject(err);
  }

  @Action
  async unblockBettingPlace(payload: UnblockBettingPlace) {
    const [err] = await to(eventService.unblockBettingPlace(payload));
    if (err) return Promise.reject(err);
  }

  @Action
  async blockUserOnEvent(payload: any) {
    const [err] = await to<any>(eventService.blockUserOnEvent(payload));
    if (err) return Promise.reject(err);
  }

  @Action
  async unblockUserOnEvent(payload: any) {
    const [err] = await to<any>(eventService.unblockUserOnEvent(payload));
    if (err) return Promise.reject(err);
  }

  @Action
  async sendEventToSettlement(intKey: number) {
    const [err] = await to<any>(eventService.sendEventToSettlement(intKey));
    if (err) return Promise.reject(err);
  }

  @Action
  handleRemoveEventFromLive(wsData: any) {
    this.removeEventFromLive(wsData.i_k);
  }

  @Action
  async changeLiveProvider(payload: { intKey: number; provider: { [key: string]: string } }) {
    const [err] = await to<any>(eventService.changeProvider(payload));
    if (err) return Promise.reject(err);
    this.updateEvent({
      intKey: payload.intKey,
      params: { liveProvider: payload.provider.live_provider },
    });
  }

  @Action
  removePrematchEvent(wsData: number[]) {
    this.removeEventFromPrematch(wsData);
  }

  @Action
  updateEventTech(wsData: { i_k: number; t: string; ip: any; hip: any }) {
    if (wsData.ip) {
      this.clearInputParams();
      this.setSignleEventInputParams(wsData.ip);
    }
    this.updateEventWithoutRender({
      intKey: wsData.i_k,
      params: { hasInputParams: wsData.hip },
    });
    this.updateEventWithoutRender({
      intKey: wsData.i_k,
      params: { tech: wsData.t },
    });
  }

  @Action
  hasGoesThrough(wsData: { i_k: number; gt: boolean }) {
    this.updateEvent({
      intKey: wsData.i_k,
      params: { goesThrough: wsData.gt },
    });
  }

  @Action
  updateCompetitorWs(wsData: any[]) {
    const mappedCompetitors = eventService.mapCompetitorsWs(wsData);
    mappedCompetitors.forEach((competitor: any) =>
      this.updateEvent({ intKey: competitor.intKey, params: competitor })
    );
  }

  @Action
  updateCompetitionWs(wsData: any[]) {
    const mappedCompetitions = eventService.mapCompetitionsWs(wsData);
    mappedCompetitions.forEach((competition: any) =>
      this.updateEvent({ intKey: competition.intKey, params: competition })
    );
  }
}

export const EventStore = getModule(Event);

if (process.env.NODE_ENV === 'development') {
  // @ts-ignore
  window.EventStore = EventStore; // this can be used to call actions and mutations from dev console
}
