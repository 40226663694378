import { api } from '@/lib/api';
import { DualEventApiType } from '.';
import {
  BulkChangeTemplatesPaylod,
  EventCreatedResponse,
  EventRequestSource,
  EventState,
  EventTemplatesPayload,
  GetSingleLiveEventResponse,
  NewEventPayload,
  NewOperatorNotePayload,
  GenerateTennisHandicapsPayload,
  MarketsByTemplateResponse,
  BlockBettingPlace,
  UnblockBettingPlace,
} from './event.types';

const updateTemplates = (intKey: number, payload: EventTemplatesPayload) => {
  return api.put(`odds/events/${intKey}/templates/`, payload);
};

const createEvent = (newEvent: NewEventPayload) => {
  return api.post(`odds/events/`, newEvent) as Promise<EventCreatedResponse>;
};

const generateOddsByInputParams = (eventIntKey: number, payload: object) => {
  return api.put(`odds/events/${eventIntKey}/generate-odds/`, payload);
};

const getTennisHandicaps = (eventIntKey: number, payload: GenerateTennisHandicapsPayload) => {
  return api.put(`/odds/events/${eventIntKey}/tennis-handicaps/`, payload);
};

const generateTennisOdds = (eventIntKey: number, payload: GenerateTennisHandicapsPayload) => {
  return api.put(`odds/events/${eventIntKey}/generate-tennis-odds/`, payload);
};

const acceptGeneratedOdds = (eventIntKey: number, payload: object) => {
  return api.put(`odds/events/${eventIntKey}/confirm-odds/`, payload);
};

const setAutoOddChange = (
  eventIntKey: number,
  nextValue: boolean,
  eventType: DualEventApiType,
  source: EventRequestSource
) => {
  return api.put(`/odds/events/${eventIntKey}/automatic-odds-change/`, {
    automatic_odds_change: nextValue,
    event_type: eventType,
    source,
  });
};

const changeState = (
  events: number[],
  nextState: EventState,
  source: string,
  sourceReq: string | null
): Promise<number[] | undefined> => {
  return api.put('/odds/events/change-state/', {
    event_ids: events,
    event_type: source,
    state: nextState,
    src: sourceReq ? sourceReq : undefined,
  });
};

const bulkChangeTemplates = (payload: BulkChangeTemplatesPaylod) => {
  return api.put('/odds/events/bulk-templates/', payload);
};

const bulkChangeLiveTemplates = (payload: BulkChangeTemplatesPaylod) => {
  return api.put('/odds/events/live-bulk-templates/', payload);
};

const bulkChangePrematchTemplates = (payload: BulkChangeTemplatesPaylod) => {
  return api.put('/odds/events/prematch-bulk-templates/', payload);
};

const updateNote = (payload: { note: string; int_key: number; event_type: DualEventApiType }) => {
  return api.put(`odds/events/note/`, payload);
};

const addOperatorNote = (payload: NewOperatorNotePayload) => {
  return api.post('/odds/events/operator-note/', payload);
};

const updateStart = (
  intKey: number,
  start: string,
  source: DualEventApiType,
  stage: string,
  settle: boolean | undefined = undefined
) => {
  return api.put(`/odds/events/${intKey}/start-date/`, { start, source, stage, settle });
};

const updateOdd = (
  oddId: number,
  value: number,
  source: EventRequestSource,
  eventType: DualEventApiType,
  eventId: number
) => {
  const multipliedOdd = value * 100;
  return api.put(`/odds/odds/${oddId}/prod-odd/`, {
    event_int_key: eventId,
    prematch_prod_odd: multipliedOdd,
    source,
    event_type: eventType,
    event_id: eventId,
  });
};

const getEvents = (params: any, urlParams: any) => {
  return api.post('odds/events/offer/', params, { params: urlParams });
};

const getSinglePrematchEvent = (eventIntKey: number) => {
  return api.get(`odds/events/${eventIntKey}/get-single-event-prematch/`, {
    cache: {
      maxAge: 0,
    },
  });
};

const getSingleLiveEvent = (eventIntKey: number): Promise<GetSingleLiveEventResponse> => {
  return api.get(`odds/events/${eventIntKey}/get-single-event-live/`, {
    cache: {
      maxAge: 0,
    },
  });
};

const getOutcomesByTemplate = (eventIntKey: number): Promise<MarketsByTemplateResponse> => {
  return api.get(`/odds/events/${eventIntKey}/get-outcomes-by-templates/`, {
    cache: {
      maxAge: 0,
    },
  });
};

const getPrematchEvents = (params: any, urlParams: any) => {
  return api.post('/odds/events/prematch/', params, { params: urlParams });
};

const getLiveEvents = (params: any, urlParams: any) => {
  return api.post('/odds/events/live/', params, { params: urlParams });
};

const suspendLiveEvent = (eventIds: number[], nextValue: boolean) => {
  return api.put(`/odds/events/live-suspend/`, { is_suspended: nextValue, events: eventIds });
};

const suspendPrematchEvent = (eventIds: number[], nextValue: boolean) => {
  return api.put(`/odds/events/prematch-suspend/`, { is_suspended: nextValue, events: eventIds });
};

const suspendMarket = (eventId: number, marketId: number, nextValue: boolean, source: string) => {
  return api.put(`/odds/events/market-event-suspend/`, {
    int_key: eventId,
    market_id: marketId,
    is_suspended: nextValue,
    suspend_type: source,
  });
};

const suspendOdd = (oddId: number, eventId: number, value: boolean, source: string) => {
  return api.put(`/odds/odds/${oddId}/suspend/`, {
    is_suspended: value,
    event_id: eventId,
    suspend_type: source,
  });
};

const setOddPrematchMaxBet = (oddId: number, eventIntKey: number, value: number) => {
  return api.put(`/odds/odds/prematch-max-amount/`, {
    int_key: oddId,
    prematch_max_amount: value,
    event_int_key: eventIntKey,
  });
};

const setOddLiveMaxBetWeb = (oddIntKey: number, eventIntKey: number, value: number | null) => {
  return api.put(`/odds/odds/${oddIntKey}/live-max-amount-web/`, {
    live_max_amount_web: value,
    event_int_key: eventIntKey,
  });
};

const setOddLiveMaxBetSc = (oddIntKey: number, eventIntKey: number, value: number | null) => {
  return api.put(`/odds/odds/${oddIntKey}/live-max-amount-sc/`, {
    live_max_amount_sc: value,
    event_int_key: eventIntKey,
  });
};

const setMarketLiveMaxBetSc = (eventIntKey: number, marketId: number, value: number) => {
  return api.put(`/odds/events/${eventIntKey}/live-market-max-amount-sc/`, {
    market_id: marketId,
    live_max_amount_sc: value,
  });
};

const setMarketLiveMaxBetWeb = (eventIntKey: number, marketId: number, value: number) => {
  return api.put(`/odds/events/${eventIntKey}/live-market-max-amount-web/`, {
    market_id: marketId,
    live_max_amount_web: value,
  });
};

const setMarketPrematchMaxBet = (eventId: number, marketId: number, value: number) => {
  return api.put(`/odds/events/prematch-market-max-amount/`, {
    int_key: eventId,
    market_id: marketId,
    prematch_max_amount: value,
  });
};

const updateLiveMaxAmountWeb = ({
  eventIntKey,
  value,
}: {
  eventIntKey: number;
  value: number | null;
}) => {
  return api.put(`/odds/events/live-max-amount-web/`, {
    live_max_amount_web: value,
    int_key: eventIntKey,
  });
};

const updateLiveMaxAmountSc = ({
  eventIntKey,
  value,
}: {
  eventIntKey: number;
  value: number | null;
}) => {
  return api.put(`/odds/events/live-max-amount-sc/`, {
    live_max_amount_sc: value,
    int_key: eventIntKey,
  });
};

const updatePrematchMaxAmount = ({ eventId, value }: { eventId: number; value: number }) => {
  return api.put(`/odds/events/prematch-max-amount/`, {
    prematch_max_amount: value,
    int_key: eventId,
  });
};

const toggleLiveSettlement = (eventIntKey: number, stopSettlement: boolean) => {
  const turnOn = () => api.put(`/odds/events/${eventIntKey}/start-live-settlement/`);
  const turnOff = () => api.put(`/odds/events/${eventIntKey}/stop-live-settlement/`);
  return stopSettlement ? turnOff() : turnOn();
};

const blockUserOnEvent = ({ event_int_key, user_id, username }: any) => {
  return api.put(`/odds/events/${event_int_key}/block-user/`, {
    user_id,
    username,
  });
};

const unblockUserOnEvent = ({ event_int_key, user_id }: any) => {
  return api.put(`/odds/events/${event_int_key}/unblock-user/`, {
    user_id,
  });
};

const blockBettingPlace = ({ betting_place_id, address, event_int_key }: BlockBettingPlace) => {
  return api.put(`/odds/events/${event_int_key}/block-betting-place/`, {
    betting_place_id,
    betting_place_name: address,
  });
};

const unblockBettingPlace = ({ event_int_key, betting_place_id }: UnblockBettingPlace) => {
  return api.put(`/odds/events/${event_int_key}/unblock-betting-place/`, {
    betting_place_id,
  });
};

const sendEventToSettlement = (intKey: number) => {
  return api.put(`/odds/events/${intKey}/send-to-settlement/`);
};

const changeProvider = (payload: { intKey: number; provider: { [key: string]: string } }) => {
  return api.put(`/odds/events/${payload.intKey}/`, payload.provider);
};

export const eventRepo = {
  getEvents,
  updateTemplates,
  createEvent,
  generateOddsByInputParams,
  generateTennisOdds,
  acceptGeneratedOdds,
  setAutoOddChange,
  changeState,
  bulkChangeTemplates,
  updateNote,
  addOperatorNote,
  updateStart,
  updateOdd,
  getPrematchEvents,
  getLiveEvents,
  suspendLiveEvent,
  suspendPrematchEvent,
  suspendMarket,
  suspendOdd,
  setOddPrematchMaxBet,
  setOddLiveMaxBetWeb,
  setOddLiveMaxBetSc,
  setMarketLiveMaxBetSc,
  setMarketLiveMaxBetWeb,
  setMarketPrematchMaxBet,
  updatePrematchMaxAmount,
  updateLiveMaxAmountSc,
  updateLiveMaxAmountWeb,
  toggleLiveSettlement,
  bulkChangeLiveTemplates,
  bulkChangePrematchTemplates,
  getSingleLiveEvent,
  getSinglePrematchEvent,
  getTennisHandicaps,
  getOutcomesByTemplate,
  blockBettingPlace,
  unblockBettingPlace,
  blockUserOnEvent,
  unblockUserOnEvent,
  sendEventToSettlement,
  changeProvider,
};
