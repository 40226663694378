import Vue from 'vue';
import store from '@/store';
import { to } from 'await-to-js';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';

import { eventService } from '@/modules/event/event.service';
import { AuthStore } from '@/lib/internal';
import { settlementService } from './settlement.service';
import {
  SettleOddsPayload,
  SettlementEvent,
  ActiveOutcomes,
  ActiveOutcomesMap,
  OddStatus,
  RawSettlementEvent,
  SettlementEventsMap,
  MillionTicketsCount,
  OddTypes,
  BackofficeUser,
} from './settlement.types';
import { CommonStore } from '../common';
import removeItemFromArray from '@/lib/removeItemFromArray';
import { mapCurrentResult, mapCurrentPhase, mapPeriodResult } from '@/lib/mapStructData';
import { playerService } from '../player/player.service';
import { SettlementEventWs } from './addToSettlement.entity';
import { EVENT_STATE } from '@/modules/event/event.constants';

const initialState = () => ({
  totalSettlement: 0,
  settlementEvents: {},
  activeId: null,
  activeEvent: {} as SettlementEvent,
  activeOutcomes: {},
  totalActiveOutcomes: 0,
  settleResults: [],
  resultHistory: [],
  playerResultHistory: {},
  matchDataChanges: {},
  missedDualEvents: [],
  missedPlayerEvents: [],
  settleOddsPayload: {
    odds: { LOST: [], WON: [], ODD_ONE: [] },
  },
  pickedPlayerEventIds: [],
  lastUpdatedSettlementEvent: '',
  eventPublished: '',
  tempPlayerValues: {},
  tempPlayerIntKey: 0,
  settlementEventsWithCtrlProviders: [],
});

const resetSettlementMaps = {
  stopwatchMap: 1,
  periodMap: 'NS',
  resultsMap: { away: 0, home: 0 },
  tempChangesMap: { period: 'NS' },
};

const arrayToObject = (array: RawSettlementEvent[]) => {
  return array.reduce((obj: any, item: RawSettlementEvent) => {
    const key = eventService.formatEventId(item.intKey);
    if (item.playerName) {
      const parsedEvent = {
        ...item,
        id: item.intKey,
      };
      const settlementHistory = mapCurrentPhase(item.settlementHistory?.fieldsMap);
      parsedEvent.settlementHistory = settlementHistory;
      obj[key] = parsedEvent;
      return obj;
    }

    const parsedEvent = {
      ...item,
      id: item.intKey,
      isLiveBooking: !!item.liveStatus.length,
      isSettleDisabled: true,
      // switch which will return a correct boolean for gotten key
      name: `${item.home} - ${item.away}`,
    };
    try {
      const periodResult = mapPeriodResult(item.periodResult?.fieldsMap);
      const currentPhase = mapCurrentPhase(item.currentPhase?.fieldsMap);
      const currentResult = mapCurrentResult(item.currentResult?.fieldsMap);

      // currentPhase and periodResult must be checked and then mapped
      //@ts-ignore
      parsedEvent.currentPhaseJson = currentPhase;
      //@ts-ignore
      parsedEvent.periodResultJson = periodResult;
      //@ts-ignore
      parsedEvent.currentResultJson = currentResult;
      parsedEvent.periodResult = undefined;
      parsedEvent.currentPhase = undefined;
      parsedEvent.currentResult = undefined;
    } catch (e) {
      console.error(e);
    }

    obj[key] = parsedEvent;
    return obj;
  }, {});
};

@Module({ dynamic: true, store, name: 'settlement', namespaced: true })
class Settlement extends VuexModule {
  totalSettlement = initialState().totalSettlement;
  settlementEvents: SettlementEventsMap = initialState().settlementEvents;
  activeId: string | number | null = initialState().activeId;
  activeEvent: SettlementEvent = initialState().activeEvent;
  activeOutcomes: ActiveOutcomesMap = initialState().activeOutcomes;
  totalActiveOutcomes: number = initialState().totalActiveOutcomes;
  settleResults: Array<any> = initialState().settleResults;
  resultHistory: any = initialState().resultHistory;
  playerResultHistory: any = initialState().playerResultHistory;
  matchDataChanges: any = initialState().matchDataChanges;
  missedDualEvents: any = initialState().missedDualEvents;
  missedPlayerEvents: any = initialState().missedPlayerEvents;
  tempPlayerValues: any = initialState().tempPlayerValues;
  settleOddsPayload: SettleOddsPayload = initialState().settleOddsPayload;
  pickedPlayerEventIds: number[] = initialState().pickedPlayerEventIds;
  lastUpdatedSettlementEvent: string = initialState().lastUpdatedSettlementEvent;
  tempPlayerIntKey: number = initialState().tempPlayerIntKey;
  settlementEventsWithCtrlProviders: any = initialState().settlementEventsWithCtrlProviders;
  eventPublished: string = initialState().eventPublished;
  millionTicketsCount = 0;
  stateChanged = 0;
  backofficeUsers: BackofficeUser[] = [];
  addAssignedEventToUserIntKey = '';

  get resultEntries() {
    return this.resultHistory;
  }

  get forSettlement() {
    return orderBy(Object.values(this.settlementEvents), ['sportId', 'start'], ['asc', 'asc']);
  }

  get usersForAssigneDropdown() {
    return settlementService.usersForAssigneDropdown(this.backofficeUsers);
  }

  get eventMatchDataChanges() {
    return (eventId: string) => this.matchDataChanges[eventId];
  }

  get isSettleOddsPayloadValid() {
    return (
      this.settleOddsPayload.odds.LOST.length ||
      this.settleOddsPayload.odds.WON.length ||
      this.settleOddsPayload.odds.ODD_ONE.length
    );
  }

  @Mutation
  removeEvent(eventId: number | string) {
    Vue.delete(this.settlementEvents, eventService.formatEventId(eventId));
  }

  @Mutation
  setOddsPayload({ type, odds }: { type: OddTypes; odds: number[] }) {
    this.settleOddsPayload.odds[type] = [...this.settleOddsPayload.odds[type], ...odds];
  }

  @Mutation
  addOddForSettlement({ oddId, type }: { oddId: number; type: 'LOST' | 'WON' | 'ODD_ONE' }) {
    this.settleOddsPayload.odds[type].push(oddId);
  }

  @Mutation
  removeOddForSettlement({ oddId, type }: { oddId: number; type: 'LOST' | 'WON' | 'ODD_ONE' }) {
    this.settleOddsPayload.odds[type] = removeItemFromArray(
      this.settleOddsPayload.odds[type],
      oddId
    );
  }

  @Mutation
  resetOddSettlement({ oddId }: { oddId: number }) {
    this.settleOddsPayload.odds.WON = removeItemFromArray(this.settleOddsPayload.odds.WON, oddId);
    this.settleOddsPayload.odds.LOST = removeItemFromArray(this.settleOddsPayload.odds.LOST, oddId);
    this.settleOddsPayload.odds.ODD_ONE = removeItemFromArray(
      this.settleOddsPayload.odds.ODD_ONE,
      oddId
    );
    this.activeOutcomes[oddId].odd_note = null;
  }

  @Mutation
  clearSettleOddsPayload() {
    this.settleOddsPayload = initialState().settleOddsPayload;
  }

  @Mutation
  setResultHistory(resultHistory: any) {
    this.resultHistory = resultHistory.settlement_history;
  }

  @Mutation
  clearResultHistory() {
    this.resultHistory = initialState().resultHistory;
  }

  @Mutation
  setForSettlement(events: SettlementEvent[]) {
    this.settlementEvents = {
      ...arrayToObject(events),
    };
  }

  @Mutation
  setForEventsWithCtrProviders(events: SettlementEvent[]) {
    this.settlementEventsWithCtrlProviders = orderBy(
      events.filter((event: any) => event.ctrlProviders),
      ['sportId', 'start'],
      ['asc', 'asc']
    );
  }

  @Mutation
  setActiveOutcomes(result: ActiveOutcomes[]) {
    const mappedOutcomes = {};
    settlementService.mapActiveOutcomes(result, mappedOutcomes);
    this.activeOutcomes = Object.assign({}, this.activeOutcomes, mappedOutcomes);
    this.totalActiveOutcomes = result.length;
  }

  @Mutation
  clearActiveOutcomes() {
    this.activeOutcomes = initialState().activeOutcomes;
    this.totalActiveOutcomes = initialState().totalActiveOutcomes;
  }

  @Mutation
  clearOnlyOutcomes() {
    this.activeOutcomes = initialState().activeOutcomes;
  }

  @Mutation
  resetStore() {
    const initial = initialState() as any;
    Object.keys(initial).forEach((key: any) => {
      if (key === 'missedDualEvents' || key === 'missedPlayerEvents') return;
      this[key as keyof this] = initial[key];
    });
  }

  @Mutation
  clearSettleResults() {
    this.settleResults = initialState().settleResults;
  }

  @Mutation
  setSettlementResult({ period, result }: { period: string; result: any }) {
    Vue.set(this.resultHistory, period, result);
  }

  @Mutation
  removeResult(period: string) {
    Vue.delete(this.resultHistory, period);
  }

  @Mutation
  mapDataChanges({ eventId, matchDataChanges }: { eventId: string; matchDataChanges: any }) {
    Vue.set(this.matchDataChanges, eventId, matchDataChanges);
  }

  @Mutation
  editDataChanges({ eventId, key, newValue }: { eventId: string; key: string; newValue: any }) {
    this.matchDataChanges[eventId][key] = newValue;
  }

  @Mutation
  clearDataChanges() {
    this.matchDataChanges = initialState().matchDataChanges;
  }

  @Mutation
  setActiveEvent(eventId: string) {
    this.activeId = eventId;
    this.activeEvent = this.settlementEvents[eventService.formatEventId(eventId)];
  }

  @Mutation
  clearActiveEvent() {
    this.activeId = null;
  }

  @Mutation
  editEvent({
    eventId,
    key,
    newValue,
  }: {
    eventId: string | number;
    key: keyof SettlementEvent;
    newValue: any;
  }) {
    const event = this.settlementEvents[eventService.formatEventId(eventId)];
    if (!event) return;
    (event as any)[key] = newValue;
    this.lastUpdatedSettlementEvent = `${eventId}_${Date.now()}`;
  }

  @Mutation
  updateEventWithoutRender({
    eventId,
    key,
    newValue,
  }: {
    eventId: string | number;
    key: keyof SettlementEvent;
    newValue: any;
  }) {
    const event = this.settlementEvents[eventService.formatEventId(eventId)];
    if (!event) return;
    (event as any)[key] = newValue;
  }

  @Mutation
  editPlayerEvent({
    eventId,
    key,
    newValue,
  }: {
    eventId: string | number;
    key: keyof SettlementEvent;
    newValue: any;
  }) {
    const event = this.settlementEvents[eventService.formatEventId(eventId)];
    (event as any)[key] = newValue;
    this.lastUpdatedSettlementEvent = `${eventId}_${Date.now()}`;
  }

  @Mutation
  enableDisablePlayerEventForSettle({
    eventId,
    key,
    newValue,
  }: {
    eventId: string | number;
    key: keyof SettlementEvent;
    newValue: any;
  }) {
    const event = this.settlementEvents[eventService.formatEventId(eventId)];
    (event as any)[key] = newValue;
  }

  @Mutation
  setActiveOddToOne() {
    this.settleOddsPayload.odds.ODD_ONE.forEach((oddId: number) => {
      this.activeOutcomes[oddId].odd_value = 1;
      this.activeOutcomes[oddId].odd_status = 'C';
    });
  }

  @Mutation
  setActiveOddStatus({ oddId, nextStatus }: { oddId: string; nextStatus: OddStatus }) {
    this.activeOutcomes[oddId].odd_status = nextStatus;
  }

  @Action
  eventEnd(intKey: number) {
    if (this.settlementEvents[eventService.formatEventId(intKey)]) {
      this.editEvent({ eventId: intKey, key: 'state', newValue: EVENT_STATE.COMPLETED });
    }
  }

  @Mutation
  addMissedDualEvents(missedEvents: any) {
    missedEvents.forEach((eventId: number) => {
      const hasMissedEvent = this.missedDualEvents.some((event: any) => event.intKey === eventId);
      const intKey = eventService.formatEventId(eventId);
      if (this.settlementEvents[intKey] && !hasMissedEvent) {
        this.missedDualEvents.push(this.settlementEvents[intKey]);
      }
    });
  }

  @Mutation
  addMissedPlayerEvents(missedEvents: any) {
    missedEvents.forEach((eventId: number) => {
      const hasMissedEvent = this.missedDualEvents.some((event: any) => event.intKey === eventId);
      const intKey = eventService.formatEventId(eventId);
      if (this.settlementEvents[intKey] && !hasMissedEvent) {
        this.missedPlayerEvents.push(this.settlementEvents[intKey]);
      }
    });
  }

  @Mutation
  removeMissedDualEvent(eventId: string) {
    this.missedDualEvents = this.missedDualEvents.filter((event: any) => event.intKey !== eventId);
  }

  @Mutation
  removeMissedPlayerEvent(eventId: string) {
    this.missedPlayerEvents = this.missedPlayerEvents.filter(
      (event: any) => event.intKey !== eventId
    );
  }

  @Mutation
  setMillionTicketsCount({ million_tickets_count }: MillionTicketsCount) {
    this.millionTicketsCount = million_tickets_count;
  }

  @Mutation
  setPickedPlayerEventIds(pickedPlayerEventIds: number[]) {
    this.pickedPlayerEventIds = pickedPlayerEventIds;
  }

  @Mutation
  setStateChanged() {
    this.stateChanged = +new Date();
  }

  @Mutation
  clearSettlementEventData(eventId: string) {
    const store = localStorage.getItem('stopwatchStore');
    const storeJson = JSON.parse(store as string);
    if (!store) return;
    const intKey = eventService.formatEventId(eventId);
    for (const map in storeJson) {
      if (storeJson[map][intKey]) {
        //@ts-ignore
        storeJson[map][intKey] = resetSettlementMaps[map];
      }
      localStorage.setItem('stopwatchStore', JSON.stringify(storeJson));
      this.lastUpdatedSettlementEvent = `${eventId}_${Date.now()}`;
    }
  }

  @Mutation
  resetResultLiveMatch({ eventIntKey }: { eventIntKey: string; sportCode: string }) {
    const accessor = eventService.formatEventId(eventIntKey);
    const event = this.settlementEvents[accessor];
    if (!event) return;
    const allFields = Object.keys(event.currentResultJson);
    allFields.forEach(field => Vue.set(event.currentResultJson, field, [0, 0]));
  }

  @Mutation
  updateOrAddEvent(event: any) {
    const eventAccesor = eventService.formatEventId(event.intKey);
    if (this.settlementEvents[eventAccesor]) {
      const settlementEvent = { ...this.settlementEvents[eventAccesor], ...event };
      this.settlementEvents[eventAccesor] = settlementEvent as any;
      this.lastUpdatedSettlementEvent = `${event.intKey}_${Date.now()}`;
      return;
    }
    this.settlementEvents[eventAccesor] = event as any;
    this.lastUpdatedSettlementEvent = `${event.intKey}_${Date.now()}`;
  }

  @Mutation
  addEventWhenPublished(event: any) {
    const eventAccesor = eventService.formatEventId(event.intKey);
    // because file "withGrpcEvents" is included in every events store, and watcher will update data
    // ag grid even if that store is not active, so this is done to prevent that
    // example: event_published ws msg will update both live page and settlement page ag grid visually,
    // because of ag grid's applyTransaction(add: {}) function
    if (isEmpty(this.settlementEvents)) return;
    if (this.settlementEvents[eventAccesor]) return;
    Vue.set(this.settlementEvents, eventAccesor, event);
    this.eventPublished = `${event.intKey}_${Date.now()}`;
  }

  @Mutation
  setPlayerTempValues({
    eventIntKey,
    playerValues,
  }: {
    eventIntKey: number;
    playerValues: { [key: string]: { [key: string]: string } };
  }) {
    this.tempPlayerValues = playerValues;
    this.tempPlayerIntKey = eventIntKey;
  }

  @Mutation
  setBackofficeUsers(users: BackofficeUser[]) {
    this.backofficeUsers = users;
  }

  @Mutation
  updateAssigneId({
    eventId,
    key,
    newValue,
  }: {
    eventId: string | number;
    key: keyof SettlementEvent;
    newValue: any;
  }) {
    const event = this.settlementEvents[eventService.formatEventId(eventId)];
    (event as any)[key] = newValue;
    this.addAssignedEventToUserIntKey = `${eventId}_${Date.now()}`;
    this.lastUpdatedSettlementEvent = `${eventId}_${Date.now()}`;
  }

  @Action
  async togglePublishEvent(payload: {
    events: number[];
    nextState: 'PUBLISHED' | 'HIDDEN';
    eventType: 'PLAYER' | 'DUAL';
    source: 'prematch' | 'live';
    sourceReq: string | null;
  }) {
    const isDual = payload.eventType === 'DUAL';
    const req = isDual ? eventService.changeState : playerService.changeState;
    const [err] = await to<any>(
      //@ts-ignore
      req(payload.events, payload.nextState, isDual ? payload.source : undefined, payload.sourceReq)
    );
    if (err) return Promise.reject(err);
    Promise.resolve();
  }

  @Action
  async pickEvent({ events, assigneeId }: { events: string[]; assigneeId: number | null }) {
    const accessor = eventService.formatEventId(events[0]);
    const eventType = this.settlementEvents[accessor]?.isLiveBooking ? 'live' : 'prematch';
    const [err] = await to<any>(
      settlementService.assignToUser({
        user_id: assigneeId || (AuthStore.userId as number),
        event_ids: events,
        event_type: eventType,
      })
    );
    if (err) return Promise.reject(err);
    if (this.settlementEvents[accessor]) {
      //If missed event is picked check if it exists in table and only then edit assignee
      this.editEvent({
        eventId: events[0],
        key: 'assignee',
        newValue: assigneeId || AuthStore.userId,
      });
    }
    return Promise.resolve();
  }

  @Action
  async verifyEvent(eventId: string) {
    const [err] = await to<any>(settlementService.verifySettlement(eventId));
    if (err) return Promise.reject(err);
    this.editEvent({ eventId, key: 'settled15', newValue: true });
    this.editEvent({ eventId, key: 'settled30', newValue: true });
    this.editEvent({ eventId, key: 'settledM1', newValue: true });
    this.editEvent({ eventId, key: 'isVerified', newValue: true });
    this.editEvent({ eventId, key: 'state', newValue: EVENT_STATE.COMPLETED });
  }

  @Action
  async deselectEvent(eventIds: string[]) {
    const accessor = eventService.formatEventId(eventIds[0]);
    const eventType = this.settlementEvents[accessor]?.isLiveBooking ? 'live' : 'prematch';
    const [err] = await to<any>(settlementService.unassignEvents(eventIds, eventType));
    if (err) return Promise.reject(err);
    eventIds.forEach(eventId => {
      this.editEvent({ eventId, key: 'assignee', newValue: 0 });
    });
    return Promise.resolve();
  }

  @Action
  async autoSettlement({ eventIds }: any) {
    const [err] = await to<any>(settlementService.autoSettlement(eventIds));
    if (err) return Promise.reject(err);
    //When implementing bulk actions put this inside forEach...
    // this.editEvent({ eventId: eventIds[0], key: 'automaticSettlementOnly', newValue: nextValue });
    return Promise.resolve();
  }

  @Action
  async resetOdd({ oddId, eventId }: { oddId: string; eventId: number }) {
    const [err] = await to(settlementService.resetOdd(oddId, eventId));
    if (err) return Promise.reject(err);
    this.setActiveOddStatus({ oddId, nextStatus: OddStatus.UNRESOLVED });
    this.editEvent({ eventId, key: 'isVerified', newValue: false });

    return Promise.resolve();
  }

  @Action
  async settleAll({ eventId, params }: any) {
    await this.getResultHistory(eventId);
    const [err, res] = await to<any>(settlementService.settleAll(eventId, params));
    if (err) return Promise.reject(err);
    return Promise.resolve(res);
  }

  @Action
  async getSettlementEvents(
    streamParams:
      | string
      | {
          date: string;
          setParamFunc: string;
          value: boolean;
        }
      | null = null
  ) {
    const res = await settlementService.getSettlementEvents(streamParams);
    this.setForSettlement(res);
    return Promise.resolve(true);
  }

  @Action
  async getSettlementCtrlProviderEvents(streamParams: string) {
    const res = await settlementService.getSettlementCtrlProviderEvents(streamParams);
    this.setForEventsWithCtrProviders(res);
    return Promise.resolve(true);
  }

  @Action
  async getActiveOutcomes(eventId: string) {
    const [err, res] = await to<any>(settlementService.getActiveOutcomes(eventId));
    if (err) {
      return Promise.reject(err);
    }
    // TODO: change api to work with int_key instead odd_id
    res.forEach((bet: any) => {
      bet.odd_id = bet.int_key;
    });
    this.setActiveOutcomes(res);
    res.forEach((bet: any) => {
      if (bet.odd_status === OddStatus.LOST) {
        this.addOddForSettlement({ oddId: Number(bet.int_key), type: 'LOST' });
      }
      if (bet.odd_status === OddStatus.WON) {
        this.addOddForSettlement({ oddId: Number(bet.int_key), type: 'WON' });
      }
      if (bet.odd_status === OddStatus.CANCELLED) {
        this.addOddForSettlement({ oddId: Number(bet.int_key), type: 'ODD_ONE' });
      }
    });
    return Promise.resolve(res.length);
  }

  @Action
  async getActiveOutcomesLivePlayer(eventId: number) {
    const [err, res] = await to<any>(settlementService.getActiveOutcomesLivePlayer(eventId));
    if (err) {
      return Promise.reject(err);
    }
    res.forEach((bet: any) => {
      bet.odd_id = bet.int_key;
    });
    this.setActiveOutcomes(res);
    res.forEach((bet: any) => {
      if (bet.odd_status === OddStatus.LOST) {
        this.addOddForSettlement({ oddId: Number(bet.int_key), type: 'LOST' });
      }
      if (bet.odd_status === OddStatus.WON) {
        this.addOddForSettlement({ oddId: Number(bet.int_key), type: 'WON' });
      }
      if (bet.odd_status === OddStatus.CANCELLED) {
        this.addOddForSettlement({ oddId: Number(bet.int_key), type: 'ODD_ONE' });
      }
    });
    return Promise.resolve(res.length);
  }

  @Action
  async setEventTerminated(eventId: string) {
    const [err] = await to(settlementService.setEventTerminated(eventId));
    if (err) return Promise.reject(err);
    this.editEvent({ eventId: eventId, key: 'stage', newValue: 'TERMINATED' });
    return Promise.resolve();
  }

  @Action
  async setPostponed(data: { eventId: string; settlementNote: string }) {
    const [err] = await to(settlementService.setPostponed(data.eventId));
    if (err) return Promise.reject(err);
    this.editEvent({ eventId: data.eventId, key: 'settlementNote', newValue: '\nODLOZENO\n' });
    return Promise.resolve();
  }

  @Action
  async setResult({
    eventId,
    payload,
    newMinute,
  }: {
    eventId: number;
    payload: any;
    newMinute?: number;
  }) {
    if (newMinute) {
      const [err, res] = await to(
        settlementService.setResult(eventId, {
          [newMinute]: { home: payload.home, away: payload.away },
        })
      );
      if (err) return Promise.reject(err);
      return Promise.resolve(res);
    }
    const [err, res] = await to(settlementService.setResult(eventId, payload));
    if (err) return Promise.reject(err);
    return Promise.resolve(res);
  }

  @Action
  onUpdateSettlementNote(wsData: any) {
    this.editEvent({ eventId: wsData.e_i_k, key: 'settlementNote', newValue: wsData.n });
  }

  @Action
  onUpdateEventNote(wsData: any) {
    this.editEvent({ eventId: wsData.e_i_k, key: 'note', newValue: wsData.n });
  }

  @Action
  async updateSettlementNote({
    eventId,
    settlementNote,
  }: {
    eventId: string;
    settlementNote: any;
  }) {
    const [err, res] = await to(
      settlementService.updateSettlementNote(eventId, { note: settlementNote })
    );
    if (err) return Promise.reject(err);
    this.editEvent({ eventId, key: 'settlementNote', newValue: settlementNote });
    return Promise.resolve(res);
  }

  @Action
  async getResultHistory(eventId: number) {
    const [err, res] = await to(settlementService.getResultHistory(eventId));
    if (err) return Promise.reject(err);
    this.setResultHistory(res);
    return Promise.resolve(res);
  }

  @Action
  async clearResult({ eventId, payload }: { eventId: string; payload: any }) {
    const [err, res] = await to(settlementService.clearResult(eventId, payload));
    if (err) return Promise.reject(err);
    this.removeResult(payload.period);
    return Promise.resolve(res);
  }

  @Action
  discardAllChanges(eventId: string) {
    this.editDataChanges({ eventId: eventId, key: 'home', newValue: '' });
    this.editDataChanges({ eventId: eventId, key: 'away', newValue: '' });
    this.editDataChanges({ eventId: eventId, key: 'period', newValue: '' });
  }

  @Action
  onEventNotStarted(message: any) {
    if (message.event_type === 'P') {
      this.addMissedPlayerEvents(message.event_ids);
    } else if (message.event_type === 'D') {
      this.addMissedDualEvents(message.event_ids);
    }
  }

  @Action
  setOddForSettlement({ oddId, type }: { oddId: number; type: OddTypes }) {
    if (this.settleOddsPayload.odds[type].includes(oddId)) {
      this.removeOddForSettlement({ oddId, type });
    } else {
      this.addOddForSettlement({ oddId, type });
    }
    for (const typeOfOdd in this.settleOddsPayload.odds) {
      if (
        typeOfOdd !== type &&
        this.settleOddsPayload.odds[typeOfOdd as OddTypes].includes(oddId)
      ) {
        this.removeOddForSettlement({ oddId, type: typeOfOdd as OddTypes });
      }
    }
  }

  @Action
  async resetAllOdds(eventId: string) {
    const [err] = await to(settlementService.resetAllOdds(eventId));
    if (err) return Promise.reject(err);
    const store = localStorage.getItem('stopwatchStore');
    if (!store) return;
    this.clearSettlementEventData(eventId);
    this.editEvent({ eventId: eventId, key: 'isVerified', newValue: false });
    this.editEvent({ eventId: eventId, key: 'settledM1', newValue: false });
    this.editEvent({ eventId: eventId, key: 'settled15', newValue: false });
    this.editEvent({ eventId: eventId, key: 'settled30', newValue: false });
    return Promise.resolve();
  }

  @Action
  bulkAddOutcomes(type: OddTypes) {
    const nonSelectedOutcomes = settlementService.findNonSelectedActiveOutcomes(
      this.activeOutcomes,
      this.settleOddsPayload.odds.WON,
      this.settleOddsPayload.odds.LOST,
      this.settleOddsPayload.odds.ODD_ONE
    );
    this.setOddsPayload({ type, odds: nonSelectedOutcomes });
  }

  // PLAYER

  @Action
  async getPlayerSettlementLiveEvents(streamParams: {
    date: string;
    setParamFunc: string;
    value: boolean;
  }) {
    CommonStore.setIsFetching(true);
    const res = await settlementService.getSettlementEvents(streamParams);
    CommonStore.setIsFetching(false);
    if (!res) return Promise.reject();

    this.setForSettlement(res);
    return Promise.resolve(true);
  }

  @Action
  async getPlayerEvents() {
    CommonStore.setIsFetching(true);
    const res = await settlementService.getPlayerEvents();
    res.forEach(event => (event.isSettleEnabled = false));
    CommonStore.setIsFetching(false);

    if (!res) return Promise.reject();
    // eslint-disable-next-line no-useless-catch
    try {
      this.setForSettlement(res);
    } catch (e) {
      throw e;
    }
    return Promise.resolve(true);
  }

  @Action
  async setFirstPlayerToScore({
    intKey,
    payload,
    eventIntKey,
  }: {
    intKey: number;
    payload: { [key: string]: boolean };
    eventIntKey: number;
  }) {
    const [err, res] = await to(settlementService.setPlayerResult(intKey, payload));
    const eventAccessor = eventService.formatEventId(intKey);
    const newValues = {
      ...this.settlementEvents[eventAccessor].settlementHistory,
      ...payload,
    };

    if (err) return Promise.reject(err);
    this.editEvent({
      eventId: intKey,
      key: 'settlementHistory',
      newValue: newValues,
    });
    const allPlayers = Object.values(this.settlementEvents);
    allPlayers.forEach(player => {
      if (player.eventIntKey === eventIntKey && player.intKey != intKey) {
        const playerEventAccessor = eventService.formatEventId(player.intKey);
        setTimeout(() => {
          this.editEvent({
            eventId: player.intKey,
            key: 'settlementHistory',
            newValue: {
              ...this.settlementEvents[playerEventAccessor].settlementHistory,
              fps: false,
            },
          });
        }, 0);
      }
    });
    return Promise.resolve(res);
  }

  @Action
  async setPlayerResult() {
    CommonStore.setIsFetching(true);
    const playerResult = {
      [Object.keys(this.tempPlayerValues)[0]]: this.tempPlayerValues[
        Object.keys(this.tempPlayerValues)[0]
      ].value,
    };

    const [err, res] = await to(
      settlementService.setPlayerResult(this.tempPlayerIntKey, playerResult)
    );
    CommonStore.setIsFetching(false);
    if (err) return Promise.reject(err);
    const eventAccessor = eventService.formatEventId(this.tempPlayerIntKey);
    const breachedLimit = Object.values(res as object)[0];
    if (breachedLimit) {
      this.tempPlayerValues[Object.keys(this.tempPlayerValues)[0]][
        'breached_limit'
      ] = breachedLimit;
    }
    const newValues = {
      ...this.settlementEvents[eventAccessor].settlementHistory,
      ...this.tempPlayerValues,
    };
    this.enableDisablePlayerEventForSettle({
      eventId: this.tempPlayerIntKey,
      key: 'isSettleEnabled',
      newValue: true,
    });
    this.editPlayerEvent({
      eventId: this.tempPlayerIntKey,
      key: 'settlementHistory',
      newValue: newValues,
    });
    return Promise.resolve(res);
  }

  @Action
  async resetAllPlayerOdds(eventId: string) {
    CommonStore.setIsFetching(true);
    const [err] = await to(settlementService.resetAllPlayerOdds(eventId));
    CommonStore.setIsFetching(false);
    if (err) return Promise.reject(err);
    return Promise.resolve();
  }

  // Depracated - NO TTX FOR NOW
  // @Action
  // async toggleTTXPlayers({ eventIds, nextValue }: { eventIds: string[]; nextValue: boolean }) {
  //   CommonStore.setIsFetching(true);
  //   const [err] = await to(settlementService.toggleTTXPlayers({ event_ids: eventIds }));
  //   CommonStore.setIsFetching(false);
  //   if (err) return Promise.reject(err);
  //   this.editEvent({ eventId: eventIds[0], key: 'is_ttx', newValue: nextValue });
  //   return Promise.resolve();
  // }

  @Action
  async getActiveOutcomesPlayer(eventId: string) {
    CommonStore.setIsFetching(true);
    const [err, res] = await to<any>(settlementService.getActiveOutcomesPlayers(eventId));
    CommonStore.setIsFetching(false);
    if (err) return Promise.reject(err);
    res.forEach((bet: any) => {
      bet.odd_id = bet.int_key;
    });
    this.setActiveOutcomes(res);
    res.forEach((outcome: any) => {
      if (outcome.odd_status === OddStatus.LOST) {
        this.addOddForSettlement({ oddId: outcome.odd_id, type: 'LOST' });
      }
      if (outcome.odd_status === OddStatus.WON) {
        this.addOddForSettlement({ oddId: outcome.odd_id, type: 'WON' });
      }
      if (outcome.odd_status === OddStatus.CANCELLED) {
        this.addOddForSettlement({ oddId: outcome.odd_id, type: 'ODD_ONE' });
      }
    });
    return Promise.resolve(res.length);
  }

  @Action
  async assignPlayerEventToUser({
    events,
    assigneeId,
  }: {
    events: string[];
    assigneeId: number | null;
  }) {
    CommonStore.setIsFetching(true);
    const payload = {
      user_id: assigneeId || AuthStore.userId,
      event_ids: events,
      event_type: 'prematch' as 'prematch', // not sure why this is required?
    };
    const [err] = await to(settlementService.assignPlayerEventToUser(payload));
    CommonStore.setIsFetching(false);
    if (err) return Promise.reject(err);
    const accessor = eventService.formatEventId(events[0]);
    if (this.settlementEvents[accessor]) {
      //If missed event is picked check if it exists in table and only then edit assignee
      this.editEvent({
        eventId: events[0],
        key: 'assignee',
        newValue: assigneeId || AuthStore.userId,
      });
    }
    return Promise.resolve();
  }

  @Action
  async deselectPlayerEvents(eventIds: string[]) {
    CommonStore.setIsFetching(true);
    const [err] = await to(
      settlementService.unassignPlayerEvents({ event_ids: eventIds, event_type: 'prematch' })
    );
    CommonStore.setIsFetching(false);
    if (err) return Promise.reject(err);
    eventIds.forEach(eventId => {
      this.editEvent({ eventId, key: 'assignee', newValue: 0 });
    });
    return Promise.resolve();
  }

  // ask for ids of events in response then loop them and give them assignee
  @Action
  async assignAllDualEventPlayerEventsToUser({
    eventsIds,
    assigneeId,
  }: {
    eventsIds: string[];
    assigneeId: number | null;
  }) {
    CommonStore.setIsFetching(true);
    const payload = {
      user_id: assigneeId || AuthStore.userId,
      event_ids: eventsIds,
    };
    const [err, playerEventIds] = await to<any>(
      settlementService.assignAllDualEventPlayerEvents(payload)
    );
    CommonStore.setIsFetching(false);
    if (err) return Promise.reject(err);
    this.setPickedPlayerEventIds(playerEventIds);
    playerEventIds.forEach((id: string) => {
      if (settlementService.findEvent(id, this.forSettlement)) {
        //If missed event is picked check if it exists in table and only then edit assignee
        this.editEvent({ eventId: id, key: 'assignee', newValue: assigneeId || AuthStore.userId });
      }
    });
    return Promise.resolve();
  }

  @Action
  async unassignAllDualEventPlayerEvents(eventIds: string[]) {
    CommonStore.setIsFetching(true);
    const [err, playerEventIds] = await to<any>(
      settlementService.unassignAllDualEventPlayerEvents({
        event_ids: eventIds,
      })
    );
    CommonStore.setIsFetching(false);
    if (err) return Promise.reject(err);
    this.setPickedPlayerEventIds(playerEventIds);
    playerEventIds.forEach((id: string) => {
      if (settlementService.findEvent(id, this.forSettlement)) {
        //If missed event is picked check if it exists in table and only then edit assignee
        this.editEvent({ eventId: id, key: 'assignee', newValue: 0 });
      }
    });
    return Promise.resolve();
  }

  @Action
  async verifyPlayerSettlement(eventId: string) {
    CommonStore.setIsFetching(true);
    const [err] = await to(settlementService.verifyPlayerSettlement(eventId));
    CommonStore.setIsFetching(false);
    if (err) return Promise.reject(err);
    this.editEvent({ eventId: eventId, key: 'isVerified', newValue: true });
    this.editEvent({ eventId, key: 'state', newValue: EVENT_STATE.COMPLETED });
    return Promise.resolve();
  }

  @Action
  async getPlayerResultHistory(eventId: string) {
    CommonStore.setIsFetching(true);
    const [err, res] = await to(settlementService.getPlayerResultHistory(eventId));
    CommonStore.setIsFetching(false);
    if (err) return Promise.reject(err);
    this.setResultHistory(res);
    return Promise.resolve(res);
  }

  @Action
  async updatePlayerSettlementNote({
    eventId,
    settlementNote,
  }: {
    eventId: string;
    settlementNote: any;
  }) {
    CommonStore.setIsFetching(true);
    const [err, res] = await to(
      settlementService.updatePlayerSettlementNote(eventId, { note: settlementNote })
    );
    CommonStore.setIsFetching(false);
    if (err) return Promise.reject(err);
    this.editEvent({ eventId: eventId, key: 'settlementNote', newValue: settlementNote });
    return Promise.resolve(res);
  }

  @Action
  async settleOdds() {
    const payload = {
      event_id: this.activeId,
      ...this.settleOddsPayload,
    };
    const [err] = await to(settlementService.settleOdds(payload));
    if (err) return Promise.reject(err);
    this.setActiveOddToOne();
    return Promise.resolve();
  }

  @Action
  async settlePlayerOdds() {
    const payload = {
      event_id: this.activeId,
      ...this.settleOddsPayload,
    };
    CommonStore.setIsFetching(true);
    const [err] = await to(settlementService.settlePlayerOdds(payload));
    if (err) return Promise.reject(err);
    this.setActiveOddToOne();
    return Promise.resolve();
  }

  @Action
  async settleAllPlayer(eventId: string) {
    const [err, res] = await to<any>(settlementService.settleAllPlayer(eventId));
    if (err) return Promise.reject(err);
    this.enableDisablePlayerEventForSettle({
      eventId: eventId,
      key: 'isSettleEnabled',
      newValue: false,
    });
    return Promise.resolve(res);
  }

  // return this if setOddToOne needs to be functionality on its own
  // @Action
  // async setPlayerOddToOne({ oddId }: { oddId: string; eventId: number }) {
  //   const [err] = await to(settlementService.setPlayerOddToOne(oddId));
  //   if (err) return Promise.reject(err);
  //   this.setActiveOddToOne(oddId);
  //   return Promise.resolve();
  // }

  @Action
  async resetPlayerOdd({ oddId, eventId }: { oddId: string; eventId: number }) {
    const [err] = await to(settlementService.resetPlayerOdd(oddId));
    if (err) return Promise.reject(err);
    this.setActiveOddStatus({ oddId, nextStatus: OddStatus.UNRESOLVED });
    this.editEvent({ eventId, key: 'isVerified', newValue: false });

    return Promise.resolve();
  }

  @Action
  millionTicketsActive(wsData: MillionTicketsCount) {
    this.setMillionTicketsCount(wsData);
  }

  @Action
  setSettleBtnState({ eventId, isDisabled }: { eventId: string; isDisabled: boolean }) {
    this.editEvent({ eventId, key: 'isSettleDisabled', newValue: isDisabled });
  }

  @Action
  async getBackofficeUsers() {
    const [err, res] = await to(
      settlementService.getBackofficeUsers({ role: AuthStore.backofficeRoleId })
    );
    if (err) return Promise.reject(err);
    if (!res) return;
    this.setBackofficeUsers(res);
  }

  @Action
  assignedEvent(wsData: any) {
    wsData.forEach((wsEvent: any) => {
      setTimeout(() => {
        const event = new SettlementEventWs(wsEvent);
        const eventAccesor = eventService.formatEventId(event.intKey);
        if (this.settlementEvents[eventAccesor]) {
          this.updateAssigneId({
            eventId: event.intKey,
            key: 'assignee',
            newValue: event.assignee,
          });
          return;
        }
      });
    });
  }

  @Action
  async changeProvider(payload: { intKey: number; provider: { [key: string]: string } }) {
    const [err] = await to<any>(eventService.changeProvider(payload));
    if (err) return Promise.reject(err);
    if (payload.provider.settlement_provider === 'manual') {
      this.editEvent({ eventId: payload.intKey, key: 'isManualSettlement', newValue: true });
      return;
    } else {
      this.editEvent({ eventId: payload.intKey, key: 'isManualSettlement', newValue: false });
    }
    if (payload.provider.settlement_provider !== 'off') {
      this.editEvent({
        eventId: payload.intKey,
        key: 'ctrlProvider',
        newValue: payload.provider.settlement_provider,
      });
    }
    if (payload.provider.settlement_provider === 'off') {
      this.editEvent({
        eventId: payload.intKey,
        key: 'ctrlProvider',
        newValue: '',
      });
    }
  }

  @Action
  enableCheckbox1530(wsData: any) {
    this.editEvent({ eventId: wsData.e_i_k, key: 'checkbox1530', newValue: true });
  }

  @Action
  enableCheckboxM1(wsData: any) {
    this.editEvent({ eventId: wsData.e_i_k, key: 'checkboxM1', newValue: true });
  }

  @Action
  updateSettlementEvent(wsData: any) {
    this.updateEventWithoutRender({
      eventId: wsData.i_k,
      key: 'hasUnsettled',
      newValue: wsData.hus,
    });
  }
}

export const SettlementStore = getModule(Settlement);
