import { api } from '@/lib/api';
import {
  BulkEventsPayload,
  AssignUserPayload,
  SettleOddsPayload,
  BulkPlayerEventsPayload,
  AssignUserPlayerPayload,
  BackofficeUser,
  NextGoalData,
} from './settlement.types';

// WORKS
const assignToUser = (payload: AssignUserPayload) => {
  return api.put('/odds/events/assign/', payload);
};

// WORKS
const unassignEvents = (payload: BulkEventsPayload) => {
  return api.put('/odds/events/unassign/', payload);
};

// WORKS WITH NEW DATA?
const settleAll = (eventId: string, payload: any) => {
  return api.put(`/odds/events/${eventId}/bet-settlement-all/`, payload);
};

// There will be no TTX as of currently
// const toggleTTX = (payload: BulkEventsPayload) => {
//   return api.put('/odds/events/ttx/', payload);
// };

// WORKS BUT PROLLY DOES NOTHING
const autoSettlement = (payload: { event_ids: string[] }) => {
  return api.put('odds/events/automatic-settlement/', payload);
};

const verifySettlement = (eventId: string) => {
  return api.put(`odds/events/${eventId}/settled/`);
};

// currently not used anywhere
const bulkVerifySettlement = (payload: BulkEventsPayload) => {
  return api.put('odds/events/bulk-settle', payload);
};

const getActiveOutcomes = (eventId: string) => {
  return api.get(`odds/events/${eventId}/played-outcomes/`, {
    cache: {
      maxAge: 0,
    },
  });
};

const getActiveOutcomesLivePlayer = (eventId: number) => {
  return api.get(`odds/events/${eventId}/played-outcomes-live-players/`, {
    cache: {
      maxAge: 0,
    },
  });
};

const setOddToOne = (oddId: string, eventId: number) => {
  return api.put(`odds/odds/${oddId}/clear/`, {
    event_int_key: eventId,
  });
};

const resetOdd = (oddId: string, eventId: number) => {
  return api.put(`odds/odds/${oddId}/unsettle/`, {
    event_int_key: eventId,
  });
};

const setAllOddsToOne = (eventId: string, eventType: 'prematch' | 'live') => {
  return api.put(`odds/events/${eventId}/clear-odds/`, { event_type: eventType });
};

const setEventTerminated = (eventId: string) => {
  return api.put(`odds/events/${eventId}/terminate/`);
};

const setPostponed = (eventId: string) => {
  return api.put(`odds/events/${eventId}/postpone/`);
};

const setEventPeriod = (eventId: string, payload: any) => {
  return api.put(`odds/events/${eventId}/set-current-period/`, payload); // {curent_period: "HT"};
};

const setResult = (eventId: number, payload: any) => {
  return api.put(`odds/events/${eventId}/set-result/`, payload); // { <minute> : {home: int, away: int} };
};

const setPeriodResult = (eventId: number, payload: any) => {
  return api.put(`odds/events/${eventId}/set-period/`, payload); // { <minute> : {home: int, away: int} };
};

const clearResult = (eventId: string, payload: any) => {
  return api.put(`odds/events/${eventId}/clear-result/`, payload); // { period : "HT" };
};

const setUnresolvedToOne = (eventId: string) => {
  return api.put(`odds/events/${eventId}/resolve-unsettled/`);
};

const getResultHistory = (eventId: number) => {
  return api.get(`odds/events/${eventId}/settlement-history/`, {
    cache: {
      maxAge: 0,
    },
  });
};

const getResultLivePlayerHistory = (eventId: number) => {
  return api.get(`odds/events/${eventId}/settlement-history/`, {
    cache: {
      maxAge: 0,
    },
  });
};

const updateSettlementNote = (eventId: string, payload: any) => {
  return api.put(`odds/events/${eventId}/settlement-note/`, payload);
};
// deprecated probably check this 31.05
const getUnsettledEvents = (payload: any, urlParams: any) => {
  return api.post(`odds/events/unsettled/`, payload, { params: urlParams });
};

const resetOddsByDateTime = (eventId: string, payload: any) => {
  return api.put(`odds/events/${eventId}/reset-odds-by-datetime/`, payload);
};

const resetOddsByOutcomeAndTime = (eventId: string, payload: any) => {
  return api.put(`odds/events/${eventId}/reset-odds-by-outcome/`, payload);
};

const resetPlayerOddsByOutcomeAndTime = (eventId: string, payload: any) => {
  return api.put(`odds/event-players/${eventId}/reset-odds-by-outcome/`, payload);
};

const resetAllOdds = (eventId: string) => {
  return api.put(`odds/events/${eventId}/reset-all-odds/`);
};

const settleOdds = (payload: SettleOddsPayload) => {
  return api.put(`odds/odds/settle-odds/`, payload);
};

// Player Settlement

const resetAllPlayerOdds = (eventId: string) => {
  return api.put(`odds/event-players/${eventId}/reset-all-odds/`);
};

// Deprecated -- NO TTX FOR NOW
const toggleTTXPlayers = (payload: BulkEventsPayload) => {
  return api.put('/odds/event-players/ttx/', payload);
};

const getActiveOutcomesPlayer = (eventId: string) => {
  return api.get(`odds/event-players/${eventId}/played-outcomes/`, {
    cache: {
      maxAge: 0,
    },
  });
};

const setAllPlayerOddsToOne = (eventId: string) => {
  return api.put(`odds/event-players/${eventId}/clear-odds/`);
};

const assignPlayerEventToUser = (payload: AssignUserPlayerPayload) => {
  return api.put('/odds/event-players/assign/', payload);
};

const unassignPlayerEvents = (payload: BulkPlayerEventsPayload) => {
  return api.put('/odds/event-players/unassign/', payload);
};

// these 2 requests (un)assign all player events that are children to dual events
const assignAllDualEventsPlayerEvents = (payload: AssignUserPlayerPayload) => {
  return api.put('/odds/event-players/assign-all/', payload);
};

const unassignAllDualEventsPlayerEvents = (payload: BulkPlayerEventsPayload) => {
  return api.put('/odds/event-players/unassign-all/', payload);
};

const setPlayerResult = (eventId: number, payload: any) => {
  return api.put(`odds/event-players/${eventId}/set-result/`, payload); //{ <minute> : {home: int, away: int} };
};

const verifyPlayerSettlement = (eventId: string) => {
  return api.put(`odds/event-players/${eventId}/settlement-verify/`);
};

const updatePlayerSettlementNote = (eventId: string, payload: any) => {
  return api.put(`odds/event-players/${eventId}/settlement-note/`, payload);
};

const setPlayerOddToOne = (oddId: string) => {
  return api.put(`odds/odd-players/${oddId}/clear/`);
};

const settlePlayerOdds = (payload: SettleOddsPayload) => {
  return api.put(`odds/odd-players/settle-odds/`, payload);
};

const resetPlayerOdd = (oddId: string) => {
  return api.put(`odds/odd-players/${oddId}/unsettle/`);
};

const settleAllPlayer = (eventId: string) => {
  return api.put(`/odds/event-players/${eventId}/bet-settlement-all/`);
};

const resetPlayerOddsByDateTime = (eventId: string, payload: any) => {
  return api.put(`odds/event-players/${eventId}/reset-odds-by-datetime/`, payload);
};

const getPlayerResultHistory = (eventId: string) => {
  return api.get(`odds/event-players/${eventId}/settlement-history/`, {
    cache: {
      maxAge: 0,
    },
  });
};

const getBackofficeUsers = ({ role }: { role: number }): Promise<BackofficeUser[]> => {
  return api.get(`/user/get-users-by-role?role=${role}`, {
    cache: {
      maxAge: 0,
    },
  });
};

const setGoalScorer = (nextGoalData: NextGoalData) => {
  return api.put(`/odds/events/${nextGoalData.eventIntKey}/set-result-live-players/`, nextGoalData);
};

export const settlementRepo = {
  resetOdd,
  // toggleTTX,
  settleAll,
  setResult,
  setPeriodResult,
  settleOdds,
  clearResult,
  setOddToOne,
  assignToUser,
  resetAllOdds,
  setEventPeriod,
  unassignEvents,
  autoSettlement,
  setAllOddsToOne,
  getResultHistory,
  verifySettlement,
  getActiveOutcomes,
  getUnsettledEvents,
  setUnresolvedToOne,
  setEventTerminated,
  setPostponed,
  resetOddsByDateTime,
  bulkVerifySettlement,
  updateSettlementNote,
  resetOddsByOutcomeAndTime,
  resetAllPlayerOdds,
  toggleTTXPlayers,
  getActiveOutcomesPlayer,
  setAllPlayerOddsToOne,
  assignPlayerEventToUser,
  unassignPlayerEvents,
  assignAllDualEventsPlayerEvents,
  unassignAllDualEventsPlayerEvents,
  setPlayerResult,
  verifyPlayerSettlement,
  updatePlayerSettlementNote,
  settlePlayerOdds,
  setPlayerOddToOne,
  resetPlayerOdd,
  getPlayerResultHistory,
  settleAllPlayer,
  resetPlayerOddsByDateTime,
  resetPlayerOddsByOutcomeAndTime,
  getBackofficeUsers,
  getResultLivePlayerHistory,
  getActiveOutcomesLivePlayer,
  setGoalScorer,
};
