// source: proto/odds_stream/odds_stream.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
goog.exportSymbol('proto.odds_stream.Antepost', null, global);
goog.exportSymbol('proto.odds_stream.AntepostBO', null, global);
goog.exportSymbol('proto.odds_stream.AntepostBet', null, global);
goog.exportSymbol('proto.odds_stream.AntepostBetBO', null, global);
goog.exportSymbol('proto.odds_stream.AntepostMarket', null, global);
goog.exportSymbol('proto.odds_stream.BORequestDate', null, global);
goog.exportSymbol('proto.odds_stream.CtrlEvent', null, global);
goog.exportSymbol('proto.odds_stream.EventCreateBO', null, global);
goog.exportSymbol('proto.odds_stream.EventCreateBOMap', null, global);
goog.exportSymbol('proto.odds_stream.EventLiveBO', null, global);
goog.exportSymbol('proto.odds_stream.EventLiveBOMap', null, global);
goog.exportSymbol('proto.odds_stream.EventMagazine', null, global);
goog.exportSymbol('proto.odds_stream.EventPrematchBO', null, global);
goog.exportSymbol('proto.odds_stream.EventPrematchBOMap', null, global);
goog.exportSymbol('proto.odds_stream.EventSC', null, global);
goog.exportSymbol('proto.odds_stream.GetAntepostResponse', null, global);
goog.exportSymbol('proto.odds_stream.GetAntepostsResponseBO', null, global);
goog.exportSymbol('proto.odds_stream.GetCtrlEventsResponse', null, global);
goog.exportSymbol('proto.odds_stream.GetEventsCreateResponseBO', null, global);
goog.exportSymbol('proto.odds_stream.GetEventsLiveResponseBO', null, global);
goog.exportSymbol('proto.odds_stream.GetEventsMagazineResponse', null, global);
goog.exportSymbol('proto.odds_stream.GetEventsOfferResponseBO', null, global);
goog.exportSymbol('proto.odds_stream.GetEventsPrematchResponseBO', null, global);
goog.exportSymbol('proto.odds_stream.GetEventsResponse', null, global);
goog.exportSymbol('proto.odds_stream.GetEventsResponseSC', null, global);
goog.exportSymbol('proto.odds_stream.GetEventsSettleResponse', null, global);
goog.exportSymbol('proto.odds_stream.GetPlayerEventsResponse', null, global);
goog.exportSymbol('proto.odds_stream.GetPlayerEventsResponseBO', null, global);
goog.exportSymbol('proto.odds_stream.GetPlayerEventsSettleResponse', null, global);
goog.exportSymbol('proto.odds_stream.Odd', null, global);
goog.exportSymbol('proto.odds_stream.OddBO', null, global);
goog.exportSymbol('proto.odds_stream.OperatorNote', null, global);
goog.exportSymbol('proto.odds_stream.PeriodResult', null, global);
goog.exportSymbol('proto.odds_stream.PlayerEvent', null, global);
goog.exportSymbol('proto.odds_stream.PlayerEventBO', null, global);
goog.exportSymbol('proto.odds_stream.PlayerEventBOMap', null, global);
goog.exportSymbol('proto.odds_stream.PlayerEventMap', null, global);
goog.exportSymbol('proto.odds_stream.SettleEvent', null, global);
goog.exportSymbol('proto.odds_stream.SettleEventMap', null, global);
goog.exportSymbol('proto.odds_stream.SettlePlayerEvent', null, global);
goog.exportSymbol('proto.odds_stream.WebEvent', null, global);
goog.exportSymbol('proto.odds_stream.WebEventMap', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.BORequestDate = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.BORequestDate, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.BORequestDate.displayName = 'proto.odds_stream.BORequestDate';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.Odd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.Odd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.Odd.displayName = 'proto.odds_stream.Odd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.WebEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.WebEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.WebEvent.displayName = 'proto.odds_stream.WebEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.GetEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.GetEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.GetEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.GetEventsResponse.displayName = 'proto.odds_stream.GetEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.EventSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.EventSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.EventSC.displayName = 'proto.odds_stream.EventSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.GetEventsResponseSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.GetEventsResponseSC.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.GetEventsResponseSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.GetEventsResponseSC.displayName = 'proto.odds_stream.GetEventsResponseSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.OddBO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.OddBO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.OddBO.displayName = 'proto.odds_stream.OddBO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.OperatorNote = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.OperatorNote.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.OperatorNote, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.OperatorNote.displayName = 'proto.odds_stream.OperatorNote';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.PeriodResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.PeriodResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.PeriodResult.displayName = 'proto.odds_stream.PeriodResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.EventLiveBO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.EventLiveBO.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.EventLiveBO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.EventLiveBO.displayName = 'proto.odds_stream.EventLiveBO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.EventLiveBOMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.EventLiveBOMap.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.EventLiveBOMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.EventLiveBOMap.displayName = 'proto.odds_stream.EventLiveBOMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.EventCreateBO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.EventCreateBO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.EventCreateBO.displayName = 'proto.odds_stream.EventCreateBO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.EventCreateBOMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.EventCreateBOMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.EventCreateBOMap.displayName = 'proto.odds_stream.EventCreateBOMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.EventMagazine = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.EventMagazine, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.EventMagazine.displayName = 'proto.odds_stream.EventMagazine';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.EventPrematchBO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.EventPrematchBO.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.EventPrematchBO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.EventPrematchBO.displayName = 'proto.odds_stream.EventPrematchBO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.EventPrematchBOMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.EventPrematchBOMap.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.EventPrematchBOMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.EventPrematchBOMap.displayName = 'proto.odds_stream.EventPrematchBOMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.GetEventsLiveResponseBO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.GetEventsLiveResponseBO.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.GetEventsLiveResponseBO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.GetEventsLiveResponseBO.displayName = 'proto.odds_stream.GetEventsLiveResponseBO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.GetEventsCreateResponseBO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.GetEventsCreateResponseBO.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.GetEventsCreateResponseBO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.GetEventsCreateResponseBO.displayName = 'proto.odds_stream.GetEventsCreateResponseBO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.GetEventsOfferResponseBO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.GetEventsOfferResponseBO.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.GetEventsOfferResponseBO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.GetEventsOfferResponseBO.displayName = 'proto.odds_stream.GetEventsOfferResponseBO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.GetEventsMagazineResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.GetEventsMagazineResponse.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.GetEventsMagazineResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.GetEventsMagazineResponse.displayName = 'proto.odds_stream.GetEventsMagazineResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.GetEventsPrematchResponseBO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.GetEventsPrematchResponseBO.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.GetEventsPrematchResponseBO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.GetEventsPrematchResponseBO.displayName = 'proto.odds_stream.GetEventsPrematchResponseBO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.PlayerEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.PlayerEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.PlayerEvent.displayName = 'proto.odds_stream.PlayerEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.GetPlayerEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.GetPlayerEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.GetPlayerEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.GetPlayerEventsResponse.displayName = 'proto.odds_stream.GetPlayerEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.AntepostMarket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.AntepostMarket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.AntepostMarket.displayName = 'proto.odds_stream.AntepostMarket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.Antepost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.Antepost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.Antepost.displayName = 'proto.odds_stream.Antepost';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.AntepostBO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.AntepostBO.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.AntepostBO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.AntepostBO.displayName = 'proto.odds_stream.AntepostBO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.AntepostBet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.AntepostBet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.AntepostBet.displayName = 'proto.odds_stream.AntepostBet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.AntepostBetBO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.AntepostBetBO.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.AntepostBetBO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.AntepostBetBO.displayName = 'proto.odds_stream.AntepostBetBO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.GetAntepostResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.GetAntepostResponse.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.GetAntepostResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.GetAntepostResponse.displayName = 'proto.odds_stream.GetAntepostResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.GetAntepostsResponseBO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.GetAntepostsResponseBO.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.GetAntepostsResponseBO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.GetAntepostsResponseBO.displayName = 'proto.odds_stream.GetAntepostsResponseBO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.WebEventMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.WebEventMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.WebEventMap.displayName = 'proto.odds_stream.WebEventMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.SettleEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.SettleEvent.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.SettleEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.SettleEvent.displayName = 'proto.odds_stream.SettleEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.SettleEventMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.SettleEventMap.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.SettleEventMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.SettleEventMap.displayName = 'proto.odds_stream.SettleEventMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.GetEventsSettleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.GetEventsSettleResponse.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.GetEventsSettleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.GetEventsSettleResponse.displayName = 'proto.odds_stream.GetEventsSettleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.GetPlayerEventsResponseBO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.GetPlayerEventsResponseBO.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.GetPlayerEventsResponseBO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.GetPlayerEventsResponseBO.displayName = 'proto.odds_stream.GetPlayerEventsResponseBO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.PlayerEventBO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.PlayerEventBO.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.PlayerEventBO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.PlayerEventBO.displayName = 'proto.odds_stream.PlayerEventBO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.PlayerEventBOMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.PlayerEventBOMap.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.PlayerEventBOMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.PlayerEventBOMap.displayName = 'proto.odds_stream.PlayerEventBOMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.PlayerEventMap = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.PlayerEventMap.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.PlayerEventMap, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.PlayerEventMap.displayName = 'proto.odds_stream.PlayerEventMap';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.GetPlayerEventsSettleResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.GetPlayerEventsSettleResponse.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.GetPlayerEventsSettleResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.GetPlayerEventsSettleResponse.displayName = 'proto.odds_stream.GetPlayerEventsSettleResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.SettlePlayerEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.SettlePlayerEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.SettlePlayerEvent.displayName = 'proto.odds_stream.SettlePlayerEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.GetCtrlEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds_stream.GetCtrlEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds_stream.GetCtrlEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.GetCtrlEventsResponse.displayName = 'proto.odds_stream.GetCtrlEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds_stream.CtrlEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds_stream.CtrlEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds_stream.CtrlEvent.displayName = 'proto.odds_stream.CtrlEvent';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.BORequestDate.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.BORequestDate.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.BORequestDate} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.BORequestDate.toObject = function(includeInstance, msg) {
  var f, obj = {
    date: jspb.Message.getFieldWithDefault(msg, 1, ""),
    players: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    unsettled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.BORequestDate}
 */
proto.odds_stream.BORequestDate.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.BORequestDate;
  return proto.odds_stream.BORequestDate.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.BORequestDate} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.BORequestDate}
 */
proto.odds_stream.BORequestDate.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDate(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPlayers(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUnsettled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.BORequestDate.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.BORequestDate.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.BORequestDate} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.BORequestDate.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayers();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getUnsettled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string date = 1;
 * @return {string}
 */
proto.odds_stream.BORequestDate.prototype.getDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.BORequestDate} returns this
 */
proto.odds_stream.BORequestDate.prototype.setDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool players = 2;
 * @return {boolean}
 */
proto.odds_stream.BORequestDate.prototype.getPlayers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.BORequestDate} returns this
 */
proto.odds_stream.BORequestDate.prototype.setPlayers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool unsettled = 3;
 * @return {boolean}
 */
proto.odds_stream.BORequestDate.prototype.getUnsettled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.BORequestDate} returns this
 */
proto.odds_stream.BORequestDate.prototype.setUnsettled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.Odd.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.Odd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.Odd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.Odd.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    oddValue: jspb.Message.getFieldWithDefault(msg, 2, 0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    intKey: jspb.Message.getFieldWithDefault(msg, 4, 0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    limit2: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    isFeed: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    isSuspendedL1: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    setnr: jspb.Message.getFieldWithDefault(msg, 9, 0),
    gamenr: jspb.Message.getFieldWithDefault(msg, 10, 0),
    periodnr: jspb.Message.getFieldWithDefault(msg, 11, 0),
    quarternr: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.Odd}
 */
proto.odds_stream.Odd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.Odd;
  return proto.odds_stream.Odd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.Odd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.Odd}
 */
proto.odds_stream.Odd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOddValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit2(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFeed(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspendedL1(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSetnr(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setGamenr(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPeriodnr(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setQuarternr(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.Odd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.Odd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.Odd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.Odd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOddValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLimit2();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getIsFeed();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getIsSuspendedL1();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getSetnr();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getGamenr();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getPeriodnr();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getQuarternr();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
};


/**
 * optional bool is_suspended = 1;
 * @return {boolean}
 */
proto.odds_stream.Odd.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.Odd} returns this
 */
proto.odds_stream.Odd.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional int32 odd_value = 2;
 * @return {number}
 */
proto.odds_stream.Odd.prototype.getOddValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.Odd} returns this
 */
proto.odds_stream.Odd.prototype.setOddValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 outcome_id = 3;
 * @return {number}
 */
proto.odds_stream.Odd.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.Odd} returns this
 */
proto.odds_stream.Odd.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 int_key = 4;
 * @return {number}
 */
proto.odds_stream.Odd.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.Odd} returns this
 */
proto.odds_stream.Odd.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double limit = 5;
 * @return {number}
 */
proto.odds_stream.Odd.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.Odd} returns this
 */
proto.odds_stream.Odd.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double limit2 = 6;
 * @return {number}
 */
proto.odds_stream.Odd.prototype.getLimit2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.Odd} returns this
 */
proto.odds_stream.Odd.prototype.setLimit2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional bool is_feed = 7;
 * @return {boolean}
 */
proto.odds_stream.Odd.prototype.getIsFeed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.Odd} returns this
 */
proto.odds_stream.Odd.prototype.setIsFeed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool is_suspended_l1 = 8;
 * @return {boolean}
 */
proto.odds_stream.Odd.prototype.getIsSuspendedL1 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.Odd} returns this
 */
proto.odds_stream.Odd.prototype.setIsSuspendedL1 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 setnr = 9;
 * @return {number}
 */
proto.odds_stream.Odd.prototype.getSetnr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.Odd} returns this
 */
proto.odds_stream.Odd.prototype.setSetnr = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 gamenr = 10;
 * @return {number}
 */
proto.odds_stream.Odd.prototype.getGamenr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.Odd} returns this
 */
proto.odds_stream.Odd.prototype.setGamenr = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 periodnr = 11;
 * @return {number}
 */
proto.odds_stream.Odd.prototype.getPeriodnr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.Odd} returns this
 */
proto.odds_stream.Odd.prototype.setPeriodnr = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 quarternr = 12;
 * @return {number}
 */
proto.odds_stream.Odd.prototype.getQuarternr = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.Odd} returns this
 */
proto.odds_stream.Odd.prototype.setQuarternr = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.WebEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.WebEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.WebEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.WebEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    feedOddsMap: (f = msg.getFeedOddsMap()) ? f.toObject(includeInstance, proto.odds_stream.Odd.toObject) : [],
    start: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 9, 0),
    note: jspb.Message.getFieldWithDefault(msg, 10, ""),
    currentResult: (f = msg.getCurrentResult()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    currentPhase: (f = msg.getCurrentPhase()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    periodResult: (f = msg.getPeriodResult()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    prematchSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    intKey: jspb.Message.getFieldWithDefault(msg, 15, 0),
    marketsMap: (f = msg.getMarketsMap()) ? f.toObject(includeInstance, undefined) : [],
    betradarId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    home: jspb.Message.getFieldWithDefault(msg, 18, ""),
    away: jspb.Message.getFieldWithDefault(msg, 19, ""),
    betStop: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    liveSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    aid: jspb.Message.getFieldWithDefault(msg, 23, 0),
    hid: jspb.Message.getFieldWithDefault(msg, 24, 0),
    settlementNote: jspb.Message.getFieldWithDefault(msg, 25, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.WebEvent}
 */
proto.odds_stream.WebEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.WebEvent;
  return proto.odds_stream.WebEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.WebEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.WebEvent}
 */
proto.odds_stream.WebEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 5:
      var value = msg.getFeedOddsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds_stream.Odd.deserializeBinaryFromReader, "", new proto.odds_stream.Odd());
         });
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 11:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCurrentResult(value);
      break;
    case 12:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCurrentPhase(value);
      break;
    case 13:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setPeriodResult(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrematchSuspended(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 16:
      var value = msg.getMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetradarId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setHome(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setAway(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBetStop(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiveSuspended(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAid(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHid(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.WebEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.WebEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.WebEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.WebEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFeedOddsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds_stream.Odd.serializeBinaryToWriter);
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCurrentResult();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getCurrentPhase();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getPeriodResult();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getPrematchSuspended();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(16, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getBetradarId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getHome();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getAway();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getBetStop();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getLiveSuspended();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getAid();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getHid();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getSettlementNote();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
};


/**
 * optional int32 competition_id = 3;
 * @return {number}
 */
proto.odds_stream.WebEvent.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * map<string, Odd> feed_odds = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds_stream.Odd>}
 */
proto.odds_stream.WebEvent.prototype.getFeedOddsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds_stream.Odd>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.odds_stream.Odd));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.clearFeedOddsMap = function() {
  this.getFeedOddsMap().clear();
  return this;};


/**
 * optional string start = 6;
 * @return {string}
 */
proto.odds_stream.WebEvent.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 sport_id = 7;
 * @return {number}
 */
proto.odds_stream.WebEvent.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 location_id = 8;
 * @return {number}
 */
proto.odds_stream.WebEvent.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 landbase_code = 9;
 * @return {number}
 */
proto.odds_stream.WebEvent.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string note = 10;
 * @return {string}
 */
proto.odds_stream.WebEvent.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.protobuf.Struct current_result = 11;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.WebEvent.prototype.getCurrentResult = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 11));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.WebEvent} returns this
*/
proto.odds_stream.WebEvent.prototype.setCurrentResult = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.clearCurrentResult = function() {
  return this.setCurrentResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.WebEvent.prototype.hasCurrentResult = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Struct current_phase = 12;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.WebEvent.prototype.getCurrentPhase = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 12));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.WebEvent} returns this
*/
proto.odds_stream.WebEvent.prototype.setCurrentPhase = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.clearCurrentPhase = function() {
  return this.setCurrentPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.WebEvent.prototype.hasCurrentPhase = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Struct period_result = 13;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.WebEvent.prototype.getPeriodResult = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 13));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.WebEvent} returns this
*/
proto.odds_stream.WebEvent.prototype.setPeriodResult = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.clearPeriodResult = function() {
  return this.setPeriodResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.WebEvent.prototype.hasPeriodResult = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool prematch_suspended = 14;
 * @return {boolean}
 */
proto.odds_stream.WebEvent.prototype.getPrematchSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.setPrematchSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional int32 int_key = 15;
 * @return {number}
 */
proto.odds_stream.WebEvent.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * map<string, bool> markets = 16;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.odds_stream.WebEvent.prototype.getMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 16, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.clearMarketsMap = function() {
  this.getMarketsMap().clear();
  return this;};


/**
 * optional string betradar_id = 17;
 * @return {string}
 */
proto.odds_stream.WebEvent.prototype.getBetradarId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.setBetradarId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string home = 18;
 * @return {string}
 */
proto.odds_stream.WebEvent.prototype.getHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.setHome = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string away = 19;
 * @return {string}
 */
proto.odds_stream.WebEvent.prototype.getAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.setAway = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional bool bet_stop = 20;
 * @return {boolean}
 */
proto.odds_stream.WebEvent.prototype.getBetStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.setBetStop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool live_suspended = 21;
 * @return {boolean}
 */
proto.odds_stream.WebEvent.prototype.getLiveSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.setLiveSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional int32 aid = 23;
 * @return {number}
 */
proto.odds_stream.WebEvent.prototype.getAid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.setAid = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 hid = 24;
 * @return {number}
 */
proto.odds_stream.WebEvent.prototype.getHid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.setHid = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional string settlement_note = 25;
 * @return {string}
 */
proto.odds_stream.WebEvent.prototype.getSettlementNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebEvent} returns this
 */
proto.odds_stream.WebEvent.prototype.setSettlementNote = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.GetEventsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.GetEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.GetEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.GetEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    prematchEventsList: jspb.Message.toObjectList(msg.getPrematchEventsList(),
    proto.odds_stream.WebEvent.toObject, includeInstance),
    liveEventsList: jspb.Message.toObjectList(msg.getLiveEventsList(),
    proto.odds_stream.WebEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.GetEventsResponse}
 */
proto.odds_stream.GetEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.GetEventsResponse;
  return proto.odds_stream.GetEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.GetEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.GetEventsResponse}
 */
proto.odds_stream.GetEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds_stream.WebEvent;
      reader.readMessage(value,proto.odds_stream.WebEvent.deserializeBinaryFromReader);
      msg.addPrematchEvents(value);
      break;
    case 2:
      var value = new proto.odds_stream.WebEvent;
      reader.readMessage(value,proto.odds_stream.WebEvent.deserializeBinaryFromReader);
      msg.addLiveEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.GetEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.GetEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.GetEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrematchEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds_stream.WebEvent.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.odds_stream.WebEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated WebEvent prematch_events = 1;
 * @return {!Array<!proto.odds_stream.WebEvent>}
 */
proto.odds_stream.GetEventsResponse.prototype.getPrematchEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.WebEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.WebEvent, 1));
};


/**
 * @param {!Array<!proto.odds_stream.WebEvent>} value
 * @return {!proto.odds_stream.GetEventsResponse} returns this
*/
proto.odds_stream.GetEventsResponse.prototype.setPrematchEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds_stream.WebEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.WebEvent}
 */
proto.odds_stream.GetEventsResponse.prototype.addPrematchEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds_stream.WebEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.GetEventsResponse} returns this
 */
proto.odds_stream.GetEventsResponse.prototype.clearPrematchEventsList = function() {
  return this.setPrematchEventsList([]);
};


/**
 * repeated WebEvent live_events = 2;
 * @return {!Array<!proto.odds_stream.WebEvent>}
 */
proto.odds_stream.GetEventsResponse.prototype.getLiveEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.WebEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.WebEvent, 2));
};


/**
 * @param {!Array<!proto.odds_stream.WebEvent>} value
 * @return {!proto.odds_stream.GetEventsResponse} returns this
*/
proto.odds_stream.GetEventsResponse.prototype.setLiveEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.odds_stream.WebEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.WebEvent}
 */
proto.odds_stream.GetEventsResponse.prototype.addLiveEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.odds_stream.WebEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.GetEventsResponse} returns this
 */
proto.odds_stream.GetEventsResponse.prototype.clearLiveEventsList = function() {
  return this.setLiveEventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.EventSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.EventSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.EventSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionShortName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    competitionName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    allOddsMap: (f = msg.getAllOddsMap()) ? f.toObject(includeInstance, proto.odds_stream.Odd.toObject) : [],
    start: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 9, 0),
    note: jspb.Message.getFieldWithDefault(msg, 10, ""),
    currentResult: (f = msg.getCurrentResult()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    currentPhase: (f = msg.getCurrentPhase()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    periodResult: (f = msg.getPeriodResult()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    prematchSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    intKey: jspb.Message.getFieldWithDefault(msg, 15, 0),
    marketsMap: (f = msg.getMarketsMap()) ? f.toObject(includeInstance, undefined) : [],
    betradarId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    home: jspb.Message.getFieldWithDefault(msg, 19, ""),
    away: jspb.Message.getFieldWithDefault(msg, 20, ""),
    betStop: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    liveSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    aid: jspb.Message.getFieldWithDefault(msg, 23, 0),
    hid: jspb.Message.getFieldWithDefault(msg, 24, 0),
    settlementNote: jspb.Message.getFieldWithDefault(msg, 25, ""),
    magazine: jspb.Message.getBooleanFieldWithDefault(msg, 26, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.EventSC}
 */
proto.odds_stream.EventSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.EventSC;
  return proto.odds_stream.EventSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.EventSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.EventSC}
 */
proto.odds_stream.EventSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionShortName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 5:
      var value = msg.getAllOddsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds_stream.Odd.deserializeBinaryFromReader, "", new proto.odds_stream.Odd());
         });
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 11:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCurrentResult(value);
      break;
    case 12:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCurrentPhase(value);
      break;
    case 13:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setPeriodResult(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrematchSuspended(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 16:
      var value = msg.getMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetradarId(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setHome(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setAway(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBetStop(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiveSuspended(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAid(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHid(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementNote(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setMagazine(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.EventSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.EventSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.EventSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionShortName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAllOddsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds_stream.Odd.serializeBinaryToWriter);
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCurrentResult();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getCurrentPhase();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getPeriodResult();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getPrematchSuspended();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(16, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getBetradarId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getHome();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAway();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getBetStop();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getLiveSuspended();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getAid();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getHid();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getSettlementNote();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getMagazine();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
};


/**
 * optional string competition_short_name = 1;
 * @return {string}
 */
proto.odds_stream.EventSC.prototype.getCompetitionShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setCompetitionShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 competition_id = 3;
 * @return {number}
 */
proto.odds_stream.EventSC.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string competition_name = 4;
 * @return {string}
 */
proto.odds_stream.EventSC.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * map<string, Odd> all_odds = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds_stream.Odd>}
 */
proto.odds_stream.EventSC.prototype.getAllOddsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds_stream.Odd>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.odds_stream.Odd));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.clearAllOddsMap = function() {
  this.getAllOddsMap().clear();
  return this;};


/**
 * optional string start = 6;
 * @return {string}
 */
proto.odds_stream.EventSC.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 sport_id = 7;
 * @return {number}
 */
proto.odds_stream.EventSC.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 location_id = 8;
 * @return {number}
 */
proto.odds_stream.EventSC.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 landbase_code = 9;
 * @return {number}
 */
proto.odds_stream.EventSC.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string note = 10;
 * @return {string}
 */
proto.odds_stream.EventSC.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.protobuf.Struct current_result = 11;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.EventSC.prototype.getCurrentResult = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 11));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.EventSC} returns this
*/
proto.odds_stream.EventSC.prototype.setCurrentResult = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.clearCurrentResult = function() {
  return this.setCurrentResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventSC.prototype.hasCurrentResult = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.protobuf.Struct current_phase = 12;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.EventSC.prototype.getCurrentPhase = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 12));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.EventSC} returns this
*/
proto.odds_stream.EventSC.prototype.setCurrentPhase = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.clearCurrentPhase = function() {
  return this.setCurrentPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventSC.prototype.hasCurrentPhase = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.protobuf.Struct period_result = 13;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.EventSC.prototype.getPeriodResult = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 13));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.EventSC} returns this
*/
proto.odds_stream.EventSC.prototype.setPeriodResult = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.clearPeriodResult = function() {
  return this.setPeriodResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventSC.prototype.hasPeriodResult = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional bool prematch_suspended = 14;
 * @return {boolean}
 */
proto.odds_stream.EventSC.prototype.getPrematchSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setPrematchSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional int32 int_key = 15;
 * @return {number}
 */
proto.odds_stream.EventSC.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * map<string, bool> markets = 16;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.odds_stream.EventSC.prototype.getMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 16, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.clearMarketsMap = function() {
  this.getMarketsMap().clear();
  return this;};


/**
 * optional string betradar_id = 17;
 * @return {string}
 */
proto.odds_stream.EventSC.prototype.getBetradarId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setBetradarId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string home = 19;
 * @return {string}
 */
proto.odds_stream.EventSC.prototype.getHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setHome = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string away = 20;
 * @return {string}
 */
proto.odds_stream.EventSC.prototype.getAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setAway = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional bool bet_stop = 21;
 * @return {boolean}
 */
proto.odds_stream.EventSC.prototype.getBetStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setBetStop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool live_suspended = 22;
 * @return {boolean}
 */
proto.odds_stream.EventSC.prototype.getLiveSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setLiveSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional int32 aid = 23;
 * @return {number}
 */
proto.odds_stream.EventSC.prototype.getAid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setAid = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 hid = 24;
 * @return {number}
 */
proto.odds_stream.EventSC.prototype.getHid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setHid = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional string settlement_note = 25;
 * @return {string}
 */
proto.odds_stream.EventSC.prototype.getSettlementNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setSettlementNote = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional bool magazine = 26;
 * @return {boolean}
 */
proto.odds_stream.EventSC.prototype.getMagazine = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventSC} returns this
 */
proto.odds_stream.EventSC.prototype.setMagazine = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.GetEventsResponseSC.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.GetEventsResponseSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.GetEventsResponseSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.GetEventsResponseSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsResponseSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    prematchEventsList: jspb.Message.toObjectList(msg.getPrematchEventsList(),
    proto.odds_stream.EventSC.toObject, includeInstance),
    liveEventsList: jspb.Message.toObjectList(msg.getLiveEventsList(),
    proto.odds_stream.EventSC.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.GetEventsResponseSC}
 */
proto.odds_stream.GetEventsResponseSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.GetEventsResponseSC;
  return proto.odds_stream.GetEventsResponseSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.GetEventsResponseSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.GetEventsResponseSC}
 */
proto.odds_stream.GetEventsResponseSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds_stream.EventSC;
      reader.readMessage(value,proto.odds_stream.EventSC.deserializeBinaryFromReader);
      msg.addPrematchEvents(value);
      break;
    case 2:
      var value = new proto.odds_stream.EventSC;
      reader.readMessage(value,proto.odds_stream.EventSC.deserializeBinaryFromReader);
      msg.addLiveEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.GetEventsResponseSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.GetEventsResponseSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.GetEventsResponseSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsResponseSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrematchEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds_stream.EventSC.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.odds_stream.EventSC.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventSC prematch_events = 1;
 * @return {!Array<!proto.odds_stream.EventSC>}
 */
proto.odds_stream.GetEventsResponseSC.prototype.getPrematchEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.EventSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.EventSC, 1));
};


/**
 * @param {!Array<!proto.odds_stream.EventSC>} value
 * @return {!proto.odds_stream.GetEventsResponseSC} returns this
*/
proto.odds_stream.GetEventsResponseSC.prototype.setPrematchEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds_stream.EventSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.EventSC}
 */
proto.odds_stream.GetEventsResponseSC.prototype.addPrematchEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds_stream.EventSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.GetEventsResponseSC} returns this
 */
proto.odds_stream.GetEventsResponseSC.prototype.clearPrematchEventsList = function() {
  return this.setPrematchEventsList([]);
};


/**
 * repeated EventSC live_events = 2;
 * @return {!Array<!proto.odds_stream.EventSC>}
 */
proto.odds_stream.GetEventsResponseSC.prototype.getLiveEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.EventSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.EventSC, 2));
};


/**
 * @param {!Array<!proto.odds_stream.EventSC>} value
 * @return {!proto.odds_stream.GetEventsResponseSC} returns this
*/
proto.odds_stream.GetEventsResponseSC.prototype.setLiveEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.odds_stream.EventSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.EventSC}
 */
proto.odds_stream.GetEventsResponseSC.prototype.addLiveEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.odds_stream.EventSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.GetEventsResponseSC} returns this
 */
proto.odds_stream.GetEventsResponseSC.prototype.clearLiveEventsList = function() {
  return this.setLiveEventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.OddBO.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.OddBO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.OddBO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.OddBO.toObject = function(includeInstance, msg) {
  var f, obj = {
    intKey: jspb.Message.getFieldWithDefault(msg, 1, 0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    limit2: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    oddValue: jspb.Message.getFieldWithDefault(msg, 4, 0),
    outcomeName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    marketId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    marketName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    oddRealValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    isSuspendedL1: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    setnr: jspb.Message.getFloatingPointFieldWithDefault(msg, 12, 0.0),
    gamenr: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    periodnr: jspb.Message.getFloatingPointFieldWithDefault(msg, 14, 0.0),
    quarternr: jspb.Message.getFloatingPointFieldWithDefault(msg, 15, 0.0),
    manualChanged: jspb.Message.getBooleanFieldWithDefault(msg, 16, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.OddBO}
 */
proto.odds_stream.OddBO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.OddBO;
  return proto.odds_stream.OddBO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.OddBO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.OddBO}
 */
proto.odds_stream.OddBO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit2(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOddValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOutcomeName(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOddRealValue(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspendedL1(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSetnr(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setGamenr(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPeriodnr(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setQuarternr(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualChanged(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.OddBO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.OddBO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.OddBO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.OddBO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getLimit2();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getOddValue();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getOutcomeName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMarketId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMarketName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOddRealValue();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getIsSuspendedL1();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getSetnr();
  if (f !== 0.0) {
    writer.writeDouble(
      12,
      f
    );
  }
  f = message.getGamenr();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getPeriodnr();
  if (f !== 0.0) {
    writer.writeDouble(
      14,
      f
    );
  }
  f = message.getQuarternr();
  if (f !== 0.0) {
    writer.writeDouble(
      15,
      f
    );
  }
  f = message.getManualChanged();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
};


/**
 * optional int32 int_key = 1;
 * @return {number}
 */
proto.odds_stream.OddBO.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.OddBO} returns this
 */
proto.odds_stream.OddBO.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional double limit = 2;
 * @return {number}
 */
proto.odds_stream.OddBO.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.OddBO} returns this
 */
proto.odds_stream.OddBO.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double limit2 = 3;
 * @return {number}
 */
proto.odds_stream.OddBO.prototype.getLimit2 = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.OddBO} returns this
 */
proto.odds_stream.OddBO.prototype.setLimit2 = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 odd_value = 4;
 * @return {number}
 */
proto.odds_stream.OddBO.prototype.getOddValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.OddBO} returns this
 */
proto.odds_stream.OddBO.prototype.setOddValue = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string outcome_name = 5;
 * @return {string}
 */
proto.odds_stream.OddBO.prototype.getOutcomeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.OddBO} returns this
 */
proto.odds_stream.OddBO.prototype.setOutcomeName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 market_id = 6;
 * @return {number}
 */
proto.odds_stream.OddBO.prototype.getMarketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.OddBO} returns this
 */
proto.odds_stream.OddBO.prototype.setMarketId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string market_name = 7;
 * @return {string}
 */
proto.odds_stream.OddBO.prototype.getMarketName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.OddBO} returns this
 */
proto.odds_stream.OddBO.prototype.setMarketName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional double odd_real_value = 8;
 * @return {number}
 */
proto.odds_stream.OddBO.prototype.getOddRealValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.OddBO} returns this
 */
proto.odds_stream.OddBO.prototype.setOddRealValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional int32 outcome_id = 9;
 * @return {number}
 */
proto.odds_stream.OddBO.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.OddBO} returns this
 */
proto.odds_stream.OddBO.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool is_suspended_l1 = 10;
 * @return {boolean}
 */
proto.odds_stream.OddBO.prototype.getIsSuspendedL1 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.OddBO} returns this
 */
proto.odds_stream.OddBO.prototype.setIsSuspendedL1 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional bool is_suspended = 11;
 * @return {boolean}
 */
proto.odds_stream.OddBO.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.OddBO} returns this
 */
proto.odds_stream.OddBO.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional double setnr = 12;
 * @return {number}
 */
proto.odds_stream.OddBO.prototype.getSetnr = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 12, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.OddBO} returns this
 */
proto.odds_stream.OddBO.prototype.setSetnr = function(value) {
  return jspb.Message.setProto3FloatField(this, 12, value);
};


/**
 * optional double gamenr = 13;
 * @return {number}
 */
proto.odds_stream.OddBO.prototype.getGamenr = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.OddBO} returns this
 */
proto.odds_stream.OddBO.prototype.setGamenr = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional double periodnr = 14;
 * @return {number}
 */
proto.odds_stream.OddBO.prototype.getPeriodnr = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 14, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.OddBO} returns this
 */
proto.odds_stream.OddBO.prototype.setPeriodnr = function(value) {
  return jspb.Message.setProto3FloatField(this, 14, value);
};


/**
 * optional double quarternr = 15;
 * @return {number}
 */
proto.odds_stream.OddBO.prototype.getQuarternr = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 15, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.OddBO} returns this
 */
proto.odds_stream.OddBO.prototype.setQuarternr = function(value) {
  return jspb.Message.setProto3FloatField(this, 15, value);
};


/**
 * optional bool manual_changed = 16;
 * @return {boolean}
 */
proto.odds_stream.OddBO.prototype.getManualChanged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.OddBO} returns this
 */
proto.odds_stream.OddBO.prototype.setManualChanged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.OperatorNote.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.OperatorNote.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.OperatorNote.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.OperatorNote} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.OperatorNote.toObject = function(includeInstance, msg) {
  var f, obj = {
    note: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    operatorName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, ""),
    paramsList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.OperatorNote}
 */
proto.odds_stream.OperatorNote.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.OperatorNote;
  return proto.odds_stream.OperatorNote.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.OperatorNote} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.OperatorNote}
 */
proto.odds_stream.OperatorNote.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setUserId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperatorName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedAt(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addParams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.OperatorNote.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.OperatorNote.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.OperatorNote} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.OperatorNote.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOperatorName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getParamsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
};


/**
 * optional string note = 2;
 * @return {string}
 */
proto.odds_stream.OperatorNote.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.OperatorNote} returns this
 */
proto.odds_stream.OperatorNote.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 user_id = 3;
 * @return {number}
 */
proto.odds_stream.OperatorNote.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.OperatorNote} returns this
 */
proto.odds_stream.OperatorNote.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string operator_name = 4;
 * @return {string}
 */
proto.odds_stream.OperatorNote.prototype.getOperatorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.OperatorNote} returns this
 */
proto.odds_stream.OperatorNote.prototype.setOperatorName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string created_at = 5;
 * @return {string}
 */
proto.odds_stream.OperatorNote.prototype.getCreatedAt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.OperatorNote} returns this
 */
proto.odds_stream.OperatorNote.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string params = 6;
 * @return {!Array<string>}
 */
proto.odds_stream.OperatorNote.prototype.getParamsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds_stream.OperatorNote} returns this
 */
proto.odds_stream.OperatorNote.prototype.setParamsList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.OperatorNote} returns this
 */
proto.odds_stream.OperatorNote.prototype.addParams = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.OperatorNote} returns this
 */
proto.odds_stream.OperatorNote.prototype.clearParamsList = function() {
  return this.setParamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.PeriodResult.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.PeriodResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.PeriodResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.PeriodResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    home: jspb.Message.getFieldWithDefault(msg, 1, 0),
    away: jspb.Message.getFieldWithDefault(msg, 2, 0),
    periodName: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.PeriodResult}
 */
proto.odds_stream.PeriodResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.PeriodResult;
  return proto.odds_stream.PeriodResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.PeriodResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.PeriodResult}
 */
proto.odds_stream.PeriodResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHome(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAway(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPeriodName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.PeriodResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.PeriodResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.PeriodResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.PeriodResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHome();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAway();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getPeriodName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 home = 1;
 * @return {number}
 */
proto.odds_stream.PeriodResult.prototype.getHome = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PeriodResult} returns this
 */
proto.odds_stream.PeriodResult.prototype.setHome = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 away = 2;
 * @return {number}
 */
proto.odds_stream.PeriodResult.prototype.getAway = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PeriodResult} returns this
 */
proto.odds_stream.PeriodResult.prototype.setAway = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string period_name = 3;
 * @return {string}
 */
proto.odds_stream.PeriodResult.prototype.getPeriodName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PeriodResult} returns this
 */
proto.odds_stream.PeriodResult.prototype.setPeriodName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.EventLiveBO.repeatedFields_ = [21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.EventLiveBO.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.EventLiveBO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.EventLiveBO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventLiveBO.toObject = function(includeInstance, msg) {
  var f, obj = {
    intKey: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    start: jspb.Message.getFieldWithDefault(msg, 3, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    home: jspb.Message.getFieldWithDefault(msg, 9, ""),
    away: jspb.Message.getFieldWithDefault(msg, 10, ""),
    liveMarketTemplateId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    liveOutcomeTemplateId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    liveApprovalTemplateId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    liveProvider: jspb.Message.getFieldWithDefault(msg, 14, ""),
    state: jspb.Message.getFieldWithDefault(msg, 15, ""),
    stage: jspb.Message.getFieldWithDefault(msg, 16, ""),
    operatorConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    baseLiveMarketsMap: (f = msg.getBaseLiveMarketsMap()) ? f.toObject(includeInstance, proto.odds_stream.OddBO.toObject) : [],
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 19, 0),
    note: jspb.Message.getFieldWithDefault(msg, 20, ""),
    operatorNotesList: jspb.Message.toObjectList(msg.getOperatorNotesList(),
    proto.odds_stream.OperatorNote.toObject, includeInstance),
    liveSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    currentResult: (f = msg.getCurrentResult()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    periodResult: (f = msg.getPeriodResult()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    manualChanged: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    liveMaxAmountWeb: jspb.Message.getFieldWithDefault(msg, 26, 0),
    liveMaxAmountSc: jspb.Message.getFieldWithDefault(msg, 27, 0),
    settlementLiveStop: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    currentPhase: (f = msg.getCurrentPhase()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    liveStatus: jspb.Message.getFieldWithDefault(msg, 30, ""),
    hasSlip: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    competitionName: jspb.Message.getFieldWithDefault(msg, 32, ""),
    locationName: jspb.Message.getFieldWithDefault(msg, 33, ""),
    betStop: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    competitionConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 35, false),
    competitorsConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
    canPublish: jspb.Message.getBooleanFieldWithDefault(msg, 37, false),
    prematchOffer: jspb.Message.getBooleanFieldWithDefault(msg, 38, false),
    liveOffer: jspb.Message.getBooleanFieldWithDefault(msg, 39, false),
    settlementNote: jspb.Message.getFieldWithDefault(msg, 40, ""),
    goesThrough: jspb.Message.getBooleanFieldWithDefault(msg, 41, false),
    homeConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 42, false),
    awayConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 43, false),
    liveProviders: (f = msg.getLiveProviders()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    marketsMap: (f = msg.getMarketsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.EventLiveBO}
 */
proto.odds_stream.EventLiveBO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.EventLiveBO;
  return proto.odds_stream.EventLiveBO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.EventLiveBO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.EventLiveBO}
 */
proto.odds_stream.EventLiveBO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHome(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAway(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveMarketTemplateId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveOutcomeTemplateId(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveApprovalTemplateId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveProvider(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setStage(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOperatorConfirmed(value);
      break;
    case 18:
      var value = msg.getBaseLiveMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds_stream.OddBO.deserializeBinaryFromReader, "", new proto.odds_stream.OddBO());
         });
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 21:
      var value = new proto.odds_stream.OperatorNote;
      reader.readMessage(value,proto.odds_stream.OperatorNote.deserializeBinaryFromReader);
      msg.addOperatorNotes(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiveSuspended(value);
      break;
    case 23:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCurrentResult(value);
      break;
    case 24:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setPeriodResult(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualChanged(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveMaxAmountWeb(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveMaxAmountSc(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSettlementLiveStop(value);
      break;
    case 29:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCurrentPhase(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveStatus(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSlip(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBetStop(value);
      break;
    case 35:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompetitionConfirmed(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompetitorsConfirmed(value);
      break;
    case 37:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanPublish(value);
      break;
    case 38:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrematchOffer(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiveOffer(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementNote(value);
      break;
    case 41:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGoesThrough(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHomeConfirmed(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAwayConfirmed(value);
      break;
    case 44:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setLiveProviders(value);
      break;
    case 45:
      var value = msg.getMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.EventLiveBO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.EventLiveBO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.EventLiveBO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventLiveBO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getHome();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAway();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLiveMarketTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getLiveOutcomeTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getLiveApprovalTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getLiveProvider();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStage();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getOperatorConfirmed();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getBaseLiveMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(18, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds_stream.OddBO.serializeBinaryToWriter);
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getOperatorNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.odds_stream.OperatorNote.serializeBinaryToWriter
    );
  }
  f = message.getLiveSuspended();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getCurrentResult();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getPeriodResult();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getManualChanged();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getLiveMaxAmountWeb();
  if (f !== 0) {
    writer.writeInt32(
      26,
      f
    );
  }
  f = message.getLiveMaxAmountSc();
  if (f !== 0) {
    writer.writeInt32(
      27,
      f
    );
  }
  f = message.getSettlementLiveStop();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getCurrentPhase();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getLiveStatus();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getHasSlip();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getBetStop();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getCompetitionConfirmed();
  if (f) {
    writer.writeBool(
      35,
      f
    );
  }
  f = message.getCompetitorsConfirmed();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getCanPublish();
  if (f) {
    writer.writeBool(
      37,
      f
    );
  }
  f = message.getPrematchOffer();
  if (f) {
    writer.writeBool(
      38,
      f
    );
  }
  f = message.getLiveOffer();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getSettlementNote();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getGoesThrough();
  if (f) {
    writer.writeBool(
      41,
      f
    );
  }
  f = message.getHomeConfirmed();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
  f = message.getAwayConfirmed();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getLiveProviders();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(45, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
};


/**
 * optional int32 int_key = 1;
 * @return {number}
 */
proto.odds_stream.EventLiveBO.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds_stream.EventLiveBO.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string start = 3;
 * @return {string}
 */
proto.odds_stream.EventLiveBO.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 competition_id = 4;
 * @return {number}
 */
proto.odds_stream.EventLiveBO.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 location_id = 6;
 * @return {number}
 */
proto.odds_stream.EventLiveBO.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 sport_id = 8;
 * @return {number}
 */
proto.odds_stream.EventLiveBO.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string home = 9;
 * @return {string}
 */
proto.odds_stream.EventLiveBO.prototype.getHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setHome = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string away = 10;
 * @return {string}
 */
proto.odds_stream.EventLiveBO.prototype.getAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setAway = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 live_market_template_id = 11;
 * @return {number}
 */
proto.odds_stream.EventLiveBO.prototype.getLiveMarketTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setLiveMarketTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 live_outcome_template_id = 12;
 * @return {number}
 */
proto.odds_stream.EventLiveBO.prototype.getLiveOutcomeTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setLiveOutcomeTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 live_approval_template_id = 13;
 * @return {number}
 */
proto.odds_stream.EventLiveBO.prototype.getLiveApprovalTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setLiveApprovalTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string live_provider = 14;
 * @return {string}
 */
proto.odds_stream.EventLiveBO.prototype.getLiveProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setLiveProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string state = 15;
 * @return {string}
 */
proto.odds_stream.EventLiveBO.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string stage = 16;
 * @return {string}
 */
proto.odds_stream.EventLiveBO.prototype.getStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setStage = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool operator_confirmed = 17;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.getOperatorConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setOperatorConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * map<string, OddBO> base_live_markets = 18;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds_stream.OddBO>}
 */
proto.odds_stream.EventLiveBO.prototype.getBaseLiveMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds_stream.OddBO>} */ (
      jspb.Message.getMapField(this, 18, opt_noLazyCreate,
      proto.odds_stream.OddBO));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.clearBaseLiveMarketsMap = function() {
  this.getBaseLiveMarketsMap().clear();
  return this;};


/**
 * optional int32 landbase_code = 19;
 * @return {number}
 */
proto.odds_stream.EventLiveBO.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string note = 20;
 * @return {string}
 */
proto.odds_stream.EventLiveBO.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * repeated OperatorNote operator_notes = 21;
 * @return {!Array<!proto.odds_stream.OperatorNote>}
 */
proto.odds_stream.EventLiveBO.prototype.getOperatorNotesList = function() {
  return /** @type{!Array<!proto.odds_stream.OperatorNote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.OperatorNote, 21));
};


/**
 * @param {!Array<!proto.odds_stream.OperatorNote>} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
*/
proto.odds_stream.EventLiveBO.prototype.setOperatorNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.odds_stream.OperatorNote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.OperatorNote}
 */
proto.odds_stream.EventLiveBO.prototype.addOperatorNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.odds_stream.OperatorNote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.clearOperatorNotesList = function() {
  return this.setOperatorNotesList([]);
};


/**
 * optional bool live_suspended = 22;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.getLiveSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setLiveSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional google.protobuf.Struct current_result = 23;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.EventLiveBO.prototype.getCurrentResult = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 23));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
*/
proto.odds_stream.EventLiveBO.prototype.setCurrentResult = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.clearCurrentResult = function() {
  return this.setCurrentResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.hasCurrentResult = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.Struct period_result = 24;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.EventLiveBO.prototype.getPeriodResult = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 24));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
*/
proto.odds_stream.EventLiveBO.prototype.setPeriodResult = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.clearPeriodResult = function() {
  return this.setPeriodResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.hasPeriodResult = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional bool manual_changed = 25;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.getManualChanged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setManualChanged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional int32 live_max_amount_web = 26;
 * @return {number}
 */
proto.odds_stream.EventLiveBO.prototype.getLiveMaxAmountWeb = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setLiveMaxAmountWeb = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional int32 live_max_amount_sc = 27;
 * @return {number}
 */
proto.odds_stream.EventLiveBO.prototype.getLiveMaxAmountSc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setLiveMaxAmountSc = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional bool settlement_live_stop = 28;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.getSettlementLiveStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setSettlementLiveStop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional google.protobuf.Struct current_phase = 29;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.EventLiveBO.prototype.getCurrentPhase = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 29));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
*/
proto.odds_stream.EventLiveBO.prototype.setCurrentPhase = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.clearCurrentPhase = function() {
  return this.setCurrentPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.hasCurrentPhase = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional string live_status = 30;
 * @return {string}
 */
proto.odds_stream.EventLiveBO.prototype.getLiveStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setLiveStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional bool has_slip = 31;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.getHasSlip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setHasSlip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional string competition_name = 32;
 * @return {string}
 */
proto.odds_stream.EventLiveBO.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string location_name = 33;
 * @return {string}
 */
proto.odds_stream.EventLiveBO.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional bool bet_stop = 34;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.getBetStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setBetStop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional bool competition_confirmed = 35;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.getCompetitionConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 35, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setCompetitionConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 35, value);
};


/**
 * optional bool competitors_confirmed = 36;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.getCompetitorsConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setCompetitorsConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional bool can_publish = 37;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.getCanPublish = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 37, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setCanPublish = function(value) {
  return jspb.Message.setProto3BooleanField(this, 37, value);
};


/**
 * optional bool prematch_offer = 38;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.getPrematchOffer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 38, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setPrematchOffer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 38, value);
};


/**
 * optional bool live_offer = 39;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.getLiveOffer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 39, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setLiveOffer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * optional string settlement_note = 40;
 * @return {string}
 */
proto.odds_stream.EventLiveBO.prototype.getSettlementNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setSettlementNote = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional bool goes_through = 41;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.getGoesThrough = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 41, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setGoesThrough = function(value) {
  return jspb.Message.setProto3BooleanField(this, 41, value);
};


/**
 * optional bool home_confirmed = 42;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.getHomeConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setHomeConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};


/**
 * optional bool away_confirmed = 43;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.getAwayConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.setAwayConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional google.protobuf.Struct live_providers = 44;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.EventLiveBO.prototype.getLiveProviders = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 44));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.EventLiveBO} returns this
*/
proto.odds_stream.EventLiveBO.prototype.setLiveProviders = function(value) {
  return jspb.Message.setWrapperField(this, 44, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.clearLiveProviders = function() {
  return this.setLiveProviders(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventLiveBO.prototype.hasLiveProviders = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * map<string, bool> markets = 45;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.odds_stream.EventLiveBO.prototype.getMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 45, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.EventLiveBO} returns this
 */
proto.odds_stream.EventLiveBO.prototype.clearMarketsMap = function() {
  this.getMarketsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.EventLiveBOMap.repeatedFields_ = [21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.EventLiveBOMap.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.EventLiveBOMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.EventLiveBOMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventLiveBOMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    intKey: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    start: jspb.Message.getFieldWithDefault(msg, 3, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    home: jspb.Message.getFieldWithDefault(msg, 9, ""),
    away: jspb.Message.getFieldWithDefault(msg, 10, ""),
    liveMarketTemplateId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    liveOutcomeTemplateId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    liveApprovalTemplateId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    liveProvider: jspb.Message.getFieldWithDefault(msg, 14, ""),
    liveState: jspb.Message.getFieldWithDefault(msg, 15, ""),
    stage: jspb.Message.getFieldWithDefault(msg, 16, ""),
    operatorConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    baseLiveMarketsMap: (f = msg.getBaseLiveMarketsMap()) ? f.toObject(includeInstance, proto.odds_stream.OddBO.toObject) : [],
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 19, 0),
    note: jspb.Message.getFieldWithDefault(msg, 20, ""),
    operatorNotesList: jspb.Message.toObjectList(msg.getOperatorNotesList(),
    proto.odds_stream.OperatorNote.toObject, includeInstance),
    liveSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    currentResult: (f = msg.getCurrentResult()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    periodResult: (f = msg.getPeriodResult()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    manualChanged: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    liveMaxAmountWeb: jspb.Message.getFieldWithDefault(msg, 26, 0),
    liveMaxAmountSc: jspb.Message.getFieldWithDefault(msg, 27, 0),
    settlementLiveStop: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    currentPhase: (f = msg.getCurrentPhase()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    liveStatus: jspb.Message.getFieldWithDefault(msg, 30, ""),
    hasSlip: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    competitionName: jspb.Message.getFieldWithDefault(msg, 32, ""),
    locationName: jspb.Message.getFieldWithDefault(msg, 33, ""),
    betStop: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    competitionConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 35, false),
    competitorsConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
    canPublish: jspb.Message.getBooleanFieldWithDefault(msg, 37, false),
    prematchOffer: jspb.Message.getBooleanFieldWithDefault(msg, 38, false),
    liveOffer: jspb.Message.getBooleanFieldWithDefault(msg, 39, false),
    settlementNote: jspb.Message.getFieldWithDefault(msg, 40, ""),
    goesThrough: jspb.Message.getBooleanFieldWithDefault(msg, 41, false),
    homeConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 42, false),
    awayConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 43, false),
    liveProviders: (f = msg.getLiveProviders()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    marketsMap: (f = msg.getMarketsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.EventLiveBOMap}
 */
proto.odds_stream.EventLiveBOMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.EventLiveBOMap;
  return proto.odds_stream.EventLiveBOMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.EventLiveBOMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.EventLiveBOMap}
 */
proto.odds_stream.EventLiveBOMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setHome(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAway(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveMarketTemplateId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveOutcomeTemplateId(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveApprovalTemplateId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveProvider(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveState(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setStage(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setOperatorConfirmed(value);
      break;
    case 18:
      var value = msg.getBaseLiveMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds_stream.OddBO.deserializeBinaryFromReader, "", new proto.odds_stream.OddBO());
         });
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 21:
      var value = new proto.odds_stream.OperatorNote;
      reader.readMessage(value,proto.odds_stream.OperatorNote.deserializeBinaryFromReader);
      msg.addOperatorNotes(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiveSuspended(value);
      break;
    case 23:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCurrentResult(value);
      break;
    case 24:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setPeriodResult(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualChanged(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveMaxAmountWeb(value);
      break;
    case 27:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveMaxAmountSc(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSettlementLiveStop(value);
      break;
    case 29:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCurrentPhase(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveStatus(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSlip(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBetStop(value);
      break;
    case 35:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompetitionConfirmed(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompetitorsConfirmed(value);
      break;
    case 37:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanPublish(value);
      break;
    case 38:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrematchOffer(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiveOffer(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementNote(value);
      break;
    case 41:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGoesThrough(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHomeConfirmed(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAwayConfirmed(value);
      break;
    case 44:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setLiveProviders(value);
      break;
    case 45:
      var value = msg.getMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.EventLiveBOMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.EventLiveBOMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.EventLiveBOMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventLiveBOMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getHome();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getAway();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLiveMarketTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getLiveOutcomeTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getLiveApprovalTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getLiveProvider();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLiveState();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStage();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getOperatorConfirmed();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getBaseLiveMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(18, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds_stream.OddBO.serializeBinaryToWriter);
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      19,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getOperatorNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.odds_stream.OperatorNote.serializeBinaryToWriter
    );
  }
  f = message.getLiveSuspended();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getCurrentResult();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getPeriodResult();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getManualChanged();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getLiveMaxAmountWeb();
  if (f !== 0) {
    writer.writeInt32(
      26,
      f
    );
  }
  f = message.getLiveMaxAmountSc();
  if (f !== 0) {
    writer.writeInt32(
      27,
      f
    );
  }
  f = message.getSettlementLiveStop();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getCurrentPhase();
  if (f != null) {
    writer.writeMessage(
      29,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getLiveStatus();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getHasSlip();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getBetStop();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getCompetitionConfirmed();
  if (f) {
    writer.writeBool(
      35,
      f
    );
  }
  f = message.getCompetitorsConfirmed();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getCanPublish();
  if (f) {
    writer.writeBool(
      37,
      f
    );
  }
  f = message.getPrematchOffer();
  if (f) {
    writer.writeBool(
      38,
      f
    );
  }
  f = message.getLiveOffer();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getSettlementNote();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getGoesThrough();
  if (f) {
    writer.writeBool(
      41,
      f
    );
  }
  f = message.getHomeConfirmed();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
  f = message.getAwayConfirmed();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getLiveProviders();
  if (f != null) {
    writer.writeMessage(
      44,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(45, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
};


/**
 * optional int32 int_key = 1;
 * @return {number}
 */
proto.odds_stream.EventLiveBOMap.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds_stream.EventLiveBOMap.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string start = 3;
 * @return {string}
 */
proto.odds_stream.EventLiveBOMap.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 competition_id = 4;
 * @return {number}
 */
proto.odds_stream.EventLiveBOMap.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 location_id = 6;
 * @return {number}
 */
proto.odds_stream.EventLiveBOMap.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 sport_id = 8;
 * @return {number}
 */
proto.odds_stream.EventLiveBOMap.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string home = 9;
 * @return {string}
 */
proto.odds_stream.EventLiveBOMap.prototype.getHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setHome = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string away = 10;
 * @return {string}
 */
proto.odds_stream.EventLiveBOMap.prototype.getAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setAway = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 live_market_template_id = 11;
 * @return {number}
 */
proto.odds_stream.EventLiveBOMap.prototype.getLiveMarketTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setLiveMarketTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 live_outcome_template_id = 12;
 * @return {number}
 */
proto.odds_stream.EventLiveBOMap.prototype.getLiveOutcomeTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setLiveOutcomeTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 live_approval_template_id = 13;
 * @return {number}
 */
proto.odds_stream.EventLiveBOMap.prototype.getLiveApprovalTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setLiveApprovalTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string live_provider = 14;
 * @return {string}
 */
proto.odds_stream.EventLiveBOMap.prototype.getLiveProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setLiveProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string live_state = 15;
 * @return {string}
 */
proto.odds_stream.EventLiveBOMap.prototype.getLiveState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setLiveState = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string stage = 16;
 * @return {string}
 */
proto.odds_stream.EventLiveBOMap.prototype.getStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setStage = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool operator_confirmed = 17;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.getOperatorConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setOperatorConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * map<string, OddBO> base_live_markets = 18;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds_stream.OddBO>}
 */
proto.odds_stream.EventLiveBOMap.prototype.getBaseLiveMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds_stream.OddBO>} */ (
      jspb.Message.getMapField(this, 18, opt_noLazyCreate,
      proto.odds_stream.OddBO));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.clearBaseLiveMarketsMap = function() {
  this.getBaseLiveMarketsMap().clear();
  return this;};


/**
 * optional int32 landbase_code = 19;
 * @return {number}
 */
proto.odds_stream.EventLiveBOMap.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional string note = 20;
 * @return {string}
 */
proto.odds_stream.EventLiveBOMap.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * repeated OperatorNote operator_notes = 21;
 * @return {!Array<!proto.odds_stream.OperatorNote>}
 */
proto.odds_stream.EventLiveBOMap.prototype.getOperatorNotesList = function() {
  return /** @type{!Array<!proto.odds_stream.OperatorNote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.OperatorNote, 21));
};


/**
 * @param {!Array<!proto.odds_stream.OperatorNote>} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
*/
proto.odds_stream.EventLiveBOMap.prototype.setOperatorNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.odds_stream.OperatorNote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.OperatorNote}
 */
proto.odds_stream.EventLiveBOMap.prototype.addOperatorNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.odds_stream.OperatorNote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.clearOperatorNotesList = function() {
  return this.setOperatorNotesList([]);
};


/**
 * optional bool live_suspended = 22;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.getLiveSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setLiveSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional google.protobuf.Struct current_result = 23;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.EventLiveBOMap.prototype.getCurrentResult = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 23));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
*/
proto.odds_stream.EventLiveBOMap.prototype.setCurrentResult = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.clearCurrentResult = function() {
  return this.setCurrentResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.hasCurrentResult = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.Struct period_result = 24;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.EventLiveBOMap.prototype.getPeriodResult = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 24));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
*/
proto.odds_stream.EventLiveBOMap.prototype.setPeriodResult = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.clearPeriodResult = function() {
  return this.setPeriodResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.hasPeriodResult = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional bool manual_changed = 25;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.getManualChanged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setManualChanged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * optional int32 live_max_amount_web = 26;
 * @return {number}
 */
proto.odds_stream.EventLiveBOMap.prototype.getLiveMaxAmountWeb = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setLiveMaxAmountWeb = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional int32 live_max_amount_sc = 27;
 * @return {number}
 */
proto.odds_stream.EventLiveBOMap.prototype.getLiveMaxAmountSc = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 27, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setLiveMaxAmountSc = function(value) {
  return jspb.Message.setProto3IntField(this, 27, value);
};


/**
 * optional bool settlement_live_stop = 28;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.getSettlementLiveStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setSettlementLiveStop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional google.protobuf.Struct current_phase = 29;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.EventLiveBOMap.prototype.getCurrentPhase = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 29));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
*/
proto.odds_stream.EventLiveBOMap.prototype.setCurrentPhase = function(value) {
  return jspb.Message.setWrapperField(this, 29, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.clearCurrentPhase = function() {
  return this.setCurrentPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.hasCurrentPhase = function() {
  return jspb.Message.getField(this, 29) != null;
};


/**
 * optional string live_status = 30;
 * @return {string}
 */
proto.odds_stream.EventLiveBOMap.prototype.getLiveStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setLiveStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional bool has_slip = 31;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.getHasSlip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setHasSlip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional string competition_name = 32;
 * @return {string}
 */
proto.odds_stream.EventLiveBOMap.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string location_name = 33;
 * @return {string}
 */
proto.odds_stream.EventLiveBOMap.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional bool bet_stop = 34;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.getBetStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setBetStop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional bool competition_confirmed = 35;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.getCompetitionConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 35, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setCompetitionConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 35, value);
};


/**
 * optional bool competitors_confirmed = 36;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.getCompetitorsConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setCompetitorsConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional bool can_publish = 37;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.getCanPublish = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 37, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setCanPublish = function(value) {
  return jspb.Message.setProto3BooleanField(this, 37, value);
};


/**
 * optional bool prematch_offer = 38;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.getPrematchOffer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 38, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setPrematchOffer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 38, value);
};


/**
 * optional bool live_offer = 39;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.getLiveOffer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 39, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setLiveOffer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * optional string settlement_note = 40;
 * @return {string}
 */
proto.odds_stream.EventLiveBOMap.prototype.getSettlementNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setSettlementNote = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional bool goes_through = 41;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.getGoesThrough = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 41, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setGoesThrough = function(value) {
  return jspb.Message.setProto3BooleanField(this, 41, value);
};


/**
 * optional bool home_confirmed = 42;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.getHomeConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setHomeConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};


/**
 * optional bool away_confirmed = 43;
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.getAwayConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.setAwayConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional google.protobuf.Struct live_providers = 44;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.EventLiveBOMap.prototype.getLiveProviders = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 44));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
*/
proto.odds_stream.EventLiveBOMap.prototype.setLiveProviders = function(value) {
  return jspb.Message.setWrapperField(this, 44, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.clearLiveProviders = function() {
  return this.setLiveProviders(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.EventLiveBOMap.prototype.hasLiveProviders = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * map<string, bool> markets = 45;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.odds_stream.EventLiveBOMap.prototype.getMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 45, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.EventLiveBOMap} returns this
 */
proto.odds_stream.EventLiveBOMap.prototype.clearMarketsMap = function() {
  this.getMarketsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.EventCreateBO.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.EventCreateBO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.EventCreateBO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventCreateBO.toObject = function(includeInstance, msg) {
  var f, obj = {
    home: jspb.Message.getFieldWithDefault(msg, 1, ""),
    away: jspb.Message.getFieldWithDefault(msg, 2, ""),
    intKey: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    locationName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    competitionName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    start: jspb.Message.getFieldWithDefault(msg, 7, ""),
    hasInputParams: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    name: jspb.Message.getFieldWithDefault(msg, 9, ""),
    prematchProvider: jspb.Message.getFieldWithDefault(msg, 10, ""),
    competitionConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    competitorsConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    state: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.EventCreateBO}
 */
proto.odds_stream.EventCreateBO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.EventCreateBO;
  return proto.odds_stream.EventCreateBO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.EventCreateBO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.EventCreateBO}
 */
proto.odds_stream.EventCreateBO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHome(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAway(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasInputParams(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrematchProvider(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompetitionConfirmed(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompetitorsConfirmed(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.EventCreateBO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.EventCreateBO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.EventCreateBO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventCreateBO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHome();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAway();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHasInputParams();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPrematchProvider();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCompetitionConfirmed();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getCompetitorsConfirmed();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string home = 1;
 * @return {string}
 */
proto.odds_stream.EventCreateBO.prototype.getHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCreateBO} returns this
 */
proto.odds_stream.EventCreateBO.prototype.setHome = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string away = 2;
 * @return {string}
 */
proto.odds_stream.EventCreateBO.prototype.getAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCreateBO} returns this
 */
proto.odds_stream.EventCreateBO.prototype.setAway = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 int_key = 3;
 * @return {number}
 */
proto.odds_stream.EventCreateBO.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventCreateBO} returns this
 */
proto.odds_stream.EventCreateBO.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 sport_id = 4;
 * @return {number}
 */
proto.odds_stream.EventCreateBO.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventCreateBO} returns this
 */
proto.odds_stream.EventCreateBO.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string location_name = 5;
 * @return {string}
 */
proto.odds_stream.EventCreateBO.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCreateBO} returns this
 */
proto.odds_stream.EventCreateBO.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string competition_name = 6;
 * @return {string}
 */
proto.odds_stream.EventCreateBO.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCreateBO} returns this
 */
proto.odds_stream.EventCreateBO.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 competition_id = 11;
 * @return {number}
 */
proto.odds_stream.EventCreateBO.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventCreateBO} returns this
 */
proto.odds_stream.EventCreateBO.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string start = 7;
 * @return {string}
 */
proto.odds_stream.EventCreateBO.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCreateBO} returns this
 */
proto.odds_stream.EventCreateBO.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool has_input_params = 8;
 * @return {boolean}
 */
proto.odds_stream.EventCreateBO.prototype.getHasInputParams = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventCreateBO} returns this
 */
proto.odds_stream.EventCreateBO.prototype.setHasInputParams = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string name = 9;
 * @return {string}
 */
proto.odds_stream.EventCreateBO.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCreateBO} returns this
 */
proto.odds_stream.EventCreateBO.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string prematch_provider = 10;
 * @return {string}
 */
proto.odds_stream.EventCreateBO.prototype.getPrematchProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCreateBO} returns this
 */
proto.odds_stream.EventCreateBO.prototype.setPrematchProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool competition_confirmed = 12;
 * @return {boolean}
 */
proto.odds_stream.EventCreateBO.prototype.getCompetitionConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventCreateBO} returns this
 */
proto.odds_stream.EventCreateBO.prototype.setCompetitionConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool competitors_confirmed = 13;
 * @return {boolean}
 */
proto.odds_stream.EventCreateBO.prototype.getCompetitorsConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventCreateBO} returns this
 */
proto.odds_stream.EventCreateBO.prototype.setCompetitorsConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string state = 14;
 * @return {string}
 */
proto.odds_stream.EventCreateBO.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCreateBO} returns this
 */
proto.odds_stream.EventCreateBO.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.EventCreateBOMap.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.EventCreateBOMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.EventCreateBOMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventCreateBOMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    home: jspb.Message.getFieldWithDefault(msg, 1, ""),
    away: jspb.Message.getFieldWithDefault(msg, 2, ""),
    intKey: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    locationName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    competitionName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    start: jspb.Message.getFieldWithDefault(msg, 7, ""),
    hasInputParams: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    name: jspb.Message.getFieldWithDefault(msg, 9, ""),
    prematchProvider: jspb.Message.getFieldWithDefault(msg, 10, ""),
    competitionConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    competitorsConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    prematchState: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.EventCreateBOMap}
 */
proto.odds_stream.EventCreateBOMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.EventCreateBOMap;
  return proto.odds_stream.EventCreateBOMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.EventCreateBOMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.EventCreateBOMap}
 */
proto.odds_stream.EventCreateBOMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHome(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAway(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasInputParams(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrematchProvider(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompetitionConfirmed(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompetitorsConfirmed(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrematchState(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.EventCreateBOMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.EventCreateBOMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.EventCreateBOMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventCreateBOMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHome();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAway();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHasInputParams();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPrematchProvider();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCompetitionConfirmed();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getCompetitorsConfirmed();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getPrematchState();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string home = 1;
 * @return {string}
 */
proto.odds_stream.EventCreateBOMap.prototype.getHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCreateBOMap} returns this
 */
proto.odds_stream.EventCreateBOMap.prototype.setHome = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string away = 2;
 * @return {string}
 */
proto.odds_stream.EventCreateBOMap.prototype.getAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCreateBOMap} returns this
 */
proto.odds_stream.EventCreateBOMap.prototype.setAway = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 int_key = 3;
 * @return {number}
 */
proto.odds_stream.EventCreateBOMap.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventCreateBOMap} returns this
 */
proto.odds_stream.EventCreateBOMap.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 sport_id = 4;
 * @return {number}
 */
proto.odds_stream.EventCreateBOMap.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventCreateBOMap} returns this
 */
proto.odds_stream.EventCreateBOMap.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string location_name = 5;
 * @return {string}
 */
proto.odds_stream.EventCreateBOMap.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCreateBOMap} returns this
 */
proto.odds_stream.EventCreateBOMap.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string competition_name = 6;
 * @return {string}
 */
proto.odds_stream.EventCreateBOMap.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCreateBOMap} returns this
 */
proto.odds_stream.EventCreateBOMap.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 competition_id = 11;
 * @return {number}
 */
proto.odds_stream.EventCreateBOMap.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventCreateBOMap} returns this
 */
proto.odds_stream.EventCreateBOMap.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string start = 7;
 * @return {string}
 */
proto.odds_stream.EventCreateBOMap.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCreateBOMap} returns this
 */
proto.odds_stream.EventCreateBOMap.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool has_input_params = 8;
 * @return {boolean}
 */
proto.odds_stream.EventCreateBOMap.prototype.getHasInputParams = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventCreateBOMap} returns this
 */
proto.odds_stream.EventCreateBOMap.prototype.setHasInputParams = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string name = 9;
 * @return {string}
 */
proto.odds_stream.EventCreateBOMap.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCreateBOMap} returns this
 */
proto.odds_stream.EventCreateBOMap.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string prematch_provider = 10;
 * @return {string}
 */
proto.odds_stream.EventCreateBOMap.prototype.getPrematchProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCreateBOMap} returns this
 */
proto.odds_stream.EventCreateBOMap.prototype.setPrematchProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool competition_confirmed = 12;
 * @return {boolean}
 */
proto.odds_stream.EventCreateBOMap.prototype.getCompetitionConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventCreateBOMap} returns this
 */
proto.odds_stream.EventCreateBOMap.prototype.setCompetitionConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool competitors_confirmed = 13;
 * @return {boolean}
 */
proto.odds_stream.EventCreateBOMap.prototype.getCompetitorsConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventCreateBOMap} returns this
 */
proto.odds_stream.EventCreateBOMap.prototype.setCompetitorsConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional string prematch_state = 14;
 * @return {string}
 */
proto.odds_stream.EventCreateBOMap.prototype.getPrematchState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventCreateBOMap} returns this
 */
proto.odds_stream.EventCreateBOMap.prototype.setPrematchState = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.EventMagazine.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.EventMagazine.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.EventMagazine} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventMagazine.toObject = function(includeInstance, msg) {
  var f, obj = {
    home: jspb.Message.getFieldWithDefault(msg, 1, ""),
    away: jspb.Message.getFieldWithDefault(msg, 2, ""),
    intKey: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    locationName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    competitionName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    start: jspb.Message.getFieldWithDefault(msg, 7, ""),
    name: jspb.Message.getFieldWithDefault(msg, 8, ""),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 9, 0),
    allOddsMap: (f = msg.getAllOddsMap()) ? f.toObject(includeInstance, proto.odds_stream.Odd.toObject) : [],
    competitionId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 12, 0),
    competitionWebUrl: jspb.Message.getFieldWithDefault(msg, 13, ""),
    competitionShortName: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.EventMagazine}
 */
proto.odds_stream.EventMagazine.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.EventMagazine;
  return proto.odds_stream.EventMagazine.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.EventMagazine} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.EventMagazine}
 */
proto.odds_stream.EventMagazine.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHome(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAway(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    case 10:
      var value = msg.getAllOddsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds_stream.Odd.deserializeBinaryFromReader, "", new proto.odds_stream.Odd());
         });
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionWebUrl(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionShortName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.EventMagazine.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.EventMagazine.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.EventMagazine} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventMagazine.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHome();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAway();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getAllOddsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(10, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds_stream.Odd.serializeBinaryToWriter);
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getCompetitionWebUrl();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCompetitionShortName();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string home = 1;
 * @return {string}
 */
proto.odds_stream.EventMagazine.prototype.getHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventMagazine} returns this
 */
proto.odds_stream.EventMagazine.prototype.setHome = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string away = 2;
 * @return {string}
 */
proto.odds_stream.EventMagazine.prototype.getAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventMagazine} returns this
 */
proto.odds_stream.EventMagazine.prototype.setAway = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 int_key = 3;
 * @return {number}
 */
proto.odds_stream.EventMagazine.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventMagazine} returns this
 */
proto.odds_stream.EventMagazine.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 sport_id = 4;
 * @return {number}
 */
proto.odds_stream.EventMagazine.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventMagazine} returns this
 */
proto.odds_stream.EventMagazine.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string location_name = 5;
 * @return {string}
 */
proto.odds_stream.EventMagazine.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventMagazine} returns this
 */
proto.odds_stream.EventMagazine.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string competition_name = 6;
 * @return {string}
 */
proto.odds_stream.EventMagazine.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventMagazine} returns this
 */
proto.odds_stream.EventMagazine.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string start = 7;
 * @return {string}
 */
proto.odds_stream.EventMagazine.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventMagazine} returns this
 */
proto.odds_stream.EventMagazine.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string name = 8;
 * @return {string}
 */
proto.odds_stream.EventMagazine.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventMagazine} returns this
 */
proto.odds_stream.EventMagazine.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional int32 landbase_code = 9;
 * @return {number}
 */
proto.odds_stream.EventMagazine.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventMagazine} returns this
 */
proto.odds_stream.EventMagazine.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * map<string, Odd> all_odds = 10;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds_stream.Odd>}
 */
proto.odds_stream.EventMagazine.prototype.getAllOddsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds_stream.Odd>} */ (
      jspb.Message.getMapField(this, 10, opt_noLazyCreate,
      proto.odds_stream.Odd));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.EventMagazine} returns this
 */
proto.odds_stream.EventMagazine.prototype.clearAllOddsMap = function() {
  this.getAllOddsMap().clear();
  return this;};


/**
 * optional int32 competition_id = 11;
 * @return {number}
 */
proto.odds_stream.EventMagazine.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventMagazine} returns this
 */
proto.odds_stream.EventMagazine.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional int32 location_id = 12;
 * @return {number}
 */
proto.odds_stream.EventMagazine.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventMagazine} returns this
 */
proto.odds_stream.EventMagazine.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string competition_web_url = 13;
 * @return {string}
 */
proto.odds_stream.EventMagazine.prototype.getCompetitionWebUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventMagazine} returns this
 */
proto.odds_stream.EventMagazine.prototype.setCompetitionWebUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string competition_short_name = 14;
 * @return {string}
 */
proto.odds_stream.EventMagazine.prototype.getCompetitionShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventMagazine} returns this
 */
proto.odds_stream.EventMagazine.prototype.setCompetitionShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.EventPrematchBO.repeatedFields_ = [26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.EventPrematchBO.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.EventPrematchBO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.EventPrematchBO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventPrematchBO.toObject = function(includeInstance, msg) {
  var f, obj = {
    home: jspb.Message.getFieldWithDefault(msg, 1, ""),
    away: jspb.Message.getFieldWithDefault(msg, 2, ""),
    intKey: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    locationName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    competitionName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    start: jspb.Message.getFieldWithDefault(msg, 7, ""),
    hasInputParams: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    name: jspb.Message.getFieldWithDefault(msg, 9, ""),
    prematchProvider: jspb.Message.getFieldWithDefault(msg, 10, ""),
    liveProvider: jspb.Message.getFieldWithDefault(msg, 11, ""),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 12, 0),
    prematchApprovalTemplateId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    liveApprovalTemplateId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    prematchMarketTemplateId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    liveMarketTemplateId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    prematchOutcomeTemplateId: jspb.Message.getFieldWithDefault(msg, 17, 0),
    liveOutcomeTemplateId: jspb.Message.getFieldWithDefault(msg, 18, 0),
    note: jspb.Message.getFieldWithDefault(msg, 19, ""),
    manualChanged: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    hasPlayers: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    hasSlip: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    tech: jspb.Message.getFieldWithDefault(msg, 23, ""),
    state: jspb.Message.getFieldWithDefault(msg, 24, ""),
    prematchSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    operatorNotesList: jspb.Message.toObjectList(msg.getOperatorNotesList(),
    proto.odds_stream.OperatorNote.toObject, includeInstance),
    basePrematchMarketsMap: (f = msg.getBasePrematchMarketsMap()) ? f.toObject(includeInstance, proto.odds_stream.OddBO.toObject) : [],
    numOfProdOddPrematch: jspb.Message.getFieldWithDefault(msg, 28, 0),
    numOfProdOddMng: jspb.Message.getFieldWithDefault(msg, 29, 0),
    templateOddsNum: jspb.Message.getFieldWithDefault(msg, 30, 0),
    liveSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    competitionId: jspb.Message.getFieldWithDefault(msg, 32, 0),
    goesThrough: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
    betStop: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    competitorsConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 35, false),
    canPublish: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
    hasPublishedPlayers: jspb.Message.getBooleanFieldWithDefault(msg, 37, false),
    homeConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 38, false),
    awayConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 39, false),
    marketsMap: (f = msg.getMarketsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.EventPrematchBO}
 */
proto.odds_stream.EventPrematchBO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.EventPrematchBO;
  return proto.odds_stream.EventPrematchBO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.EventPrematchBO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.EventPrematchBO}
 */
proto.odds_stream.EventPrematchBO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHome(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAway(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasInputParams(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrematchProvider(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveProvider(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrematchApprovalTemplateId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveApprovalTemplateId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrematchMarketTemplateId(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveMarketTemplateId(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrematchOutcomeTemplateId(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveOutcomeTemplateId(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualChanged(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPlayers(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSlip(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setTech(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrematchSuspended(value);
      break;
    case 26:
      var value = new proto.odds_stream.OperatorNote;
      reader.readMessage(value,proto.odds_stream.OperatorNote.deserializeBinaryFromReader);
      msg.addOperatorNotes(value);
      break;
    case 27:
      var value = msg.getBasePrematchMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds_stream.OddBO.deserializeBinaryFromReader, "", new proto.odds_stream.OddBO());
         });
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumOfProdOddPrematch(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumOfProdOddMng(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTemplateOddsNum(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiveSuspended(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGoesThrough(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBetStop(value);
      break;
    case 35:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompetitorsConfirmed(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanPublish(value);
      break;
    case 37:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPublishedPlayers(value);
      break;
    case 38:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHomeConfirmed(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAwayConfirmed(value);
      break;
    case 40:
      var value = msg.getMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.EventPrematchBO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.EventPrematchBO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.EventPrematchBO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventPrematchBO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHome();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAway();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHasInputParams();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPrematchProvider();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLiveProvider();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getPrematchApprovalTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getLiveApprovalTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getPrematchMarketTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getLiveMarketTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getPrematchOutcomeTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getLiveOutcomeTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getManualChanged();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getHasPlayers();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getHasSlip();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getTech();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getPrematchSuspended();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getOperatorNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.odds_stream.OperatorNote.serializeBinaryToWriter
    );
  }
  f = message.getBasePrematchMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(27, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds_stream.OddBO.serializeBinaryToWriter);
  }
  f = message.getNumOfProdOddPrematch();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getNumOfProdOddMng();
  if (f !== 0) {
    writer.writeInt32(
      29,
      f
    );
  }
  f = message.getTemplateOddsNum();
  if (f !== 0) {
    writer.writeInt32(
      30,
      f
    );
  }
  f = message.getLiveSuspended();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      32,
      f
    );
  }
  f = message.getGoesThrough();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getBetStop();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getCompetitorsConfirmed();
  if (f) {
    writer.writeBool(
      35,
      f
    );
  }
  f = message.getCanPublish();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getHasPublishedPlayers();
  if (f) {
    writer.writeBool(
      37,
      f
    );
  }
  f = message.getHomeConfirmed();
  if (f) {
    writer.writeBool(
      38,
      f
    );
  }
  f = message.getAwayConfirmed();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(40, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
};


/**
 * optional string home = 1;
 * @return {string}
 */
proto.odds_stream.EventPrematchBO.prototype.getHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setHome = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string away = 2;
 * @return {string}
 */
proto.odds_stream.EventPrematchBO.prototype.getAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setAway = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 int_key = 3;
 * @return {number}
 */
proto.odds_stream.EventPrematchBO.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 sport_id = 4;
 * @return {number}
 */
proto.odds_stream.EventPrematchBO.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string location_name = 5;
 * @return {string}
 */
proto.odds_stream.EventPrematchBO.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string competition_name = 6;
 * @return {string}
 */
proto.odds_stream.EventPrematchBO.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string start = 7;
 * @return {string}
 */
proto.odds_stream.EventPrematchBO.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool has_input_params = 8;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBO.prototype.getHasInputParams = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setHasInputParams = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string name = 9;
 * @return {string}
 */
proto.odds_stream.EventPrematchBO.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string prematch_provider = 10;
 * @return {string}
 */
proto.odds_stream.EventPrematchBO.prototype.getPrematchProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setPrematchProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string live_provider = 11;
 * @return {string}
 */
proto.odds_stream.EventPrematchBO.prototype.getLiveProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setLiveProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 landbase_code = 12;
 * @return {number}
 */
proto.odds_stream.EventPrematchBO.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 prematch_approval_template_id = 13;
 * @return {number}
 */
proto.odds_stream.EventPrematchBO.prototype.getPrematchApprovalTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setPrematchApprovalTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 live_approval_template_id = 14;
 * @return {number}
 */
proto.odds_stream.EventPrematchBO.prototype.getLiveApprovalTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setLiveApprovalTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 prematch_market_template_id = 15;
 * @return {number}
 */
proto.odds_stream.EventPrematchBO.prototype.getPrematchMarketTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setPrematchMarketTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 live_market_template_id = 16;
 * @return {number}
 */
proto.odds_stream.EventPrematchBO.prototype.getLiveMarketTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setLiveMarketTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 prematch_outcome_template_id = 17;
 * @return {number}
 */
proto.odds_stream.EventPrematchBO.prototype.getPrematchOutcomeTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setPrematchOutcomeTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 live_outcome_template_id = 18;
 * @return {number}
 */
proto.odds_stream.EventPrematchBO.prototype.getLiveOutcomeTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setLiveOutcomeTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string note = 19;
 * @return {string}
 */
proto.odds_stream.EventPrematchBO.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional bool manual_changed = 20;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBO.prototype.getManualChanged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setManualChanged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool has_players = 21;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBO.prototype.getHasPlayers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setHasPlayers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool has_slip = 22;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBO.prototype.getHasSlip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setHasSlip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional string tech = 23;
 * @return {string}
 */
proto.odds_stream.EventPrematchBO.prototype.getTech = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setTech = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string state = 24;
 * @return {string}
 */
proto.odds_stream.EventPrematchBO.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional bool prematch_suspended = 25;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBO.prototype.getPrematchSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setPrematchSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * repeated OperatorNote operator_notes = 26;
 * @return {!Array<!proto.odds_stream.OperatorNote>}
 */
proto.odds_stream.EventPrematchBO.prototype.getOperatorNotesList = function() {
  return /** @type{!Array<!proto.odds_stream.OperatorNote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.OperatorNote, 26));
};


/**
 * @param {!Array<!proto.odds_stream.OperatorNote>} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
*/
proto.odds_stream.EventPrematchBO.prototype.setOperatorNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.odds_stream.OperatorNote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.OperatorNote}
 */
proto.odds_stream.EventPrematchBO.prototype.addOperatorNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.odds_stream.OperatorNote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.clearOperatorNotesList = function() {
  return this.setOperatorNotesList([]);
};


/**
 * map<string, OddBO> base_prematch_markets = 27;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds_stream.OddBO>}
 */
proto.odds_stream.EventPrematchBO.prototype.getBasePrematchMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds_stream.OddBO>} */ (
      jspb.Message.getMapField(this, 27, opt_noLazyCreate,
      proto.odds_stream.OddBO));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.clearBasePrematchMarketsMap = function() {
  this.getBasePrematchMarketsMap().clear();
  return this;};


/**
 * optional int32 num_of_prod_odd_prematch = 28;
 * @return {number}
 */
proto.odds_stream.EventPrematchBO.prototype.getNumOfProdOddPrematch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setNumOfProdOddPrematch = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional int32 num_of_prod_odd_mng = 29;
 * @return {number}
 */
proto.odds_stream.EventPrematchBO.prototype.getNumOfProdOddMng = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setNumOfProdOddMng = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};


/**
 * optional int32 template_odds_num = 30;
 * @return {number}
 */
proto.odds_stream.EventPrematchBO.prototype.getTemplateOddsNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setTemplateOddsNum = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional bool live_suspended = 31;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBO.prototype.getLiveSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setLiveSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional int32 competition_id = 32;
 * @return {number}
 */
proto.odds_stream.EventPrematchBO.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional bool goes_through = 33;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBO.prototype.getGoesThrough = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setGoesThrough = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional bool bet_stop = 34;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBO.prototype.getBetStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setBetStop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional bool competitors_confirmed = 35;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBO.prototype.getCompetitorsConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 35, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setCompetitorsConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 35, value);
};


/**
 * optional bool can_publish = 36;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBO.prototype.getCanPublish = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setCanPublish = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional bool has_published_players = 37;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBO.prototype.getHasPublishedPlayers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 37, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setHasPublishedPlayers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 37, value);
};


/**
 * optional bool home_confirmed = 38;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBO.prototype.getHomeConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 38, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setHomeConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 38, value);
};


/**
 * optional bool away_confirmed = 39;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBO.prototype.getAwayConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 39, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.setAwayConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * map<string, bool> markets = 40;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.odds_stream.EventPrematchBO.prototype.getMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 40, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.EventPrematchBO} returns this
 */
proto.odds_stream.EventPrematchBO.prototype.clearMarketsMap = function() {
  this.getMarketsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.EventPrematchBOMap.repeatedFields_ = [26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.EventPrematchBOMap.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.EventPrematchBOMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.EventPrematchBOMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventPrematchBOMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    home: jspb.Message.getFieldWithDefault(msg, 1, ""),
    away: jspb.Message.getFieldWithDefault(msg, 2, ""),
    intKey: jspb.Message.getFieldWithDefault(msg, 3, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    locationName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    competitionName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    start: jspb.Message.getFieldWithDefault(msg, 7, ""),
    hasInputParams: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    name: jspb.Message.getFieldWithDefault(msg, 9, ""),
    prematchProvider: jspb.Message.getFieldWithDefault(msg, 10, ""),
    liveProvider: jspb.Message.getFieldWithDefault(msg, 11, ""),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 12, 0),
    prematchApprovalTemplateId: jspb.Message.getFieldWithDefault(msg, 13, 0),
    liveApprovalTemplateId: jspb.Message.getFieldWithDefault(msg, 14, 0),
    prematchMarketTemplateId: jspb.Message.getFieldWithDefault(msg, 15, 0),
    liveMarketTemplateId: jspb.Message.getFieldWithDefault(msg, 16, 0),
    prematchOutcomeTemplateId: jspb.Message.getFieldWithDefault(msg, 17, 0),
    liveOutcomeTemplateId: jspb.Message.getFieldWithDefault(msg, 18, 0),
    note: jspb.Message.getFieldWithDefault(msg, 19, ""),
    manualChanged: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    hasPlayers: jspb.Message.getBooleanFieldWithDefault(msg, 21, false),
    hasSlip: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    tech: jspb.Message.getFieldWithDefault(msg, 23, ""),
    prematchState: jspb.Message.getFieldWithDefault(msg, 24, ""),
    prematchSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 25, false),
    operatorNotesList: jspb.Message.toObjectList(msg.getOperatorNotesList(),
    proto.odds_stream.OperatorNote.toObject, includeInstance),
    basePrematchMarketsMap: (f = msg.getBasePrematchMarketsMap()) ? f.toObject(includeInstance, proto.odds_stream.OddBO.toObject) : [],
    numOfProdOddPrematch: jspb.Message.getFieldWithDefault(msg, 28, 0),
    numOfProdOddMng: jspb.Message.getFieldWithDefault(msg, 29, 0),
    templateOddsNum: jspb.Message.getFieldWithDefault(msg, 30, 0),
    liveSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 31, false),
    competitionId: jspb.Message.getFieldWithDefault(msg, 32, 0),
    goesThrough: jspb.Message.getBooleanFieldWithDefault(msg, 33, false),
    betStop: jspb.Message.getBooleanFieldWithDefault(msg, 34, false),
    competitorsConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 35, false),
    canPublish: jspb.Message.getBooleanFieldWithDefault(msg, 36, false),
    hasPublishedPlayers: jspb.Message.getBooleanFieldWithDefault(msg, 37, false),
    homeConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 38, false),
    awayConfirmed: jspb.Message.getBooleanFieldWithDefault(msg, 39, false),
    marketsMap: (f = msg.getMarketsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.EventPrematchBOMap}
 */
proto.odds_stream.EventPrematchBOMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.EventPrematchBOMap;
  return proto.odds_stream.EventPrematchBOMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.EventPrematchBOMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.EventPrematchBOMap}
 */
proto.odds_stream.EventPrematchBOMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setHome(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAway(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasInputParams(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrematchProvider(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveProvider(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrematchApprovalTemplateId(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveApprovalTemplateId(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrematchMarketTemplateId(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveMarketTemplateId(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrematchOutcomeTemplateId(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLiveOutcomeTemplateId(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setManualChanged(value);
      break;
    case 21:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPlayers(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSlip(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setTech(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrematchState(value);
      break;
    case 25:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrematchSuspended(value);
      break;
    case 26:
      var value = new proto.odds_stream.OperatorNote;
      reader.readMessage(value,proto.odds_stream.OperatorNote.deserializeBinaryFromReader);
      msg.addOperatorNotes(value);
      break;
    case 27:
      var value = msg.getBasePrematchMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds_stream.OddBO.deserializeBinaryFromReader, "", new proto.odds_stream.OddBO());
         });
      break;
    case 28:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumOfProdOddPrematch(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNumOfProdOddMng(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTemplateOddsNum(value);
      break;
    case 31:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiveSuspended(value);
      break;
    case 32:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 33:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGoesThrough(value);
      break;
    case 34:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBetStop(value);
      break;
    case 35:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCompetitorsConfirmed(value);
      break;
    case 36:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanPublish(value);
      break;
    case 37:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasPublishedPlayers(value);
      break;
    case 38:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHomeConfirmed(value);
      break;
    case 39:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAwayConfirmed(value);
      break;
    case 40:
      var value = msg.getMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.EventPrematchBOMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.EventPrematchBOMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.EventPrematchBOMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.EventPrematchBOMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHome();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAway();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHasInputParams();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPrematchProvider();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLiveProvider();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getPrematchApprovalTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getLiveApprovalTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getPrematchMarketTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getLiveMarketTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getPrematchOutcomeTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getLiveOutcomeTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getManualChanged();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getHasPlayers();
  if (f) {
    writer.writeBool(
      21,
      f
    );
  }
  f = message.getHasSlip();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getTech();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getPrematchState();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getPrematchSuspended();
  if (f) {
    writer.writeBool(
      25,
      f
    );
  }
  f = message.getOperatorNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      26,
      f,
      proto.odds_stream.OperatorNote.serializeBinaryToWriter
    );
  }
  f = message.getBasePrematchMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(27, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds_stream.OddBO.serializeBinaryToWriter);
  }
  f = message.getNumOfProdOddPrematch();
  if (f !== 0) {
    writer.writeInt32(
      28,
      f
    );
  }
  f = message.getNumOfProdOddMng();
  if (f !== 0) {
    writer.writeInt32(
      29,
      f
    );
  }
  f = message.getTemplateOddsNum();
  if (f !== 0) {
    writer.writeInt32(
      30,
      f
    );
  }
  f = message.getLiveSuspended();
  if (f) {
    writer.writeBool(
      31,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      32,
      f
    );
  }
  f = message.getGoesThrough();
  if (f) {
    writer.writeBool(
      33,
      f
    );
  }
  f = message.getBetStop();
  if (f) {
    writer.writeBool(
      34,
      f
    );
  }
  f = message.getCompetitorsConfirmed();
  if (f) {
    writer.writeBool(
      35,
      f
    );
  }
  f = message.getCanPublish();
  if (f) {
    writer.writeBool(
      36,
      f
    );
  }
  f = message.getHasPublishedPlayers();
  if (f) {
    writer.writeBool(
      37,
      f
    );
  }
  f = message.getHomeConfirmed();
  if (f) {
    writer.writeBool(
      38,
      f
    );
  }
  f = message.getAwayConfirmed();
  if (f) {
    writer.writeBool(
      39,
      f
    );
  }
  f = message.getMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(40, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
};


/**
 * optional string home = 1;
 * @return {string}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setHome = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string away = 2;
 * @return {string}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setAway = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 int_key = 3;
 * @return {number}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 sport_id = 4;
 * @return {number}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string location_name = 5;
 * @return {string}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string competition_name = 6;
 * @return {string}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string start = 7;
 * @return {string}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool has_input_params = 8;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getHasInputParams = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setHasInputParams = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string name = 9;
 * @return {string}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string prematch_provider = 10;
 * @return {string}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getPrematchProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setPrematchProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string live_provider = 11;
 * @return {string}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getLiveProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setLiveProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional int32 landbase_code = 12;
 * @return {number}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional int32 prematch_approval_template_id = 13;
 * @return {number}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getPrematchApprovalTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setPrematchApprovalTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional int32 live_approval_template_id = 14;
 * @return {number}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getLiveApprovalTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setLiveApprovalTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 prematch_market_template_id = 15;
 * @return {number}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getPrematchMarketTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setPrematchMarketTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int32 live_market_template_id = 16;
 * @return {number}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getLiveMarketTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setLiveMarketTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional int32 prematch_outcome_template_id = 17;
 * @return {number}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getPrematchOutcomeTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setPrematchOutcomeTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional int32 live_outcome_template_id = 18;
 * @return {number}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getLiveOutcomeTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setLiveOutcomeTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string note = 19;
 * @return {string}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional bool manual_changed = 20;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getManualChanged = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setManualChanged = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional bool has_players = 21;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getHasPlayers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 21, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setHasPlayers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 21, value);
};


/**
 * optional bool has_slip = 22;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getHasSlip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setHasSlip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional string tech = 23;
 * @return {string}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getTech = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setTech = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string prematch_state = 24;
 * @return {string}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getPrematchState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setPrematchState = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional bool prematch_suspended = 25;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getPrematchSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 25, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setPrematchSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 25, value);
};


/**
 * repeated OperatorNote operator_notes = 26;
 * @return {!Array<!proto.odds_stream.OperatorNote>}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getOperatorNotesList = function() {
  return /** @type{!Array<!proto.odds_stream.OperatorNote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.OperatorNote, 26));
};


/**
 * @param {!Array<!proto.odds_stream.OperatorNote>} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
*/
proto.odds_stream.EventPrematchBOMap.prototype.setOperatorNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 26, value);
};


/**
 * @param {!proto.odds_stream.OperatorNote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.OperatorNote}
 */
proto.odds_stream.EventPrematchBOMap.prototype.addOperatorNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 26, opt_value, proto.odds_stream.OperatorNote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.clearOperatorNotesList = function() {
  return this.setOperatorNotesList([]);
};


/**
 * map<string, OddBO> base_prematch_markets = 27;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds_stream.OddBO>}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getBasePrematchMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds_stream.OddBO>} */ (
      jspb.Message.getMapField(this, 27, opt_noLazyCreate,
      proto.odds_stream.OddBO));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.clearBasePrematchMarketsMap = function() {
  this.getBasePrematchMarketsMap().clear();
  return this;};


/**
 * optional int32 num_of_prod_odd_prematch = 28;
 * @return {number}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getNumOfProdOddPrematch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 28, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setNumOfProdOddPrematch = function(value) {
  return jspb.Message.setProto3IntField(this, 28, value);
};


/**
 * optional int32 num_of_prod_odd_mng = 29;
 * @return {number}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getNumOfProdOddMng = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setNumOfProdOddMng = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};


/**
 * optional int32 template_odds_num = 30;
 * @return {number}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getTemplateOddsNum = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 30, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setTemplateOddsNum = function(value) {
  return jspb.Message.setProto3IntField(this, 30, value);
};


/**
 * optional bool live_suspended = 31;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getLiveSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 31, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setLiveSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 31, value);
};


/**
 * optional int32 competition_id = 32;
 * @return {number}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 32, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 32, value);
};


/**
 * optional bool goes_through = 33;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getGoesThrough = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 33, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setGoesThrough = function(value) {
  return jspb.Message.setProto3BooleanField(this, 33, value);
};


/**
 * optional bool bet_stop = 34;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getBetStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 34, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setBetStop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 34, value);
};


/**
 * optional bool competitors_confirmed = 35;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getCompetitorsConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 35, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setCompetitorsConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 35, value);
};


/**
 * optional bool can_publish = 36;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getCanPublish = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 36, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setCanPublish = function(value) {
  return jspb.Message.setProto3BooleanField(this, 36, value);
};


/**
 * optional bool has_published_players = 37;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getHasPublishedPlayers = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 37, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setHasPublishedPlayers = function(value) {
  return jspb.Message.setProto3BooleanField(this, 37, value);
};


/**
 * optional bool home_confirmed = 38;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getHomeConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 38, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setHomeConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 38, value);
};


/**
 * optional bool away_confirmed = 39;
 * @return {boolean}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getAwayConfirmed = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 39, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.setAwayConfirmed = function(value) {
  return jspb.Message.setProto3BooleanField(this, 39, value);
};


/**
 * map<string, bool> markets = 40;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.odds_stream.EventPrematchBOMap.prototype.getMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 40, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.EventPrematchBOMap} returns this
 */
proto.odds_stream.EventPrematchBOMap.prototype.clearMarketsMap = function() {
  this.getMarketsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.GetEventsLiveResponseBO.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.GetEventsLiveResponseBO.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.GetEventsLiveResponseBO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.GetEventsLiveResponseBO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsLiveResponseBO.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds_stream.EventLiveBO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.GetEventsLiveResponseBO}
 */
proto.odds_stream.GetEventsLiveResponseBO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.GetEventsLiveResponseBO;
  return proto.odds_stream.GetEventsLiveResponseBO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.GetEventsLiveResponseBO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.GetEventsLiveResponseBO}
 */
proto.odds_stream.GetEventsLiveResponseBO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds_stream.EventLiveBO;
      reader.readMessage(value,proto.odds_stream.EventLiveBO.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.GetEventsLiveResponseBO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.GetEventsLiveResponseBO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.GetEventsLiveResponseBO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsLiveResponseBO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds_stream.EventLiveBO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventLiveBO events = 1;
 * @return {!Array<!proto.odds_stream.EventLiveBO>}
 */
proto.odds_stream.GetEventsLiveResponseBO.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.EventLiveBO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.EventLiveBO, 1));
};


/**
 * @param {!Array<!proto.odds_stream.EventLiveBO>} value
 * @return {!proto.odds_stream.GetEventsLiveResponseBO} returns this
*/
proto.odds_stream.GetEventsLiveResponseBO.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds_stream.EventLiveBO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.EventLiveBO}
 */
proto.odds_stream.GetEventsLiveResponseBO.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds_stream.EventLiveBO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.GetEventsLiveResponseBO} returns this
 */
proto.odds_stream.GetEventsLiveResponseBO.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.GetEventsCreateResponseBO.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.GetEventsCreateResponseBO.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.GetEventsCreateResponseBO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.GetEventsCreateResponseBO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsCreateResponseBO.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds_stream.EventCreateBO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.GetEventsCreateResponseBO}
 */
proto.odds_stream.GetEventsCreateResponseBO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.GetEventsCreateResponseBO;
  return proto.odds_stream.GetEventsCreateResponseBO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.GetEventsCreateResponseBO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.GetEventsCreateResponseBO}
 */
proto.odds_stream.GetEventsCreateResponseBO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds_stream.EventCreateBO;
      reader.readMessage(value,proto.odds_stream.EventCreateBO.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.GetEventsCreateResponseBO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.GetEventsCreateResponseBO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.GetEventsCreateResponseBO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsCreateResponseBO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds_stream.EventCreateBO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventCreateBO events = 1;
 * @return {!Array<!proto.odds_stream.EventCreateBO>}
 */
proto.odds_stream.GetEventsCreateResponseBO.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.EventCreateBO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.EventCreateBO, 1));
};


/**
 * @param {!Array<!proto.odds_stream.EventCreateBO>} value
 * @return {!proto.odds_stream.GetEventsCreateResponseBO} returns this
*/
proto.odds_stream.GetEventsCreateResponseBO.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds_stream.EventCreateBO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.EventCreateBO}
 */
proto.odds_stream.GetEventsCreateResponseBO.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds_stream.EventCreateBO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.GetEventsCreateResponseBO} returns this
 */
proto.odds_stream.GetEventsCreateResponseBO.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.GetEventsOfferResponseBO.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.GetEventsOfferResponseBO.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.GetEventsOfferResponseBO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.GetEventsOfferResponseBO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsOfferResponseBO.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds_stream.EventPrematchBO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.GetEventsOfferResponseBO}
 */
proto.odds_stream.GetEventsOfferResponseBO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.GetEventsOfferResponseBO;
  return proto.odds_stream.GetEventsOfferResponseBO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.GetEventsOfferResponseBO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.GetEventsOfferResponseBO}
 */
proto.odds_stream.GetEventsOfferResponseBO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds_stream.EventPrematchBO;
      reader.readMessage(value,proto.odds_stream.EventPrematchBO.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.GetEventsOfferResponseBO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.GetEventsOfferResponseBO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.GetEventsOfferResponseBO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsOfferResponseBO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds_stream.EventPrematchBO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventPrematchBO events = 1;
 * @return {!Array<!proto.odds_stream.EventPrematchBO>}
 */
proto.odds_stream.GetEventsOfferResponseBO.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.EventPrematchBO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.EventPrematchBO, 1));
};


/**
 * @param {!Array<!proto.odds_stream.EventPrematchBO>} value
 * @return {!proto.odds_stream.GetEventsOfferResponseBO} returns this
*/
proto.odds_stream.GetEventsOfferResponseBO.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds_stream.EventPrematchBO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.EventPrematchBO}
 */
proto.odds_stream.GetEventsOfferResponseBO.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds_stream.EventPrematchBO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.GetEventsOfferResponseBO} returns this
 */
proto.odds_stream.GetEventsOfferResponseBO.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.GetEventsMagazineResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.GetEventsMagazineResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.GetEventsMagazineResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.GetEventsMagazineResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsMagazineResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds_stream.EventMagazine.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.GetEventsMagazineResponse}
 */
proto.odds_stream.GetEventsMagazineResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.GetEventsMagazineResponse;
  return proto.odds_stream.GetEventsMagazineResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.GetEventsMagazineResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.GetEventsMagazineResponse}
 */
proto.odds_stream.GetEventsMagazineResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds_stream.EventMagazine;
      reader.readMessage(value,proto.odds_stream.EventMagazine.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.GetEventsMagazineResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.GetEventsMagazineResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.GetEventsMagazineResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsMagazineResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds_stream.EventMagazine.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventMagazine events = 1;
 * @return {!Array<!proto.odds_stream.EventMagazine>}
 */
proto.odds_stream.GetEventsMagazineResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.EventMagazine>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.EventMagazine, 1));
};


/**
 * @param {!Array<!proto.odds_stream.EventMagazine>} value
 * @return {!proto.odds_stream.GetEventsMagazineResponse} returns this
*/
proto.odds_stream.GetEventsMagazineResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds_stream.EventMagazine=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.EventMagazine}
 */
proto.odds_stream.GetEventsMagazineResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds_stream.EventMagazine, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.GetEventsMagazineResponse} returns this
 */
proto.odds_stream.GetEventsMagazineResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.GetEventsPrematchResponseBO.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.GetEventsPrematchResponseBO.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.GetEventsPrematchResponseBO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.GetEventsPrematchResponseBO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsPrematchResponseBO.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds_stream.EventPrematchBO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.GetEventsPrematchResponseBO}
 */
proto.odds_stream.GetEventsPrematchResponseBO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.GetEventsPrematchResponseBO;
  return proto.odds_stream.GetEventsPrematchResponseBO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.GetEventsPrematchResponseBO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.GetEventsPrematchResponseBO}
 */
proto.odds_stream.GetEventsPrematchResponseBO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds_stream.EventPrematchBO;
      reader.readMessage(value,proto.odds_stream.EventPrematchBO.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.GetEventsPrematchResponseBO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.GetEventsPrematchResponseBO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.GetEventsPrematchResponseBO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsPrematchResponseBO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds_stream.EventPrematchBO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventPrematchBO events = 1;
 * @return {!Array<!proto.odds_stream.EventPrematchBO>}
 */
proto.odds_stream.GetEventsPrematchResponseBO.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.EventPrematchBO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.EventPrematchBO, 1));
};


/**
 * @param {!Array<!proto.odds_stream.EventPrematchBO>} value
 * @return {!proto.odds_stream.GetEventsPrematchResponseBO} returns this
*/
proto.odds_stream.GetEventsPrematchResponseBO.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds_stream.EventPrematchBO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.EventPrematchBO}
 */
proto.odds_stream.GetEventsPrematchResponseBO.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds_stream.EventPrematchBO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.GetEventsPrematchResponseBO} returns this
 */
proto.odds_stream.GetEventsPrematchResponseBO.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.PlayerEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.PlayerEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.PlayerEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.PlayerEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    allOddsMap: (f = msg.getAllOddsMap()) ? f.toObject(includeInstance, proto.odds_stream.Odd.toObject) : [],
    start: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    eventCode: jspb.Message.getFieldWithDefault(msg, 9, 0),
    note: jspb.Message.getFieldWithDefault(msg, 10, ""),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    intKey: jspb.Message.getFieldWithDefault(msg, 15, 0),
    marketsMap: (f = msg.getMarketsMap()) ? f.toObject(includeInstance, undefined) : [],
    dualIntKey: jspb.Message.getFieldWithDefault(msg, 17, 0),
    dualHome: jspb.Message.getFieldWithDefault(msg, 18, ""),
    dualAway: jspb.Message.getFieldWithDefault(msg, 19, ""),
    competitorId: jspb.Message.getFieldWithDefault(msg, 20, 0),
    dualLandbaseCode: jspb.Message.getFieldWithDefault(msg, 22, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.PlayerEvent}
 */
proto.odds_stream.PlayerEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.PlayerEvent;
  return proto.odds_stream.PlayerEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.PlayerEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.PlayerEvent}
 */
proto.odds_stream.PlayerEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 21:
      var value = msg.getAllOddsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds_stream.Odd.deserializeBinaryFromReader, "", new proto.odds_stream.Odd());
         });
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 16:
      var value = msg.getMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDualIntKey(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setDualHome(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDualAway(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitorId(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDualLandbaseCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.PlayerEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.PlayerEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.PlayerEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.PlayerEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getAllOddsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(21, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds_stream.Odd.serializeBinaryToWriter);
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getEventCode();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(16, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getDualIntKey();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getDualHome();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDualAway();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCompetitorId();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getDualLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
};


/**
 * optional string player_name = 2;
 * @return {string}
 */
proto.odds_stream.PlayerEvent.prototype.getPlayerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEvent} returns this
 */
proto.odds_stream.PlayerEvent.prototype.setPlayerName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 competition_id = 3;
 * @return {number}
 */
proto.odds_stream.PlayerEvent.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEvent} returns this
 */
proto.odds_stream.PlayerEvent.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * map<string, Odd> all_odds = 21;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds_stream.Odd>}
 */
proto.odds_stream.PlayerEvent.prototype.getAllOddsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds_stream.Odd>} */ (
      jspb.Message.getMapField(this, 21, opt_noLazyCreate,
      proto.odds_stream.Odd));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.PlayerEvent} returns this
 */
proto.odds_stream.PlayerEvent.prototype.clearAllOddsMap = function() {
  this.getAllOddsMap().clear();
  return this;};


/**
 * optional string start = 6;
 * @return {string}
 */
proto.odds_stream.PlayerEvent.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEvent} returns this
 */
proto.odds_stream.PlayerEvent.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 sport_id = 7;
 * @return {number}
 */
proto.odds_stream.PlayerEvent.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEvent} returns this
 */
proto.odds_stream.PlayerEvent.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 location_id = 8;
 * @return {number}
 */
proto.odds_stream.PlayerEvent.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEvent} returns this
 */
proto.odds_stream.PlayerEvent.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 event_code = 9;
 * @return {number}
 */
proto.odds_stream.PlayerEvent.prototype.getEventCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEvent} returns this
 */
proto.odds_stream.PlayerEvent.prototype.setEventCode = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string note = 10;
 * @return {string}
 */
proto.odds_stream.PlayerEvent.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEvent} returns this
 */
proto.odds_stream.PlayerEvent.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool is_suspended = 14;
 * @return {boolean}
 */
proto.odds_stream.PlayerEvent.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.PlayerEvent} returns this
 */
proto.odds_stream.PlayerEvent.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional int32 int_key = 15;
 * @return {number}
 */
proto.odds_stream.PlayerEvent.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEvent} returns this
 */
proto.odds_stream.PlayerEvent.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * map<string, bool> markets = 16;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.odds_stream.PlayerEvent.prototype.getMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 16, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.PlayerEvent} returns this
 */
proto.odds_stream.PlayerEvent.prototype.clearMarketsMap = function() {
  this.getMarketsMap().clear();
  return this;};


/**
 * optional int32 dual_int_key = 17;
 * @return {number}
 */
proto.odds_stream.PlayerEvent.prototype.getDualIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEvent} returns this
 */
proto.odds_stream.PlayerEvent.prototype.setDualIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string dual_home = 18;
 * @return {string}
 */
proto.odds_stream.PlayerEvent.prototype.getDualHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEvent} returns this
 */
proto.odds_stream.PlayerEvent.prototype.setDualHome = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string dual_away = 19;
 * @return {string}
 */
proto.odds_stream.PlayerEvent.prototype.getDualAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEvent} returns this
 */
proto.odds_stream.PlayerEvent.prototype.setDualAway = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 competitor_id = 20;
 * @return {number}
 */
proto.odds_stream.PlayerEvent.prototype.getCompetitorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEvent} returns this
 */
proto.odds_stream.PlayerEvent.prototype.setCompetitorId = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional int32 dual_landbase_code = 22;
 * @return {number}
 */
proto.odds_stream.PlayerEvent.prototype.getDualLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEvent} returns this
 */
proto.odds_stream.PlayerEvent.prototype.setDualLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.GetPlayerEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.GetPlayerEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.GetPlayerEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.GetPlayerEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetPlayerEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    prematchEventsList: jspb.Message.toObjectList(msg.getPrematchEventsList(),
    proto.odds_stream.PlayerEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.GetPlayerEventsResponse}
 */
proto.odds_stream.GetPlayerEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.GetPlayerEventsResponse;
  return proto.odds_stream.GetPlayerEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.GetPlayerEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.GetPlayerEventsResponse}
 */
proto.odds_stream.GetPlayerEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds_stream.PlayerEvent;
      reader.readMessage(value,proto.odds_stream.PlayerEvent.deserializeBinaryFromReader);
      msg.addPrematchEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.GetPlayerEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.GetPlayerEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.GetPlayerEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetPlayerEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrematchEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds_stream.PlayerEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PlayerEvent prematch_events = 1;
 * @return {!Array<!proto.odds_stream.PlayerEvent>}
 */
proto.odds_stream.GetPlayerEventsResponse.prototype.getPrematchEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.PlayerEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.PlayerEvent, 1));
};


/**
 * @param {!Array<!proto.odds_stream.PlayerEvent>} value
 * @return {!proto.odds_stream.GetPlayerEventsResponse} returns this
*/
proto.odds_stream.GetPlayerEventsResponse.prototype.setPrematchEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds_stream.PlayerEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.PlayerEvent}
 */
proto.odds_stream.GetPlayerEventsResponse.prototype.addPrematchEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds_stream.PlayerEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.GetPlayerEventsResponse} returns this
 */
proto.odds_stream.GetPlayerEventsResponse.prototype.clearPrematchEventsList = function() {
  return this.setPrematchEventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.AntepostMarket.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.AntepostMarket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.AntepostMarket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.AntepostMarket.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    id: jspb.Message.getFieldWithDefault(msg, 6, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.AntepostMarket}
 */
proto.odds_stream.AntepostMarket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.AntepostMarket;
  return proto.odds_stream.AntepostMarket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.AntepostMarket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.AntepostMarket}
 */
proto.odds_stream.AntepostMarket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.AntepostMarket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.AntepostMarket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.AntepostMarket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.AntepostMarket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 competition_id = 1;
 * @return {number}
 */
proto.odds_stream.AntepostMarket.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.AntepostMarket} returns this
 */
proto.odds_stream.AntepostMarket.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds_stream.AntepostMarket.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.AntepostMarket} returns this
 */
proto.odds_stream.AntepostMarket.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.odds_stream.AntepostMarket.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.AntepostMarket} returns this
 */
proto.odds_stream.AntepostMarket.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_suspended = 4;
 * @return {boolean}
 */
proto.odds_stream.AntepostMarket.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.AntepostMarket} returns this
 */
proto.odds_stream.AntepostMarket.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 id = 6;
 * @return {number}
 */
proto.odds_stream.AntepostMarket.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.AntepostMarket} returns this
 */
proto.odds_stream.AntepostMarket.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 location_id = 7;
 * @return {number}
 */
proto.odds_stream.AntepostMarket.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.AntepostMarket} returns this
 */
proto.odds_stream.AntepostMarket.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.Antepost.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.Antepost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.Antepost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.Antepost.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, ""),
    end: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    note: jspb.Message.getFieldWithDefault(msg, 4, ""),
    intKey: jspb.Message.getFieldWithDefault(msg, 5, 0),
    antepostBetsMap: (f = msg.getAntepostBetsMap()) ? f.toObject(includeInstance, proto.odds_stream.AntepostBet.toObject) : [],
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    marketId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 9, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.Antepost}
 */
proto.odds_stream.Antepost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.Antepost;
  return proto.odds_stream.Antepost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.Antepost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.Antepost}
 */
proto.odds_stream.Antepost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnd(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 6:
      var value = msg.getAntepostBetsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds_stream.AntepostBet.deserializeBinaryFromReader, "", new proto.odds_stream.AntepostBet());
         });
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.Antepost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.Antepost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.Antepost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.Antepost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAntepostBetsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(6, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds_stream.AntepostBet.serializeBinaryToWriter);
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMarketId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
};


/**
 * optional string start = 1;
 * @return {string}
 */
proto.odds_stream.Antepost.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.Antepost} returns this
 */
proto.odds_stream.Antepost.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string end = 2;
 * @return {string}
 */
proto.odds_stream.Antepost.prototype.getEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.Antepost} returns this
 */
proto.odds_stream.Antepost.prototype.setEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_suspended = 3;
 * @return {boolean}
 */
proto.odds_stream.Antepost.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.Antepost} returns this
 */
proto.odds_stream.Antepost.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string note = 4;
 * @return {string}
 */
proto.odds_stream.Antepost.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.Antepost} returns this
 */
proto.odds_stream.Antepost.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 int_key = 5;
 * @return {number}
 */
proto.odds_stream.Antepost.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.Antepost} returns this
 */
proto.odds_stream.Antepost.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * map<string, AntepostBet> antepost_bets = 6;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds_stream.AntepostBet>}
 */
proto.odds_stream.Antepost.prototype.getAntepostBetsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds_stream.AntepostBet>} */ (
      jspb.Message.getMapField(this, 6, opt_noLazyCreate,
      proto.odds_stream.AntepostBet));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.Antepost} returns this
 */
proto.odds_stream.Antepost.prototype.clearAntepostBetsMap = function() {
  this.getAntepostBetsMap().clear();
  return this;};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.odds_stream.Antepost.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.Antepost} returns this
 */
proto.odds_stream.Antepost.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 market_id = 8;
 * @return {number}
 */
proto.odds_stream.Antepost.prototype.getMarketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.Antepost} returns this
 */
proto.odds_stream.Antepost.prototype.setMarketId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 landbase_code = 9;
 * @return {number}
 */
proto.odds_stream.Antepost.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.Antepost} returns this
 */
proto.odds_stream.Antepost.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.AntepostBO.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.AntepostBO.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.AntepostBO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.AntepostBO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.AntepostBO.toObject = function(includeInstance, msg) {
  var f, obj = {
    intKey: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 3, 0),
    start: jspb.Message.getFieldWithDefault(msg, 4, ""),
    end: jspb.Message.getFieldWithDefault(msg, 5, ""),
    operatorNotesList: jspb.Message.toObjectList(msg.getOperatorNotesList(),
    proto.odds_stream.OperatorNote.toObject, includeInstance),
    note: jspb.Message.getFieldWithDefault(msg, 7, ""),
    state: jspb.Message.getFieldWithDefault(msg, 8, ""),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    marketId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    allBetsMap: (f = msg.getAllBetsMap()) ? f.toObject(includeInstance, proto.odds_stream.AntepostBetBO.toObject) : [],
    antepostMaxAmount: jspb.Message.getFieldWithDefault(msg, 12, 0),
    assigneeName: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.AntepostBO}
 */
proto.odds_stream.AntepostBO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.AntepostBO;
  return proto.odds_stream.AntepostBO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.AntepostBO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.AntepostBO}
 */
proto.odds_stream.AntepostBO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnd(value);
      break;
    case 6:
      var value = new proto.odds_stream.OperatorNote;
      reader.readMessage(value,proto.odds_stream.OperatorNote.deserializeBinaryFromReader);
      msg.addOperatorNotes(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketId(value);
      break;
    case 11:
      var value = msg.getAllBetsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds_stream.AntepostBetBO.deserializeBinaryFromReader, "", new proto.odds_stream.AntepostBetBO());
         });
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAntepostMaxAmount(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssigneeName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.AntepostBO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.AntepostBO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.AntepostBO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.AntepostBO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getEnd();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOperatorNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.odds_stream.OperatorNote.serializeBinaryToWriter
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getMarketId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getAllBetsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(11, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds_stream.AntepostBetBO.serializeBinaryToWriter);
  }
  f = message.getAntepostMaxAmount();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getAssigneeName();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional int32 int_key = 1;
 * @return {number}
 */
proto.odds_stream.AntepostBO.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.AntepostBO} returns this
 */
proto.odds_stream.AntepostBO.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds_stream.AntepostBO.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.AntepostBO} returns this
 */
proto.odds_stream.AntepostBO.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 landbase_code = 3;
 * @return {number}
 */
proto.odds_stream.AntepostBO.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.AntepostBO} returns this
 */
proto.odds_stream.AntepostBO.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string start = 4;
 * @return {string}
 */
proto.odds_stream.AntepostBO.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.AntepostBO} returns this
 */
proto.odds_stream.AntepostBO.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string end = 5;
 * @return {string}
 */
proto.odds_stream.AntepostBO.prototype.getEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.AntepostBO} returns this
 */
proto.odds_stream.AntepostBO.prototype.setEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated OperatorNote operator_notes = 6;
 * @return {!Array<!proto.odds_stream.OperatorNote>}
 */
proto.odds_stream.AntepostBO.prototype.getOperatorNotesList = function() {
  return /** @type{!Array<!proto.odds_stream.OperatorNote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.OperatorNote, 6));
};


/**
 * @param {!Array<!proto.odds_stream.OperatorNote>} value
 * @return {!proto.odds_stream.AntepostBO} returns this
*/
proto.odds_stream.AntepostBO.prototype.setOperatorNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.odds_stream.OperatorNote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.OperatorNote}
 */
proto.odds_stream.AntepostBO.prototype.addOperatorNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.odds_stream.OperatorNote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.AntepostBO} returns this
 */
proto.odds_stream.AntepostBO.prototype.clearOperatorNotesList = function() {
  return this.setOperatorNotesList([]);
};


/**
 * optional string note = 7;
 * @return {string}
 */
proto.odds_stream.AntepostBO.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.AntepostBO} returns this
 */
proto.odds_stream.AntepostBO.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string state = 8;
 * @return {string}
 */
proto.odds_stream.AntepostBO.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.AntepostBO} returns this
 */
proto.odds_stream.AntepostBO.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional bool is_suspended = 9;
 * @return {boolean}
 */
proto.odds_stream.AntepostBO.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.AntepostBO} returns this
 */
proto.odds_stream.AntepostBO.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional int32 market_id = 10;
 * @return {number}
 */
proto.odds_stream.AntepostBO.prototype.getMarketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.AntepostBO} returns this
 */
proto.odds_stream.AntepostBO.prototype.setMarketId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * map<string, AntepostBetBO> all_bets = 11;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds_stream.AntepostBetBO>}
 */
proto.odds_stream.AntepostBO.prototype.getAllBetsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds_stream.AntepostBetBO>} */ (
      jspb.Message.getMapField(this, 11, opt_noLazyCreate,
      proto.odds_stream.AntepostBetBO));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.AntepostBO} returns this
 */
proto.odds_stream.AntepostBO.prototype.clearAllBetsMap = function() {
  this.getAllBetsMap().clear();
  return this;};


/**
 * optional int32 antepost_max_amount = 12;
 * @return {number}
 */
proto.odds_stream.AntepostBO.prototype.getAntepostMaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.AntepostBO} returns this
 */
proto.odds_stream.AntepostBO.prototype.setAntepostMaxAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string assignee_name = 13;
 * @return {string}
 */
proto.odds_stream.AntepostBO.prototype.getAssigneeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.AntepostBO} returns this
 */
proto.odds_stream.AntepostBO.prototype.setAssigneeName = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.AntepostBet.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.AntepostBet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.AntepostBet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.AntepostBet.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    param: jspb.Message.getFieldWithDefault(msg, 2, ""),
    oddValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    intKey: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    manualStatus: jspb.Message.getFieldWithDefault(msg, 6, ""),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    antepostCode: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.AntepostBet}
 */
proto.odds_stream.AntepostBet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.AntepostBet;
  return proto.odds_stream.AntepostBet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.AntepostBet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.AntepostBet}
 */
proto.odds_stream.AntepostBet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setParam(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOddValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setManualStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAntepostCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.AntepostBet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.AntepostBet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.AntepostBet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.AntepostBet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getParam();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOddValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getManualStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAntepostCode();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.odds_stream.AntepostBet.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.AntepostBet} returns this
 */
proto.odds_stream.AntepostBet.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string param = 2;
 * @return {string}
 */
proto.odds_stream.AntepostBet.prototype.getParam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.AntepostBet} returns this
 */
proto.odds_stream.AntepostBet.prototype.setParam = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double odd_value = 3;
 * @return {number}
 */
proto.odds_stream.AntepostBet.prototype.getOddValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.AntepostBet} returns this
 */
proto.odds_stream.AntepostBet.prototype.setOddValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 int_key = 4;
 * @return {number}
 */
proto.odds_stream.AntepostBet.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.AntepostBet} returns this
 */
proto.odds_stream.AntepostBet.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_suspended = 5;
 * @return {boolean}
 */
proto.odds_stream.AntepostBet.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.AntepostBet} returns this
 */
proto.odds_stream.AntepostBet.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string manual_status = 6;
 * @return {string}
 */
proto.odds_stream.AntepostBet.prototype.getManualStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.AntepostBet} returns this
 */
proto.odds_stream.AntepostBet.prototype.setManualStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.odds_stream.AntepostBet.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.AntepostBet} returns this
 */
proto.odds_stream.AntepostBet.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 antepost_code = 8;
 * @return {number}
 */
proto.odds_stream.AntepostBet.prototype.getAntepostCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.AntepostBet} returns this
 */
proto.odds_stream.AntepostBet.prototype.setAntepostCode = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.AntepostBetBO.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.AntepostBetBO.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.AntepostBetBO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.AntepostBetBO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.AntepostBetBO.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    param: jspb.Message.getFieldWithDefault(msg, 2, ""),
    oddValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    intKey: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    manualStatus: jspb.Message.getFieldWithDefault(msg, 6, ""),
    name: jspb.Message.getFieldWithDefault(msg, 7, ""),
    antepostCode: jspb.Message.getFieldWithDefault(msg, 8, 0),
    state: jspb.Message.getFieldWithDefault(msg, 9, ""),
    oddNoteList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.AntepostBetBO}
 */
proto.odds_stream.AntepostBetBO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.AntepostBetBO;
  return proto.odds_stream.AntepostBetBO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.AntepostBetBO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.AntepostBetBO}
 */
proto.odds_stream.AntepostBetBO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setParam(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOddValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setManualStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAntepostCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.addOddNote(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.AntepostBetBO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.AntepostBetBO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.AntepostBetBO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.AntepostBetBO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getParam();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOddValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getManualStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAntepostCode();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOddNoteList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      10,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.odds_stream.AntepostBetBO.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.AntepostBetBO} returns this
 */
proto.odds_stream.AntepostBetBO.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string param = 2;
 * @return {string}
 */
proto.odds_stream.AntepostBetBO.prototype.getParam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.AntepostBetBO} returns this
 */
proto.odds_stream.AntepostBetBO.prototype.setParam = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double odd_value = 3;
 * @return {number}
 */
proto.odds_stream.AntepostBetBO.prototype.getOddValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.AntepostBetBO} returns this
 */
proto.odds_stream.AntepostBetBO.prototype.setOddValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 int_key = 4;
 * @return {number}
 */
proto.odds_stream.AntepostBetBO.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.AntepostBetBO} returns this
 */
proto.odds_stream.AntepostBetBO.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_suspended = 5;
 * @return {boolean}
 */
proto.odds_stream.AntepostBetBO.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.AntepostBetBO} returns this
 */
proto.odds_stream.AntepostBetBO.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string manual_status = 6;
 * @return {string}
 */
proto.odds_stream.AntepostBetBO.prototype.getManualStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.AntepostBetBO} returns this
 */
proto.odds_stream.AntepostBetBO.prototype.setManualStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.odds_stream.AntepostBetBO.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.AntepostBetBO} returns this
 */
proto.odds_stream.AntepostBetBO.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 antepost_code = 8;
 * @return {number}
 */
proto.odds_stream.AntepostBetBO.prototype.getAntepostCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.AntepostBetBO} returns this
 */
proto.odds_stream.AntepostBetBO.prototype.setAntepostCode = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string state = 9;
 * @return {string}
 */
proto.odds_stream.AntepostBetBO.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.AntepostBetBO} returns this
 */
proto.odds_stream.AntepostBetBO.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * repeated string odd_note = 10;
 * @return {!Array<string>}
 */
proto.odds_stream.AntepostBetBO.prototype.getOddNoteList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds_stream.AntepostBetBO} returns this
 */
proto.odds_stream.AntepostBetBO.prototype.setOddNoteList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.AntepostBetBO} returns this
 */
proto.odds_stream.AntepostBetBO.prototype.addOddNote = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.AntepostBetBO} returns this
 */
proto.odds_stream.AntepostBetBO.prototype.clearOddNoteList = function() {
  return this.setOddNoteList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.GetAntepostResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.GetAntepostResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.GetAntepostResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.GetAntepostResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetAntepostResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    antepostsList: jspb.Message.toObjectList(msg.getAntepostsList(),
    proto.odds_stream.Antepost.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.GetAntepostResponse}
 */
proto.odds_stream.GetAntepostResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.GetAntepostResponse;
  return proto.odds_stream.GetAntepostResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.GetAntepostResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.GetAntepostResponse}
 */
proto.odds_stream.GetAntepostResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds_stream.Antepost;
      reader.readMessage(value,proto.odds_stream.Antepost.deserializeBinaryFromReader);
      msg.addAnteposts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.GetAntepostResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.GetAntepostResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.GetAntepostResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetAntepostResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAntepostsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds_stream.Antepost.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Antepost anteposts = 1;
 * @return {!Array<!proto.odds_stream.Antepost>}
 */
proto.odds_stream.GetAntepostResponse.prototype.getAntepostsList = function() {
  return /** @type{!Array<!proto.odds_stream.Antepost>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.Antepost, 1));
};


/**
 * @param {!Array<!proto.odds_stream.Antepost>} value
 * @return {!proto.odds_stream.GetAntepostResponse} returns this
*/
proto.odds_stream.GetAntepostResponse.prototype.setAntepostsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds_stream.Antepost=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.Antepost}
 */
proto.odds_stream.GetAntepostResponse.prototype.addAnteposts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds_stream.Antepost, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.GetAntepostResponse} returns this
 */
proto.odds_stream.GetAntepostResponse.prototype.clearAntepostsList = function() {
  return this.setAntepostsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.GetAntepostsResponseBO.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.GetAntepostsResponseBO.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.GetAntepostsResponseBO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.GetAntepostsResponseBO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetAntepostsResponseBO.toObject = function(includeInstance, msg) {
  var f, obj = {
    antepostsList: jspb.Message.toObjectList(msg.getAntepostsList(),
    proto.odds_stream.AntepostBO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.GetAntepostsResponseBO}
 */
proto.odds_stream.GetAntepostsResponseBO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.GetAntepostsResponseBO;
  return proto.odds_stream.GetAntepostsResponseBO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.GetAntepostsResponseBO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.GetAntepostsResponseBO}
 */
proto.odds_stream.GetAntepostsResponseBO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds_stream.AntepostBO;
      reader.readMessage(value,proto.odds_stream.AntepostBO.deserializeBinaryFromReader);
      msg.addAnteposts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.GetAntepostsResponseBO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.GetAntepostsResponseBO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.GetAntepostsResponseBO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetAntepostsResponseBO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAntepostsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds_stream.AntepostBO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AntepostBO anteposts = 1;
 * @return {!Array<!proto.odds_stream.AntepostBO>}
 */
proto.odds_stream.GetAntepostsResponseBO.prototype.getAntepostsList = function() {
  return /** @type{!Array<!proto.odds_stream.AntepostBO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.AntepostBO, 1));
};


/**
 * @param {!Array<!proto.odds_stream.AntepostBO>} value
 * @return {!proto.odds_stream.GetAntepostsResponseBO} returns this
*/
proto.odds_stream.GetAntepostsResponseBO.prototype.setAntepostsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds_stream.AntepostBO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.AntepostBO}
 */
proto.odds_stream.GetAntepostsResponseBO.prototype.addAnteposts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds_stream.AntepostBO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.GetAntepostsResponseBO} returns this
 */
proto.odds_stream.GetAntepostsResponseBO.prototype.clearAntepostsList = function() {
  return this.setAntepostsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.WebEventMap.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.WebEventMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.WebEventMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.WebEventMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    allOddsMap: (f = msg.getAllOddsMap()) ? f.toObject(includeInstance, proto.odds_stream.Odd.toObject) : [],
    start: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 6, 0),
    note: jspb.Message.getFieldWithDefault(msg, 7, ""),
    currentResult: (f = msg.getCurrentResult()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    currentPhase: (f = msg.getCurrentPhase()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    periodResult: (f = msg.getPeriodResult()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    prematchSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    intKey: jspb.Message.getFieldWithDefault(msg, 12, 0),
    betradarId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    home: jspb.Message.getFieldWithDefault(msg, 14, ""),
    away: jspb.Message.getFieldWithDefault(msg, 15, ""),
    betStop: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    liveSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    aid: jspb.Message.getFieldWithDefault(msg, 23, 0),
    hid: jspb.Message.getFieldWithDefault(msg, 24, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.WebEventMap}
 */
proto.odds_stream.WebEventMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.WebEventMap;
  return proto.odds_stream.WebEventMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.WebEventMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.WebEventMap}
 */
proto.odds_stream.WebEventMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 2:
      var value = msg.getAllOddsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds_stream.Odd.deserializeBinaryFromReader, "", new proto.odds_stream.Odd());
         });
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 8:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCurrentResult(value);
      break;
    case 9:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCurrentPhase(value);
      break;
    case 10:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setPeriodResult(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrematchSuspended(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetradarId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setHome(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAway(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBetStop(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLiveSuspended(value);
      break;
    case 23:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAid(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setHid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.WebEventMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.WebEventMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.WebEventMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.WebEventMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getAllOddsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(2, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds_stream.Odd.serializeBinaryToWriter);
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCurrentResult();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getCurrentPhase();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getPeriodResult();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getPrematchSuspended();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getBetradarId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getHome();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAway();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getBetStop();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getLiveSuspended();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getAid();
  if (f !== 0) {
    writer.writeInt32(
      23,
      f
    );
  }
  f = message.getHid();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
};


/**
 * optional int32 competition_id = 1;
 * @return {number}
 */
proto.odds_stream.WebEventMap.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * map<string, Odd> all_odds = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds_stream.Odd>}
 */
proto.odds_stream.WebEventMap.prototype.getAllOddsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds_stream.Odd>} */ (
      jspb.Message.getMapField(this, 2, opt_noLazyCreate,
      proto.odds_stream.Odd));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.clearAllOddsMap = function() {
  this.getAllOddsMap().clear();
  return this;};


/**
 * optional string start = 3;
 * @return {string}
 */
proto.odds_stream.WebEventMap.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 sport_id = 4;
 * @return {number}
 */
proto.odds_stream.WebEventMap.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 location_id = 5;
 * @return {number}
 */
proto.odds_stream.WebEventMap.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 landbase_code = 6;
 * @return {number}
 */
proto.odds_stream.WebEventMap.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string note = 7;
 * @return {string}
 */
proto.odds_stream.WebEventMap.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Struct current_result = 8;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.WebEventMap.prototype.getCurrentResult = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 8));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.WebEventMap} returns this
*/
proto.odds_stream.WebEventMap.prototype.setCurrentResult = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.clearCurrentResult = function() {
  return this.setCurrentResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.WebEventMap.prototype.hasCurrentResult = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Struct current_phase = 9;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.WebEventMap.prototype.getCurrentPhase = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 9));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.WebEventMap} returns this
*/
proto.odds_stream.WebEventMap.prototype.setCurrentPhase = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.clearCurrentPhase = function() {
  return this.setCurrentPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.WebEventMap.prototype.hasCurrentPhase = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Struct period_result = 10;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.WebEventMap.prototype.getPeriodResult = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 10));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.WebEventMap} returns this
*/
proto.odds_stream.WebEventMap.prototype.setPeriodResult = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.clearPeriodResult = function() {
  return this.setPeriodResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.WebEventMap.prototype.hasPeriodResult = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool prematch_suspended = 11;
 * @return {boolean}
 */
proto.odds_stream.WebEventMap.prototype.getPrematchSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.setPrematchSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 int_key = 12;
 * @return {number}
 */
proto.odds_stream.WebEventMap.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string betradar_id = 13;
 * @return {string}
 */
proto.odds_stream.WebEventMap.prototype.getBetradarId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.setBetradarId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string home = 14;
 * @return {string}
 */
proto.odds_stream.WebEventMap.prototype.getHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.setHome = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string away = 15;
 * @return {string}
 */
proto.odds_stream.WebEventMap.prototype.getAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.setAway = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional bool bet_stop = 16;
 * @return {boolean}
 */
proto.odds_stream.WebEventMap.prototype.getBetStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.setBetStop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional bool live_suspended = 17;
 * @return {boolean}
 */
proto.odds_stream.WebEventMap.prototype.getLiveSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.setLiveSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional int32 aid = 23;
 * @return {number}
 */
proto.odds_stream.WebEventMap.prototype.getAid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 23, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.setAid = function(value) {
  return jspb.Message.setProto3IntField(this, 23, value);
};


/**
 * optional int32 hid = 24;
 * @return {number}
 */
proto.odds_stream.WebEventMap.prototype.getHid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.WebEventMap} returns this
 */
proto.odds_stream.WebEventMap.prototype.setHid = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.SettleEvent.repeatedFields_ = [50];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.SettleEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.SettleEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.SettleEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.SettleEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    competitionName: jspb.Message.getFieldWithDefault(msg, 31, ""),
    start: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 6, 0),
    note: jspb.Message.getFieldWithDefault(msg, 7, ""),
    currentResult: (f = msg.getCurrentResult()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    currentPhase: (f = msg.getCurrentPhase()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    periodResult: (f = msg.getPeriodResult()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    intKey: jspb.Message.getFieldWithDefault(msg, 12, 0),
    betradarId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    home: jspb.Message.getFieldWithDefault(msg, 14, ""),
    away: jspb.Message.getFieldWithDefault(msg, 15, ""),
    betStop: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    stage: jspb.Message.getFieldWithDefault(msg, 17, ""),
    isVerified: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    liveStatus: jspb.Message.getFieldWithDefault(msg, 19, ""),
    assignee: jspb.Message.getFieldWithDefault(msg, 20, 0),
    state: jspb.Message.getFieldWithDefault(msg, 21, ""),
    isSettled: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    currentPeriod: jspb.Message.getFieldWithDefault(msg, 23, ""),
    detailedResult: (f = msg.getDetailedResult()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    settlementNote: jspb.Message.getFieldWithDefault(msg, 25, ""),
    settled15: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    settled30: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    checkbox1530: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    locationName: jspb.Message.getFieldWithDefault(msg, 29, ""),
    automaticSettlement: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
    goesThrough: jspb.Message.getBooleanFieldWithDefault(msg, 41, false),
    checkboxM1: jspb.Message.getBooleanFieldWithDefault(msg, 42, false),
    settledM1: jspb.Message.getBooleanFieldWithDefault(msg, 43, false),
    assigneeName: jspb.Message.getFieldWithDefault(msg, 44, ""),
    ctrlProviders: (f = msg.getCtrlProviders()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    ctrlProvider: jspb.Message.getFieldWithDefault(msg, 46, ""),
    liveProviders: (f = msg.getLiveProviders()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    liveProvider: jspb.Message.getFieldWithDefault(msg, 48, ""),
    isManualSettlement: jspb.Message.getBooleanFieldWithDefault(msg, 49, false),
    settledPeriodsList: (f = jspb.Message.getRepeatedField(msg, 50)) == null ? undefined : f,
    lastActivePeriod: jspb.Message.getFieldWithDefault(msg, 51, ""),
    hasUnsettled: jspb.Message.getBooleanFieldWithDefault(msg, 52, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.SettleEvent}
 */
proto.odds_stream.SettleEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.SettleEvent;
  return proto.odds_stream.SettleEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.SettleEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.SettleEvent}
 */
proto.odds_stream.SettleEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 8:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCurrentResult(value);
      break;
    case 9:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCurrentPhase(value);
      break;
    case 10:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setPeriodResult(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetradarId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setHome(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAway(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBetStop(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setStage(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsVerified(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveStatus(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAssignee(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSettled(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentPeriod(value);
      break;
    case 24:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setDetailedResult(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementNote(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSettled15(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSettled30(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckbox1530(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutomaticSettlement(value);
      break;
    case 41:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGoesThrough(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckboxM1(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSettledM1(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssigneeName(value);
      break;
    case 45:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCtrlProviders(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setCtrlProvider(value);
      break;
    case 47:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setLiveProviders(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveProvider(value);
      break;
    case 49:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsManualSettlement(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.addSettledPeriods(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastActivePeriod(value);
      break;
    case 52:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasUnsettled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.SettleEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.SettleEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.SettleEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.SettleEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCurrentResult();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getCurrentPhase();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getPeriodResult();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getBetradarId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getHome();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAway();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getBetStop();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getStage();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getIsVerified();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getLiveStatus();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAssignee();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getIsSettled();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getCurrentPeriod();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getDetailedResult();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getSettlementNote();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getSettled15();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getSettled30();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getCheckbox1530();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getAutomaticSettlement();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getGoesThrough();
  if (f) {
    writer.writeBool(
      41,
      f
    );
  }
  f = message.getCheckboxM1();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
  f = message.getSettledM1();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getAssigneeName();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getCtrlProviders();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getCtrlProvider();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getLiveProviders();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getLiveProvider();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getIsManualSettlement();
  if (f) {
    writer.writeBool(
      49,
      f
    );
  }
  f = message.getSettledPeriodsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      50,
      f
    );
  }
  f = message.getLastActivePeriod();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getHasUnsettled();
  if (f) {
    writer.writeBool(
      52,
      f
    );
  }
};


/**
 * optional int32 competition_id = 1;
 * @return {number}
 */
proto.odds_stream.SettleEvent.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string competition_name = 31;
 * @return {string}
 */
proto.odds_stream.SettleEvent.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string start = 3;
 * @return {string}
 */
proto.odds_stream.SettleEvent.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 sport_id = 4;
 * @return {number}
 */
proto.odds_stream.SettleEvent.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 location_id = 5;
 * @return {number}
 */
proto.odds_stream.SettleEvent.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 landbase_code = 6;
 * @return {number}
 */
proto.odds_stream.SettleEvent.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string note = 7;
 * @return {string}
 */
proto.odds_stream.SettleEvent.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Struct current_result = 8;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.SettleEvent.prototype.getCurrentResult = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 8));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.SettleEvent} returns this
*/
proto.odds_stream.SettleEvent.prototype.setCurrentResult = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.clearCurrentResult = function() {
  return this.setCurrentResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.hasCurrentResult = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Struct current_phase = 9;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.SettleEvent.prototype.getCurrentPhase = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 9));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.SettleEvent} returns this
*/
proto.odds_stream.SettleEvent.prototype.setCurrentPhase = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.clearCurrentPhase = function() {
  return this.setCurrentPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.hasCurrentPhase = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Struct period_result = 10;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.SettleEvent.prototype.getPeriodResult = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 10));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.SettleEvent} returns this
*/
proto.odds_stream.SettleEvent.prototype.setPeriodResult = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.clearPeriodResult = function() {
  return this.setPeriodResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.hasPeriodResult = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool is_suspended = 11;
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 int_key = 12;
 * @return {number}
 */
proto.odds_stream.SettleEvent.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string betradar_id = 13;
 * @return {string}
 */
proto.odds_stream.SettleEvent.prototype.getBetradarId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setBetradarId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string home = 14;
 * @return {string}
 */
proto.odds_stream.SettleEvent.prototype.getHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setHome = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string away = 15;
 * @return {string}
 */
proto.odds_stream.SettleEvent.prototype.getAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setAway = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional bool bet_stop = 16;
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.getBetStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setBetStop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string stage = 17;
 * @return {string}
 */
proto.odds_stream.SettleEvent.prototype.getStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setStage = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool is_verified = 18;
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.getIsVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setIsVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string live_status = 19;
 * @return {string}
 */
proto.odds_stream.SettleEvent.prototype.getLiveStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setLiveStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 assignee = 20;
 * @return {number}
 */
proto.odds_stream.SettleEvent.prototype.getAssignee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setAssignee = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string state = 21;
 * @return {string}
 */
proto.odds_stream.SettleEvent.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional bool is_settled = 22;
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.getIsSettled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setIsSettled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional string current_period = 23;
 * @return {string}
 */
proto.odds_stream.SettleEvent.prototype.getCurrentPeriod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setCurrentPeriod = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional google.protobuf.Struct detailed_result = 24;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.SettleEvent.prototype.getDetailedResult = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 24));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.SettleEvent} returns this
*/
proto.odds_stream.SettleEvent.prototype.setDetailedResult = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.clearDetailedResult = function() {
  return this.setDetailedResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.hasDetailedResult = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional string settlement_note = 25;
 * @return {string}
 */
proto.odds_stream.SettleEvent.prototype.getSettlementNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setSettlementNote = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional bool settled_15 = 26;
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.getSettled15 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setSettled15 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool settled_30 = 27;
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.getSettled30 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setSettled30 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional bool checkbox_15_30 = 28;
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.getCheckbox1530 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setCheckbox1530 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional string location_name = 29;
 * @return {string}
 */
proto.odds_stream.SettleEvent.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional bool automatic_settlement = 30;
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.getAutomaticSettlement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setAutomaticSettlement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional bool goes_through = 41;
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.getGoesThrough = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 41, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setGoesThrough = function(value) {
  return jspb.Message.setProto3BooleanField(this, 41, value);
};


/**
 * optional bool checkbox_m1 = 42;
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.getCheckboxM1 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setCheckboxM1 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};


/**
 * optional bool settled_m1 = 43;
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.getSettledM1 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setSettledM1 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional string assignee_name = 44;
 * @return {string}
 */
proto.odds_stream.SettleEvent.prototype.getAssigneeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setAssigneeName = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional google.protobuf.Struct ctrl_providers = 45;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.SettleEvent.prototype.getCtrlProviders = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 45));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.SettleEvent} returns this
*/
proto.odds_stream.SettleEvent.prototype.setCtrlProviders = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.clearCtrlProviders = function() {
  return this.setCtrlProviders(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.hasCtrlProviders = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional string ctrl_provider = 46;
 * @return {string}
 */
proto.odds_stream.SettleEvent.prototype.getCtrlProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setCtrlProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional google.protobuf.Struct live_providers = 47;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.SettleEvent.prototype.getLiveProviders = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 47));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.SettleEvent} returns this
*/
proto.odds_stream.SettleEvent.prototype.setLiveProviders = function(value) {
  return jspb.Message.setWrapperField(this, 47, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.clearLiveProviders = function() {
  return this.setLiveProviders(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.hasLiveProviders = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional string live_provider = 48;
 * @return {string}
 */
proto.odds_stream.SettleEvent.prototype.getLiveProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setLiveProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional bool is_manual_settlement = 49;
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.getIsManualSettlement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 49, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setIsManualSettlement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 49, value);
};


/**
 * repeated string settled_periods = 50;
 * @return {!Array<string>}
 */
proto.odds_stream.SettleEvent.prototype.getSettledPeriodsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 50));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setSettledPeriodsList = function(value) {
  return jspb.Message.setField(this, 50, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.addSettledPeriods = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 50, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.clearSettledPeriodsList = function() {
  return this.setSettledPeriodsList([]);
};


/**
 * optional string last_active_period = 51;
 * @return {string}
 */
proto.odds_stream.SettleEvent.prototype.getLastActivePeriod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setLastActivePeriod = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional bool has_unsettled = 52;
 * @return {boolean}
 */
proto.odds_stream.SettleEvent.prototype.getHasUnsettled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 52, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEvent} returns this
 */
proto.odds_stream.SettleEvent.prototype.setHasUnsettled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 52, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.SettleEventMap.repeatedFields_ = [50];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.SettleEventMap.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.SettleEventMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.SettleEventMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.SettleEventMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    competitionName: jspb.Message.getFieldWithDefault(msg, 31, ""),
    start: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 6, 0),
    note: jspb.Message.getFieldWithDefault(msg, 7, ""),
    currentResult: (f = msg.getCurrentResult()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    currentPhase: (f = msg.getCurrentPhase()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    periodResult: (f = msg.getPeriodResult()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    intKey: jspb.Message.getFieldWithDefault(msg, 12, 0),
    betradarId: jspb.Message.getFieldWithDefault(msg, 13, ""),
    home: jspb.Message.getFieldWithDefault(msg, 14, ""),
    away: jspb.Message.getFieldWithDefault(msg, 15, ""),
    betStop: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    stage: jspb.Message.getFieldWithDefault(msg, 17, ""),
    isVerified: jspb.Message.getBooleanFieldWithDefault(msg, 18, false),
    liveStatus: jspb.Message.getFieldWithDefault(msg, 19, ""),
    assignee: jspb.Message.getFieldWithDefault(msg, 20, 0),
    prematchState: jspb.Message.getFieldWithDefault(msg, 21, ""),
    liveState: jspb.Message.getFieldWithDefault(msg, 32, ""),
    isSettled: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    currentPeriod: jspb.Message.getFieldWithDefault(msg, 23, ""),
    detailedResult: (f = msg.getDetailedResult()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    settlementNote: jspb.Message.getFieldWithDefault(msg, 25, ""),
    settled15: jspb.Message.getBooleanFieldWithDefault(msg, 26, false),
    settled30: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    checkbox1530: jspb.Message.getBooleanFieldWithDefault(msg, 28, false),
    locationName: jspb.Message.getFieldWithDefault(msg, 29, ""),
    automaticSettlement: jspb.Message.getBooleanFieldWithDefault(msg, 30, false),
    goesThrough: jspb.Message.getBooleanFieldWithDefault(msg, 41, false),
    checkboxM1: jspb.Message.getBooleanFieldWithDefault(msg, 42, false),
    settledM1: jspb.Message.getBooleanFieldWithDefault(msg, 43, false),
    assigneeName: jspb.Message.getFieldWithDefault(msg, 44, ""),
    ctrlProviders: (f = msg.getCtrlProviders()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    ctrlProvider: jspb.Message.getFieldWithDefault(msg, 46, ""),
    liveProviders: (f = msg.getLiveProviders()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    liveProvider: jspb.Message.getFieldWithDefault(msg, 48, ""),
    isManualSettlement: jspb.Message.getBooleanFieldWithDefault(msg, 49, false),
    settledPeriodsList: (f = jspb.Message.getRepeatedField(msg, 50)) == null ? undefined : f,
    lastActivePeriod: jspb.Message.getFieldWithDefault(msg, 51, ""),
    hasUnsettled: jspb.Message.getBooleanFieldWithDefault(msg, 52, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.SettleEventMap}
 */
proto.odds_stream.SettleEventMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.SettleEventMap;
  return proto.odds_stream.SettleEventMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.SettleEventMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.SettleEventMap}
 */
proto.odds_stream.SettleEventMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 8:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCurrentResult(value);
      break;
    case 9:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCurrentPhase(value);
      break;
    case 10:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setPeriodResult(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBetradarId(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setHome(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAway(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBetStop(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setStage(value);
      break;
    case 18:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsVerified(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveStatus(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAssignee(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrematchState(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveState(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSettled(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentPeriod(value);
      break;
    case 24:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setDetailedResult(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementNote(value);
      break;
    case 26:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSettled15(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSettled30(value);
      break;
    case 28:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckbox1530(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 30:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAutomaticSettlement(value);
      break;
    case 41:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGoesThrough(value);
      break;
    case 42:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCheckboxM1(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSettledM1(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssigneeName(value);
      break;
    case 45:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCtrlProviders(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setCtrlProvider(value);
      break;
    case 47:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setLiveProviders(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveProvider(value);
      break;
    case 49:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsManualSettlement(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.addSettledPeriods(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastActivePeriod(value);
      break;
    case 52:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasUnsettled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.SettleEventMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.SettleEventMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.SettleEventMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.SettleEventMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCurrentResult();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getCurrentPhase();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getPeriodResult();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getBetradarId();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getHome();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAway();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getBetStop();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getStage();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getIsVerified();
  if (f) {
    writer.writeBool(
      18,
      f
    );
  }
  f = message.getLiveStatus();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getAssignee();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
  f = message.getPrematchState();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getLiveState();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getIsSettled();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getCurrentPeriod();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getDetailedResult();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getSettlementNote();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getSettled15();
  if (f) {
    writer.writeBool(
      26,
      f
    );
  }
  f = message.getSettled30();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getCheckbox1530();
  if (f) {
    writer.writeBool(
      28,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getAutomaticSettlement();
  if (f) {
    writer.writeBool(
      30,
      f
    );
  }
  f = message.getGoesThrough();
  if (f) {
    writer.writeBool(
      41,
      f
    );
  }
  f = message.getCheckboxM1();
  if (f) {
    writer.writeBool(
      42,
      f
    );
  }
  f = message.getSettledM1();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
  f = message.getAssigneeName();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getCtrlProviders();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getCtrlProvider();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getLiveProviders();
  if (f != null) {
    writer.writeMessage(
      47,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getLiveProvider();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getIsManualSettlement();
  if (f) {
    writer.writeBool(
      49,
      f
    );
  }
  f = message.getSettledPeriodsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      50,
      f
    );
  }
  f = message.getLastActivePeriod();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getHasUnsettled();
  if (f) {
    writer.writeBool(
      52,
      f
    );
  }
};


/**
 * optional int32 competition_id = 1;
 * @return {number}
 */
proto.odds_stream.SettleEventMap.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string competition_name = 31;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string start = 3;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 sport_id = 4;
 * @return {number}
 */
proto.odds_stream.SettleEventMap.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 location_id = 5;
 * @return {number}
 */
proto.odds_stream.SettleEventMap.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional int32 landbase_code = 6;
 * @return {number}
 */
proto.odds_stream.SettleEventMap.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string note = 7;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.protobuf.Struct current_result = 8;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.SettleEventMap.prototype.getCurrentResult = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 8));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
*/
proto.odds_stream.SettleEventMap.prototype.setCurrentResult = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.clearCurrentResult = function() {
  return this.setCurrentResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.hasCurrentResult = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.protobuf.Struct current_phase = 9;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.SettleEventMap.prototype.getCurrentPhase = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 9));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
*/
proto.odds_stream.SettleEventMap.prototype.setCurrentPhase = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.clearCurrentPhase = function() {
  return this.setCurrentPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.hasCurrentPhase = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.Struct period_result = 10;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.SettleEventMap.prototype.getPeriodResult = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 10));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
*/
proto.odds_stream.SettleEventMap.prototype.setPeriodResult = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.clearPeriodResult = function() {
  return this.setPeriodResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.hasPeriodResult = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional bool is_suspended = 11;
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 int_key = 12;
 * @return {number}
 */
proto.odds_stream.SettleEventMap.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string betradar_id = 13;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getBetradarId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setBetradarId = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string home = 14;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setHome = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string away = 15;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setAway = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional bool bet_stop = 16;
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.getBetStop = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setBetStop = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional string stage = 17;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setStage = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional bool is_verified = 18;
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.getIsVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 18, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setIsVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 18, value);
};


/**
 * optional string live_status = 19;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getLiveStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setLiveStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 assignee = 20;
 * @return {number}
 */
proto.odds_stream.SettleEventMap.prototype.getAssignee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setAssignee = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string prematch_state = 21;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getPrematchState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setPrematchState = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string live_state = 32;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getLiveState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setLiveState = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional bool is_settled = 22;
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.getIsSettled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setIsSettled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional string current_period = 23;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getCurrentPeriod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setCurrentPeriod = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional google.protobuf.Struct detailed_result = 24;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.SettleEventMap.prototype.getDetailedResult = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 24));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
*/
proto.odds_stream.SettleEventMap.prototype.setDetailedResult = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.clearDetailedResult = function() {
  return this.setDetailedResult(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.hasDetailedResult = function() {
  return jspb.Message.getField(this, 24) != null;
};


/**
 * optional string settlement_note = 25;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getSettlementNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setSettlementNote = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional bool settled_15 = 26;
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.getSettled15 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 26, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setSettled15 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 26, value);
};


/**
 * optional bool settled_30 = 27;
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.getSettled30 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setSettled30 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * optional bool checkbox_15_30 = 28;
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.getCheckbox1530 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 28, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setCheckbox1530 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 28, value);
};


/**
 * optional string location_name = 29;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional bool automatic_settlement = 30;
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.getAutomaticSettlement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 30, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setAutomaticSettlement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 30, value);
};


/**
 * optional bool goes_through = 41;
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.getGoesThrough = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 41, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setGoesThrough = function(value) {
  return jspb.Message.setProto3BooleanField(this, 41, value);
};


/**
 * optional bool checkbox_m1 = 42;
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.getCheckboxM1 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 42, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setCheckboxM1 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 42, value);
};


/**
 * optional bool settled_m1 = 43;
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.getSettledM1 = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setSettledM1 = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};


/**
 * optional string assignee_name = 44;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getAssigneeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setAssigneeName = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional google.protobuf.Struct ctrl_providers = 45;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.SettleEventMap.prototype.getCtrlProviders = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 45));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
*/
proto.odds_stream.SettleEventMap.prototype.setCtrlProviders = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.clearCtrlProviders = function() {
  return this.setCtrlProviders(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.hasCtrlProviders = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional string ctrl_provider = 46;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getCtrlProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setCtrlProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional google.protobuf.Struct live_providers = 47;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.SettleEventMap.prototype.getLiveProviders = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 47));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
*/
proto.odds_stream.SettleEventMap.prototype.setLiveProviders = function(value) {
  return jspb.Message.setWrapperField(this, 47, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.clearLiveProviders = function() {
  return this.setLiveProviders(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.hasLiveProviders = function() {
  return jspb.Message.getField(this, 47) != null;
};


/**
 * optional string live_provider = 48;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getLiveProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setLiveProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional bool is_manual_settlement = 49;
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.getIsManualSettlement = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 49, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setIsManualSettlement = function(value) {
  return jspb.Message.setProto3BooleanField(this, 49, value);
};


/**
 * repeated string settled_periods = 50;
 * @return {!Array<string>}
 */
proto.odds_stream.SettleEventMap.prototype.getSettledPeriodsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 50));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setSettledPeriodsList = function(value) {
  return jspb.Message.setField(this, 50, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.addSettledPeriods = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 50, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.clearSettledPeriodsList = function() {
  return this.setSettledPeriodsList([]);
};


/**
 * optional string last_active_period = 51;
 * @return {string}
 */
proto.odds_stream.SettleEventMap.prototype.getLastActivePeriod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setLastActivePeriod = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional bool has_unsettled = 52;
 * @return {boolean}
 */
proto.odds_stream.SettleEventMap.prototype.getHasUnsettled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 52, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettleEventMap} returns this
 */
proto.odds_stream.SettleEventMap.prototype.setHasUnsettled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 52, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.GetEventsSettleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.GetEventsSettleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.GetEventsSettleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.GetEventsSettleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsSettleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds_stream.SettleEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.GetEventsSettleResponse}
 */
proto.odds_stream.GetEventsSettleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.GetEventsSettleResponse;
  return proto.odds_stream.GetEventsSettleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.GetEventsSettleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.GetEventsSettleResponse}
 */
proto.odds_stream.GetEventsSettleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds_stream.SettleEvent;
      reader.readMessage(value,proto.odds_stream.SettleEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.GetEventsSettleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.GetEventsSettleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.GetEventsSettleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetEventsSettleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds_stream.SettleEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SettleEvent events = 1;
 * @return {!Array<!proto.odds_stream.SettleEvent>}
 */
proto.odds_stream.GetEventsSettleResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.SettleEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.SettleEvent, 1));
};


/**
 * @param {!Array<!proto.odds_stream.SettleEvent>} value
 * @return {!proto.odds_stream.GetEventsSettleResponse} returns this
*/
proto.odds_stream.GetEventsSettleResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds_stream.SettleEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.SettleEvent}
 */
proto.odds_stream.GetEventsSettleResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds_stream.SettleEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.GetEventsSettleResponse} returns this
 */
proto.odds_stream.GetEventsSettleResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.GetPlayerEventsResponseBO.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.GetPlayerEventsResponseBO.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.GetPlayerEventsResponseBO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.GetPlayerEventsResponseBO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetPlayerEventsResponseBO.toObject = function(includeInstance, msg) {
  var f, obj = {
    prematchEventsList: jspb.Message.toObjectList(msg.getPrematchEventsList(),
    proto.odds_stream.PlayerEventBO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.GetPlayerEventsResponseBO}
 */
proto.odds_stream.GetPlayerEventsResponseBO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.GetPlayerEventsResponseBO;
  return proto.odds_stream.GetPlayerEventsResponseBO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.GetPlayerEventsResponseBO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.GetPlayerEventsResponseBO}
 */
proto.odds_stream.GetPlayerEventsResponseBO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds_stream.PlayerEventBO;
      reader.readMessage(value,proto.odds_stream.PlayerEventBO.deserializeBinaryFromReader);
      msg.addPrematchEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.GetPlayerEventsResponseBO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.GetPlayerEventsResponseBO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.GetPlayerEventsResponseBO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetPlayerEventsResponseBO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrematchEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds_stream.PlayerEventBO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PlayerEventBO prematch_events = 1;
 * @return {!Array<!proto.odds_stream.PlayerEventBO>}
 */
proto.odds_stream.GetPlayerEventsResponseBO.prototype.getPrematchEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.PlayerEventBO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.PlayerEventBO, 1));
};


/**
 * @param {!Array<!proto.odds_stream.PlayerEventBO>} value
 * @return {!proto.odds_stream.GetPlayerEventsResponseBO} returns this
*/
proto.odds_stream.GetPlayerEventsResponseBO.prototype.setPrematchEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds_stream.PlayerEventBO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.PlayerEventBO}
 */
proto.odds_stream.GetPlayerEventsResponseBO.prototype.addPrematchEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds_stream.PlayerEventBO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.GetPlayerEventsResponseBO} returns this
 */
proto.odds_stream.GetPlayerEventsResponseBO.prototype.clearPrematchEventsList = function() {
  return this.setPrematchEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.PlayerEventBO.repeatedFields_ = [21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.PlayerEventBO.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.PlayerEventBO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.PlayerEventBO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.PlayerEventBO.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    note: jspb.Message.getFieldWithDefault(msg, 3, ""),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sportCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    locationName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    start: jspb.Message.getFieldWithDefault(msg, 9, ""),
    playerName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    competitionName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    intKey: jspb.Message.getFieldWithDefault(msg, 14, 0),
    eventIntKey: jspb.Message.getFieldWithDefault(msg, 15, 0),
    eventName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    competitorName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    baseMarketsMap: (f = msg.getBaseMarketsMap()) ? f.toObject(includeInstance, proto.odds_stream.OddBO.toObject) : [],
    hasSlip: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    operatorNotesList: jspb.Message.toObjectList(msg.getOperatorNotesList(),
    proto.odds_stream.OperatorNote.toObject, includeInstance),
    prematchMaxAmount: jspb.Message.getFieldWithDefault(msg, 22, 0),
    eventPublished: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    marketTemplateId: jspb.Message.getFieldWithDefault(msg, 24, 0),
    approvalTemplateId: jspb.Message.getFieldWithDefault(msg, 25, 0),
    competitionId: jspb.Message.getFieldWithDefault(msg, 26, 0),
    canPublish: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    marketsMap: (f = msg.getMarketsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.PlayerEventBO}
 */
proto.odds_stream.PlayerEventBO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.PlayerEventBO;
  return proto.odds_stream.PlayerEventBO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.PlayerEventBO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.PlayerEventBO}
 */
proto.odds_stream.PlayerEventBO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSportCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventIntKey(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventName(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitorName(value);
      break;
    case 19:
      var value = msg.getBaseMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds_stream.OddBO.deserializeBinaryFromReader, "", new proto.odds_stream.OddBO());
         });
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSlip(value);
      break;
    case 21:
      var value = new proto.odds_stream.OperatorNote;
      reader.readMessage(value,proto.odds_stream.OperatorNote.deserializeBinaryFromReader);
      msg.addOperatorNotes(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrematchMaxAmount(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEventPublished(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketTemplateId(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setApprovalTemplateId(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanPublish(value);
      break;
    case 28:
      var value = msg.getMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.PlayerEventBO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.PlayerEventBO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.PlayerEventBO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.PlayerEventBO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSportCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPlayerName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getEventIntKey();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCompetitorName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getBaseMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(19, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds_stream.OddBO.serializeBinaryToWriter);
  }
  f = message.getHasSlip();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getOperatorNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.odds_stream.OperatorNote.serializeBinaryToWriter
    );
  }
  f = message.getPrematchMaxAmount();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getEventPublished();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getMarketTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getApprovalTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      26,
      f
    );
  }
  f = message.getCanPublish();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(28, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.odds_stream.PlayerEventBO.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.odds_stream.PlayerEventBO.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string note = 3;
 * @return {string}
 */
proto.odds_stream.PlayerEventBO.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 landbase_code = 4;
 * @return {number}
 */
proto.odds_stream.PlayerEventBO.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds_stream.PlayerEventBO.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string sport_code = 6;
 * @return {string}
 */
proto.odds_stream.PlayerEventBO.prototype.getSportCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setSportCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string location_name = 8;
 * @return {string}
 */
proto.odds_stream.PlayerEventBO.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string start = 9;
 * @return {string}
 */
proto.odds_stream.PlayerEventBO.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string player_name = 10;
 * @return {string}
 */
proto.odds_stream.PlayerEventBO.prototype.getPlayerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setPlayerName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string competition_name = 12;
 * @return {string}
 */
proto.odds_stream.PlayerEventBO.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool is_suspended = 13;
 * @return {boolean}
 */
proto.odds_stream.PlayerEventBO.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int32 int_key = 14;
 * @return {number}
 */
proto.odds_stream.PlayerEventBO.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 event_int_key = 15;
 * @return {number}
 */
proto.odds_stream.PlayerEventBO.prototype.getEventIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setEventIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string event_name = 16;
 * @return {string}
 */
proto.odds_stream.PlayerEventBO.prototype.getEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string competitor_name = 18;
 * @return {string}
 */
proto.odds_stream.PlayerEventBO.prototype.getCompetitorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setCompetitorName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * map<string, OddBO> base_markets = 19;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds_stream.OddBO>}
 */
proto.odds_stream.PlayerEventBO.prototype.getBaseMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds_stream.OddBO>} */ (
      jspb.Message.getMapField(this, 19, opt_noLazyCreate,
      proto.odds_stream.OddBO));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.clearBaseMarketsMap = function() {
  this.getBaseMarketsMap().clear();
  return this;};


/**
 * optional bool has_slip = 20;
 * @return {boolean}
 */
proto.odds_stream.PlayerEventBO.prototype.getHasSlip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setHasSlip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * repeated OperatorNote operator_notes = 21;
 * @return {!Array<!proto.odds_stream.OperatorNote>}
 */
proto.odds_stream.PlayerEventBO.prototype.getOperatorNotesList = function() {
  return /** @type{!Array<!proto.odds_stream.OperatorNote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.OperatorNote, 21));
};


/**
 * @param {!Array<!proto.odds_stream.OperatorNote>} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
*/
proto.odds_stream.PlayerEventBO.prototype.setOperatorNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.odds_stream.OperatorNote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.OperatorNote}
 */
proto.odds_stream.PlayerEventBO.prototype.addOperatorNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.odds_stream.OperatorNote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.clearOperatorNotesList = function() {
  return this.setOperatorNotesList([]);
};


/**
 * optional int32 prematch_max_amount = 22;
 * @return {number}
 */
proto.odds_stream.PlayerEventBO.prototype.getPrematchMaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setPrematchMaxAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional bool event_published = 23;
 * @return {boolean}
 */
proto.odds_stream.PlayerEventBO.prototype.getEventPublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setEventPublished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional int32 market_template_id = 24;
 * @return {number}
 */
proto.odds_stream.PlayerEventBO.prototype.getMarketTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setMarketTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 approval_template_id = 25;
 * @return {number}
 */
proto.odds_stream.PlayerEventBO.prototype.getApprovalTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setApprovalTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional int32 competition_id = 26;
 * @return {number}
 */
proto.odds_stream.PlayerEventBO.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional bool can_publish = 27;
 * @return {boolean}
 */
proto.odds_stream.PlayerEventBO.prototype.getCanPublish = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.setCanPublish = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * map<string, bool> markets = 28;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.odds_stream.PlayerEventBO.prototype.getMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 28, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.PlayerEventBO} returns this
 */
proto.odds_stream.PlayerEventBO.prototype.clearMarketsMap = function() {
  this.getMarketsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.PlayerEventBOMap.repeatedFields_ = [21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.PlayerEventBOMap.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.PlayerEventBOMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.PlayerEventBOMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.PlayerEventBOMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    note: jspb.Message.getFieldWithDefault(msg, 3, ""),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sportCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    locationName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    start: jspb.Message.getFieldWithDefault(msg, 9, ""),
    playerName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    competitionName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    intKey: jspb.Message.getFieldWithDefault(msg, 14, 0),
    eventIntKey: jspb.Message.getFieldWithDefault(msg, 15, 0),
    eventName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    competitorName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    baseMarketsMap: (f = msg.getBaseMarketsMap()) ? f.toObject(includeInstance, proto.odds_stream.OddBO.toObject) : [],
    hasSlip: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    operatorNotesList: jspb.Message.toObjectList(msg.getOperatorNotesList(),
    proto.odds_stream.OperatorNote.toObject, includeInstance),
    prematchMaxAmount: jspb.Message.getFieldWithDefault(msg, 22, 0),
    eventPublished: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    marketTemplateId: jspb.Message.getFieldWithDefault(msg, 24, 0),
    approvalTemplateId: jspb.Message.getFieldWithDefault(msg, 25, 0),
    competitionId: jspb.Message.getFieldWithDefault(msg, 26, 0),
    canPublish: jspb.Message.getBooleanFieldWithDefault(msg, 27, false),
    marketsMap: (f = msg.getMarketsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.PlayerEventBOMap}
 */
proto.odds_stream.PlayerEventBOMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.PlayerEventBOMap;
  return proto.odds_stream.PlayerEventBOMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.PlayerEventBOMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.PlayerEventBOMap}
 */
proto.odds_stream.PlayerEventBOMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSportCode(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerName(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventIntKey(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventName(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitorName(value);
      break;
    case 19:
      var value = msg.getBaseMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds_stream.OddBO.deserializeBinaryFromReader, "", new proto.odds_stream.OddBO());
         });
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSlip(value);
      break;
    case 21:
      var value = new proto.odds_stream.OperatorNote;
      reader.readMessage(value,proto.odds_stream.OperatorNote.deserializeBinaryFromReader);
      msg.addOperatorNotes(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrematchMaxAmount(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEventPublished(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketTemplateId(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setApprovalTemplateId(value);
      break;
    case 26:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 27:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCanPublish(value);
      break;
    case 28:
      var value = msg.getMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.PlayerEventBOMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.PlayerEventBOMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.PlayerEventBOMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.PlayerEventBOMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSportCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPlayerName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getEventIntKey();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCompetitorName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getBaseMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(19, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds_stream.OddBO.serializeBinaryToWriter);
  }
  f = message.getHasSlip();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getOperatorNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.odds_stream.OperatorNote.serializeBinaryToWriter
    );
  }
  f = message.getPrematchMaxAmount();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getEventPublished();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getMarketTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getApprovalTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      26,
      f
    );
  }
  f = message.getCanPublish();
  if (f) {
    writer.writeBool(
      27,
      f
    );
  }
  f = message.getMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(28, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string note = 3;
 * @return {string}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 landbase_code = 4;
 * @return {number}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string sport_code = 6;
 * @return {string}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getSportCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setSportCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string location_name = 8;
 * @return {string}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string start = 9;
 * @return {string}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string player_name = 10;
 * @return {string}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getPlayerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setPlayerName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string competition_name = 12;
 * @return {string}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool is_suspended = 13;
 * @return {boolean}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int32 int_key = 14;
 * @return {number}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 event_int_key = 15;
 * @return {number}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getEventIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setEventIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string event_name = 16;
 * @return {string}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string competitor_name = 18;
 * @return {string}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getCompetitorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setCompetitorName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * map<string, OddBO> base_markets = 19;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds_stream.OddBO>}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getBaseMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds_stream.OddBO>} */ (
      jspb.Message.getMapField(this, 19, opt_noLazyCreate,
      proto.odds_stream.OddBO));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.clearBaseMarketsMap = function() {
  this.getBaseMarketsMap().clear();
  return this;};


/**
 * optional bool has_slip = 20;
 * @return {boolean}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getHasSlip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setHasSlip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * repeated OperatorNote operator_notes = 21;
 * @return {!Array<!proto.odds_stream.OperatorNote>}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getOperatorNotesList = function() {
  return /** @type{!Array<!proto.odds_stream.OperatorNote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.OperatorNote, 21));
};


/**
 * @param {!Array<!proto.odds_stream.OperatorNote>} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
*/
proto.odds_stream.PlayerEventBOMap.prototype.setOperatorNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.odds_stream.OperatorNote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.OperatorNote}
 */
proto.odds_stream.PlayerEventBOMap.prototype.addOperatorNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.odds_stream.OperatorNote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.clearOperatorNotesList = function() {
  return this.setOperatorNotesList([]);
};


/**
 * optional int32 prematch_max_amount = 22;
 * @return {number}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getPrematchMaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setPrematchMaxAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional bool event_published = 23;
 * @return {boolean}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getEventPublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setEventPublished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional int32 market_template_id = 24;
 * @return {number}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getMarketTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setMarketTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 approval_template_id = 25;
 * @return {number}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getApprovalTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setApprovalTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional int32 competition_id = 26;
 * @return {number}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 26, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 26, value);
};


/**
 * optional bool can_publish = 27;
 * @return {boolean}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getCanPublish = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 27, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.setCanPublish = function(value) {
  return jspb.Message.setProto3BooleanField(this, 27, value);
};


/**
 * map<string, bool> markets = 28;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.odds_stream.PlayerEventBOMap.prototype.getMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 28, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.PlayerEventBOMap} returns this
 */
proto.odds_stream.PlayerEventBOMap.prototype.clearMarketsMap = function() {
  this.getMarketsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.PlayerEventMap.repeatedFields_ = [21];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.PlayerEventMap.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.PlayerEventMap.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.PlayerEventMap} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.PlayerEventMap.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    state: jspb.Message.getFieldWithDefault(msg, 2, ""),
    note: jspb.Message.getFieldWithDefault(msg, 3, ""),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    sportCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    locationId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    locationName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    start: jspb.Message.getFieldWithDefault(msg, 9, ""),
    playerName: jspb.Message.getFieldWithDefault(msg, 10, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 11, 0),
    competitionName: jspb.Message.getFieldWithDefault(msg, 12, ""),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 13, false),
    intKey: jspb.Message.getFieldWithDefault(msg, 14, 0),
    eventIntKey: jspb.Message.getFieldWithDefault(msg, 15, 0),
    eventName: jspb.Message.getFieldWithDefault(msg, 16, ""),
    competitorId: jspb.Message.getFieldWithDefault(msg, 17, 0),
    competitorName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    baseMarketsMap: (f = msg.getBaseMarketsMap()) ? f.toObject(includeInstance, proto.odds_stream.Odd.toObject) : [],
    hasSlip: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    operatorNotesList: jspb.Message.toObjectList(msg.getOperatorNotesList(),
    proto.odds_stream.OperatorNote.toObject, includeInstance),
    prematchMaxAmount: jspb.Message.getFieldWithDefault(msg, 22, 0),
    eventPublished: jspb.Message.getBooleanFieldWithDefault(msg, 23, false),
    marketTemplateId: jspb.Message.getFieldWithDefault(msg, 24, 0),
    approvalTemplateId: jspb.Message.getFieldWithDefault(msg, 25, 0),
    allOddsMap: (f = msg.getAllOddsMap()) ? f.toObject(includeInstance, proto.odds_stream.Odd.toObject) : [],
    eventHome: jspb.Message.getFieldWithDefault(msg, 27, ""),
    eventAway: jspb.Message.getFieldWithDefault(msg, 28, ""),
    eventLandbaseCode: jspb.Message.getFieldWithDefault(msg, 29, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.PlayerEventMap}
 */
proto.odds_stream.PlayerEventMap.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.PlayerEventMap;
  return proto.odds_stream.PlayerEventMap.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.PlayerEventMap} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.PlayerEventMap}
 */
proto.odds_stream.PlayerEventMap.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSportCode(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerName(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 14:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventIntKey(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventName(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitorId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitorName(value);
      break;
    case 19:
      var value = msg.getBaseMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds_stream.Odd.deserializeBinaryFromReader, "", new proto.odds_stream.Odd());
         });
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasSlip(value);
      break;
    case 21:
      var value = new proto.odds_stream.OperatorNote;
      reader.readMessage(value,proto.odds_stream.OperatorNote.deserializeBinaryFromReader);
      msg.addOperatorNotes(value);
      break;
    case 22:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPrematchMaxAmount(value);
      break;
    case 23:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEventPublished(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketTemplateId(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setApprovalTemplateId(value);
      break;
    case 26:
      var value = msg.getAllOddsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.odds_stream.Odd.deserializeBinaryFromReader, "", new proto.odds_stream.Odd());
         });
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventHome(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventAway(value);
      break;
    case 29:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventLandbaseCode(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.PlayerEventMap.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.PlayerEventMap.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.PlayerEventMap} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.PlayerEventMap.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getSportCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getPlayerName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      14,
      f
    );
  }
  f = message.getEventIntKey();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCompetitorId();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getCompetitorName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getBaseMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(19, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds_stream.Odd.serializeBinaryToWriter);
  }
  f = message.getHasSlip();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getOperatorNotesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      21,
      f,
      proto.odds_stream.OperatorNote.serializeBinaryToWriter
    );
  }
  f = message.getPrematchMaxAmount();
  if (f !== 0) {
    writer.writeInt32(
      22,
      f
    );
  }
  f = message.getEventPublished();
  if (f) {
    writer.writeBool(
      23,
      f
    );
  }
  f = message.getMarketTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      24,
      f
    );
  }
  f = message.getApprovalTemplateId();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
  f = message.getAllOddsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(26, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.odds_stream.Odd.serializeBinaryToWriter);
  }
  f = message.getEventHome();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getEventAway();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getEventLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      29,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.odds_stream.PlayerEventMap.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string state = 2;
 * @return {string}
 */
proto.odds_stream.PlayerEventMap.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string note = 3;
 * @return {string}
 */
proto.odds_stream.PlayerEventMap.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 landbase_code = 4;
 * @return {number}
 */
proto.odds_stream.PlayerEventMap.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds_stream.PlayerEventMap.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string sport_code = 6;
 * @return {string}
 */
proto.odds_stream.PlayerEventMap.prototype.getSportCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setSportCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 location_id = 7;
 * @return {number}
 */
proto.odds_stream.PlayerEventMap.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string location_name = 8;
 * @return {string}
 */
proto.odds_stream.PlayerEventMap.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string start = 9;
 * @return {string}
 */
proto.odds_stream.PlayerEventMap.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string player_name = 10;
 * @return {string}
 */
proto.odds_stream.PlayerEventMap.prototype.getPlayerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setPlayerName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 competition_id = 11;
 * @return {number}
 */
proto.odds_stream.PlayerEventMap.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional string competition_name = 12;
 * @return {string}
 */
proto.odds_stream.PlayerEventMap.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional bool is_suspended = 13;
 * @return {boolean}
 */
proto.odds_stream.PlayerEventMap.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};


/**
 * optional int32 int_key = 14;
 * @return {number}
 */
proto.odds_stream.PlayerEventMap.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 14, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 14, value);
};


/**
 * optional int32 event_int_key = 15;
 * @return {number}
 */
proto.odds_stream.PlayerEventMap.prototype.getEventIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setEventIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string event_name = 16;
 * @return {string}
 */
proto.odds_stream.PlayerEventMap.prototype.getEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int32 competitor_id = 17;
 * @return {number}
 */
proto.odds_stream.PlayerEventMap.prototype.getCompetitorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setCompetitorId = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string competitor_name = 18;
 * @return {string}
 */
proto.odds_stream.PlayerEventMap.prototype.getCompetitorName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setCompetitorName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * map<string, Odd> base_markets = 19;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds_stream.Odd>}
 */
proto.odds_stream.PlayerEventMap.prototype.getBaseMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds_stream.Odd>} */ (
      jspb.Message.getMapField(this, 19, opt_noLazyCreate,
      proto.odds_stream.Odd));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.clearBaseMarketsMap = function() {
  this.getBaseMarketsMap().clear();
  return this;};


/**
 * optional bool has_slip = 20;
 * @return {boolean}
 */
proto.odds_stream.PlayerEventMap.prototype.getHasSlip = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setHasSlip = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * repeated OperatorNote operator_notes = 21;
 * @return {!Array<!proto.odds_stream.OperatorNote>}
 */
proto.odds_stream.PlayerEventMap.prototype.getOperatorNotesList = function() {
  return /** @type{!Array<!proto.odds_stream.OperatorNote>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.OperatorNote, 21));
};


/**
 * @param {!Array<!proto.odds_stream.OperatorNote>} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
*/
proto.odds_stream.PlayerEventMap.prototype.setOperatorNotesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 21, value);
};


/**
 * @param {!proto.odds_stream.OperatorNote=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.OperatorNote}
 */
proto.odds_stream.PlayerEventMap.prototype.addOperatorNotes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 21, opt_value, proto.odds_stream.OperatorNote, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.clearOperatorNotesList = function() {
  return this.setOperatorNotesList([]);
};


/**
 * optional int32 prematch_max_amount = 22;
 * @return {number}
 */
proto.odds_stream.PlayerEventMap.prototype.getPrematchMaxAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 22, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setPrematchMaxAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 22, value);
};


/**
 * optional bool event_published = 23;
 * @return {boolean}
 */
proto.odds_stream.PlayerEventMap.prototype.getEventPublished = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 23, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setEventPublished = function(value) {
  return jspb.Message.setProto3BooleanField(this, 23, value);
};


/**
 * optional int32 market_template_id = 24;
 * @return {number}
 */
proto.odds_stream.PlayerEventMap.prototype.getMarketTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setMarketTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional int32 approval_template_id = 25;
 * @return {number}
 */
proto.odds_stream.PlayerEventMap.prototype.getApprovalTemplateId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setApprovalTemplateId = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * map<string, Odd> all_odds = 26;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.odds_stream.Odd>}
 */
proto.odds_stream.PlayerEventMap.prototype.getAllOddsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.odds_stream.Odd>} */ (
      jspb.Message.getMapField(this, 26, opt_noLazyCreate,
      proto.odds_stream.Odd));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.clearAllOddsMap = function() {
  this.getAllOddsMap().clear();
  return this;};


/**
 * optional string event_home = 27;
 * @return {string}
 */
proto.odds_stream.PlayerEventMap.prototype.getEventHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setEventHome = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string event_away = 28;
 * @return {string}
 */
proto.odds_stream.PlayerEventMap.prototype.getEventAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setEventAway = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional int32 event_landbase_code = 29;
 * @return {number}
 */
proto.odds_stream.PlayerEventMap.prototype.getEventLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 29, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.PlayerEventMap} returns this
 */
proto.odds_stream.PlayerEventMap.prototype.setEventLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 29, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.GetPlayerEventsSettleResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.GetPlayerEventsSettleResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.GetPlayerEventsSettleResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.GetPlayerEventsSettleResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetPlayerEventsSettleResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds_stream.SettlePlayerEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.GetPlayerEventsSettleResponse}
 */
proto.odds_stream.GetPlayerEventsSettleResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.GetPlayerEventsSettleResponse;
  return proto.odds_stream.GetPlayerEventsSettleResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.GetPlayerEventsSettleResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.GetPlayerEventsSettleResponse}
 */
proto.odds_stream.GetPlayerEventsSettleResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds_stream.SettlePlayerEvent;
      reader.readMessage(value,proto.odds_stream.SettlePlayerEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.GetPlayerEventsSettleResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.GetPlayerEventsSettleResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.GetPlayerEventsSettleResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetPlayerEventsSettleResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds_stream.SettlePlayerEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SettlePlayerEvent events = 1;
 * @return {!Array<!proto.odds_stream.SettlePlayerEvent>}
 */
proto.odds_stream.GetPlayerEventsSettleResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.SettlePlayerEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.SettlePlayerEvent, 1));
};


/**
 * @param {!Array<!proto.odds_stream.SettlePlayerEvent>} value
 * @return {!proto.odds_stream.GetPlayerEventsSettleResponse} returns this
*/
proto.odds_stream.GetPlayerEventsSettleResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds_stream.SettlePlayerEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.SettlePlayerEvent}
 */
proto.odds_stream.GetPlayerEventsSettleResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds_stream.SettlePlayerEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.GetPlayerEventsSettleResponse} returns this
 */
proto.odds_stream.GetPlayerEventsSettleResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.SettlePlayerEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.SettlePlayerEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.SettlePlayerEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.SettlePlayerEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    competitionName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    start: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sportName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sportCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    locationName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 8, 0),
    playerName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    intKey: jspb.Message.getFieldWithDefault(msg, 10, 0),
    isTtx: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    assignee: jspb.Message.getFieldWithDefault(msg, 12, 0),
    state: jspb.Message.getFieldWithDefault(msg, 13, ""),
    hasUnsettled: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    isVerified: jspb.Message.getBooleanFieldWithDefault(msg, 15, false),
    settlementNote: jspb.Message.getFieldWithDefault(msg, 16, ""),
    eventIntKey: jspb.Message.getFieldWithDefault(msg, 17, 0),
    eventName: jspb.Message.getFieldWithDefault(msg, 18, ""),
    assigneeName: jspb.Message.getFieldWithDefault(msg, 19, ""),
    settlementHistory: (f = msg.getSettlementHistory()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.SettlePlayerEvent}
 */
proto.odds_stream.SettlePlayerEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.SettlePlayerEvent;
  return proto.odds_stream.SettlePlayerEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.SettlePlayerEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.SettlePlayerEvent}
 */
proto.odds_stream.SettlePlayerEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSportName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSportCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerName(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsTtx(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAssignee(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setState(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasUnsettled(value);
      break;
    case 15:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsVerified(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementNote(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventIntKey(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventName(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssigneeName(value);
      break;
    case 20:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setSettlementHistory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.SettlePlayerEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.SettlePlayerEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.SettlePlayerEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.SettlePlayerEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSportName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSportCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getPlayerName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getIsTtx();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getAssignee();
  if (f !== 0) {
    writer.writeInt32(
      12,
      f
    );
  }
  f = message.getState();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getHasUnsettled();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIsVerified();
  if (f) {
    writer.writeBool(
      15,
      f
    );
  }
  f = message.getSettlementNote();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getEventIntKey();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getEventName();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getAssigneeName();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSettlementHistory();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 competition_id = 1;
 * @return {number}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string competition_name = 2;
 * @return {string}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string start = 3;
 * @return {string}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 sport_id = 4;
 * @return {number}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string sport_name = 5;
 * @return {string}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getSportName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setSportName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string sport_code = 6;
 * @return {string}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getSportCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setSportCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string location_name = 7;
 * @return {string}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 landbase_code = 8;
 * @return {number}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional string player_name = 9;
 * @return {string}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getPlayerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setPlayerName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 int_key = 10;
 * @return {number}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool is_ttx = 11;
 * @return {boolean}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getIsTtx = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setIsTtx = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional int32 assignee = 12;
 * @return {number}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getAssignee = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setAssignee = function(value) {
  return jspb.Message.setProto3IntField(this, 12, value);
};


/**
 * optional string state = 13;
 * @return {string}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getState = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setState = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool has_unsettled = 14;
 * @return {boolean}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getHasUnsettled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setHasUnsettled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional bool is_verified = 15;
 * @return {boolean}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getIsVerified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 15, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setIsVerified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 15, value);
};


/**
 * optional string settlement_note = 16;
 * @return {string}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getSettlementNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setSettlementNote = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional int32 event_int_key = 17;
 * @return {number}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getEventIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setEventIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string event_name = 18;
 * @return {string}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getEventName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setEventName = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string assignee_name = 19;
 * @return {string}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getAssigneeName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.setAssigneeName = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional google.protobuf.Struct settlement_history = 20;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.SettlePlayerEvent.prototype.getSettlementHistory = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 20));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
*/
proto.odds_stream.SettlePlayerEvent.prototype.setSettlementHistory = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.SettlePlayerEvent} returns this
 */
proto.odds_stream.SettlePlayerEvent.prototype.clearSettlementHistory = function() {
  return this.setSettlementHistory(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.SettlePlayerEvent.prototype.hasSettlementHistory = function() {
  return jspb.Message.getField(this, 20) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds_stream.GetCtrlEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.GetCtrlEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.GetCtrlEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.GetCtrlEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetCtrlEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventsList: jspb.Message.toObjectList(msg.getEventsList(),
    proto.odds_stream.CtrlEvent.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.GetCtrlEventsResponse}
 */
proto.odds_stream.GetCtrlEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.GetCtrlEventsResponse;
  return proto.odds_stream.GetCtrlEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.GetCtrlEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.GetCtrlEventsResponse}
 */
proto.odds_stream.GetCtrlEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds_stream.CtrlEvent;
      reader.readMessage(value,proto.odds_stream.CtrlEvent.deserializeBinaryFromReader);
      msg.addEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.GetCtrlEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.GetCtrlEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.GetCtrlEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.GetCtrlEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds_stream.CtrlEvent.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CtrlEvent events = 1;
 * @return {!Array<!proto.odds_stream.CtrlEvent>}
 */
proto.odds_stream.GetCtrlEventsResponse.prototype.getEventsList = function() {
  return /** @type{!Array<!proto.odds_stream.CtrlEvent>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds_stream.CtrlEvent, 1));
};


/**
 * @param {!Array<!proto.odds_stream.CtrlEvent>} value
 * @return {!proto.odds_stream.GetCtrlEventsResponse} returns this
*/
proto.odds_stream.GetCtrlEventsResponse.prototype.setEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds_stream.CtrlEvent=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds_stream.CtrlEvent}
 */
proto.odds_stream.GetCtrlEventsResponse.prototype.addEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds_stream.CtrlEvent, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds_stream.GetCtrlEventsResponse} returns this
 */
proto.odds_stream.GetCtrlEventsResponse.prototype.clearEventsList = function() {
  return this.setEventsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds_stream.CtrlEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.odds_stream.CtrlEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds_stream.CtrlEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.CtrlEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    competitionName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    start: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    sportName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sportCode: jspb.Message.getFieldWithDefault(msg, 6, ""),
    locationName: jspb.Message.getFieldWithDefault(msg, 7, ""),
    landbaseCode: jspb.Message.getFieldWithDefault(msg, 8, 0),
    intKey: jspb.Message.getFieldWithDefault(msg, 9, 0),
    name: jspb.Message.getFieldWithDefault(msg, 10, ""),
    prematchProvider: jspb.Message.getFieldWithDefault(msg, 11, ""),
    liveProvider: jspb.Message.getFieldWithDefault(msg, 12, ""),
    ctrlProvider: jspb.Message.getFieldWithDefault(msg, 13, ""),
    ctrlProviders: (f = msg.getCtrlProviders()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    currentPeriod: jspb.Message.getFieldWithDefault(msg, 15, ""),
    stage: jspb.Message.getFieldWithDefault(msg, 16, ""),
    home: jspb.Message.getFieldWithDefault(msg, 17, ""),
    away: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds_stream.CtrlEvent}
 */
proto.odds_stream.CtrlEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds_stream.CtrlEvent;
  return proto.odds_stream.CtrlEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds_stream.CtrlEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds_stream.CtrlEvent}
 */
proto.odds_stream.CtrlEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSportName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSportCode(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLandbaseCode(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrematchProvider(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLiveProvider(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCtrlProvider(value);
      break;
    case 14:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setCtrlProviders(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrentPeriod(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setStage(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setHome(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setAway(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds_stream.CtrlEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds_stream.CtrlEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds_stream.CtrlEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds_stream.CtrlEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getSportName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSportCode();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getLandbaseCode();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPrematchProvider();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLiveProvider();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getCtrlProvider();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCtrlProviders();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = message.getCurrentPeriod();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStage();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getHome();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getAway();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional int32 competition_id = 1;
 * @return {number}
 */
proto.odds_stream.CtrlEvent.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string competition_name = 2;
 * @return {string}
 */
proto.odds_stream.CtrlEvent.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string start = 3;
 * @return {string}
 */
proto.odds_stream.CtrlEvent.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 sport_id = 4;
 * @return {number}
 */
proto.odds_stream.CtrlEvent.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string sport_name = 5;
 * @return {string}
 */
proto.odds_stream.CtrlEvent.prototype.getSportName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setSportName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string sport_code = 6;
 * @return {string}
 */
proto.odds_stream.CtrlEvent.prototype.getSportCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setSportCode = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string location_name = 7;
 * @return {string}
 */
proto.odds_stream.CtrlEvent.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 landbase_code = 8;
 * @return {number}
 */
proto.odds_stream.CtrlEvent.prototype.getLandbaseCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setLandbaseCode = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 int_key = 9;
 * @return {number}
 */
proto.odds_stream.CtrlEvent.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string name = 10;
 * @return {string}
 */
proto.odds_stream.CtrlEvent.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string prematch_provider = 11;
 * @return {string}
 */
proto.odds_stream.CtrlEvent.prototype.getPrematchProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setPrematchProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string live_provider = 12;
 * @return {string}
 */
proto.odds_stream.CtrlEvent.prototype.getLiveProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setLiveProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string ctrl_provider = 13;
 * @return {string}
 */
proto.odds_stream.CtrlEvent.prototype.getCtrlProvider = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setCtrlProvider = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional google.protobuf.Struct ctrl_providers = 14;
 * @return {?proto.google.protobuf.Struct}
 */
proto.odds_stream.CtrlEvent.prototype.getCtrlProviders = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 14));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
*/
proto.odds_stream.CtrlEvent.prototype.setCtrlProviders = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.clearCtrlProviders = function() {
  return this.setCtrlProviders(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds_stream.CtrlEvent.prototype.hasCtrlProviders = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string current_period = 15;
 * @return {string}
 */
proto.odds_stream.CtrlEvent.prototype.getCurrentPeriod = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setCurrentPeriod = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string stage = 16;
 * @return {string}
 */
proto.odds_stream.CtrlEvent.prototype.getStage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setStage = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string home = 17;
 * @return {string}
 */
proto.odds_stream.CtrlEvent.prototype.getHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setHome = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string away = 18;
 * @return {string}
 */
proto.odds_stream.CtrlEvent.prototype.getAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds_stream.CtrlEvent} returns this
 */
proto.odds_stream.CtrlEvent.prototype.setAway = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


goog.object.extend(exports, proto.odds_stream);
