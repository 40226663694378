/* eslint-disable prettier/prettier */
import { i18n } from '@/ui/locales/setupLocale';
import { SportCode } from '../sport';
import { EventState, LiveEventState } from './event.types';

export const EVENT_TYPES = [
  { label: i18n.t('event.player'), value: 'P' },
  { label: i18n.t('event.multi'), value: 'M' },
  { label: i18n.t('event.outright'), value: 'O' },
  { label: i18n.t('event.group'), value: 'G' },
];

interface StateOption {
  label: any;
  value: EventState | LiveEventState;
  class?: string;
}

export interface FlagOption {
  label: any;
  class: string;
  description: any;
}

//This might be inappropriatly mixed up by mistake
//Todo check that out!
export const EVENT_STATES: StateOption[] = [
  { label: i18n.t('selectOptions.all'), value: '' },
  { label: i18n.t('event.createdStateFilter'), value: 'CREATED', class: 'orange-row' },
  { label: i18n.t('event.validatedStateFilter'), value: 'VALIDATED', class: 'info-row' },
  { label: i18n.t('event.publishedStateFilter'), value: 'PUBLISHED', class: 'primary-row' },
  { label: i18n.t('event.interruptedStateFilter'), value: 'INT' },
  { label: i18n.t('event.selectedStateFilter'), value: 'SELECTED', class: 'dark-row' },
  { label: i18n.t('event.hiddenStateFilter'), value: 'HIDDEN', class: 'purple-row' },
  { label: i18n.t('event.dbpStateFilter'), value: 'D' },
  { label: i18n.t('event.completed'), value: 'COMPLETED', class: 'yellow-row' },
  { label: i18n.t('event.notStarted'), value: 'NOT_STARTED' },
  { label: i18n.t('event.liveBreak'), value: 'LIVE,BREAK', class: 'primary-row' },
  { label: i18n.t('event.break'), value: 'BREAK', class: 'yellow-row' },
  { label: i18n.t('event.ended'), value: 'ENDED', class: 'warning-row' },
  { label: i18n.t('event.addToOffer'), value: 'ADD_TO_OFFER' },
  { label: i18n.t('event.notConfirmedCompetition'), value: 'NOT_CONFIRMED' },
];

export const EVENT_ENDED_REASON = {
  ABD: i18n.t('eventEndReason.abandoned'),
  WOP1W: i18n.t('eventEndReason.walkover1'),
  WOP2W: i18n.t('eventEndReason.walkover2'),
  P1RP2W: i18n.t('eventEndReason.retired1'),
  P2RP1W: i18n.t('eventEndReason.retired2'),
  SU: i18n.t('eventEndReason.suspended'),
  RET: i18n.t('eventEndReason.suspended'),
  P1DP2W: i18n.t('eventEndReason.defaulted1'),
  P2DP1W: i18n.t('eventEndReason.defaulted2'),
};

// class is uniq ID + label for flagOptionsList
// TODO refactor flag managemant for live events page add translations
export const FLAG_EVENT_STATES: FlagOption[] = [
  { label: i18n.t('event.finished'), class: 'grey-row', description: 'Event is Finished' },
  { label: i18n.t('event.live'), class: 'green-row', description: 'Event is Live' },
  {
    label: i18n.t('event.live'),
    class: 'orange-row',
    description: 'Event has unconfirmed competitior',
  },
  { label: i18n.t('event.break'), class: 'green-row', description: 'Event is Live' },
  {
    label: i18n.t('event.break'),
    class: 'orange-row',
    description: 'Event has unconfirmed competitior',
  },
  { label: i18n.t('event.notStarted'), class: 'purple-row', description: 'Event not started' },
  { label: i18n.t('event.notStarted'), class: 'yellow-row', description: 'Event has no prematch' },
  {
    label: i18n.t('event.notStarted'),
    class: 'red-row',
    description: 'Event has unconfirmed competition',
  },
  {
    label: i18n.t('event.notStarted'),
    class: 'orange-row',
    description: 'Event has unconfirmed competitior',
  },
];

export const EVENT_MNG_STATES: EventState[] = ['SELECTED', 'VALIDATED', 'PUBLISHED'];
export const EVENT_PLAYER_STATES: EventState[] = ['SELECTED', 'VALIDATED', 'PUBLISHED'];

const INPUT_PARAMS_FOOTBALL = [
  { key: 'draw', isReciprocal: true, required: false },
  { key: 'handicap_favorite', isReciprocal: true, required: false },
  { key: 'handicap_limit', isReciprocal: false, required: false },
  { key: 'total_limit', isReciprocal: false, required: false },
  { key: 'total_under', isReciprocal: true, required: false },
]; //reciprocal N = 1/n;

const INPUT_PARAMS_BASKETBALL = [
  { key: 'total_ft_limit', isReciprocal: false, required: true },
  { key: 'total_ht_limit', isReciprocal: false, required: false },
  { key: 'total_1q_limit', isReciprocal: false, required: false },
  { key: `handicap_ft_limit`, isReciprocal: false, required: true },
  { key: `handicap_ht_limit`, isReciprocal: false, required: false },
  { key: `handicap_1q_limit`, isReciprocal: false, required: false },
];

const INPUT_PARAMS_HOCKEY = [
  { key: 'total_ft_limit', isReciprocal: false, required: true },
  { key: `handicap_ft_limit`, isReciprocal: false, required: true },
  { key: `handicap_1st_period_limit`, isReciprocal: false, required: false },
  { key: `total_1st_period_limit`, isReciprocal: false, required: false },
];

const INPUT_PARAMS_HANDBALL = [
  { key: 'total_ft_limit', isReciprocal: false, required: true },
  { key: `handicap_ft_limit`, isReciprocal: false, required: true },
  { key: `total_ht_limit`, isReciprocal: false, required: false },
  { key: `handicap_ht_limit`, isReciprocal: false, required: false },
];

const INPUT_PARAMS_TENNIS = [
  { key: 'total_games_limit', isReciprocal: false, required: true },
  { key: `total_games_over`, isReciprocal: true, required: true },
  { key: `winner_favorite`, isReciprocal: true, required: true },
];

export const INPUT_PARAMS: { [key: string]: any } = {
  [SportCode.FOOTBALL]: INPUT_PARAMS_FOOTBALL,
  [SportCode.BASKETBALL]: INPUT_PARAMS_BASKETBALL,
  [SportCode.HANDBALL]: INPUT_PARAMS_HANDBALL,
  [SportCode.HOCKEY]: INPUT_PARAMS_HOCKEY,
  [SportCode.TENNIS]: INPUT_PARAMS_TENNIS,
};

// Every sport has its own set of properties sent in currentResult field
// they are used for live results
export const CURRENT_RESULT_KEYS_BY_SPORT: { [key: string]: any } = {
  [SportCode.FOOTBALL]: [{ key: 'G', name: 'FT' }],
  [SportCode.ESOCCER]: [{ key: 'G', name: 'FT' }],
  [SportCode.HANDBALL]: [{ key: 'G', name: 'FT' }],
  [SportCode.HOCKEY]: [{ key: 'G', name: 'FT' }],
  [SportCode.WATERPOLO]: [{ key: 'G', name: 'FT' }],
  [SportCode.FUTSAL]: [{ key: 'G', name: 'FT' }],
  [SportCode.BASKETBALL]: [{ key: 'P', name: 'FT' }],
  [SportCode.TENNIS]: [
    { key: 'P', name: 'P' },
    { key: 'G', name: 'G' },
  ],
  [SportCode.TABLE_TENNIS]: [
    { key: 'P', name: 'P' },
    { key: 'S', name: 'S' },
  ],
  [SportCode.VOLEYBALL]: [
    { key: 'P', name: 'P' },
    { key: 'S', name: 'S' },
  ],
  [SportCode.SNOOKER]: [
    { key: 'P', name: 'P' },
    { key: 'S', name: 'S' },
  ],
  [SportCode.BASEBALL]: [{ key: 'P', name: 'P' }],
};

export const CURRENT_RESULT_KEYS_BY_SPORT_SETTLEMENT: { [key: string]: any } = {
  [SportCode.FOOTBALL]: [{ key: 'G', name: 'FT' }],
  [SportCode.ESOCCER]: [{ key: 'G', name: 'FT' }],
  [SportCode.HANDBALL]: [{ key: 'G', name: 'FT' }],
  [SportCode.HOCKEY]: [{ key: 'G', name: 'FT' }],
  [SportCode.WATERPOLO]: [{ key: 'G', name: 'FT' }],
  [SportCode.FUTSAL]: [{ key: 'G', name: 'FT' }],
  [SportCode.BASKETBALL]: [{ key: 'P', name: 'FT' }],
  [SportCode.TENNIS]: [{ key: 'G' }],
  [SportCode.TABLE_TENNIS]: [{ key: 'P' }],
  [SportCode.VOLEYBALL]: [{ key: 'P' }],
  [SportCode.SNOOKER]: [{ key: 'P' }],
  [SportCode.BASEBALL]: [{ key: 'P', name: 'P' }],
  [SportCode.AMERICAN_FOOTBALL]: [{ key: 'P', name: 'FT' }],
  [SportCode.DARTS]: [{ key: 'S' }],
  [SportCode.MMA]: [{ key: 'P' }],
};

// Some sports need to show results for certain periods, if key is array of strings, then
// we combine the results of those 2 periods when rendering,
// resultKey is the prop which is required for that sport results
export const PERIOD_RESULT_KEYS_BY_SPORT: { [key: string]: any } = {
  [SportCode.FOOTBALL]: [{ key: 'FH', name: 'HT', resultKey: 'G' }],
  [SportCode.ESOCCER]: [{ key: 'OH', name: 'HT', resultKey: 'G' }],
  [SportCode.HANDBALL]: [{ key: 'FH', name: 'HT', resultKey: 'G' }],
  [SportCode.HOCKEY]: [{ key: 'P1', name: '1/3', resultKey: 'G' }],
  [SportCode.WATERPOLO]: [{ key: 'G', name: 'FT', resultKey: 'G' }],
  [SportCode.FUTSAL]: [{ key: 'FH', name: 'HT', resultKey: 'G' }],
  [SportCode.BASKETBALL]: [{ key: ['Q1', ['Q2']], name: 'HT', resultKey: 'P' }],
  [SportCode.AMERICAN_FOOTBALL]: [{ key: ['Q1', ['Q2']], name: 'HT', resultKey: 'P' }],
  [SportCode.BASEBALL]: [{ key: 'P', name: 'P', resultKey: 'G' }],
};

export const SPORTS_WITH_INPUT_PARAMS = Object.keys(INPUT_PARAMS);

export const PROVIDER_SELECT_OPTIONS = [
  { value: 'betradar', label: i18n.t('selectOptions.betradar') },
  { value: 'betgenius', label: i18n.t('selectOptions.betgenius') },
  { value: '', label: i18n.t('selectOptions.all') },
];

export const PERIODS_BY_SPORT_MATCH_DATA: { [key: string]: string[] } = {
  [SportCode.FOOTBALL]: ['p15', 'p30', 'HT', 'FT', 'OT'],
  [SportCode.FUTSAL]: ['HT', 'OT', 'FT'],
  [SportCode.HOCKEY]: ['P1', 'P2', 'P3', 'OT', 'FT'],
  [SportCode.BASKETBALL]: ['M1', 'Q1', 'Q2', 'Q3', 'Q4', 'OT', 'FT'],
  [SportCode.AMERICAN_FOOTBALL]: ['Q1', 'Q2', 'Q3', 'Q4', 'OT', 'FT'],
  [SportCode.VOLEYBALL]: ['SET1', 'SET2', 'SET3', 'SET4', 'SET5', 'FT'],
  [SportCode.HANDBALL]: ['HT', 'OT', 'FT'],
  [SportCode.SNOOKER]: ['FT'],
  [SportCode.DARTS]: ['FT'],
  [SportCode.TABLE_TENNIS]: ['SET1', 'SET2', 'SET3', 'SET4', 'SET5', 'FT'],
  [SportCode.TENNIS]: ['SET1', 'SET2', 'SET3', 'SET4', 'SET5', 'FT'],
  [SportCode.WATERPOLO]: ['Q1', 'Q2', 'Q3', 'Q4', 'OT', 'FT'],
  [SportCode.BASEBALL]: ['1/9', '2/9', '3/9', '4/9', '5/9', '6/9', '7/9', '8/9', '9/9', 'OT', 'FT'],
};

export const EVENT_STATE = {
  CREATED: 'CREATED',
  PREPARED: 'PREPARED',
  VALIDATED: 'VALIDATED',
  PUBLISHED: 'PUBLISHED',
  SELECTED: 'SELECTED',
  COMPLETED: 'COMPLETED',
  HIDDEN: 'HIDDEN',
  NOT_CONFIRMED: 'NOT_CONFIRMED',
};

export const FINAL_PERIODS = [
  'ENDED',
  'ABD',
  'WOP1W',
  'WOP2W',
  'P1RP2W',
  'P2RP1W',
  'SU',
  'RET',
  'P1DP2W',
  'P2DP1W',
  'AET',
  'AP',
  'AGS',
];
