import isObject from 'lodash/isObject';

export const mapCurrentResult = (data: any) => {
  const currentResultData = data;
  const mappedCurrentResult = currentResultData.reduce((acc: any, result: any) => {
    if (result[1].listValue) {
      const resultValue = result[1].listValue.valuesList.map((value: any) => value.numberValue);
      return { ...acc, [result[0]]: resultValue };
    }
    if (result[1].valuesList) {
      const resultValue = result[1].valuesList.map((value: any) => value.numberValue);
      return { ...acc, [result[0]]: resultValue };
    }
  }, {});
  return mappedCurrentResult;
};

export const mapCurrentPhase = (data: any) => {
  let periodData = {};
  let timeData = {};
  data.forEach((periodArray: any) => {
    for (const key in periodArray[1]) {
      if (periodArray[0] === 'B') {
        periodData = {
          ...periodData,
          [periodArray[0]]: periodArray[1]['boolValue'],
        };
      }
      if (isObject(periodArray[1][key]) && periodArray[1][key].fieldsMap) {
        periodArray[1][key].fieldsMap.forEach((time: any) => {
          for (const key in time[1]) {
            if (time[1][key]) {
              timeData = {
                ...timeData,
                [time[0]]: time[1][key],
              };
            }
          }
        });
        periodData = {
          ...periodData,
          [periodArray[0]]: timeData,
        };
        return;
      }
      if (periodArray[1][key]) {
        periodData = {
          ...periodData,
          [periodArray[0]]: periodArray[1][key],
        };
      }
    }
  });
  return periodData;
};

export const mapPeriodResult = (data: any) => {
  const dataFromHelperFunc = mapCurrentPhase(data);
  const allPeriods = {};
  for (const [period, value] of Object.entries(dataFromHelperFunc)) {
    const periods = [];
    for (const [innerKey, innerValue] of Object.entries(value as object)) {
      if (isObject(innerValue)) {
        periods.push([`${innerKey}`, innerValue]);
      }
    }
    //@ts-ignore
    allPeriods[period] = mapCurrentResult(periods);
  }
  return allPeriods;
};
