export enum GrpcActions {
  StreamGetEventsCreate = 'streamGetEventsCreateBO',
  StreamGetEventsLive = 'streamGetEventsLiveBO',
  StreamGetEventsOffer = 'streamGetEventsOfferBO',
  StreamGetEventsPrematch = 'streamGetEventsPrematchBO',
  GetAntepostMarketsBO = 'getAntepostMarketsBO',
  StreamGetAntepostsOfferBO = 'streamGetAntepostsOfferBO',
  StreamGetAntepostsPrematchBO = 'streamGetAntepostsPrematchBO',
  StreamGetEventsSettle = 'streamGetEventsSettle',
  StreamGetPlayerEventsSettle = 'streamGetPlayerEventsSettle',
  StreamGetAntepostEventsSettle = 'streamGetAntepostsSettlementBO',
  StreamGetPlayerEventsOfferBO = 'streamGetPlayerEventsOfferBO',
  StreamGetPlayerEventsPrematchBO = 'streamGetPlayerEventsPrematchBO',
  StreamGetPlayerEventsSC = 'streamGetPlayerEventsSC',
  StreamGetCtrlEvents = 'streamGetCtrlEvents',
  StreamGetEventsMagazine = 'streamGetEventsMagazine',
}
