import { playerRepo } from './player.repo';
import { objToArr } from '@/lib/objToArr';
import {
  EventRequestSource,
  EventState,
  EventStatesClassMap,
  StateActionMap,
} from '@/modules/event/event.types';
import { EVENT_STATES } from '@/modules/event/event.constants';
import {
  BulkChangePlayerTemplates,
  EditPlayerOddsPayload,
  GeneratedOddsResponse,
  GeneratePlayerMarketOdds,
  PlayerEvent,
  UpdatePlayerEventTemplate,
  EditPlayersPayload,
  NewPlayer,
  Player,
  PlayerFormData,
  PlayerOdd,
} from './player.types';
import {
  PLAYER_SPORTS_WITH_INPUT_PARAMS,
  BASKETBALL_TOTAL_POINTS_MARKET_ID,
} from './player.constants';
import { SportCode } from '@/modules/sport';
import { sportProtoApi } from '../grpc/sport.api';

class PlayerService {
  // Player Config Api

  getCompetitorPlayers(competitorId: number): Promise<Player[]> {
    return playerRepo.getCompetitorPlayers(competitorId);
  }

  savePlayers(payload: NewPlayer[]) {
    return playerRepo.savePlayers(payload);
  }

  editPlayers(payload: EditPlayersPayload[]) {
    return playerRepo.editPlayers(payload);
  }

  // Player Events Api

  getEvents() {
    return sportProtoApi.fetchPlayerEvents();
  }

  getPrematchPlayers() {
    return sportProtoApi.fetchPrematchPlayers();
  }

  changeState(events: number[], nextState: EventState) {
    return playerRepo.changeState(events, nextState);
  }

  updateNote(eventId: number, newNote: string) {
    return playerRepo.updateNote(eventId, { note: newNote });
  }

  addOperatorNote(eventId: number, note: string, source: EventRequestSource) {
    const payload = {
      event_player_id: eventId,
      note,
      source,
    };
    return playerRepo.addOperatorNote(payload);
  }

  createOdd(eventId: number, payload: EditPlayerOddsPayload) {
    return playerRepo.createOdd(eventId, payload);
  }

  updateOdd(oddId: number, limit: number, value: number, source: EventRequestSource) {
    // oddValueToInt needs to be sent as value multiplied by 100
    // @ts-ignore
    const oddValueToInt = parseInt(value * 100);
    return playerRepo.updateOdd(oddId, limit, oddValueToInt, source);
  }

  updateEventTemplates(
    event: PlayerEvent,
    key: keyof UpdatePlayerEventTemplate,
    value: number,
    source: EventRequestSource
  ) {
    const updatedEvent: PlayerEvent = {
      ...event,
      [key]: value,
    };
    const updatePlayerTemplatesPayload: UpdatePlayerEventTemplate = {
      player_approval_template_id: updatedEvent.approvalTemplateId,
      player_market_template_id: updatedEvent.marketTemplateId,
      source,
    };
    return playerRepo.updateEventTemplates(event.intKey, updatePlayerTemplatesPayload);
  }

  getState(state: EventState) {
    return EVENT_STATES.find((s: any) => s.value === state);
  }

  addToArray(state: EventState) {
    const option = this.getState(state);
    return option ? [option] : [];
  }

  //change to StateActionMap
  stateActonsMap: any = {
    SELECTED: {
      next: [...this.addToArray('VALIDATED')],
      prev: null,
    },
    VALIDATED: {
      next: [...this.addToArray('PUBLISHED')],
      prev: null,
    },
    PUBLISHED: {
      next: [...this.addToArray('HIDDEN')],
    },
    HIDDEN: {
      next: null,
      prev: [...this.addToArray('PUBLISHED')],
    },
    COMPLETED: {
      next: null,
      prev: null,
    },
  };

  getStateTransition(state: keyof StateActionMap) {
    return this.stateActonsMap[state];
  }

  bulkStateTansition<T extends { state: EventState }>(events: T[]) {
    const inTheSameState = events.every((e: any) => e.state === events[0].state);
    const canPublish = events.every(
      (e: any) =>
        (!!e.canPublish && e.state === 'VALIDATED') ||
        (e.state === 'VALIDATED' && e.baseMarkets.length)
    );
    const canGoToValidated = events.every(
      (e: any) => (e.state === 'SELECTED' && e.baseMarkets.length) || e.canValidate
    );

    if (canGoToValidated && events[0]) {
      return this.getStateTransition(events[0].state);
    }

    if (!canPublish || !inTheSameState || !events[0]) return null;
    return this.getStateTransition(events[0].state);
  }

  bulkChangeTemplates(payload: BulkChangePlayerTemplates) {
    return playerRepo.bulkChangeTemplates(payload);
  }

  generateOddsByInputParams(eventId: number, payload: GeneratePlayerMarketOdds) {
    return playerRepo.generateOddsByInputParams(eventId, payload);
  }

  getOddsValues(markets: any) {
    const marketOdds = Object.keys(markets.odds);
    const odds = marketOdds.reduce((acc: any, key: any) => {
      const newOdd = new Object();
      const limit = markets.odds[key].limit;
      if (markets.odds[key]?.outcome_over_id !== undefined) {
        Object.assign(newOdd, { outcome_over_id: markets.odds[key].outcome_over_id });
        Object.assign(newOdd, { odd_value_over: markets.odds[key].odd });
      }
      if (markets.odds[key]?.outcome_under_id !== undefined) {
        Object.assign(newOdd, { outcome_under_id: markets.odds[key].outcome_under_id });
        Object.assign(newOdd, { odd_value_under: markets.odds[key].odd });
      }
      return {
        ...acc,
        [limit]: { ...acc[limit], ...newOdd },
      };
    }, {});
    return (markets.odds = { ...odds });
  }

  confirmGeneratedOdds(eventId: number, payload: any) {
    return playerRepo.confirmGeneratedOdds(eventId, payload);
  }

  suspendEvent(eventIds: number[], nextValue: boolean) {
    return playerRepo.suspendEvent(eventIds, nextValue);
  }

  suspendMarket(eventId: number, marketId: number, nextValue: boolean, suspendType: string) {
    return playerRepo.suspendMarket(eventId, marketId, nextValue, suspendType);
  }

  suspendOdd(oddId: string, nextValue: boolean, intKey: number, suspendType: string) {
    return playerRepo.suspendOdd(oddId, nextValue, intKey, suspendType);
  }

  setOddPrematchMaxBet(oddId: number, value: number) {
    return playerRepo.setOddPrematchMaxBet(oddId, value);
  }

  setMarketPrematchMaxBet(eventId: number, marketId: number, value: number) {
    return playerRepo.setMarketPrematchMaxBet(eventId, marketId, value);
  }

  setPrematchMaxAmount({ eventId, value }: { eventId: number; value: number }) {
    return playerRepo.updatePrematchMaxAmount({ eventId, value });
  }

  getPlayerInputParams(intKey: number) {
    return playerRepo.getPlayerInputParams(intKey);
  }

  // Player Config Helpers

  buildPlayer(playerFormData: PlayerFormData, baseCompetitorId: number) {
    const formatedData = objToArr(playerFormData);
    formatedData.forEach(player => {
      player.competitors = Object.values(player.competitors);
      player.competitors = player.competitors.filter((c: any) => !!c);
      player.competitors.push({ is_base: true, competitor_id: baseCompetitorId });
    });
    return formatedData;
  }

  buildEditPlayerPayload(
    playerId: string,
    playerName: string,
    baseCompetitorId: number,
    altCompetitor1Id: number,
    altCompetitor2Id: number
  ) {
    // With current flow we can never change base competitor!
    const updatedPlayer = {
      id: playerId,
      name: playerName,
      competitors: [
        {
          competitor_id: baseCompetitorId,
          is_base: true,
        },
      ],
    };
    if (altCompetitor1Id) {
      updatedPlayer.competitors.push({
        competitor_id: altCompetitor1Id,
        is_base: false,
      });
    }
    if (altCompetitor2Id) {
      updatedPlayer.competitors.push({
        competitor_id: altCompetitor2Id,
        is_base: false,
      });
    }
    return updatedPlayer;
  }

  getAltCompetitor(competitors: any[], index: number) {
    const altCompetitors = competitors.filter((c: any) => !c.is_base);
    if (index > altCompetitors.length - 1) return null;
    return altCompetitors[index];
  }

  // Player Events Helpers

  getPlayerEventClass(event: PlayerEvent) {
    if (!event) return '';
    const map: EventStatesClassMap = EVENT_STATES.reduce((agg, state) => {
      return {
        ...agg,
        [state.value]: state.class,
      };
    }, {});
    const { isSuspended } = event;
    const suspendedClass = isSuspended ? ['suspended-event-row'] : [];
    const stateClass = map[event.state] || '';
    return [...[stateClass], ...suspendedClass].join(' ');
  }

  canBulkSuspend(selectedEvents: PlayerEvent[]) {
    let canSuspend = true;
    selectedEvents.forEach((event, i: number) => {
      if (i !== 0) {
        if (event.isSuspended !== selectedEvents[i - 1].isSuspended) {
          canSuspend = false;
        }
      }
    });
    return canSuspend;
  }

  hasInputParams(sportCode: SportCode) {
    return PLAYER_SPORTS_WITH_INPUT_PARAMS.includes(sportCode);
  }

  // Now only basketball Total Points market has this functionality
  mapGeneratedOddsForOddComponent(generatedOdds: GeneratedOddsResponse) {
    const pointsMarket = generatedOdds[BASKETBALL_TOTAL_POINTS_MARKET_ID];
    const limits = Object.keys(pointsMarket);
    const odds = limits.reduce(
      (obj: any, l: any, i: number) => {
        const currentLimitOdd = pointsMarket[l];
        const under = {
          odd: currentLimitOdd.odd_value_under,
          limit: l,
          outcome_name: '-',
          outcome_under_id: currentLimitOdd.outcome_under_id,
        };
        const over = {
          odd: currentLimitOdd.odd_value_over,
          limit: l,
          outcome_name: '+',
          outcome_over_id: currentLimitOdd.outcome_over_id,
        };
        obj.odds[`${i}_${currentLimitOdd.outcome_under_id}`] = under;
        obj.odds[`${i}_${currentLimitOdd.outcome_over_id}`] = over;
        return obj;
      },
      {
        name: 'Ukupno poena',
        order: 1,
        odds: {},
      }
    );
    return odds;
  }

  buildCreateOddPayload(outcomeId: number, value: number, limit: number) {
    const odd = {
      outcome_player_id: outcomeId,
      // @ts-ignore
      odd_value: parseInt(value * 100),
    };
    return limit ? [{ ...odd, limit: limit }] : [odd];
  }

  canGoToPublished(selectedEvents: PlayerEvent[]) {
    return selectedEvents.some(e => e.eventPublished);
  }

  formatEventId(intKey: number | string) {
    return `e_${intKey}`;
  }

  sortByDate(a: any, b: any) {
    const dateA = new Date(a.start).getTime();
    const dateB = new Date(b.start).getTime();

    if (dateA < dateB) return -1;
    if (dateA > dateB) return 1;
    return 0;
  }

  sortEvents(events: PlayerEvent[]) {
    return events.sort((a, b) => {
      return a.locationName.localeCompare(b.locationName) || this.sortByDate(a, b);
    });
  }

  mapPlayerNewOdds(odds: PlayerOdd[]) {
    return odds.map((odd: PlayerOdd) => {
      return {
        intKey: odd.i_k,
        iSuspended: odd.i_s,
        limit: Number(odd.l),
        marketId: odd.m,
        marketName: odd.m_n,
        oddValue: odd.p_v,
        outcomeId: odd.o,
        outcomeName: odd.o_n,
        prematchMaxAmount: odd.p_m_a,
      };
    });
  }
}

export const playerService = new PlayerService();
