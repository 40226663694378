/**
 * @fileoverview gRPC-Web generated client stub for odds
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')
const proto = {};
proto.odds = require('./odds_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.odds.OddsServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.odds.OddsServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetEventsResponse>}
 */
const methodDescriptor_OddsService_StreamGetEvents = new grpc.web.MethodDescriptor(
  '/odds.OddsService/StreamGetEvents',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetEventsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetEventsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetEventsResponse>}
 */
const methodInfo_OddsService_StreamGetEvents = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetEventsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetEventsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetEventsResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.streamGetEvents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds.OddsService/StreamGetEvents',
      request,
      metadata || {},
      methodDescriptor_OddsService_StreamGetEvents);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetEventsResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServicePromiseClient.prototype.streamGetEvents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds.OddsService/StreamGetEvents',
      request,
      metadata || {},
      methodDescriptor_OddsService_StreamGetEvents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetPlayerEventsResponse>}
 */
const methodDescriptor_OddsService_StreamGetPlayerEvents = new grpc.web.MethodDescriptor(
  '/odds.OddsService/StreamGetPlayerEvents',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetPlayerEventsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetPlayerEventsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetPlayerEventsResponse>}
 */
const methodInfo_OddsService_StreamGetPlayerEvents = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetPlayerEventsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetPlayerEventsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetPlayerEventsResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.streamGetPlayerEvents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds.OddsService/StreamGetPlayerEvents',
      request,
      metadata || {},
      methodDescriptor_OddsService_StreamGetPlayerEvents);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetPlayerEventsResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServicePromiseClient.prototype.streamGetPlayerEvents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds.OddsService/StreamGetPlayerEvents',
      request,
      metadata || {},
      methodDescriptor_OddsService_StreamGetPlayerEvents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetEventsResponseSC>}
 */
const methodDescriptor_OddsService_StreamGetEventsSC = new grpc.web.MethodDescriptor(
  '/odds.OddsService/StreamGetEventsSC',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetEventsResponseSC,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetEventsResponseSC.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetEventsResponseSC>}
 */
const methodInfo_OddsService_StreamGetEventsSC = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetEventsResponseSC,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetEventsResponseSC.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetEventsResponseSC>}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.streamGetEventsSC =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds.OddsService/StreamGetEventsSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_StreamGetEventsSC);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetEventsResponseSC>}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServicePromiseClient.prototype.streamGetEventsSC =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds.OddsService/StreamGetEventsSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_StreamGetEventsSC);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetPlayerEventsResponseSC>}
 */
const methodDescriptor_OddsService_StreamGetPlayerEventsSC = new grpc.web.MethodDescriptor(
  '/odds.OddsService/StreamGetPlayerEventsSC',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetPlayerEventsResponseSC,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetPlayerEventsResponseSC.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetPlayerEventsResponseSC>}
 */
const methodInfo_OddsService_StreamGetPlayerEventsSC = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetPlayerEventsResponseSC,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetPlayerEventsResponseSC.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetPlayerEventsResponseSC>}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.streamGetPlayerEventsSC =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds.OddsService/StreamGetPlayerEventsSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_StreamGetPlayerEventsSC);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetPlayerEventsResponseSC>}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServicePromiseClient.prototype.streamGetPlayerEventsSC =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds.OddsService/StreamGetPlayerEventsSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_StreamGetPlayerEventsSC);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetSportsMarketGroupsResponse>}
 */
const methodDescriptor_OddsService_GetSportsMarketGroups = new grpc.web.MethodDescriptor(
  '/odds.OddsService/GetSportsMarketGroups',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetSportsMarketGroupsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetSportsMarketGroupsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetSportsMarketGroupsResponse>}
 */
const methodInfo_OddsService_GetSportsMarketGroups = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetSportsMarketGroupsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetSportsMarketGroupsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.odds.GetSportsMarketGroupsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetSportsMarketGroupsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.getSportsMarketGroups =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/odds.OddsService/GetSportsMarketGroups',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetSportsMarketGroups,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.odds.GetSportsMarketGroupsResponse>}
 *     Promise that resolves to the response
 */
proto.odds.OddsServicePromiseClient.prototype.getSportsMarketGroups =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/odds.OddsService/GetSportsMarketGroups',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetSportsMarketGroups);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetSportsSCResponse>}
 */
const methodDescriptor_OddsService_GetSportsSC = new grpc.web.MethodDescriptor(
  '/odds.OddsService/GetSportsSC',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetSportsSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetSportsSCResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetSportsSCResponse>}
 */
const methodInfo_OddsService_GetSportsSC = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetSportsSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetSportsSCResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.odds.GetSportsSCResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetSportsSCResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.getSportsSC =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/odds.OddsService/GetSportsSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetSportsSC,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.odds.GetSportsSCResponse>}
 *     Promise that resolves to the response
 */
proto.odds.OddsServicePromiseClient.prototype.getSportsSC =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/odds.OddsService/GetSportsSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetSportsSC);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetLocationsSCResponse>}
 */
const methodDescriptor_OddsService_GetLocationsSC = new grpc.web.MethodDescriptor(
  '/odds.OddsService/GetLocationsSC',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetLocationsSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetLocationsSCResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetLocationsSCResponse>}
 */
const methodInfo_OddsService_GetLocationsSC = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetLocationsSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetLocationsSCResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.odds.GetLocationsSCResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetLocationsSCResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.getLocationsSC =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/odds.OddsService/GetLocationsSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetLocationsSC,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.odds.GetLocationsSCResponse>}
 *     Promise that resolves to the response
 */
proto.odds.OddsServicePromiseClient.prototype.getLocationsSC =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/odds.OddsService/GetLocationsSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetLocationsSC);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetCompetitionsSCResponse>}
 */
const methodDescriptor_OddsService_GetCompetitionsSC = new grpc.web.MethodDescriptor(
  '/odds.OddsService/GetCompetitionsSC',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetCompetitionsSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetCompetitionsSCResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetCompetitionsSCResponse>}
 */
const methodInfo_OddsService_GetCompetitionsSC = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetCompetitionsSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetCompetitionsSCResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.odds.GetCompetitionsSCResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetCompetitionsSCResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.getCompetitionsSC =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/odds.OddsService/GetCompetitionsSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetCompetitionsSC,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.odds.GetCompetitionsSCResponse>}
 *     Promise that resolves to the response
 */
proto.odds.OddsServicePromiseClient.prototype.getCompetitionsSC =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/odds.OddsService/GetCompetitionsSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetCompetitionsSC);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetSportsMarketSCResponse>}
 */
const methodDescriptor_OddsService_GetSportsMarketSC = new grpc.web.MethodDescriptor(
  '/odds.OddsService/GetSportsMarketSC',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetSportsMarketSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetSportsMarketSCResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetSportsMarketSCResponse>}
 */
const methodInfo_OddsService_GetSportsMarketSC = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetSportsMarketSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetSportsMarketSCResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.odds.GetSportsMarketSCResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetSportsMarketSCResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.getSportsMarketSC =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/odds.OddsService/GetSportsMarketSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetSportsMarketSC,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.odds.GetSportsMarketSCResponse>}
 *     Promise that resolves to the response
 */
proto.odds.OddsServicePromiseClient.prototype.getSportsMarketSC =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/odds.OddsService/GetSportsMarketSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetSportsMarketSC);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetOutcomesSCResponse>}
 */
const methodDescriptor_OddsService_GetOutcomesSC = new grpc.web.MethodDescriptor(
  '/odds.OddsService/GetOutcomesSC',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetOutcomesSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetOutcomesSCResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetOutcomesSCResponse>}
 */
const methodInfo_OddsService_GetOutcomesSC = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetOutcomesSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetOutcomesSCResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.odds.GetOutcomesSCResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetOutcomesSCResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.getOutcomesSC =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/odds.OddsService/GetOutcomesSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetOutcomesSC,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.odds.GetOutcomesSCResponse>}
 *     Promise that resolves to the response
 */
proto.odds.OddsServicePromiseClient.prototype.getOutcomesSC =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/odds.OddsService/GetOutcomesSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetOutcomesSC);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetPlayerOutcomesSCResponse>}
 */
const methodDescriptor_OddsService_GetPlayerOutcomesSC = new grpc.web.MethodDescriptor(
  '/odds.OddsService/GetPlayerOutcomesSC',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetPlayerOutcomesSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetPlayerOutcomesSCResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetPlayerOutcomesSCResponse>}
 */
const methodInfo_OddsService_GetPlayerOutcomesSC = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetPlayerOutcomesSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetPlayerOutcomesSCResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.odds.GetPlayerOutcomesSCResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetPlayerOutcomesSCResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.getPlayerOutcomesSC =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/odds.OddsService/GetPlayerOutcomesSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetPlayerOutcomesSC,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.odds.GetPlayerOutcomesSCResponse>}
 *     Promise that resolves to the response
 */
proto.odds.OddsServicePromiseClient.prototype.getPlayerOutcomesSC =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/odds.OddsService/GetPlayerOutcomesSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetPlayerOutcomesSC);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetMarketGroupsSCResponse>}
 */
const methodDescriptor_OddsService_GetMarketGroupsSC = new grpc.web.MethodDescriptor(
  '/odds.OddsService/GetMarketGroupsSC',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetMarketGroupsSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetMarketGroupsSCResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetMarketGroupsSCResponse>}
 */
const methodInfo_OddsService_GetMarketGroupsSC = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetMarketGroupsSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetMarketGroupsSCResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.odds.GetMarketGroupsSCResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetMarketGroupsSCResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.getMarketGroupsSC =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/odds.OddsService/GetMarketGroupsSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetMarketGroupsSC,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.odds.GetMarketGroupsSCResponse>}
 *     Promise that resolves to the response
 */
proto.odds.OddsServicePromiseClient.prototype.getMarketGroupsSC =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/odds.OddsService/GetMarketGroupsSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetMarketGroupsSC);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetSportsMarketSCResponse>}
 */
const methodDescriptor_OddsService_GetLiveSportsMarketSC = new grpc.web.MethodDescriptor(
  '/odds.OddsService/GetLiveSportsMarketSC',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetSportsMarketSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetSportsMarketSCResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetSportsMarketSCResponse>}
 */
const methodInfo_OddsService_GetLiveSportsMarketSC = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetSportsMarketSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetSportsMarketSCResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.odds.GetSportsMarketSCResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetSportsMarketSCResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.getLiveSportsMarketSC =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/odds.OddsService/GetLiveSportsMarketSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetLiveSportsMarketSC,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.odds.GetSportsMarketSCResponse>}
 *     Promise that resolves to the response
 */
proto.odds.OddsServicePromiseClient.prototype.getLiveSportsMarketSC =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/odds.OddsService/GetLiveSportsMarketSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetLiveSportsMarketSC);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetPlayerSportsMarketSCResponse>}
 */
const methodDescriptor_OddsService_GetPlayerSportsMarketSC = new grpc.web.MethodDescriptor(
  '/odds.OddsService/GetPlayerSportsMarketSC',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetPlayerSportsMarketSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetPlayerSportsMarketSCResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetPlayerSportsMarketSCResponse>}
 */
const methodInfo_OddsService_GetPlayerSportsMarketSC = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetPlayerSportsMarketSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetPlayerSportsMarketSCResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.odds.GetPlayerSportsMarketSCResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetPlayerSportsMarketSCResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.getPlayerSportsMarketSC =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/odds.OddsService/GetPlayerSportsMarketSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetPlayerSportsMarketSC,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.odds.GetPlayerSportsMarketSCResponse>}
 *     Promise that resolves to the response
 */
proto.odds.OddsServicePromiseClient.prototype.getPlayerSportsMarketSC =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/odds.OddsService/GetPlayerSportsMarketSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetPlayerSportsMarketSC);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetPlayerSportsMarketResponse>}
 */
const methodDescriptor_OddsService_GetPlayerAntepostSportsMarkets = new grpc.web.MethodDescriptor(
  '/odds.OddsService/GetPlayerAntepostSportsMarkets',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetPlayerSportsMarketResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetPlayerSportsMarketResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetPlayerSportsMarketResponse>}
 */
const methodInfo_OddsService_GetPlayerAntepostSportsMarkets = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetPlayerSportsMarketResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetPlayerSportsMarketResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.odds.GetPlayerSportsMarketResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetPlayerSportsMarketResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.getPlayerAntepostSportsMarkets =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/odds.OddsService/GetPlayerAntepostSportsMarkets',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetPlayerAntepostSportsMarkets,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.odds.GetPlayerSportsMarketResponse>}
 *     Promise that resolves to the response
 */
proto.odds.OddsServicePromiseClient.prototype.getPlayerAntepostSportsMarkets =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/odds.OddsService/GetPlayerAntepostSportsMarkets',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetPlayerAntepostSportsMarkets);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetAntepostMarketsSCResponse>}
 */
const methodDescriptor_OddsService_GetAntepostMarketsSC = new grpc.web.MethodDescriptor(
  '/odds.OddsService/GetAntepostMarketsSC',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetAntepostMarketsSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetAntepostMarketsSCResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetAntepostMarketsSCResponse>}
 */
const methodInfo_OddsService_GetAntepostMarketsSC = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetAntepostMarketsSCResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetAntepostMarketsSCResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.odds.GetAntepostMarketsSCResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetAntepostMarketsSCResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.getAntepostMarketsSC =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/odds.OddsService/GetAntepostMarketsSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetAntepostMarketsSC,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.odds.GetAntepostMarketsSCResponse>}
 *     Promise that resolves to the response
 */
proto.odds.OddsServicePromiseClient.prototype.getAntepostMarketsSC =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/odds.OddsService/GetAntepostMarketsSC',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetAntepostMarketsSC);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetAntepostMarketsBOResponse>}
 */
const methodDescriptor_OddsService_GetAntepostMarketsBO = new grpc.web.MethodDescriptor(
  '/odds.OddsService/GetAntepostMarketsBO',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.odds.GetAntepostMarketsBOResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetAntepostMarketsBOResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds.GetAntepostMarketsBOResponse>}
 */
const methodInfo_OddsService_GetAntepostMarketsBO = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds.GetAntepostMarketsBOResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds.GetAntepostMarketsBOResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.odds.GetAntepostMarketsBOResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.odds.GetAntepostMarketsBOResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.odds.OddsServiceClient.prototype.getAntepostMarketsBO =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/odds.OddsService/GetAntepostMarketsBO',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetAntepostMarketsBO,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.odds.GetAntepostMarketsBOResponse>}
 *     Promise that resolves to the response
 */
proto.odds.OddsServicePromiseClient.prototype.getAntepostMarketsBO =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/odds.OddsService/GetAntepostMarketsBO',
      request,
      metadata || {},
      methodDescriptor_OddsService_GetAntepostMarketsBO);
};


module.exports = proto.odds;

