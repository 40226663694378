import { Module, VuexModule, Action, getModule, Mutation } from 'vuex-module-decorators';

import store from '@/store';
import { sportFilterService } from './sportFilter.service';
import to from 'await-to-js';
import {
  Competition,
  isLocation,
  isSport,
  Location,
  FiltersSport,
  SportFilterItem,
  SportFilterState,
  ToggleMode,
  ToggleKey,
  SportSource,
  SelectedSportLocationCompetitionName,
  DefaultSelected,
} from './sportFilter.types';
import { sportService } from '../sport/sport.service';

@Module({ dynamic: true, store, name: 'sportFilter', namespaced: true })
export class SportFilter extends VuexModule implements SportFilterState {
  sports: FiltersSport[] | null = null;
  expandedItems: string[] = [];
  isLoading = false;
  sportsWithPlayers: number[] = [];

  get isExpanded() {
    return (clientId: string) => {
      return this.expandedItems.includes(clientId);
    };
  }

  get sportsWithNoLocations() {
    const onlySports: { [key: number]: Array<null> } = {};
    this.sports?.forEach(sport => {
      onlySports[sport.id] = [null];
    });
    return onlySports;
  }

  get selectedCompetitions(): number[] {
    const competitions: number[] = [];
    if (!this.sports) return [];
    this.sports.forEach(sport => {
      sport.locations.forEach(location => {
        if (!location.competitions) return;
        location.competitions.forEach(competition => {
          if (competition.isSelected) {
            competitions.push(competition.id);
          }
        });
      });
    });
    return competitions;
  }

  get defaultSelected(): DefaultSelected {
    return {
      sports: this.selectedSports,
      locations: this.parsedSelectedLocations as number[],
      competitions: this.selectedCompetitions,
    };
  }

  get numberOfLocationsBySport(): number {
    return this.sports?.reduce((relationsMap: any, sport: FiltersSport) => {
      return { ...relationsMap, [sport.name.toLowerCase()]: sport.locations.length };
    }, {});
  }

  get numberOfCompetitionsBySportAndLocation(): number {
    return this.sports?.reduce((relationsMap: any, sport: FiltersSport) => {
      const locationsMap = sport.locations.reduce((locationRelationsMap: any, location: any) => {
        return {
          ...locationRelationsMap,
          [location.name.toLowerCase()]: location.competitions.length,
        };
      }, {});
      return { ...relationsMap, [sport.name]: locationsMap };
    }, {});
  }

  get selectedLocations() {
    return sportFilterService.selectedLocations(this.sports);
  }

  get selectedSports(): number[] {
    if (!this.sports) return [];
    return sportFilterService.selectedSports(this.sports);
  }

  get parsedSelectedLocations() {
    if (!this.sports) return [];
    return Object.values(this.selectedLocations).flat();
  }

  get parsedSelectedCompetitions() {
    return this.selectedCompetitions.join(',');
  }

  get selectedSportLocationCompetition(): SelectedSportLocationCompetitionName | null {
    if (!this.sports) return null;
    let result: SelectedSportLocationCompetitionName | null = null;
    this.sports.forEach(sport => {
      sport.locations.forEach(location => {
        if (!location.competitions) return null;
        location.competitions.forEach(competition => {
          if (competition.isSelected) {
            return (result = {
              sport: { name: sport.name, id: sport.id },
              competition: { name: competition.name, id: competition.id },
              location: { name: location.name, id: location.id },
            });
          }
        });
      });
    });
    return result;
  }

  get sportCompetitionsDetails() {
    return (competitionId: number): SelectedSportLocationCompetitionName | null => {
      if (!this.sports) return null;
      let result: SelectedSportLocationCompetitionName | null = null;
      this.sports.forEach(sport => {
        sport.locations.forEach(location => {
          if (!location.competitions) return null;
          location.competitions.forEach(competition => {
            if (competition.id === competitionId) {
              return (result = {
                sport: { name: sport.name, id: sport.id },
                competition: { name: competition.name, id: competition.id },
                location: { name: location.name, id: location.id },
              });
            }
          });
        });
      });
      return result;
    };
  }

  get locationBySport() {
    return (sportId: number) => sportFilterService.locationsForSport(this.sports, sportId);
  }

  get competitionByLocationsAndSport() {
    return (locationId: number, sportId: number) =>
      sportFilterService.competitionByLocationsAndSport(this.sports, sportId, locationId);
  }

  @Mutation
  public closeAll() {
    this.expandedItems = [];
  }

  @Mutation
  private setSports(data: FiltersSport[]) {
    if (this.sportsWithPlayers.length) {
      const sportsWithPlayers = data.filter(
        sport => this.sportsWithPlayers.indexOf(sport.id) !== -1
      );
      this.sports = sportService.sort(sportsWithPlayers);
      return;
    }
    this.sports = sportService.sort(data);
  }

  @Mutation
  setSportsWithPlayers(sportsWithPlayers: number[]) {
    this.sportsWithPlayers = sportsWithPlayers;
  }

  @Mutation
  public clear() {
    this.sports = null;
    this.expandedItems = [];
    this.isLoading = false;
  }

  @Mutation
  private updateCollapsedItems(payload: string[]) {
    this.expandedItems = payload;
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  public resetStore() {
    this.sports = null;
    this.expandedItems = [];
    this.isLoading = false;
  }

  @Mutation
  private updateItem<K extends keyof SportFilterItem>({
    item,
    key,
    value,
  }: {
    item: SportFilterItem;
    key: K;
    value: SportFilterItem[K];
  }) {
    item[key] = value;
  }

  @Mutation
  private toggleSportChildren({
    item,
    nextValue,
    key,
  }: {
    item: FiltersSport;
    nextValue: boolean;
    key: ToggleKey;
  }) {
    item.locations.forEach(location => {
      location[key] = nextValue;
      if (!location.competitions) return;
      location.competitions.forEach(competition => (competition[key] = nextValue));
    });
  }

  @Mutation
  private toggleCompetititons({
    item,
    nextValue,
    key,
  }: {
    item: Location;
    nextValue: boolean;
    key: ToggleKey;
  }) {
    item.competitions.forEach(competition => (competition[key] = nextValue));
  }

  @Action
  public async loadData(source: SportSource) {
    this.setLoading(true);
    const [err, result] = await to(sportFilterService.loadSportList(source, this.defaultSelected));
    if (err) return;
    this.setSports(result as any);
    this.setLoading(false);
  }

  @Action
  public expandCollapse(clientId: string) {
    const data = sportFilterService.toggleItemInArray(clientId, this.expandedItems);
    this.updateCollapsedItems(data);
  }

  @Action
  public toggleParent({ item, mode }: { item: Location | Competition; mode: ToggleMode }) {
    const key = sportFilterService.getToggleKey(mode);
    const sport = this.sports?.find(sport => sport.clientId === item.parents[0]);
    if (!sport) return;

    const location = sport.locations.find(location => location.clientId === item.parents[1]);
    if (location) {
      const isSomeChildrenSelected = sportFilterService.isSomeChildrenSelected(location, key);
      if (isSomeChildrenSelected) {
        this.updateItem({ item: location, key, value: true });
      } else {
        this.updateItem({ item: location, key, value: false });
      }
    }

    const isSomeChildrenSelected = sportFilterService.isSomeChildrenSelected(sport, key);
    if (isSomeChildrenSelected) {
      this.updateItem({ item: sport, key, value: true });
    } else {
      this.updateItem({ item: sport, key, value: false });
    }
  }

  @Action
  bulkSelect({
    item,
    nextValue,
    mode,
  }: {
    item: SportFilterItem;
    nextValue: boolean;
    mode: ToggleMode;
  }) {
    const key = sportFilterService.getToggleKey(mode);
    if (isSport(item)) {
      this.toggleSportChildren({ item, nextValue, key });
    } else if (isLocation(item)) {
      this.toggleCompetititons({ item, nextValue, key });
    }

    // we need to to deselect parent if all childrean are unselected or selected
    if (!isSport(item)) {
      this.toggleParent({ item, mode });
    }
  }

  @Action
  public toggleSelection({ item, mode }: { item: SportFilterItem; mode: ToggleMode }) {
    const key = sportFilterService.getToggleKey(mode);
    const nextValue = !item[key];
    this.updateItem({ item, key, value: nextValue });
    this.bulkSelect({ item, nextValue, mode });
  }

  @Action
  deselectAll() {
    this.sports?.forEach(sport => {
      this.updateItem({ item: sport, key: 'isSelected', value: false });
      this.bulkSelect({ item: sport, mode: 'select', nextValue: false });
    });
  }

  @Action
  selectAll() {
    this.sports?.forEach(sport => {
      this.updateItem({ item: sport, key: 'isSelected', value: true });
      this.bulkSelect({ item: sport, mode: 'select', nextValue: true });
    });
  }
}

export const SportFilterStore = getModule(SportFilter);
