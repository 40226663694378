import to from 'await-to-js';
import { betRadarEventRepo } from '../betRadarEvent/betRadarEvent.repo';
import { settlementAntepostRepo } from '../settlementAntepost/';
import { sportRepo } from '../sport/sport.repo';
import { sportService } from '../sport/sport.service';
import forEach from 'lodash/forEach';
import {
  ItemType,
  FiltersSport,
  Location,
  Competition,
  SportFilterItem,
  ToggleMode,
  ToggleKey,
  isSport,
  isLocation,
  SportSource,
  DefaultSelected,
} from './sportFilter.types';

const DEFAULTS = {
  isSelected: false,
  isActive: true,
};

class SportFilterService {
  async loadSportList(source: SportSource, defaultSelected: DefaultSelected) {
    type Request = { [T in SportSource]: () => Promise<any> };
    const request: Request = {
      [SportSource.betRadar]: betRadarEventRepo.getSports,
      [SportSource.factory]: sportRepo.getSportsWithCompetitions,
      [SportSource.competitors]: sportRepo.getSportsWithCompetitors,
      [SportSource.antepost]: sportRepo.getAntepost,
      [SportSource.antepostSettlement]: settlementAntepostRepo.antepostSportFilter,
    };
    const api = request[source];

    const [err, response] = (await to(api())) as any;
    if (err) {
      return Promise.reject(err);
    }
    return this.setClientData(this.sortSports(response), defaultSelected);
  }

  getClientId(id: number, type: ItemType, parentId?: string) {
    const clientId = `${type.charAt(0)}_${id}`;
    if (parentId) return `${parentId}-${clientId}`;
    return clientId;
  }

  sortSports(sport: FiltersSport[]) {
    return sportService.sort(sport);
  }

  getToggleKey(mode: ToggleMode): 'isActive' | 'isSelected' {
    return mode === 'activate' ? 'isActive' : 'isSelected';
  }

  setClientData(data: any[], defaultSelected: DefaultSelected) {
    return data.map((sport: FiltersSport, _index: number) => {
      sport.type = 'sport';
      sport.clientId = this.getClientId(sport.id, sport.type);
      sport.isSelected = defaultSelected.sports.includes(sport.id) ? true : DEFAULTS.isSelected;
      sport.isActive = DEFAULTS.isSelected;
      sport.locations.forEach((location: Location) => {
        location.type = 'location';
        location.parents = [sport.clientId];
        location.isSelected = defaultSelected.locations.includes(location.id)
          ? true
          : DEFAULTS.isSelected;
        location.isActive = DEFAULTS.isActive;
        location.clientId = this.getClientId(location.id, location.type, sport.clientId);
        if (!location.competitions) return;
        location.competitions.forEach((competition: Competition) => {
          competition.parents = [sport.clientId, location.clientId];
          competition.type = 'competition';
          competition.isSelected = defaultSelected.competitions.includes(competition.id);
          competition.isActive = DEFAULTS.isActive;
          competition.clientId = this.getClientId(
            competition.id,
            competition.type,
            location.clientId
          );
        });
      });
      return sport;
    });
  }

  toggleItemInArray(clientId: string, data: string[]): string[] {
    const index = data.indexOf(clientId);
    let result = [];

    if (!data[index]) {
      result = [...data, ...[clientId]];
    } else {
      result = data.filter(key => key !== clientId);
    }
    return result;
  }

  isSomeCompetitionSelected(competitions: Competition[], key: ToggleKey) {
    return competitions.some(competition => competition[key]);
  }

  isSomeChildrenSelected(item: SportFilterItem, key: ToggleKey) {
    let result = false;
    if (isSport(item)) {
      forEach(item.locations, location => {
        if (location[key] === true) {
          result = true;
          return;
        }
        if (!location.competitions) return;
        if (this.isSomeCompetitionSelected(location.competitions, key) === true) {
          result = true;
          return;
        }
      });
    } else if (isLocation(item)) {
      return this.isSomeCompetitionSelected(item.competitions, key);
    }

    return result;
  }

  selectedSports(sports: FiltersSport[] | null) {
    if (!sports) return [];
    return sports.filter(s => s.isSelected).map(s => s.id);
  }

  selectedLocations(sports: FiltersSport[] | null) {
    if (!sports) return [];
    const collection: any = {};
    if (!sports) return [];
    forEach(sports, sport => {
      if (!sport.isSelected) return collection;
      collection[sport.id] = [];
      forEach(sport.locations, location => {
        if (!location.isSelected) return;
        if (location.id !== null) {
          collection[sport.id].push(location.id);
        }
      });
    });
    return collection;
  }

  filterSports(data: any[], availableCompetitions: number[]) {
    return data
      .map((sport: any) => {
        sport.locations.forEach((location: any) => {
          location.competitions.forEach((competition: any) => {
            competition.hasEventsInOffer = availableCompetitions.includes(competition.id);
          });
          location.competitions = location.competitions.filter((c: any) => c.hasEventsInOffer);
        });
        sport.locations = sport.locations.filter((location: any) => location.competitions.length);
        return sport;
      })
      .filter((s: any) => s.locations.length);
  }

  locationsForSport(sports: FiltersSport[] | null, sportId: number | undefined) {
    if (!sports || !sportId) return null;
    return sports.find(sport => sport.id === sportId)?.locations.filter(location => location.id);
  }

  competitionByLocationsAndSport(
    sports: FiltersSport[] | null,
    sportId: number | undefined,
    locationId: number | undefined
  ) {
    if (!sports || !sportId || !locationId) return null;
    return sports
      .find(sport => sport.id === sportId)
      ?.locations.find(location => location.id === locationId)?.competitions;
  }
}

export const sportFilterService = new SportFilterService();
