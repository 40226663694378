import { SportCode } from '../sport';

export const PLAYER_SPORTS_WITH_INPUT_PARAMS = [SportCode.BASKETBALL];

export const BASKETBALL_TOTAL_POINTS_MARKET_ID = '4'; // this is bad

export const BASKETBALL_INPUT_PARAMS = [
  { key: 'exp_points', isReciprocal: false, required: true },
  { key: 'kvota_under', isReciprocal: false, required: true },
];
