import isObject from 'lodash/isObject';

export enum EventType {
  d = 'D',
  dual = 'dual',
}

export enum DualEventApiType {
  prematch = 'prematch',
  live = 'live',
}

export enum InputParams {
  totalLimitProvider = 'total_limit_provider',
  handicapLimitProvider = 'handicap_limit_provider',
  totalFtLimitProvider = 'total_ft_limit_provider',
  handicapFtLimitProvider = 'handicap_ft_limit_provider',
}

export enum NoteType {
  GAME = 0,
  OPERATOR = 1,
}

export type EventState =
  | 'CREATED'
  | 'PREPARED'
  | 'VALIDATED'
  | 'PUBLISHED'
  | 'SELECTED'
  | 'COMPLETED'
  | 'HIDDEN'
  | 'NOT_CONFIRMED'
  | 'ADD_TO_OFFER'
  | '';

// THIS SHOULD BE SEPARATED state FROM live_status, those are two diff things
export type LiveEventState =
  | 'NOT_STARTED'
  | 'LIVE,BREAK'
  | 'BREAK'
  | 'ENDED'
  | 'LIVE'
  | 'INT'
  | 'D';

export enum EventStages {
  PREMATCH = 'PREMATCH',
  LIVE = 'LIVE',
  TERMINATED = 'TERMINATED',
  CANCELED = 'CANCELED',
  FINISHED = 'FINISHED',
  RESULTED = 'RESULTED',
}

export type EventStage = EventStages;

export interface OperatorNote {
  note: string;
  userId: number;
  createdAt: string;
  paramsList: string[];
}

interface DualEvent {
  away: string;
  awayConfirmed: boolean;
  homeConfirmed: boolean;
  basePrematchMarkets: BaseMarket[]; // This is caclutated from basePrematchMarketsMap
  basePrematchMarketsMap?: any[][];
  competitionName: string;
  competitionId: number;
  goesThrough: boolean;
  hasInputParams: boolean;
  hasPlayers: boolean;
  hasPublishedPlayers: boolean;
  hasSlip: boolean;
  home: string;
  intKey: number;
  landbaseCode: number;
  liveApprovalTemplateId: string;
  competitorsConfirmed: boolean;
  liveMarketTemplateId: string;
  liveOutcomeTemplateId: string;
  liveProvider: string;
  liveSuspended: boolean;
  locationName: string;
  manualChanged: boolean;
  name: string;
  note: string;
  numOfProdOddMng: number;
  numOfProdOddPrematch: number;
  operatorNotesList: any[];
  prematchApprovalTemplateId: number | '';
  prematchMarketTemplateId: number | '';
  prematchOutcomeTemplateId: number | '';
  prematchProvider: string;
  marketsMap: any;
  prematchSuspended: boolean;
  sportId: number;
  start: string;
  tech: string;
  eventType: DualEventApiType; // set in event store, used for api calls payload mainly
  betStop: boolean;
  templateOddsNum: number;
  ctrlProvider: string;
  currentPeriod: string;
  ctrlProviders: string[];
  liveProviders: string[];
  currentPhaseJson: CurrentPhase;
}

export interface PrematchEvent extends DualEvent {
  state: EventState;
  prematchRemoved: boolean;
  sentToSettlement: boolean;
}

export interface CurrentPhase {
  S?: string; // servingƒ
  T?: {
    M: string; // minutes
    S: string; // seconds
  };
  N: string; // periodName
  SN: string; // periodShortName
  B: boolean; // isBreak
}

export enum CurrentResultKeys {
  POINTS = 'P',
  GOALS_OR_GEMS = 'G',
  SETS = 'S',
  CORNERS = 'C',
  YELLOW_CARDS = 'YC',
  RED_CARDS = 'RC',
  PERIOD_NAME = 'N',
}

export type CurrentResult = {
  [key in CurrentResultKeys]?: number[];
};

export interface PeriodResult {
  [key: string]: CurrentResult;
}

export interface SportModels {
  [key: string]: number[];
}
export interface SportModel {
  [key: number]: string;
}

export interface SportModelDefault {
  [key: string]: number;
}

export interface LiveEvent extends DualEvent {
  state: EventState;
  stage: LiveEventState;
  settlementLiveStop: boolean;
  baseLiveMarkets: BaseMarketMap[];
  baseLiveMarketsMap?: any[];
  liveMaxAmountSc: number;
  liveProvider: string;
  liveMaxAmountWeb: number;
  currentResult:
    | {
        fieldsMap: object;
      }
    | undefined;

  currentResultJson: CurrentResult;
  periodResult?:
    | {
        fieldsMap: object;
      }
    | undefined;
  betStop: boolean;
  periodResultJson: PeriodResult;
  currentPhase:
    | {
        fieldsMap: object;
      }
    | undefined; // we parse this to JSON and save it in currentPhaseJson & setting currentPhase to undefined
  liveStatus: LiveEventState;
  canPublish: boolean; // competitionsConfirmed && (competitorCanUnconfirm || competitorsConfirmed)
  prematchOffer: boolean;
  liveOffer: boolean;
  prematchRemoved: boolean;
  sentToSettlement: boolean;
  isVerified: boolean;
  settledM1: boolean;
}

export type FactoryEvent = LiveEvent | PrematchEvent;

export type FactoryEventMap = { [key: string]: FactoryEvent };

export interface EventTemplatesPayload {
  live_approval_template_id: number | null;
  live_market_template_id: number | null;
  live_outcome_template_id: number | null;
  prematch_approval_template_id: number | null;
  prematch_market_template_id: number | null;
  prematch_outcome_template_id: number | null;
  source: EventRequestSource;
}

export interface NewEventPayload {
  name: string;
  start: string;
  competition: number;
  home: number;
  away: number;
  state: 'SELECTED';
}

interface ActionItem {
  label: string;
  value: EventState;
}

export type StateActionMap = {
  [key in EventState]: {
    next: ActionItem[] | null;
    prev: ActionItem[] | null;
  };
};

export interface BulkChangeTemplatesPaylod {
  events: string[];
  template: EventTemplates;
  source: EventRequestSource;
}

export interface BaseMarketMap {
  id: number;
  is_suspended: boolean;
  odds: any;
}

export interface BaseMarket {
  intKey: number;
  limit: number;
  oddValue: number;
  realValue: number;
  outcomeName: string;
  marketId: number;
}

export interface EventTemplates {
  live_market_template_id: number | null;
  prematch_market_template_id: number | null;
  live_outcome_template_id: number | null;
  prematch_outcome_template_id: number | null;
  live_approval_template_id: number | null;
  prematch_approval_template_id: number | null;
}

export interface CamelizedTemplates {
  liveApprovalTemplateId: number | null;
  liveMarketTemplateId: number | null;
  liveOutcomeTemplateId: number | null;
  prematchApprovalTemplateId: number | null;
  prematchMarketTemplateId: number | null;
  prematchOutcomeTemplateId: number | null;
}

export interface NewOperatorNotePayload {
  int_key: number;
  note: string;
  source: EventRequestSource;
  event_type: DualEventApiType;
}

export interface GenerateTennisHandicapsPayload {
  favorite: 1 | 0;
  margin: '5%' | '7.5%';
  total_games_limit_operator: number;
  total_games_over_operator: number;
  winner_favorite_operator: number;
}

export interface GenerateTennisOddsPayload {
  parameter_id: string;
  margin: string;
  favorite: 1 | 0;
}

export interface GetSingleLiveEventResponse {
  live_max_amount_sc: number;
  live_max_amount_web: number;
  live_status: 'LIVE' | 'BREAK' | 'NOT_STARTED' | 'ENDED';
}
interface GrpcFieldsMap {
  fieldsMap: {
    boolValue: boolean;
    listValue: any[] | undefined;
    nullValue: number;
    numberValue: number;
    stringValue: string;
    structValue: any | undefined;
  };
}

export type EventStatesClassMap = {
  [key in LiveEventState | EventState]?: string;
};

export type EventRequestSource = 'EVENT-MNG' | 'PREMATCH' | 'other' | 'LIVE';

export type ChangeTemplateRequestType = 'ALL' | 'LIVE' | 'PREMATCH' | 'PLAYER';

export const isPrematch = (object: any): object is PrematchEvent => {
  return 'numOfProdOddPrematch' in object;
};

export const isLive = (object: any): object is LiveEvent => {
  if (!object) return false;
  if (!isObject(object)) return false;
  return (
    // @ts-ignore-next_line
    ('currentResult' in object && !!object.liveProvider) ||
    // @ts-ignore-next_line
    ('currentResultJson' in object && !!object.liveProvider)
  );
};

export const isStatscoreEvent = (object: any): object is FactoryEvent => {
  if (!object) return false;
  if (!isObject(object)) return false;
  // @ts-ignore-next_line
  return !!object.ctrlProvider;
};

export interface EventCreatedResponse {
  betradar_id?: any;
  int_key: number;
  name: string;
  competition_id: number;
  competition_name: string;
  competition_short_name: string;
  competition_confirmed: boolean;
  location_id: number;
  location_name: string;
  sport_id: number;
  sport_name: string;
  sport_code: string;
  state: string;
  stage: string;
  start: string;
  landbase_code: number;
  lc_redis_key?: any;
  current_phase: CurrentPhase;
  current_result: any[];
  prematch_suspended: boolean;
  live_suspended: boolean;
  hid: number;
  home: string;
  aid: number;
  away: string;
  manual_changed: boolean;
  has_players: boolean;
  note: string;
  live_status: string;
  prematch_offer: boolean;
  was_published: boolean;
  can_publish: boolean;
  tech: string;
  prematch_max_amount: number;
  live_max_amount_web: number;
  live_max_amount_sc: number;
  live_market_template_id: number;
  prematch_market_template_id: number;
  live_approval_template_id: number;
  prematch_approval_template_id: number;
  live_outcome_template_id: number;
  prematch_outcome_template_id: number;
  has_input_params: boolean;
  has_slip: boolean;
  prematch_state: string;
  operator_confirmed: boolean;
  template_odds_num: number;
  num_of_prod_odd_prematch: number;
  num_of_prod_odd_mng: number;
  operator_notes: any[];
  goes_through: boolean;
  bet_stop: boolean;
  live_provider: string;
}

export interface OutcomesByTemplate {
  outcome_id: number;
  name: string;
  has_limit: boolean;
  prematch_prod_odd: number;
  limit: number;
}

export interface MarketsByTemplate {
  market_name: string;
  outcomes: OutcomesByTemplate[];
}

export type MarketsByTemplateResponse = MarketsByTemplate[];
export interface BlockBettingPlace {
  betting_place_id: number;
  address: string;
  event_int_key: number | undefined;
}

export interface BlockedBettingPlace {
  address: string;
  bettingPlaceId: number;
}

export interface UnblockBettingPlace {
  betting_place_id: number;
  event_int_key: number | undefined;
}

export interface BlockUserPayload {
  username: string;
  userId: number;
}
