// @ts-nocheck
import omitBy from 'lodash/omitBy';
import isArray from 'lodash/isArray';

import {
  EVENT_STATES,
  INPUT_PARAMS,
  SPORTS_WITH_INPUT_PARAMS,
  FLAG_EVENT_STATES,
  EVENT_ENDED_REASON,
  FINAL_PERIODS,
} from './event.constants';
import { eventRepo } from './event.repo';
import {
  EventState,
  EventTemplates,
  EventTemplatesPayload,
  FactoryEvent,
  StateActionMap,
  CurrentPhase,
  EventRequestSource,
  SportModel,
  BlockBettingPlace,
  UnblockBettingPlace,
} from './event.types';
import { EgalOddWs, ResultMessageData } from '@/modules/feed';
import dayjs from 'dayjs';
import {
  ChangeTemplateRequestType,
  DualEventApiType,
  EventStatesClassMap,
  GetSingleLiveEventResponse,
  GenerateTennisHandicapsPayload,
  LiveEvent,
  PrematchEvent,
  GenerateTennisOddsPayload,
} from '.';
import { isLive, isPrematch } from './event.types';
import { SportShortCode } from '../sport';
import { sportProtoApi } from '../grpc/sport.api';
import { oddToInt } from '@/lib/oddToInt';
import isEmpty from 'lodash/isEmpty';

class EventService {
  getEvents() {
    return sportProtoApi.fetchOffer();
  }

  getMagazineEvents() {
    return sportProtoApi.fetchMagazineEvents();
  }

  getPrematch() {
    return sportProtoApi.fetchPrematch();
  }

  getLive(date = '') {
    return sportProtoApi.fetchLive(date);
  }

  getSinglePrematchEvent(eventIntKey: number) {
    return eventRepo.getSinglePrematchEvent(eventIntKey);
  }

  getSingleLiveEvent(eventIntKey: number): Promise<GetSingleLiveEventResponse> {
    return eventRepo.getSingleLiveEvent(eventIntKey);
  }

  getOutcomesByTemplate(eventIntKey: number) {
    return eventRepo.getOutcomesByTemplate(eventIntKey);
  }

  getOddValues(markets: any) {
    let odds = {};
    let oddKeys = {};
    const allMarketsEntries = Object.values(markets);
    allMarketsEntries.forEach(market => {
      odds = {
        ...odds,
        ...market.odds.reduce((oddsToObj, odd) => {
          return { ...oddsToObj, [odd.outcome_id]: { ...odd } };
        }, {}),
      };
    });
    Object.entries(odds).forEach(([key, value]: any) => {
      let realOddValue;
      if (value.odd_real_value === null) {
        realOddValue = 1;
      } else {
        realOddValue = parseFloat(value.odd_real_value?.toFixed(2));
      }
      oddKeys = {
        ...oddKeys,
        [key]: {
          prod_odd: oddToInt(value.odd_value),
          real_odd: realOddValue,
          limit: value.limit,
          limit2: value.limit2,
        },
      };
    });
    return oddKeys;
  }

  mapEvents(events: FactoryEvent[]) {
    return events.map((e: any) => e.id);
  }

  private getPrematchEventClass(
    map: EventStatesClassMap,
    event: PrematchEvent,
    isSettlement: boolean
  ) {
    const stateClass = map[event.state] || '';
    const isSuspended = event.prematchSuspended;
    const suspendedClass = isSuspended && !isSettlement ? ['suspended-event-row'] : [];
    return [...[stateClass], ...suspendedClass].join(' ');
  }

  private getLiveEventClass(map: EventStatesClassMap, event: LiveEvent) {
    const stateClass = map[event.state] || '';
    const isSuspended = event.liveSuspended;
    const isLiveSettleSuspended = !!event.settlementLiveStop;

    const suspendedClass = isSuspended ? ['suspended-event-row'] : [];
    const liveSettleSuspendedClass = isLiveSettleSuspended ? ['live-settlement-suspended'] : [];
    return [...[stateClass], ...suspendedClass, ...liveSettleSuspendedClass].join(' ');
  }

  getEventClass(event: FactoryEvent, isSettlement = false): string {
    if (!event) return '';
    const map: EventStatesClassMap = EVENT_STATES.reduce((agg, state) => {
      return {
        ...agg,
        [state.value]: state.class,
      };
    }, {});
    if (isSettlement) {
      return this.getPrematchEventClass(map, event as PrematchEvent, isSettlement);
    }
    if (isPrematch(event)) {
      return this.getPrematchEventClass(map, event, isSettlement);
    }
    if (isLive(event)) {
      return this.getLiveEventClass(map, event);
    }
    return '';
  }

  // TODO refactor this magical function
  getLiveEventFlagOption(event: LiveEvent): string {
    if (!event) return { label: '', description: '', class: '' };
    // event finished
    if (FINAL_PERIODS.includes(event.liveStatus))
      return FLAG_EVENT_STATES.find(flag => flag.class === 'grey-row');
    // event LIVE, BREAK
    if (event.liveStatus === 'LIVE' || event.liveStatus === 'BREAK') {
      if (event.competitionConfirmed && !event.competitorsConfirmed && event.canPublish) {
        return FLAG_EVENT_STATES.find(
          flag =>
            flag.class === 'orange-row' &&
            flag.label.toLowerCase() === event.liveStatus.toLowerCase()
        );
      }
      return FLAG_EVENT_STATES.find(
        flag =>
          flag.class === 'green-row' && flag.label.toLowerCase() === event.liveStatus.toLowerCase()
      );
    }
    if (event.liveStatus === 'NOT_STARTED') {
      // event booked, was or NOT in prematch, NOT configured (Competition: not confirmed)
      if (!event.competitionConfirmed)
        return FLAG_EVENT_STATES.find(flag => flag.class === 'red-row');

      // event booked, was in prematch, configured
      if (event.prematchOffer && event.competitionConfirmed && event.competitorsConfirmed)
        return FLAG_EVENT_STATES.find(flag => flag.class === 'purple-row');

      // event booked, was NOT in prematch, configured
      if (!event.prematchOffer && event.competitionConfirmed && event.competitorsConfirmed)
        return FLAG_EVENT_STATES.find(flag => flag.class === 'yellow-row');

      // event booked, was or NOT in prematch, NOT configured (Competitor not confirmed, can unconfirmed)
      if (event.competitionConfirmed && !event.competitorsConfirmed && event.canPublish) {
        return FLAG_EVENT_STATES.find(
          flag =>
            flag.class === 'orange-row' && flag.label.toLowerCase() === 'NOT STARTED'.toLowerCase()
        );
      }
    }
    return { label: event.liveStatus, description: '', class: '' };
  }

  updateTemplate(
    event: FactoryEvent,
    key: keyof EventTemplatesPayload,
    value: number,
    source: EventRequestSource
  ) {
    const updatedEvent: FactoryEvent = {
      ...event,
      [key]: value,
    };
    const eventTemplates: EventTemplatesPayload = {
      live_approval_template_id: Number(updatedEvent.liveApprovalTemplateId),
      live_market_template_id: Number(updatedEvent.liveMarketTemplateId),
      live_outcome_template_id: Number(updatedEvent.liveOutcomeTemplateId),
      prematch_approval_template_id: Number(updatedEvent.prematchApprovalTemplateId),
      prematch_market_template_id: Number(updatedEvent.prematchMarketTemplateId),
      prematch_outcome_template_id: Number(updatedEvent.prematchOutcomeTemplateId),
      source,
    };

    return eventRepo.updateTemplates(event.intKey, eventTemplates);
  }

  createEvent(newEvent: any) {
    const payload = {
      ...newEvent,
      competition: newEvent.competition.id,
      name: `${newEvent.home.name} - ${newEvent.away.name}`,
      state: 'CREATED',
      home: newEvent.home.id,
      away: newEvent.away.id,
    };
    return eventRepo.createEvent(payload);
  }

  getState(state: EventState) {
    return EVENT_STATES.find((s: any) => s.value === state);
  }

  addToArray(state: EventState) {
    const option = this.getState(state);
    return option ? [option] : [];
  }

  //change to StateActionMap
  stateActonsMap: any = {
    CREATED: {
      next: [...this.addToArray('SELECTED')],
      prev: null,
    },
    SELECTED: {
      next: [...this.addToArray('VALIDATED')],
      prev: [...this.addToArray('CREATED')],
    },
    VALIDATED: {
      next: [...this.addToArray('PUBLISHED')],
      prev: [...this.addToArray('PREPARED')],
    },
    PUBLISHED: {
      next: [...this.addToArray('HIDDEN')],
    },
    HIDDEN: {
      next: null,
      prev: [...this.addToArray('PUBLISHED')],
    },
    COMPLETED: {
      next: null,
      prev: null,
    },
  };

  getStateTransition(state: keyof StateActionMap) {
    return this.stateActonsMap[state];
  }

  bulkStateTansition<T extends { state: EventState }>(events: T[]) {
    const inTheSameState = events.every((e: any) => e.state === events[0].state);
    const canPublish = events.every((e: any) => !!e.canPublish || e.state !== 'VALIDATED');
    if (!canPublish || !inTheSameState || !events[0]) return null;

    return this.getStateTransition(events[0].state);
  }

  setAutoOddChange(eventId: number, nextValue: boolean, source: EventRequestSource) {
    const eventType = source === 'LIVE' ? 'live' : 'prematch';
    return eventRepo.setAutoOddChange(eventId, nextValue, eventType, source);
  }

  changeState(events: number[], nextState: EventState, source: string, sourceReq: string | null) {
    return eventRepo.changeState(events, nextState, source, sourceReq);
  }

  getSportIdFromSelectedEvents(events: FactoryEvent[]) {
    const firstEvent = events[0];
    if (!firstEvent) return;
    const isSameSport = events.every(e => e.sportId === firstEvent.sportId);
    if (!isSameSport) return;
    return firstEvent.sportId;
  }

  bulkChangeTemplates(
    selectedEvents: FactoryEvent[],
    template: EventTemplates,
    source: EventRequestSource,
    templatesType: ChangeTemplateRequestType
  ) {
    const events = selectedEvents.map((e: any) => e.intKey);
    const templatesWithId = omitBy(
      Object.keys(template).reduce((acc, key) => {
        if (!key.includes('_id')) {
          acc = { ...acc, [`${key}_id`]: template[key as keyof EventTemplates] };
        } else {
          acc = { ...acc, [key]: template[key as keyof EventTemplates] };
        }
        return acc;
      }, {}) as EventTemplates,
      value => !value
    );
    let req: any;
    switch (templatesType) {
      case 'ALL':
        req = eventRepo.bulkChangeTemplates;
        break;
      case 'LIVE':
        req = eventRepo.bulkChangeLiveTemplates;
        break;
      case 'PREMATCH':
        req = eventRepo.bulkChangePrematchTemplates;
        break;
    }
    return req({ events, template: templatesWithId, source });
  }

  bulkChangeLiveTemplates(
    selectedEvents: FactoryEvent[],
    template: EventTemplates,
    source: EventRequestSource
  ) {
    const events = selectedEvents.map((e: any) => e.id);
    const templatesWithId = Object.keys(template).reduce((acc, key) => {
      if (!key.includes('_id')) {
        acc = { ...acc, [`${key}_id`]: template[key as keyof EventTemplates] };
      } else {
        acc = { ...acc, [key]: template[key as keyof EventTemplates] };
      }
      return acc;
    }, {}) as EventTemplates;
    return eventRepo.bulkChangeLiveTemplates({ events, template: templatesWithId, source });
  }

  updateNote(intKey: number, newNote: string, eventType: DualEventApiType) {
    return eventRepo.updateNote({ note: newNote, int_key: intKey, event_type: eventType });
  }

  sendEventToSettlement(intKey: number) {
    return eventRepo.sendEventToSettlement(intKey);
  }

  addOperatorNote(
    intKey: number,
    note: string,
    source: EventRequestSource,
    eventType: DualEventApiType
  ) {
    return eventRepo.addOperatorNote({ int_key: intKey, note, source, event_type: eventType });
  }

  updateStart(
    intKey: number,
    date: string,
    source: DualEventApiType,
    stage: string,
    settle: boolean
  ) {
    const utcDate = dayjs(date).utc();
    return eventRepo.updateStart(intKey, utcDate, source, stage.toLowerCase(), settle);
  }

  updateProdOdd(
    oddInt: number,
    value: number,
    source: EventRequestSource,
    eventType: DualEventApiType,
    eventId: number
  ) {
    return eventRepo.updateOdd(oddInt, value, source, eventType, eventId);
  }

  suspendEvent(eventIds: number[], nextValue: boolean, isLive: boolean) {
    const request = isLive ? eventRepo.suspendLiveEvent : eventRepo.suspendPrematchEvent;
    return request(eventIds, nextValue);
  }

  suspendMarket(eventId: number, marketId: number, nextValue: boolean, isLive: boolean) {
    const source = isLive ? 'live' : 'prematch';
    return eventRepo.suspendMarket(eventId, marketId, nextValue, source);
  }

  suspendOdd(oddId: number, eventId: number, nextValue: boolean, isLive: boolean) {
    const source = isLive ? 'live' : 'prematch';
    return eventRepo.suspendOdd(oddId, eventId, nextValue, source);
  }

  setOddPrematchMaxBet(oddId: number, eventIntKey: number, value: number) {
    return eventRepo.setOddPrematchMaxBet(oddId, eventIntKey, value);
  }

  setMarketLiveMaxBetSc(eventIntKey: number, marketId: number, value: number) {
    return eventRepo.setMarketLiveMaxBetSc(eventIntKey, marketId, value);
  }

  setMarketLiveMaxBetWeb(eventIntKey: number, marketId: number, value: number) {
    return eventRepo.setMarketLiveMaxBetWeb(eventIntKey, marketId, value);
  }

  setMarketPrematchMaxBet(eventId: number, marketId: number, value: number) {
    return eventRepo.setMarketPrematchMaxBet(eventId, marketId, value);
  }

  generateOddsByInputParams(eventIntKey: number, payload: object) {
    return eventRepo.generateOddsByInputParams(eventIntKey, payload);
  }

  getTennisHandicaps(eventIntKey: number, payload: GenerateTennisHandicapsPayload) {
    return eventRepo.getTennisHandicaps(eventIntKey, payload);
  }

  generateTennisOdds(eventIntKey: number, payload: object) {
    return eventRepo.generateTennisOdds(eventIntKey, payload);
  }

  confirmOddsChange(eventIntKey: number, payload: object) {
    return eventRepo.acceptGeneratedOdds(eventIntKey, payload);
  }

  eventTime({
    result,
    currentPhase,
    startTime,
    liveStatus,
  }: {
    result: ResultMessageData;
    currentPhase?: CurrentPhase;
    startTime: string;
    liveStatus: any;
  }) {
    const hasTime = currentPhase && currentPhase.T;
    const hasPeriodShortName = currentPhase && currentPhase.SN;
    if (result && result.time) {
      if (isNaN(result.time[0])) {
        return result.time;
      }
      const lastPeriod = [Object.keys(result.r.d_r)[Object.keys(result.r.d_r).length - 1]];
      return `${lastPeriod} ${result.time}’`;
    }

    if (FINAL_PERIODS.includes(liveStatus)) return startTime;
    if (!hasTime && hasPeriodShortName) return `${currentPhase?.SN}`;
    if (!result && !hasTime) return startTime;
    if (hasTime && !result) {
      if (isNaN(currentPhase?.T.M)) {
        return `${currentPhase.SN} ${currentPhase?.T.M}`;
      }
      return `${currentPhase.SN} ${currentPhase?.T.M}’`;
    }
    if (isNaN(result.time)) {
      return result.time;
    }
    return `${result.time}’`;
  }

  parseInputParams(oddFormatParams: any, sportCode: SportShortCode) {
    const validParams: any = { input_parameters: {} };
    INPUT_PARAMS[sportCode].forEach((param: any) => {
      const paramKey = `${param.key}_operator`;
      if (param.isReciprocal) {
        validParams.input_parameters[paramKey] = Number(
          Number(1 / oddFormatParams[paramKey]).toFixed(5)
        );
      } else {
        validParams.input_parameters[paramKey] = oddFormatParams[paramKey];
      }
    });
    return validParams;
  }

  hasInputParams(sportCode: SportShortCode) {
    return SPORTS_WITH_INPUT_PARAMS.includes(sportCode);
  }

  getInputParamsBySport(sportCode: SportShortCode) {
    return INPUT_PARAMS[sportCode];
  }

  toggleLiveSettlement(eventIntKey: number, nextState: boolean) {
    return eventRepo.toggleLiveSettlement(eventIntKey, nextState);
  }

  setPrematchMaxAmount({ eventId, value }: { eventId: number; value: number }) {
    return eventRepo.updatePrematchMaxAmount({ eventId, value });
  }

  setLiveMaxAmountWeb({ eventIntKey, value }: { eventIntKey: number; value: number | null }) {
    return eventRepo.updateLiveMaxAmountWeb({ eventIntKey, value });
  }

  setLiveMaxAmountSc({ eventIntKey, value }: { eventIntKey: number; value: number | null }) {
    return eventRepo.updateLiveMaxAmountSc({ eventIntKey, value });
  }

  blockBettingPlace(payload: BlockBettingPlace) {
    return eventRepo.blockBettingPlace(payload);
  }

  unblockBettingPlace(payload: UnblockBettingPlace) {
    return eventRepo.unblockBettingPlace(payload);
  }

  setOddLiveMaxAmountSc({
    oddIntKey,
    eventIntKey,
    value,
  }: {
    oddIntKey: number;
    eventIntKey: number;
    value: number | null;
  }) {
    return eventRepo.setOddLiveMaxBetSc(oddIntKey, eventIntKey, value);
  }

  setOddLiveMaxAmountWeb({
    oddIntKey,
    eventIntKey,
    value,
  }: {
    oddIntKey: number;
    eventIntKey: number;
    value: number | null;
  }) {
    return eventRepo.setOddLiveMaxBetWeb(oddIntKey, eventIntKey, value);
  }

  isEventEditDisabled(eventState: EventState | undefined, requestSource: EventRequestSource) {
    return eventState === 'PUBLISHED' && requestSource === 'EVENT-MNG';
  }

  canBulkSuspend(selectedEvents: FactoryEvent[]) {
    let canSuspend = true;
    selectedEvents.forEach((event, i: number) => {
      if (i !== 0) {
        let key = '' as keyof FactoryEvent;
        if (isPrematch(event)) {
          key = 'prematch_suspended' as keyof FactoryEvent;
        } else if (isLive(event)) {
          key = 'live_suspended' as keyof FactoryEvent;
        }
        if (event[key] !== selectedEvents[i - 1][key]) {
          canSuspend = false;
        }
        if (event.state === 'VALIDATED' || event.state === 'SELECTED') {
          canSuspend = false;
        }
      }
    });
    return canSuspend;
  }

  mapEgalOddsBaseMarketWSMessage(wsOdds: EgalOddWs[]) {
    return wsOdds.map(odd => ({
      id: odd.i_k,
      int_key: odd.i_k,
      is_suspended: odd.i_s,
      live_max_amount_sc: odd.l_m_a_s,
      live_max_amount_web: odd.l_m_a_w,
      live_prod_odd: odd.r_v,
      live_real_odd: odd.p_v,
      manual_changed: false,
      limit: odd.lim,
      outcome_id: odd.o,
      outcome_name: odd.o_n,
    }));
  }

  mapTemplateInputs(liveTemplates: any[], prematchTemplates: any[]) {
    if (liveTemplates.length !== prematchTemplates.length) {
      return { ...liveTemplates, ...prematchTemplates };
    }
    const templates: any = [];
    liveTemplates.forEach((liveTemplate: any, index: number) => {
      templates.push(prematchTemplates[index]);
      templates.push(liveTemplate);
    });
    return templates;
  }

  formatEventId(intKey: number | string) {
    return `e_${intKey}`;
  }

  parseBaseOdd(odd: any) {
    const dividedOddBy100 = odd.oddValue / 100;
    return {
      id: odd.intKey,
      int_key: odd.intKey,
      is_suspended: odd.isSuspended,
      limit: odd.limit,
      live_max_amount_sc: null,
      live_max_amount_web: null,
      manual_changed: odd.manualChanged,
      outcome_id: odd.outcomeId,
      outcome_name: odd.outcomeName,
      prematch_prod_odd: dividedOddBy100,
      prematch_real_odd: odd.oddRealValue,
      prematch_max_amount: odd.prematchMaxAmount,
    };
  }

  parseBaseOddFromBaseOdds(odd: any) {
    const dividedOddBy100 = odd.p_v / 100;
    return {
      id: odd.i_k,
      int_key: odd.i_k,
      is_suspended: odd.i_s,
      limit: odd.l || odd.lim,
      live_max_amount_sc: null,
      live_max_amount_web: null,
      manual_changed: odd.m_c,
      outcome_id: odd.o,
      outcome_name: odd.o_n,
      prematch_prod_odd: dividedOddBy100,
      prematch_real_odd: odd.r_v,
      live_prod_odd: dividedOddBy100,
      live_real_odd: odd.r_v,
    };
  }

  parseBaseLiveOdd(odd: any) {
    const dividedOddBy100 = odd.oddValue / 100;
    return {
      id: odd.intKey,
      int_key: odd.intKey,
      is_suspended: odd.isSuspended,
      limit: odd.limit,
      live_max_amount_sc: null,
      live_max_amount_web: null,
      live_prod_odd: dividedOddBy100,
      live_real_odd: odd.oddRealValue,
      manual_changed: false,
      outcome_id: odd.outcomeId,
      outcome_name: odd.outcomeName,
    };
  }

  createBaseMarket({ odd, suspendedMarkets }: { odd: any; suspendedMarkets: string[] }) {
    return {
      id: odd.marketId,
      is_suspended: suspendedMarkets ? suspendedMarkets.includes(odd.marketId.toString()) : false,
      name: odd.marketName,
      odds: [this.parseBaseOdd(odd)],
    };
  }

  createBaseLiveMarket({ odd, suspendedMarkets }: { odd: any; suspendedMarkets: string[] }) {
    return {
      id: odd.marketId,
      is_suspended: suspendedMarkets ? suspendedMarkets.includes(odd.marketId.toString()) : false,
      name: odd.marketName,
      odds: [this.parseBaseLiveOdd(odd)],
    };
  }

  createBaseMarketFromBaseOdds(odd: any) {
    return {
      id: odd.m,
      is_suspended: odd.i_s,
      name: odd.m_n,
      odds: [this.parseBaseOddFromBaseOdds(odd)],
    };
  }

  groupPrematchOddsByMarket({
    odds,
    suspendedMarkets,
  }: {
    odds: any[];
    suspendedMarkets: string[];
  }) {
    return odds.reduce((marketList, odd) => {
      if (!marketList.length) {
        return [this.createBaseMarket({ odd, suspendedMarkets })];
      }
      const createdMarket = marketList.find((m: any) => odd.marketId === m.id);
      if (createdMarket) {
        createdMarket.odds.push(this.parseBaseOdd(odd));
        return marketList;
      } else {
        return [...marketList, this.createBaseMarket({ odd, suspendedMarkets })];
      }
    }, []);
  }

  groupPrematchBaseOddsByMarket(odds: any) {
    return odds.reduce((marketList, odd) => {
      if (!marketList.length) {
        return [this.createBaseMarketFromBaseOdds(odd)];
      }
      const createdMarket = marketList.find((m: any) => odd.m === m.id);
      if (createdMarket) {
        createdMarket.odds.push(this.parseBaseOddFromBaseOdds(odd));
        return marketList;
      } else {
        return [...marketList, this.createBaseMarketFromBaseOdds(odd)];
      }
    }, []);
  }

  filterSuspendedMarkes(isMarketSuspendedMap: any[][]) {
    return isMarketSuspendedMap
      .map(market => {
        if (market[1]) {
          return market[0];
        }
      })
      .filter(elem => elem);
  }

  pluckBaseMarkets({
    baseMarketsMap,
    isMarketSuspendedMap,
  }: {
    baseMarketsMap?: any[][];
    isMarketSuspendedMap: any[][];
  }) {
    if (!baseMarketsMap) return [];
    const pluckedOdds = baseMarketsMap.map(baseMarketMap => {
      return baseMarketMap[1];
    });
    return this.groupPrematchOddsByMarket({
      odds: pluckedOdds,
      suspendedMarkets: this.filterSuspendedMarkes(isMarketSuspendedMap),
    });
  }

  pluckLiveBaseMarkets({
    baseMarketsMap,
    isMarketSuspendedMap,
  }: {
    baseMarketsMap?: any[][];
    isMarketSuspendedMap: any[][];
  }) {
    if (!baseMarketsMap) return [];
    const pluckedMakrets = baseMarketsMap.map(baseMarketMap => {
      return baseMarketMap[1];
    });
    return pluckedMakrets.reduce((validMarkets: any, odd: any) => {
      if (!validMarkets.length) {
        return [
          this.createBaseLiveMarket({
            odd,
            suspendedMarkets: this.filterSuspendedMarkes(isMarketSuspendedMap),
          }),
        ];
      }
      const createdMarket = validMarkets.find((m: any) => odd.marketId === m.id);
      if (createdMarket) {
        createdMarket.odds.push(this.parseBaseLiveOdd(odd));
        return validMarkets;
      } else {
        return [
          ...validMarkets,
          this.createBaseLiveMarket({
            odd,
            suspendedMarkets: this.filterSuspendedMarkes(isMarketSuspendedMap),
          }),
        ];
      }
    }, []);
  }

  pluckLiveMarketsOnPublish = ({
    pluckedMakrets,
    isMarketSuspendedMap,
  }: {
    pluckedMakrets: any[][];
    isMarketSuspendedMap: any[][];
  }) => {
    return pluckedMakrets.reduce((validMarkets: any, odd: any) => {
      if (!validMarkets.length) {
        return [
          this.createBaseLiveMarket({
            odd,
            isMarketSuspendedMap: this.filterSuspendedMarkes(isMarketSuspendedMap),
          }),
        ];
      }
      const createdMarket = validMarkets.find((m: any) => odd.marketId === m.id);
      if (createdMarket) {
        createdMarket.odds.push(this.parseBaseLiveOdd(odd));
        return validMarkets;
      } else {
        return [
          ...validMarkets,
          this.createBaseLiveMarket({
            odd,
            isMarketSuspendedMap: this.filterSuspendedMarkes(isMarketSuspendedMap),
          }),
        ];
      }
    }, []);
  };

  getNonBookedPrematchEvents(liveEvents: LiveEvent[], prematchEvents: PrematchEvent[]) {
    const liveEventIntKeys = liveEvents.map((e: any) => e.intKey);
    return prematchEvents.filter((prematchEvent: any) => {
      return !liveEventIntKeys.includes(prematchEvent.intKey) && prematchEvent.state !== 'HIDDEN';
    });
  }

  sortByDateAndCompetitionName(a: any, b: any) {
    const dateA = new Date(a.start).getTime();
    const dateB = new Date(b.start).getTime();
    let order = dateA - dateB;
    if (order === 0) {
      order = a.competitionName.localeCompare(b.competitionName);
    }
    return order;
  }

  sortEvents(events: FactoryEvent[]) {
    return events.sort((a, b) => {
      return this.sortByDateAndCompetitionName(a, b);
    });
  }

  parseTennisHandicapsPayload(formData: any): GenerateTennisHandicapsPayload {
    return {
      winner_favorite_operator:
        typeof formData.winner_favorite_operator_home === 'number'
          ? formData.winner_favorite_operator_home
          : formData.winner_favorite_operator_away,
      margin: formData.margin,
      favorite: typeof formData.winner_favorite_operator_home === 'number' ? 1 : 0,
      total_games_limit_operator: formData.total_games_limit_operator,
      total_games_over_operator: formData.total_games_over_operator,
    };
  }

  parseTennisGenerateOddsPayload(formData: any): GenerateTennisOddsPayload {
    return {
      parameter_id: formData.parameter,
      margin: formData.margin,
      favorite: typeof formData.winner_favorite_operator_home === 'number' ? 1 : 0,
    };
  }

  parseTennisTempInputParams(formData: any) {
    return {
      ...formData,
      total_games_over_operator: 1 / formData.total_games_over_operator,
      favorite: typeof formData.winner_favorite_operator_home === 'number' ? 1 : 0,
      winner_favorite_operator:
        formData.winner_favorite_operator_home || formData.winner_favorite_operator_away,
    };
  }

  getTennisInputParamsForConfirmOdds(payload: any, odds: any) {
    // we dont need these two values because from them we extract winner_favorite_operator which is sent to BE
    const {
      winner_favorite_operator_home: _wfoh,
      winner_favorite_operator_away: _wfoa,
      ...rest
    } = payload;
    return { input_parameters: rest, odds: odds };
  }

  getLiveImportantPeriodResults(periodResults: any, periodConfig: any) {
    return periodConfig.reduce((_result: number[], config: any) => {
      if (isArray(config.key)) {
        const totalResult = [0, 0];
        config.key.forEach((a: any) => {
          const accessors = (periodResults as any)[a];
          if (accessors) {
            totalResult[0] = totalResult[0] + accessors[config.resultKey]?.[0];
            totalResult[1] = totalResult[1] + accessors[config.resultKey]?.[1];
          }
        });
        return totalResult;
      }

      return periodResults?.[config.key]?.[config.resultKey];
    }, []);
  }

  getModelsBySport(models: number[], modelValues: SportModel) {
    return models.reduce((result: any, modelValue) => {
      return [...result, { key: modelValues[modelValue], value: modelValue }];
    }, []);
  }

  divideOddsBy100(odds: any) {
    return Object.keys(odds).reduce((oddsObj, key) => {
      const oddsDividedBy100 = Object.keys(odds[key].odds).reduce((oddsValues, oddKey) => {
        return {
          ...oddsValues,
          [oddKey]: {
            ...odds[key].odds[oddKey],
            odd_value: odds[key].odds[oddKey].odd_value / 100,
          },
        };
      }, {});
      return { ...oddsObj, [key]: { ...odds[key], odds: oddsDividedBy100 } };
    }, {});
  }

  blockUserOnEvent(payload: any) {
    return eventRepo.blockUserOnEvent(payload);
  }

  unblockUserOnEvent(payload: any) {
    return eventRepo.unblockUserOnEvent(payload);
  }

  mapBlockedUsers(blockedUsers: { [key: number]: string }) {
    const mappedUsers = [];

    for (const key in blockedUsers) {
      mappedUsers.push({
        username: blockedUsers[key],
        userId: Number(key),
      });
    }

    return mappedUsers;
  }

  mapBettingPlaces(blockedBp: { [key: number]: string }) {
    const mappedBp = [];

    for (const key in blockedBp) {
      mappedBp.push({
        address: blockedBp[key],
        bettingPlaceId: Number(key),
      });
    }

    return mappedBp;
  }

  getLiveEventEndReason(event: LiveEvent | undefined) {
    if (!event) return false;
    return EVENT_ENDED_REASON[event.liveStatus] || false;
  }

  liveEventTimeFootballSettle(time: string) {
    if (time === 'P') return '120';
    if (time === ' OT') return '120';
    if (time === 'FT') return '90';
    if (time === 'HT') return '45';
    return time;
  }

  eventTimeOnInitalLoad(time: { M: string; S: string }) {
    return Number(time.M) + 1;
  }

  mapProvidersFromStruct(providers: any) {
    console.log('PROVIDERS', providers);
    if (!providers) return [];
    if (!providers.fieldsMap) return [];

    if ('fieldsMap' in providers) {
      return providers.fieldsMap.map((provider: string, index: number) => {
        const providerName = provider[0];
        return {
          label: `${providerName[0].toUpperCase()}${providerName.slice(1)}`,
          value: providerName,
          key: index + 1,
          class: `${providerName}-select`,
        };
      });
    }
    if (isEmpty(providers)) return [];
    const mappedProviders = Object.keys(providers);
    return mappedProviders.map((provider: string, index: number) => {
      return {
        label: `${provider.toUpperCase()}${provider.slice(1)}`,
        value: provider,
        key: index + 1,
        class: `${provider}-select`,
      };
    });
  }

  changeProvider(payload: { intKey: number; provider: { [key: string]: string } }) {
    return eventRepo.changeProvider(payload);
  }

  mapCompetitorHelper(wsCompetitor: any) {
    return {
      intKey: wsCompetitor.i_k,
      name: wsCompetitor.n,
      home: wsCompetitor.h,
      away: wsCompetitor.a,
      competitorsConfirmed: wsCompetitor.csc,
      canPublish: wsCompetitor.cp,
      homeConfirmed: wsCompetitor.h_c,
      awayConfirmed: wsCompetitor.a_c,
    };
  }

  mapCompetitorsWs(wsData: any) {
    return wsData.map(competitor => this.mapCompetitorHelper(competitor));
  }

  mapCompetitionsWs(wsData: any) {
    return wsData.map(competititon => {
      return {
        intKey: competititon.i_k,
        locationId: competititon.l_i,
        locationName: competititon.l_n,
        competitionId: competititon.c_i,
        competititonName: competititon.c_n,
        competitionShortName: competititon.c_s_n,
        competitionConfirmed: competititon.cc,
        canPublish: competititon.cp,
        liveMarketTemplateId: competititon.lmti,
        liveOutcomeTemplateId: competititon.loti,
        liveApprovalTemplateId: competititon.lati,
        prematchMarketTemplateId: competititon.pmti,
        prematchOutcomeTemplateId: competititon.poti,
        prematchApprovalTemplateId: competititon.pati,
        // state: competititon.p_s,
        isAutomatic: competititon.i_a,
        automatic_time: competititon.a_t,
      };
    });
  }

  isManualTech(event: FactoryEvent) {
    return event.tech === 'M';
  }
}

export const eventService = new EventService();
