import isObject from 'lodash/isObject';
import transform from 'lodash/transform';
import isArray from 'lodash/isArray';
import camelCase from 'lodash/camelCase';

export const objectKeysToCamelCase = (obj: any) => {
  return transform(obj, (acc: any, value, key, target) => {
    const camelKey = isArray(target) ? key : camelCase(key as string);
    acc[camelKey] = isObject(value) ? objectKeysToCamelCase(value) : value;
  });
};
