import { api } from '@/lib/api';
import {
  ResetAntepostByDateTimePayload,
  SettleAntepostOddsPayload,
} from './settlementAntepost.types';

const antepostSportFilter = () => {
  return api.get('/odds/sports/competitions-antepost-markets/');
};

const resetAllAntepostBets = (intKey: number) => {
  return api.put(`/odds/anteposts/${intKey}/reset-all-odds/`);
};

const resetAntepostBetsByDateTime = (intKey: number, payload: ResetAntepostByDateTimePayload) => {
  return api.put(`/odds/anteposts/${intKey}/reset-odds-by-datetime/`, payload);
};

const resetMarketAntepostBetsByDateTime = (
  intKey: number,
  payload: ResetAntepostByDateTimePayload
) => {
  return api.put(`/odds/anteposts-markets/${intKey}/reset-odds-by-datetime/`, payload);
};

const settleOdds = (intKey: number, payload: SettleAntepostOddsPayload) => {
  return api.put(`/odds/antepost-bets/settle-odds/`, {
    odds: {
      PASSED: [...payload.WON],
      FAILED: [...payload.LOST],
      ODD_ONE: [...payload.ODD_ONE],
    },
    event_id: intKey,
  });
};

const setOddToOne = (intKey: number) => {
  return api.put(`/odds/antepost-bets/${intKey}/clear/`);
};

const resetOdd = (oddId: number) => {
  return api.put(`odds/antepost-bets/${oddId}/unsettle/`);
};

const resetAllBets = (oddId: number) => {
  return api.put(`odds/anteposts/${oddId}/reset-all-odds/`);
};

const refetchPlayedOutcomes = (intKey: number) => {
  return api.get(`odds/anteposts/${intKey}/played-outcomes/`, {
    cache: {
      maxAge: 0,
    },
  });
};

export const settlementAntepostRepo = {
  settleOdds,
  antepostSportFilter,
  resetAllAntepostBets,
  resetAntepostBetsByDateTime,
  setOddToOne,
  resetOdd,
  resetAllBets,
  resetMarketAntepostBetsByDateTime,
  refetchPlayedOutcomes,
};
