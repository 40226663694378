/**
 * @fileoverview gRPC-Web generated client stub for odds_stream
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js')
const proto = {};
proto.odds_stream = require('./odds_stream_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.odds_stream.OddsStreamServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.odds_stream.OddsStreamServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetEventsResponse>}
 */
const methodDescriptor_OddsStreamService_StreamGetEventsPrematch = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetEventsPrematch',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetEventsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetEventsResponse>}
 */
const methodInfo_OddsStreamService_StreamGetEventsPrematch = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetEventsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetEventsPrematch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsPrematch',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsPrematch);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetEventsPrematch =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsPrematch',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsPrematch);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetEventsResponse>}
 */
const methodDescriptor_OddsStreamService_StreamGetEventsLive = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetEventsLive',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetEventsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetEventsResponse>}
 */
const methodInfo_OddsStreamService_StreamGetEventsLive = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetEventsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetEventsLive =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsLive',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsLive);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetEventsLive =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsLive',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsLive);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetPlayerEventsResponse>}
 */
const methodDescriptor_OddsStreamService_StreamGetPlayerEvents = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetPlayerEvents',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetPlayerEventsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetPlayerEventsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetPlayerEventsResponse>}
 */
const methodInfo_OddsStreamService_StreamGetPlayerEvents = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetPlayerEventsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetPlayerEventsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetPlayerEventsResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetPlayerEvents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetPlayerEvents',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetPlayerEvents);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetPlayerEventsResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetPlayerEvents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetPlayerEvents',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetPlayerEvents);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetEventsResponseSC>}
 */
const methodDescriptor_OddsStreamService_StreamGetEventsPrematchSC = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetEventsPrematchSC',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetEventsResponseSC,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsResponseSC.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetEventsResponseSC>}
 */
const methodInfo_OddsStreamService_StreamGetEventsPrematchSC = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetEventsResponseSC,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsResponseSC.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsResponseSC>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetEventsPrematchSC =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsPrematchSC',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsPrematchSC);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsResponseSC>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetEventsPrematchSC =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsPrematchSC',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsPrematchSC);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetEventsResponseSC>}
 */
const methodDescriptor_OddsStreamService_StreamGetEventsLiveSC = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetEventsLiveSC',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetEventsResponseSC,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsResponseSC.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetEventsResponseSC>}
 */
const methodInfo_OddsStreamService_StreamGetEventsLiveSC = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetEventsResponseSC,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsResponseSC.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsResponseSC>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetEventsLiveSC =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsLiveSC',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsLiveSC);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsResponseSC>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetEventsLiveSC =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsLiveSC',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsLiveSC);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetEventsCreateResponseBO>}
 */
const methodDescriptor_OddsStreamService_StreamGetEventsCreateBO = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetEventsCreateBO',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetEventsCreateResponseBO,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsCreateResponseBO.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetEventsCreateResponseBO>}
 */
const methodInfo_OddsStreamService_StreamGetEventsCreateBO = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetEventsCreateResponseBO,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsCreateResponseBO.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsCreateResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetEventsCreateBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsCreateBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsCreateBO);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsCreateResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetEventsCreateBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsCreateBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsCreateBO);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetEventsOfferResponseBO>}
 */
const methodDescriptor_OddsStreamService_StreamGetEventsOfferBO = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetEventsOfferBO',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetEventsOfferResponseBO,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsOfferResponseBO.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetEventsOfferResponseBO>}
 */
const methodInfo_OddsStreamService_StreamGetEventsOfferBO = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetEventsOfferResponseBO,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsOfferResponseBO.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsOfferResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetEventsOfferBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsOfferBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsOfferBO);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsOfferResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetEventsOfferBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsOfferBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsOfferBO);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetEventsMagazineResponse>}
 */
const methodDescriptor_OddsStreamService_StreamGetEventsMagazine = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetEventsMagazine',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetEventsMagazineResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsMagazineResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetEventsMagazineResponse>}
 */
const methodInfo_OddsStreamService_StreamGetEventsMagazine = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetEventsMagazineResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsMagazineResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsMagazineResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetEventsMagazine =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsMagazine',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsMagazine);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsMagazineResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetEventsMagazine =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsMagazine',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsMagazine);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetEventsPrematchResponseBO>}
 */
const methodDescriptor_OddsStreamService_StreamGetEventsPrematchBO = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetEventsPrematchBO',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetEventsPrematchResponseBO,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsPrematchResponseBO.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetEventsPrematchResponseBO>}
 */
const methodInfo_OddsStreamService_StreamGetEventsPrematchBO = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetEventsPrematchResponseBO,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsPrematchResponseBO.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsPrematchResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetEventsPrematchBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsPrematchBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsPrematchBO);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsPrematchResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetEventsPrematchBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsPrematchBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsPrematchBO);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.odds_stream.BORequestDate,
 *   !proto.odds_stream.GetEventsLiveResponseBO>}
 */
const methodDescriptor_OddsStreamService_StreamGetEventsLiveBO = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetEventsLiveBO',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.odds_stream.BORequestDate,
  proto.odds_stream.GetEventsLiveResponseBO,
  /**
   * @param {!proto.odds_stream.BORequestDate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsLiveResponseBO.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.odds_stream.BORequestDate,
 *   !proto.odds_stream.GetEventsLiveResponseBO>}
 */
const methodInfo_OddsStreamService_StreamGetEventsLiveBO = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetEventsLiveResponseBO,
  /**
   * @param {!proto.odds_stream.BORequestDate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsLiveResponseBO.deserializeBinary
);


/**
 * @param {!proto.odds_stream.BORequestDate} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsLiveResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetEventsLiveBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsLiveBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsLiveBO);
};


/**
 * @param {!proto.odds_stream.BORequestDate} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsLiveResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetEventsLiveBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsLiveBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsLiveBO);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetPlayerEventsResponseBO>}
 */
const methodDescriptor_OddsStreamService_StreamGetPlayerEventsOfferBO = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetPlayerEventsOfferBO',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetPlayerEventsResponseBO,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetPlayerEventsResponseBO.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetPlayerEventsResponseBO>}
 */
const methodInfo_OddsStreamService_StreamGetPlayerEventsOfferBO = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetPlayerEventsResponseBO,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetPlayerEventsResponseBO.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetPlayerEventsResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetPlayerEventsOfferBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetPlayerEventsOfferBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetPlayerEventsOfferBO);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetPlayerEventsResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetPlayerEventsOfferBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetPlayerEventsOfferBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetPlayerEventsOfferBO);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetPlayerEventsResponseBO>}
 */
const methodDescriptor_OddsStreamService_StreamGetPlayerEventsPrematchBO = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetPlayerEventsPrematchBO',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetPlayerEventsResponseBO,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetPlayerEventsResponseBO.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetPlayerEventsResponseBO>}
 */
const methodInfo_OddsStreamService_StreamGetPlayerEventsPrematchBO = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetPlayerEventsResponseBO,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetPlayerEventsResponseBO.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetPlayerEventsResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetPlayerEventsPrematchBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetPlayerEventsPrematchBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetPlayerEventsPrematchBO);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetPlayerEventsResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetPlayerEventsPrematchBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetPlayerEventsPrematchBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetPlayerEventsPrematchBO);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetPlayerEventsResponse>}
 */
const methodDescriptor_OddsStreamService_StreamGetPlayerEventsSC = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetPlayerEventsSC',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetPlayerEventsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetPlayerEventsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetPlayerEventsResponse>}
 */
const methodInfo_OddsStreamService_StreamGetPlayerEventsSC = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetPlayerEventsResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetPlayerEventsResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetPlayerEventsResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetPlayerEventsSC =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetPlayerEventsSC',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetPlayerEventsSC);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetPlayerEventsResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetPlayerEventsSC =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetPlayerEventsSC',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetPlayerEventsSC);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetAntepostResponse>}
 */
const methodDescriptor_OddsStreamService_StreamGetAntepost = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetAntepost',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetAntepostResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetAntepostResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetAntepostResponse>}
 */
const methodInfo_OddsStreamService_StreamGetAntepost = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetAntepostResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetAntepostResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetAntepostResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetAntepost =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetAntepost',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetAntepost);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetAntepostResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetAntepost =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetAntepost',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetAntepost);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetAntepostsResponseBO>}
 */
const methodDescriptor_OddsStreamService_StreamGetAntepostsOfferBO = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetAntepostsOfferBO',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetAntepostsResponseBO,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetAntepostsResponseBO.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetAntepostsResponseBO>}
 */
const methodInfo_OddsStreamService_StreamGetAntepostsOfferBO = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetAntepostsResponseBO,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetAntepostsResponseBO.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetAntepostsResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetAntepostsOfferBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetAntepostsOfferBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetAntepostsOfferBO);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetAntepostsResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetAntepostsOfferBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetAntepostsOfferBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetAntepostsOfferBO);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetAntepostsResponseBO>}
 */
const methodDescriptor_OddsStreamService_StreamGetAntepostsPrematchBO = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetAntepostsPrematchBO',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetAntepostsResponseBO,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetAntepostsResponseBO.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetAntepostsResponseBO>}
 */
const methodInfo_OddsStreamService_StreamGetAntepostsPrematchBO = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetAntepostsResponseBO,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetAntepostsResponseBO.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetAntepostsResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetAntepostsPrematchBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetAntepostsPrematchBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetAntepostsPrematchBO);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetAntepostsResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetAntepostsPrematchBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetAntepostsPrematchBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetAntepostsPrematchBO);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetAntepostsResponseBO>}
 */
const methodDescriptor_OddsStreamService_StreamGetAntepostsSettlementBO = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetAntepostsSettlementBO',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetAntepostsResponseBO,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetAntepostsResponseBO.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetAntepostsResponseBO>}
 */
const methodInfo_OddsStreamService_StreamGetAntepostsSettlementBO = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetAntepostsResponseBO,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetAntepostsResponseBO.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetAntepostsResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetAntepostsSettlementBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetAntepostsSettlementBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetAntepostsSettlementBO);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetAntepostsResponseBO>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetAntepostsSettlementBO =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetAntepostsSettlementBO',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetAntepostsSettlementBO);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.odds_stream.BORequestDate,
 *   !proto.odds_stream.GetEventsSettleResponse>}
 */
const methodDescriptor_OddsStreamService_StreamGetEventsSettle = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetEventsSettle',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.odds_stream.BORequestDate,
  proto.odds_stream.GetEventsSettleResponse,
  /**
   * @param {!proto.odds_stream.BORequestDate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsSettleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.odds_stream.BORequestDate,
 *   !proto.odds_stream.GetEventsSettleResponse>}
 */
const methodInfo_OddsStreamService_StreamGetEventsSettle = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetEventsSettleResponse,
  /**
   * @param {!proto.odds_stream.BORequestDate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetEventsSettleResponse.deserializeBinary
);


/**
 * @param {!proto.odds_stream.BORequestDate} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsSettleResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetEventsSettle =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsSettle',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsSettle);
};


/**
 * @param {!proto.odds_stream.BORequestDate} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetEventsSettleResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetEventsSettle =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetEventsSettle',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetEventsSettle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetPlayerEventsSettleResponse>}
 */
const methodDescriptor_OddsStreamService_StreamGetPlayerEventsSettle = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetPlayerEventsSettle',
  grpc.web.MethodType.SERVER_STREAMING,
  google_protobuf_empty_pb.Empty,
  proto.odds_stream.GetPlayerEventsSettleResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetPlayerEventsSettleResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.google.protobuf.Empty,
 *   !proto.odds_stream.GetPlayerEventsSettleResponse>}
 */
const methodInfo_OddsStreamService_StreamGetPlayerEventsSettle = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetPlayerEventsSettleResponse,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetPlayerEventsSettleResponse.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetPlayerEventsSettleResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetPlayerEventsSettle =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetPlayerEventsSettle',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetPlayerEventsSettle);
};


/**
 * @param {!proto.google.protobuf.Empty} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetPlayerEventsSettleResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetPlayerEventsSettle =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetPlayerEventsSettle',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetPlayerEventsSettle);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.odds_stream.BORequestDate,
 *   !proto.odds_stream.GetCtrlEventsResponse>}
 */
const methodDescriptor_OddsStreamService_StreamGetCtrlEvents = new grpc.web.MethodDescriptor(
  '/odds_stream.OddsStreamService/StreamGetCtrlEvents',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.odds_stream.BORequestDate,
  proto.odds_stream.GetCtrlEventsResponse,
  /**
   * @param {!proto.odds_stream.BORequestDate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetCtrlEventsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.odds_stream.BORequestDate,
 *   !proto.odds_stream.GetCtrlEventsResponse>}
 */
const methodInfo_OddsStreamService_StreamGetCtrlEvents = new grpc.web.AbstractClientBase.MethodInfo(
  proto.odds_stream.GetCtrlEventsResponse,
  /**
   * @param {!proto.odds_stream.BORequestDate} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.odds_stream.GetCtrlEventsResponse.deserializeBinary
);


/**
 * @param {!proto.odds_stream.BORequestDate} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetCtrlEventsResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServiceClient.prototype.streamGetCtrlEvents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetCtrlEvents',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetCtrlEvents);
};


/**
 * @param {!proto.odds_stream.BORequestDate} request The request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.odds_stream.GetCtrlEventsResponse>}
 *     The XHR Node Readable Stream
 */
proto.odds_stream.OddsStreamServicePromiseClient.prototype.streamGetCtrlEvents =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/odds_stream.OddsStreamService/StreamGetCtrlEvents',
      request,
      metadata || {},
      methodDescriptor_OddsStreamService_StreamGetCtrlEvents);
};


module.exports = proto.odds_stream;

