import store from '@/store';
import { to } from 'await-to-js';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import removeItemFromArray from '@/lib/removeItemFromArray';
import { settlementAntepostService } from './settlementAntepost.service';
import { OddStatus, SettleOddsPayload, OddTypes } from '@/modules/settlement/settlement.types';
import { Antepost as IAntepost } from '@/modules/antepost/antepost.types';
import { AntepostEventsMap } from '.';
import { objectKeysToCamelCase } from '@/lib/objectKeysToCamelCase';
import Vue from 'vue';

const arrayToObject = (array: IAntepost[]) => {
  return array.reduce((obj: any, item: IAntepost) => {
    const key = settlementAntepostService.formatEventId(item.intKey);
    obj[key] = { ...item, allBetsMap: settlementAntepostService.mapToArray(item.allBetsMap) };
    return obj;
  }, []);
};

const initialState = () => ({
  totalSettlement: 0,
  events: {},
  activeId: null,
  settleOddsPayload: {
    odds: { LOST: [], WON: [], ODD_ONE: [] },
  },
});

@Module({ dynamic: true, store, name: 'settlementAntepost', namespaced: true })
class SettlementAntepost extends VuexModule {
  totalSettlement = initialState().totalSettlement;
  events: AntepostEventsMap = initialState().events;
  activeId: number | null = initialState().activeId;
  settleOddsPayload: SettleOddsPayload = initialState().settleOddsPayload;

  get resultEntries() {
    return this.totalSettlement;
  }

  get settlementAntepostsList() {
    return Object.values(this.events);
  }

  get selectedAntepost() {
    if (!this.activeId) return null;
    return this.events[settlementAntepostService.formatEventId(this.activeId)];
  }

  get activeOutcomes() {
    if (!this.activeId) return [];
    return this.events[settlementAntepostService.formatEventId(this.activeId)]?.allBetsMap;
  }

  get isSettleOddsPayloadValid() {
    return (
      this.settleOddsPayload.odds.LOST.length ||
      this.settleOddsPayload.odds.WON.length ||
      this.settleOddsPayload.odds.ODD_ONE.length
    );
  }

  @Mutation
  resetAllAntepostBetsStatus(oddId: number) {
    const activeOutcomes = this.events[`e_${oddId}`].allBetsMap;
    activeOutcomes.forEach(outcome => {
      outcome.manualStatus = OddStatus.UNRESOLVED;
    });
    this.settleOddsPayload = initialState().settleOddsPayload;
  }

  @Mutation
  setTempEventSettings() {
    this.settleOddsPayload.odds = {
      WON: this.events[settlementAntepostService.formatEventId(this.activeId)].allBetsMap.reduce(
        (acc: number[], odd) => {
          if (odd.manualStatus === OddStatus.WON) {
            acc.push(odd.intKey);
          }
          return acc;
        },
        []
      ) as number[],
      LOST: this.events[settlementAntepostService.formatEventId(this.activeId)].allBetsMap.reduce(
        (acc: number[], odd) => {
          if (odd.manualStatus === OddStatus.LOST) {
            acc.push(odd.intKey);
          }
          return acc;
        },
        []
      ) as number[],
      ODD_ONE: this.events[
        settlementAntepostService.formatEventId(this.activeId)
      ].allBetsMap.reduce((acc: number[], odd) => {
        if (odd.manualStatus === OddStatus.CANCELLED) {
          acc.push(odd.intKey);
        }
        return acc;
      }, []) as number[],
    };
  }

  @Mutation
  updateOdd({ oddId, key, value }: { oddId: number; key: string; value: any }) {
    if (!this.activeId) return null;
    const activeOutcomes = this.events[settlementAntepostService.formatEventId(this.activeId)]
      .allBetsMap;
    const outcome = activeOutcomes.find(o => o.intKey === oddId);
    if (!outcome) return;
    // @ts-ignore
    outcome[key] = value;
  }

  @Mutation
  addOddForSettlement({ oddId, type }: { oddId: number; type: OddTypes }) {
    this.settleOddsPayload.odds[type].push(oddId);
  }

  @Mutation
  removeOddForSettlement({ oddId, type }: { oddId: number; type: OddTypes }) {
    this.settleOddsPayload.odds[type] = removeItemFromArray(
      this.settleOddsPayload.odds[type],
      oddId
    );
  }

  @Mutation
  resetAntepostOdd({ oddId }: { oddId: number }) {
    this.settleOddsPayload.odds.WON = removeItemFromArray(this.settleOddsPayload.odds.WON, oddId);
    this.settleOddsPayload.odds.LOST = removeItemFromArray(this.settleOddsPayload.odds.LOST, oddId);
    this.settleOddsPayload.odds.ODD_ONE = removeItemFromArray(
      this.settleOddsPayload.odds.ODD_ONE,
      oddId
    );
  }

  @Mutation
  setOddsPayload({ type, odds }: { type: OddTypes; odds: number[] }) {
    this.settleOddsPayload.odds[type] = [...this.settleOddsPayload.odds[type], ...odds];
  }

  @Mutation
  clearSettleOddsPayload() {
    this.settleOddsPayload = initialState().settleOddsPayload;
  }

  @Mutation
  clearActive() {
    this.activeId = initialState().activeId;
  }

  @Mutation
  setSelectedAntepost(eventId: number) {
    this.activeId = eventId;
  }

  @Mutation
  setEvents(payload: IAntepost[]) {
    this.events = { ...arrayToObject(payload) };
  }

  @Mutation
  resetStore() {
    const initial = initialState() as any;
    Object.keys(initial).forEach(key => {
      this[key as keyof this] = initial[key];
    });
  }

  @Mutation
  divideOddValueBy100(bet: any) {
    bet.oddValue = bet.oddValue / 100;
  }

  @Mutation
  updateAntepostEvent({ eventId, key, newValue }: { eventId: number; key: string; newValue: any }) {
    const eventAccesor = settlementAntepostService.formatEventId(eventId);

    Vue.set(this.events[eventAccesor], key, newValue);
  }

  @Action
  async settleOdds() {
    if (this.activeId === null) return;
    const [err] = await to(
      settlementAntepostService.settleOdds(this.activeId, { ...this.settleOddsPayload.odds })
    );
    if (err) {
      this.setTempEventSettings();
      return Promise.reject(err);
    }
    if (this.settleOddsPayload.odds.WON.length) {
      this.settleOddsPayload.odds.WON.forEach((oddId: number) => {
        this.updateOdd({ oddId, key: 'manualStatus', value: OddStatus.WON });
      });
    }
    if (this.settleOddsPayload.odds.LOST.length) {
      this.settleOddsPayload.odds.LOST.forEach((oddId: number) => {
        this.updateOdd({ oddId, key: 'manualStatus', value: OddStatus.LOST });
      });
    }
    if (this.settleOddsPayload.odds.ODD_ONE.length) {
      this.settleOddsPayload.odds.ODD_ONE.forEach((oddId: number) => {
        this.updateOdd({ oddId, key: 'manualStatus', value: OddStatus.CANCELLED });
      });
    }
    return Promise.resolve();
  }

  @Action
  async getAntepostForSettlement() {
    const [err, response] = await to<any>(settlementAntepostService.getAntepostForSettlement());
    if (err) return Promise.reject(err);
    this.setEvents(response);
    response.forEach((antepost: any) => {
      antepost.allBetsMap.forEach((bet: any) => {
        this.divideOddValueBy100(bet[1]);
        if (bet[1].manualStatus === OddStatus.LOST) {
          this.addOddForSettlement({ oddId: Number(bet[1].intKey), type: 'LOST' });
        }
        if (bet[1].manualStatus === OddStatus.WON) {
          this.addOddForSettlement({ oddId: Number(bet[1].intKey), type: 'WON' });
        }
        if (bet[1].manualStatus === OddStatus.CANCELLED) {
          this.addOddForSettlement({ oddId: Number(bet[1].intKey), type: 'ODD_ONE' });
        }
      });
    });
  }

  @Action
  async antepostSportFilter() {
    const [err] = await to<any>(settlementAntepostService.antepostSportFilter());
    if (err) return Promise.reject(err);
  }

  @Action
  bulkAddOutcomes(type: OddTypes) {
    const nonSelectedOutcomes = settlementAntepostService.findNonSelectedActiveOutcomes(
      this.activeOutcomes,
      this.settleOddsPayload.odds.WON,
      this.settleOddsPayload.odds.LOST,
      this.settleOddsPayload.odds.ODD_ONE
    );
    this.setOddsPayload({ type, odds: nonSelectedOutcomes });
  }

  @Action
  setOddForSettlement({ oddId, type }: { oddId: number; type: OddTypes }) {
    if (this.settleOddsPayload.odds['WON'].includes(oddId)) {
      this.removeOddForSettlement({ oddId, type: 'WON' });
    }
    if (this.settleOddsPayload.odds['LOST'].includes(oddId)) {
      this.removeOddForSettlement({ oddId, type: 'LOST' });
    }
    if (this.settleOddsPayload.odds['ODD_ONE'].includes(oddId)) {
      this.removeOddForSettlement({ oddId, type: 'ODD_ONE' });
    }
    if (this.settleOddsPayload.odds[type].includes(oddId)) {
      this.removeOddForSettlement({ oddId, type });
    } else {
      this.addOddForSettlement({ oddId, type });
    }
  }

  @Action
  async setOddToOne(oddId: number) {
    const [err] = await to(settlementAntepostService.setOddToOne(oddId));
    if (err) return Promise.reject(err);
    this.updateOdd({ oddId, key: 'oddValue', value: 1 });
    return Promise.resolve();
  }

  @Action
  async resetOdd(oddId: number) {
    const [err] = await to(settlementAntepostService.resetOdd(oddId));
    if (err) return Promise.reject(err);
    this.updateOdd({ oddId, key: 'manualStatus', value: OddStatus.UNRESOLVED });
    return Promise.resolve();
  }

  @Action
  async resetAllBets(oddId: number) {
    const [err] = await to(settlementAntepostService.resetAllBets(oddId));
    if (err) return Promise.reject(err);
    this.resetAllAntepostBetsStatus(oddId);
    return Promise.resolve();
  }

  @Action
  async refetchPlayedOutcomes(intKey: number) {
    const [err, res] = await to(settlementAntepostService.refetchPlayedOutcomes(intKey));
    if (err) return Promise.reject(err);
    this.updateAntepostEvent({
      eventId: intKey,
      key: 'allBetsMap',
      newValue: objectKeysToCamelCase(res),
    });
  }
}

export const SettlementAntepostStore = getModule(SettlementAntepost);
