/* eslint-disable @typescript-eslint/camelcase */
import { api } from '@/lib/api';

const BASE_ROUTE = '/odds/events';

const getSports = () => {
  return api.get(`${BASE_ROUTE}/sports/`);
};

const addToOffer = (events: string[]) => {
  return api.put(`${BASE_ROUTE}/add-to-offer/`, { event_ids: events });
};

export const betRadarEventRepo = { getSports, addToOffer };
