import Empty from 'google-protobuf/google/protobuf/empty_pb';

import ProtobufApi from '@/lib/protobufApi';
import { getApiUrl } from '@/lib/urlBuilder';
import { OddsStreamServiceClient } from '@/proto/odds_stream/odds_stream_grpc_web_pb';
import { OddsServiceClient } from '@/proto/odds/odds_grpc_web_pb';
import { BORequestDate } from '@/proto/odds_stream/odds_stream_pb';

import { BetRadarEvent } from '../betRadarEvent/betRadarEvent.types';
import { GrpcActions } from './sport.types';
import { AntepostMarket, Antepost } from '@/modules/antepost/antepost.types';
import { PrematchEvent, LiveEvent } from '@/modules/event';
import { Player } from '@/modules/player';
import isObject from 'lodash/isObject';

/**
 * Place to define all GRPC calls related with Odds service
 */
class SportApi {
  private clientGo: any;
  private clientPy: any;

  private apiGo: ProtobufApi;
  private apiPy: ProtobufApi;

  constructor() {
    this.clientGo = new OddsStreamServiceClient(getApiUrl('GO'));
    this.clientPy = new OddsServiceClient(getApiUrl('PYTHON'));

    this.apiGo = new ProtobufApi(this.clientGo);
    this.apiPy = new ProtobufApi(this.clientPy);
  }

  /**
   * Fetch Events for Create Offer
   * @returns Promise<BetRadarEvent>
   */

  fetchCreateOfferEvents = () => {
    return this.fetchAllStream<BetRadarEvent>(
      GrpcActions.StreamGetEventsCreate,
      'eventsList',
      null
    );
  };

  /**
   * Fetch Events for Event Management
   * @returns Promise<FactoryEvent>
   */
  fetchOffer = () => {
    return this.fetchAllStream<PrematchEvent>(GrpcActions.StreamGetEventsOffer, 'eventsList', null);
  };

  /**
   * Fetch Events for Event Management
   * @returns Promise<FactoryEvent>
   */
  fetchMagazineEvents = () => {
    return this.fetchAllStream<PrematchEvent>(
      GrpcActions.StreamGetEventsMagazine,
      'eventsList',
      null
    );
  };

  /**
   * Fetch Prematch Events
   * @returns Promise<FactoryEvent>
   */
  fetchPrematch = () => {
    return this.fetchAllStream<PrematchEvent>(
      GrpcActions.StreamGetEventsPrematch,
      'eventsList',
      null
    );
  };

  /**
   * Fetch Prematch Events
   * @returns Promise<FactoryEvent>
   */
  fetchLive = (date: string) => {
    return this.fetchAllStream<LiveEvent>(GrpcActions.StreamGetEventsLive, 'eventsList', date);
  };

  /**
   * Fetch Antepost Markets
   * @returns Promise<AntepostMarket>
   */
  fetchAntepostMarkets = () => {
    return this.fetchAllRequest<AntepostMarket>(GrpcActions.GetAntepostMarketsBO);
  };

  /**
   * Fetch Antepost Offer
   * @returns Promise<Antepost>
   */
  fetchAntepostOffer = () => {
    return this.fetchAllStream<Antepost>(
      GrpcActions.StreamGetAntepostsOfferBO,
      'antepostsList',
      null
    );
  };

  /**
   * Fetch Antepost Prematch Offer
   * @returns Promise<Antepost>
   */
  fetchPrematchAnteposts = () => {
    return this.fetchAllStream<Antepost>(
      GrpcActions.StreamGetAntepostsPrematchBO,
      'antepostsList',
      null
    );
  };

  /**
   * Fetch Events for Settlement
   * @returns Promise<SettlementEvent>
   */
  fetchSettlementEvents = (
    streamParams:
      | string
      | {
          date: string;
          setParamFunc: string;
          value: boolean;
        }
      | null = null
  ) => {
    return this.fetchAllStream<any>(GrpcActions.StreamGetEventsSettle, 'eventsList', streamParams);
  };

  /**
   * Fetch Events for Settlement Who has Control Providers
   * @returns Promise<SettlementEvent>
   */
  getSettlementCtrlProviderEvents = (streamParams: string) => {
    return this.fetchAllStream<any>(GrpcActions.StreamGetCtrlEvents, 'eventsList', streamParams);
  };

  /**
   * Fetch Player Events for Settlement
   * @returns Promise<SettlementEvent>
   */
  fetchSettlementPlayerEvents = () => {
    return this.fetchAllStream<any>(GrpcActions.StreamGetPlayerEventsSettle, 'eventsList', null);
  };

  /**
   * Fetch Player Events for Settlement
   * @returns Promise<SettlementEvent>
   */
  fetchSettlementAntepostEvents = () => {
    return this.fetchAllStream<any>(
      GrpcActions.StreamGetAntepostEventsSettle,
      'antepostsList',
      null
    );
  };

  /**
   * Fetch Players Prematch Offer
   * @returns Promise<Player>
   */
  fetchPrematchPlayers = () => {
    return this.fetchAllStream<Player>(
      GrpcActions.StreamGetPlayerEventsPrematchBO,
      'prematchEventsList',
      null
    );
  };

  /**
   * Fetch Players Offer
   * @returns Promise<Player>
   */
  fetchPlayerEvents = () => {
    return this.fetchAllStream<Player>(
      GrpcActions.StreamGetPlayerEventsOfferBO,
      'prematchEventsList',
      null
    );
  };

  /**
   * Since GRPC streaming data, we don't need to update store on every bach of data
   * instead of that we will save data into temp. array and return all streamed data.
   * @param grpcAction
   * @returns Promise<T[]>
   */
  private fetchAllStream<T>(
    grpcAction: GrpcActions,
    eventAccesor: 'eventsList' | 'antepostsList' | 'prematchEventsList' = 'eventsList',
    streamParams: string | { date: string; value: boolean; setParamFunc: string } | null = null
  ): Promise<T[]> {
    let request: any;
    if (isObject(streamParams)) {
      request = new BORequestDate();
      request[streamParams.setParamFunc](streamParams.value);
      request.setDate(streamParams.date);
    } else if (typeof streamParams === 'string') {
      request = new BORequestDate();
      request.setDate(streamParams);
    } else {
      request = new Empty.Empty();
    }

    const response: T[] = [];

    return new Promise(resolve => {
      this.apiGo.stream(
        grpcAction,
        (data: any) => {
          const result = data.toObject();
          response.push(...result[eventAccesor]);
        },
        () => {
          resolve(response);
        },
        [request, {}]
      );
    });
  }

  private fetchAllRequest<T>(grpcAction: GrpcActions): Promise<T[]> {
    const request = new Empty.Empty();
    return this.apiPy.request(grpcAction, [request, {}]);
  }
}

export const sportProtoApi = new SportApi();
