import { sportProtoApi } from '../grpc/sport.api';
import { settlementAntepostRepo } from './settlementAntepost.repo';
import { AntepostBet } from '@/modules/antepost/antepost.types';
import { ResetAntepostByDateTimePayload, SettleAntepostOddsPayload } from '.';

class SettlementAntepost {
  getAntepostForSettlement() {
    return sportProtoApi.fetchSettlementAntepostEvents();
  }

  antepostSportFilter() {
    return settlementAntepostRepo.antepostSportFilter();
  }

  resetAllAntepostBets(intKey: number) {
    return settlementAntepostRepo.resetAllAntepostBets(intKey);
  }

  resetAntepostBetsByDateTime(intKey: number, payload: ResetAntepostByDateTimePayload) {
    return settlementAntepostRepo.resetAntepostBetsByDateTime(intKey, payload);
  }

  resetMarketAntepostBetsByDateTime(intKey: number, payload: ResetAntepostByDateTimePayload) {
    return settlementAntepostRepo.resetMarketAntepostBetsByDateTime(intKey, payload);
  }

  settleOdds(intKey: number, payload: SettleAntepostOddsPayload) {
    return settlementAntepostRepo.settleOdds(intKey, payload);
  }

  mapToArray = (list: any) => {
    return list.map((item: any) => item[1]);
  };

  formatEventId(intKey: number | string | null) {
    return `e_${intKey}`;
  }

  findNonSelectedActiveOutcomes(
    activeOutcomes: AntepostBet[],
    wonOdds: number[],
    lostOdds: number[],
    oddOne: number[]
  ) {
    const nonSelectedOutcomes: number[] = [];
    activeOutcomes.forEach(outcome => {
      if (
        !wonOdds.includes(outcome.intKey) &&
        !lostOdds.includes(outcome.intKey) &&
        !oddOne.includes(outcome.intKey)
      ) {
        nonSelectedOutcomes.push(outcome.intKey);
      }
    });
    return nonSelectedOutcomes;
  }

  setOddToOne(oddId: number) {
    return settlementAntepostRepo.setOddToOne(oddId);
  }

  resetOdd(oddId: number) {
    return settlementAntepostRepo.resetOdd(oddId);
  }

  resetAllBets(oddId: number) {
    return settlementAntepostRepo.resetAllBets(oddId);
  }

  refetchPlayedOutcomes(intKey: number) {
    return settlementAntepostRepo.refetchPlayedOutcomes(intKey);
  }
}

export const settlementAntepostService = new SettlementAntepost();
