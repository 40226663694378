import { api } from '@/lib/api';
import {
  AddPlayerOperatorNote,
  BulkChangePlayerTemplates,
  EditPlayerOddsPayload,
  UpdatePlayerEventTemplate,
  GeneratePlayerMarketOdds,
} from '.';
import { EventRequestSource, EventState } from '../event';
import { EditPlayersPayload, NewPlayer, Player, InputParams } from './player.types';

// Player Configuration

const getCompetitorPlayers = (competitorId: number): Promise<Player[]> => {
  return api.get(`/odds/competitors/${competitorId}/players/`, {
    cache: {
      maxAge: 0,
    },
  });
};

const savePlayers = (payload: NewPlayer[]) => {
  return api.post(`/odds/players/`, payload);
};

const editPlayers = (payload: EditPlayersPayload[]) => {
  return api.put(`/odds/players/edit/`, payload);
};

// Player Events

const getPlayerEvents = (payload: any, urlParams: any) => {
  return api.post('/odds/event-players/list/', payload, { params: urlParams });
};

const getPlayerEventsPrematch = (payload: any, urlParams: any) => {
  return api.post('/odds/event-players/list-prematch/', payload, { params: urlParams });
};

const updateNote = (eventId: number, payload: { note: string }) => {
  return api.put(`/odds/event-players/${eventId}/note/`, payload);
};

const addOperatorNote = (payload: AddPlayerOperatorNote) => {
  return api.post(`/odds/event-players/operator-note/`, payload);
};

const createOdd = (eventId: number, payload: EditPlayerOddsPayload) => {
  return api.put(`/odds/event-players/${eventId}/odds/`, payload);
};

const updateOdd = (oddId: number, limit: number, value: number, source: EventRequestSource) => {
  return api.put(`/odds/odd-players/${oddId}/`, { odd_value: value, limit, source });
};

const updateEventTemplates = (eventId: number, payload: UpdatePlayerEventTemplate) => {
  return api.put(`/odds/event-players/${eventId}/templates/`, payload);
};

const bulkChangeTemplates = (payload: BulkChangePlayerTemplates) => {
  return api.put(`/odds/event-players/bulk-templates/`, payload);
};

const generateOddsByInputParams = (eventId: number, payload: GeneratePlayerMarketOdds) => {
  return api.put(`/odds/event-players/${eventId}/generate-basketball-player-odds/`, payload);
};

const confirmGeneratedOdds = (eventId: number, payload: any) => {
  return api.put(`/odds/event-players/${eventId}/confirm-basketball-player-odds/`, payload);
};

const changeState = (events: number[], nextState: EventState) => {
  return api.put('/odds/event-players/change-state/', {
    event_player_ids: events,
    state: nextState,
  });
};

const suspendEvent = (eventIds: number[], nextValue: boolean) => {
  return api.put(`/odds/event-players/prematch-suspend/`, {
    is_suspended: nextValue,
    events: eventIds,
  });
};

const suspendMarket = (
  eventId: number,
  marketId: number,
  nextValue: boolean,
  suspendType: string
) => {
  return api.put(`/odds/event-players/${eventId}/market-event-suspend/`, {
    market_id: marketId,
    is_suspended: nextValue,
    suspend_type: suspendType,
    int_key: eventId,
  });
};

const suspendOdd = (oddId: string, value: boolean, intKey: number, suspendType: string) => {
  return api.put(`/odds/odd-players/${oddId}/suspend/`, {
    is_suspended: value,
    int_key: intKey,
    suspend_type: suspendType,
  });
};

const setOddPrematchMaxBet = (oddId: number, value: number) => {
  return api.put(`/odds/odd-players/${oddId}/prematch-max-amount/`, {
    prematch_max_amount: value,
  });
};

const setMarketPrematchMaxBet = (eventId: number, marketId: number, value: number) => {
  return api.put(`/odds/event-players/${eventId}/prematch-market-max-amount/`, {
    market_id: marketId,
    prematch_max_amount: value,
  });
};

const updatePrematchMaxAmount = ({ eventId, value }: any) => {
  return api.put(`/odds/event-players/${eventId}/prematch-max-amount/`, {
    prematch_max_amount: value,
  });
};

const getPlayerInputParams = (intKey: number) => {
  return api.get<InputParams>(`/odds/event-players/${intKey}/get-single-player-event/`, {
    cache: {
      maxAge: 0,
    },
  });
};

export const playerRepo = {
  savePlayers,
  editPlayers,
  getCompetitorPlayers,
  changeState,
  updateNote,
  updateOdd,
  createOdd,
  getPlayerEvents,
  addOperatorNote,
  bulkChangeTemplates,
  confirmGeneratedOdds,
  generateOddsByInputParams,
  updateEventTemplates,
  suspendEvent,
  suspendMarket,
  suspendOdd,
  setOddPrematchMaxBet,
  setMarketPrematchMaxBet,
  updatePrematchMaxAmount,
  getPlayerEventsPrematch,
  getPlayerInputParams,
};
