// source: proto/odds/odds.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.odds.Antepost', null, global);
goog.exportSymbol('proto.odds.AntepostBet', null, global);
goog.exportSymbol('proto.odds.AntepostMarket', null, global);
goog.exportSymbol('proto.odds.AntepostMarketBO', null, global);
goog.exportSymbol('proto.odds.AntepostMarketSC', null, global);
goog.exportSymbol('proto.odds.AntepostSportBO', null, global);
goog.exportSymbol('proto.odds.BaseMarketGroup', null, global);
goog.exportSymbol('proto.odds.Competition', null, global);
goog.exportSymbol('proto.odds.CompetitionSC', null, global);
goog.exportSymbol('proto.odds.CurrentPhase', null, global);
goog.exportSymbol('proto.odds.Event', null, global);
goog.exportSymbol('proto.odds.EventPlayer', null, global);
goog.exportSymbol('proto.odds.EventPlayerSC', null, global);
goog.exportSymbol('proto.odds.EventSC', null, global);
goog.exportSymbol('proto.odds.GetAntepostMarketsBOResponse', null, global);
goog.exportSymbol('proto.odds.GetAntepostMarketsSCResponse', null, global);
goog.exportSymbol('proto.odds.GetCompetitionsSCResponse', null, global);
goog.exportSymbol('proto.odds.GetEventsResponse', null, global);
goog.exportSymbol('proto.odds.GetEventsResponseSC', null, global);
goog.exportSymbol('proto.odds.GetLocationsSCResponse', null, global);
goog.exportSymbol('proto.odds.GetMarketGroupsSCResponse', null, global);
goog.exportSymbol('proto.odds.GetOutcomesSCResponse', null, global);
goog.exportSymbol('proto.odds.GetPlayerEventsResponse', null, global);
goog.exportSymbol('proto.odds.GetPlayerEventsResponseSC', null, global);
goog.exportSymbol('proto.odds.GetPlayerOutcomesSCResponse', null, global);
goog.exportSymbol('proto.odds.GetPlayerSportsMarketResponse', null, global);
goog.exportSymbol('proto.odds.GetPlayerSportsMarketSCResponse', null, global);
goog.exportSymbol('proto.odds.GetSportsMarketGroupsResponse', null, global);
goog.exportSymbol('proto.odds.GetSportsMarketSCResponse', null, global);
goog.exportSymbol('proto.odds.GetSportsSCResponse', null, global);
goog.exportSymbol('proto.odds.InitialBasicOffer', null, global);
goog.exportSymbol('proto.odds.InitialBasicOfferMarket', null, global);
goog.exportSymbol('proto.odds.Location', null, global);
goog.exportSymbol('proto.odds.LocationSC', null, global);
goog.exportSymbol('proto.odds.Market', null, global);
goog.exportSymbol('proto.odds.MarketGroup', null, global);
goog.exportSymbol('proto.odds.MarketGroupSC', null, global);
goog.exportSymbol('proto.odds.MarketSC', null, global);
goog.exportSymbol('proto.odds.Odd', null, global);
goog.exportSymbol('proto.odds.OddPlayer', null, global);
goog.exportSymbol('proto.odds.OddPlayerSC', null, global);
goog.exportSymbol('proto.odds.OddSC', null, global);
goog.exportSymbol('proto.odds.Outcome', null, global);
goog.exportSymbol('proto.odds.OutcomePlayer', null, global);
goog.exportSymbol('proto.odds.OutcomeSC', null, global);
goog.exportSymbol('proto.odds.PlayerMarket', null, global);
goog.exportSymbol('proto.odds.PlayerMarketSC', null, global);
goog.exportSymbol('proto.odds.PlayerOutcomeSC', null, global);
goog.exportSymbol('proto.odds.PlayerSportMarket', null, global);
goog.exportSymbol('proto.odds.PlayerSportMarketSC', null, global);
goog.exportSymbol('proto.odds.Sport', null, global);
goog.exportSymbol('proto.odds.SportMarketSC', null, global);
goog.exportSymbol('proto.odds.SportSC', null, global);
goog.exportSymbol('proto.odds.TemplateData', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetMarketGroupsSCResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetMarketGroupsSCResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetMarketGroupsSCResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetMarketGroupsSCResponse.displayName = 'proto.odds.GetMarketGroupsSCResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetEventsResponse.displayName = 'proto.odds.GetEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetPlayerEventsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetPlayerEventsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetPlayerEventsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetPlayerEventsResponse.displayName = 'proto.odds.GetPlayerEventsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetAntepostMarketsSCResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetAntepostMarketsSCResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetAntepostMarketsSCResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetAntepostMarketsSCResponse.displayName = 'proto.odds.GetAntepostMarketsSCResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetEventsResponseSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetEventsResponseSC.repeatedFields_, null);
};
goog.inherits(proto.odds.GetEventsResponseSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetEventsResponseSC.displayName = 'proto.odds.GetEventsResponseSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetPlayerEventsResponseSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetPlayerEventsResponseSC.repeatedFields_, null);
};
goog.inherits(proto.odds.GetPlayerEventsResponseSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetPlayerEventsResponseSC.displayName = 'proto.odds.GetPlayerEventsResponseSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetSportsMarketGroupsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetSportsMarketGroupsResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetSportsMarketGroupsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetSportsMarketGroupsResponse.displayName = 'proto.odds.GetSportsMarketGroupsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetSportsSCResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetSportsSCResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetSportsSCResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetSportsSCResponse.displayName = 'proto.odds.GetSportsSCResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetLocationsSCResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetLocationsSCResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetLocationsSCResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetLocationsSCResponse.displayName = 'proto.odds.GetLocationsSCResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetCompetitionsSCResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetCompetitionsSCResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetCompetitionsSCResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetCompetitionsSCResponse.displayName = 'proto.odds.GetCompetitionsSCResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetSportsMarketSCResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetSportsMarketSCResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetSportsMarketSCResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetSportsMarketSCResponse.displayName = 'proto.odds.GetSportsMarketSCResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetPlayerSportsMarketSCResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetPlayerSportsMarketSCResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetPlayerSportsMarketSCResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetPlayerSportsMarketSCResponse.displayName = 'proto.odds.GetPlayerSportsMarketSCResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetOutcomesSCResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetOutcomesSCResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetOutcomesSCResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetOutcomesSCResponse.displayName = 'proto.odds.GetOutcomesSCResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetPlayerOutcomesSCResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetPlayerOutcomesSCResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetPlayerOutcomesSCResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetPlayerOutcomesSCResponse.displayName = 'proto.odds.GetPlayerOutcomesSCResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetPlayerSportsMarketResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetPlayerSportsMarketResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetPlayerSportsMarketResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetPlayerSportsMarketResponse.displayName = 'proto.odds.GetPlayerSportsMarketResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.GetAntepostMarketsBOResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.GetAntepostMarketsBOResponse.repeatedFields_, null);
};
goog.inherits(proto.odds.GetAntepostMarketsBOResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.GetAntepostMarketsBOResponse.displayName = 'proto.odds.GetAntepostMarketsBOResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.BaseMarketGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.BaseMarketGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.BaseMarketGroup.displayName = 'proto.odds.BaseMarketGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.MarketGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.MarketGroup.repeatedFields_, null);
};
goog.inherits(proto.odds.MarketGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.MarketGroup.displayName = 'proto.odds.MarketGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Market = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.Market.repeatedFields_, null);
};
goog.inherits(proto.odds.Market, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Market.displayName = 'proto.odds.Market';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.MarketSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.MarketSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.MarketSC.displayName = 'proto.odds.MarketSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.PlayerMarketSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.PlayerMarketSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.PlayerMarketSC.displayName = 'proto.odds.PlayerMarketSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.PlayerMarket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.PlayerMarket.repeatedFields_, null);
};
goog.inherits(proto.odds.PlayerMarket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.PlayerMarket.displayName = 'proto.odds.PlayerMarket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.OutcomePlayer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.OutcomePlayer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.OutcomePlayer.displayName = 'proto.odds.OutcomePlayer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Outcome = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.Outcome, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Outcome.displayName = 'proto.odds.Outcome';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.OutcomeSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.OutcomeSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.OutcomeSC.displayName = 'proto.odds.OutcomeSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.PlayerOutcomeSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.PlayerOutcomeSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.PlayerOutcomeSC.displayName = 'proto.odds.PlayerOutcomeSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Sport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.Sport.repeatedFields_, null);
};
goog.inherits(proto.odds.Sport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Sport.displayName = 'proto.odds.Sport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.AntepostSportBO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.AntepostSportBO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.AntepostSportBO.displayName = 'proto.odds.AntepostSportBO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.InitialBasicOffer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.InitialBasicOffer.repeatedFields_, null);
};
goog.inherits(proto.odds.InitialBasicOffer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.InitialBasicOffer.displayName = 'proto.odds.InitialBasicOffer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.InitialBasicOfferMarket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.InitialBasicOfferMarket.repeatedFields_, null);
};
goog.inherits(proto.odds.InitialBasicOfferMarket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.InitialBasicOfferMarket.displayName = 'proto.odds.InitialBasicOfferMarket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SportSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.SportSC.repeatedFields_, null);
};
goog.inherits(proto.odds.SportSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SportSC.displayName = 'proto.odds.SportSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.LocationSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.LocationSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.LocationSC.displayName = 'proto.odds.LocationSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.CompetitionSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.CompetitionSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.CompetitionSC.displayName = 'proto.odds.CompetitionSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.MarketGroupSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.MarketGroupSC.repeatedFields_, null);
};
goog.inherits(proto.odds.MarketGroupSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.MarketGroupSC.displayName = 'proto.odds.MarketGroupSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.SportMarketSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.SportMarketSC.repeatedFields_, null);
};
goog.inherits(proto.odds.SportMarketSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.SportMarketSC.displayName = 'proto.odds.SportMarketSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.PlayerSportMarketSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.PlayerSportMarketSC.repeatedFields_, null);
};
goog.inherits(proto.odds.PlayerSportMarketSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.PlayerSportMarketSC.displayName = 'proto.odds.PlayerSportMarketSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.PlayerSportMarket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.PlayerSportMarket.repeatedFields_, null);
};
goog.inherits(proto.odds.PlayerSportMarket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.PlayerSportMarket.displayName = 'proto.odds.PlayerSportMarket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Location = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.Location.repeatedFields_, null);
};
goog.inherits(proto.odds.Location, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Location.displayName = 'proto.odds.Location';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Competition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.Competition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Competition.displayName = 'proto.odds.Competition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Odd = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.Odd, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Odd.displayName = 'proto.odds.Odd';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.OddPlayer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.OddPlayer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.OddPlayer.displayName = 'proto.odds.OddPlayer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.OddSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.OddSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.OddSC.displayName = 'proto.odds.OddSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.OddPlayerSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.OddPlayerSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.OddPlayerSC.displayName = 'proto.odds.OddPlayerSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.TemplateData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.TemplateData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.TemplateData.displayName = 'proto.odds.TemplateData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.CurrentPhase = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.CurrentPhase, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.CurrentPhase.displayName = 'proto.odds.CurrentPhase';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Event = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.Event.repeatedFields_, null);
};
goog.inherits(proto.odds.Event, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Event.displayName = 'proto.odds.Event';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.AntepostMarket = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.AntepostMarket, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.AntepostMarket.displayName = 'proto.odds.AntepostMarket';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.AntepostMarketBO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.AntepostMarketBO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.AntepostMarketBO.displayName = 'proto.odds.AntepostMarketBO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.AntepostMarketSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.AntepostMarketSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.AntepostMarketSC.displayName = 'proto.odds.AntepostMarketSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.Antepost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.Antepost.repeatedFields_, null);
};
goog.inherits(proto.odds.Antepost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.Antepost.displayName = 'proto.odds.Antepost';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.AntepostBet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.odds.AntepostBet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.AntepostBet.displayName = 'proto.odds.AntepostBet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.EventPlayer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.EventPlayer.repeatedFields_, null);
};
goog.inherits(proto.odds.EventPlayer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.EventPlayer.displayName = 'proto.odds.EventPlayer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.EventSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.EventSC.repeatedFields_, null);
};
goog.inherits(proto.odds.EventSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.EventSC.displayName = 'proto.odds.EventSC';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.odds.EventPlayerSC = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.odds.EventPlayerSC.repeatedFields_, null);
};
goog.inherits(proto.odds.EventPlayerSC, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.odds.EventPlayerSC.displayName = 'proto.odds.EventPlayerSC';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetMarketGroupsSCResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetMarketGroupsSCResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetMarketGroupsSCResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetMarketGroupsSCResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetMarketGroupsSCResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketGroupsList: jspb.Message.toObjectList(msg.getMarketGroupsList(),
    proto.odds.BaseMarketGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetMarketGroupsSCResponse}
 */
proto.odds.GetMarketGroupsSCResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetMarketGroupsSCResponse;
  return proto.odds.GetMarketGroupsSCResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetMarketGroupsSCResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetMarketGroupsSCResponse}
 */
proto.odds.GetMarketGroupsSCResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.BaseMarketGroup;
      reader.readMessage(value,proto.odds.BaseMarketGroup.deserializeBinaryFromReader);
      msg.addMarketGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetMarketGroupsSCResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetMarketGroupsSCResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetMarketGroupsSCResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetMarketGroupsSCResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.BaseMarketGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated BaseMarketGroup market_groups = 1;
 * @return {!Array<!proto.odds.BaseMarketGroup>}
 */
proto.odds.GetMarketGroupsSCResponse.prototype.getMarketGroupsList = function() {
  return /** @type{!Array<!proto.odds.BaseMarketGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.BaseMarketGroup, 1));
};


/**
 * @param {!Array<!proto.odds.BaseMarketGroup>} value
 * @return {!proto.odds.GetMarketGroupsSCResponse} returns this
*/
proto.odds.GetMarketGroupsSCResponse.prototype.setMarketGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.BaseMarketGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.BaseMarketGroup}
 */
proto.odds.GetMarketGroupsSCResponse.prototype.addMarketGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.BaseMarketGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetMarketGroupsSCResponse} returns this
 */
proto.odds.GetMarketGroupsSCResponse.prototype.clearMarketGroupsList = function() {
  return this.setMarketGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetEventsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    prematchEventsList: jspb.Message.toObjectList(msg.getPrematchEventsList(),
    proto.odds.Event.toObject, includeInstance),
    liveEventsList: jspb.Message.toObjectList(msg.getLiveEventsList(),
    proto.odds.Event.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetEventsResponse}
 */
proto.odds.GetEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetEventsResponse;
  return proto.odds.GetEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetEventsResponse}
 */
proto.odds.GetEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.Event;
      reader.readMessage(value,proto.odds.Event.deserializeBinaryFromReader);
      msg.addPrematchEvents(value);
      break;
    case 2:
      var value = new proto.odds.Event;
      reader.readMessage(value,proto.odds.Event.deserializeBinaryFromReader);
      msg.addLiveEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrematchEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.Event.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.odds.Event.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Event prematch_events = 1;
 * @return {!Array<!proto.odds.Event>}
 */
proto.odds.GetEventsResponse.prototype.getPrematchEventsList = function() {
  return /** @type{!Array<!proto.odds.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Event, 1));
};


/**
 * @param {!Array<!proto.odds.Event>} value
 * @return {!proto.odds.GetEventsResponse} returns this
*/
proto.odds.GetEventsResponse.prototype.setPrematchEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Event}
 */
proto.odds.GetEventsResponse.prototype.addPrematchEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetEventsResponse} returns this
 */
proto.odds.GetEventsResponse.prototype.clearPrematchEventsList = function() {
  return this.setPrematchEventsList([]);
};


/**
 * repeated Event live_events = 2;
 * @return {!Array<!proto.odds.Event>}
 */
proto.odds.GetEventsResponse.prototype.getLiveEventsList = function() {
  return /** @type{!Array<!proto.odds.Event>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Event, 2));
};


/**
 * @param {!Array<!proto.odds.Event>} value
 * @return {!proto.odds.GetEventsResponse} returns this
*/
proto.odds.GetEventsResponse.prototype.setLiveEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.odds.Event=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Event}
 */
proto.odds.GetEventsResponse.prototype.addLiveEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.odds.Event, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetEventsResponse} returns this
 */
proto.odds.GetEventsResponse.prototype.clearLiveEventsList = function() {
  return this.setLiveEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetPlayerEventsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetPlayerEventsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetPlayerEventsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetPlayerEventsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPlayerEventsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    prematchEventsList: jspb.Message.toObjectList(msg.getPrematchEventsList(),
    proto.odds.EventPlayer.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetPlayerEventsResponse}
 */
proto.odds.GetPlayerEventsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetPlayerEventsResponse;
  return proto.odds.GetPlayerEventsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetPlayerEventsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetPlayerEventsResponse}
 */
proto.odds.GetPlayerEventsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.EventPlayer;
      reader.readMessage(value,proto.odds.EventPlayer.deserializeBinaryFromReader);
      msg.addPrematchEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetPlayerEventsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetPlayerEventsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetPlayerEventsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPlayerEventsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrematchEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.EventPlayer.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventPlayer prematch_events = 1;
 * @return {!Array<!proto.odds.EventPlayer>}
 */
proto.odds.GetPlayerEventsResponse.prototype.getPrematchEventsList = function() {
  return /** @type{!Array<!proto.odds.EventPlayer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.EventPlayer, 1));
};


/**
 * @param {!Array<!proto.odds.EventPlayer>} value
 * @return {!proto.odds.GetPlayerEventsResponse} returns this
*/
proto.odds.GetPlayerEventsResponse.prototype.setPrematchEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.EventPlayer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.EventPlayer}
 */
proto.odds.GetPlayerEventsResponse.prototype.addPrematchEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.EventPlayer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetPlayerEventsResponse} returns this
 */
proto.odds.GetPlayerEventsResponse.prototype.clearPrematchEventsList = function() {
  return this.setPrematchEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetAntepostMarketsSCResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetAntepostMarketsSCResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetAntepostMarketsSCResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetAntepostMarketsSCResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetAntepostMarketsSCResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    antepostMarketsList: jspb.Message.toObjectList(msg.getAntepostMarketsList(),
    proto.odds.AntepostMarketSC.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetAntepostMarketsSCResponse}
 */
proto.odds.GetAntepostMarketsSCResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetAntepostMarketsSCResponse;
  return proto.odds.GetAntepostMarketsSCResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetAntepostMarketsSCResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetAntepostMarketsSCResponse}
 */
proto.odds.GetAntepostMarketsSCResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.AntepostMarketSC;
      reader.readMessage(value,proto.odds.AntepostMarketSC.deserializeBinaryFromReader);
      msg.addAntepostMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetAntepostMarketsSCResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetAntepostMarketsSCResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetAntepostMarketsSCResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetAntepostMarketsSCResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAntepostMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.AntepostMarketSC.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AntepostMarketSC antepost_markets = 1;
 * @return {!Array<!proto.odds.AntepostMarketSC>}
 */
proto.odds.GetAntepostMarketsSCResponse.prototype.getAntepostMarketsList = function() {
  return /** @type{!Array<!proto.odds.AntepostMarketSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.AntepostMarketSC, 1));
};


/**
 * @param {!Array<!proto.odds.AntepostMarketSC>} value
 * @return {!proto.odds.GetAntepostMarketsSCResponse} returns this
*/
proto.odds.GetAntepostMarketsSCResponse.prototype.setAntepostMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.AntepostMarketSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.AntepostMarketSC}
 */
proto.odds.GetAntepostMarketsSCResponse.prototype.addAntepostMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.AntepostMarketSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetAntepostMarketsSCResponse} returns this
 */
proto.odds.GetAntepostMarketsSCResponse.prototype.clearAntepostMarketsList = function() {
  return this.setAntepostMarketsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetEventsResponseSC.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetEventsResponseSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetEventsResponseSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetEventsResponseSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsResponseSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    prematchEventsList: jspb.Message.toObjectList(msg.getPrematchEventsList(),
    proto.odds.EventSC.toObject, includeInstance),
    liveEventsList: jspb.Message.toObjectList(msg.getLiveEventsList(),
    proto.odds.EventSC.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetEventsResponseSC}
 */
proto.odds.GetEventsResponseSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetEventsResponseSC;
  return proto.odds.GetEventsResponseSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetEventsResponseSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetEventsResponseSC}
 */
proto.odds.GetEventsResponseSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.EventSC;
      reader.readMessage(value,proto.odds.EventSC.deserializeBinaryFromReader);
      msg.addPrematchEvents(value);
      break;
    case 2:
      var value = new proto.odds.EventSC;
      reader.readMessage(value,proto.odds.EventSC.deserializeBinaryFromReader);
      msg.addLiveEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetEventsResponseSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetEventsResponseSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetEventsResponseSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetEventsResponseSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrematchEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.EventSC.serializeBinaryToWriter
    );
  }
  f = message.getLiveEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.odds.EventSC.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventSC prematch_events = 1;
 * @return {!Array<!proto.odds.EventSC>}
 */
proto.odds.GetEventsResponseSC.prototype.getPrematchEventsList = function() {
  return /** @type{!Array<!proto.odds.EventSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.EventSC, 1));
};


/**
 * @param {!Array<!proto.odds.EventSC>} value
 * @return {!proto.odds.GetEventsResponseSC} returns this
*/
proto.odds.GetEventsResponseSC.prototype.setPrematchEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.EventSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.EventSC}
 */
proto.odds.GetEventsResponseSC.prototype.addPrematchEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.EventSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetEventsResponseSC} returns this
 */
proto.odds.GetEventsResponseSC.prototype.clearPrematchEventsList = function() {
  return this.setPrematchEventsList([]);
};


/**
 * repeated EventSC live_events = 2;
 * @return {!Array<!proto.odds.EventSC>}
 */
proto.odds.GetEventsResponseSC.prototype.getLiveEventsList = function() {
  return /** @type{!Array<!proto.odds.EventSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.EventSC, 2));
};


/**
 * @param {!Array<!proto.odds.EventSC>} value
 * @return {!proto.odds.GetEventsResponseSC} returns this
*/
proto.odds.GetEventsResponseSC.prototype.setLiveEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.odds.EventSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.EventSC}
 */
proto.odds.GetEventsResponseSC.prototype.addLiveEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.odds.EventSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetEventsResponseSC} returns this
 */
proto.odds.GetEventsResponseSC.prototype.clearLiveEventsList = function() {
  return this.setLiveEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetPlayerEventsResponseSC.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetPlayerEventsResponseSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetPlayerEventsResponseSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetPlayerEventsResponseSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPlayerEventsResponseSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    prematchEventsList: jspb.Message.toObjectList(msg.getPrematchEventsList(),
    proto.odds.EventPlayerSC.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetPlayerEventsResponseSC}
 */
proto.odds.GetPlayerEventsResponseSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetPlayerEventsResponseSC;
  return proto.odds.GetPlayerEventsResponseSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetPlayerEventsResponseSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetPlayerEventsResponseSC}
 */
proto.odds.GetPlayerEventsResponseSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.EventPlayerSC;
      reader.readMessage(value,proto.odds.EventPlayerSC.deserializeBinaryFromReader);
      msg.addPrematchEvents(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetPlayerEventsResponseSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetPlayerEventsResponseSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetPlayerEventsResponseSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPlayerEventsResponseSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrematchEventsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.EventPlayerSC.serializeBinaryToWriter
    );
  }
};


/**
 * repeated EventPlayerSC prematch_events = 1;
 * @return {!Array<!proto.odds.EventPlayerSC>}
 */
proto.odds.GetPlayerEventsResponseSC.prototype.getPrematchEventsList = function() {
  return /** @type{!Array<!proto.odds.EventPlayerSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.EventPlayerSC, 1));
};


/**
 * @param {!Array<!proto.odds.EventPlayerSC>} value
 * @return {!proto.odds.GetPlayerEventsResponseSC} returns this
*/
proto.odds.GetPlayerEventsResponseSC.prototype.setPrematchEventsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.EventPlayerSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.EventPlayerSC}
 */
proto.odds.GetPlayerEventsResponseSC.prototype.addPrematchEvents = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.EventPlayerSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetPlayerEventsResponseSC} returns this
 */
proto.odds.GetPlayerEventsResponseSC.prototype.clearPrematchEventsList = function() {
  return this.setPrematchEventsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetSportsMarketGroupsResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetSportsMarketGroupsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetSportsMarketGroupsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetSportsMarketGroupsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetSportsMarketGroupsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketGroupsList: jspb.Message.toObjectList(msg.getMarketGroupsList(),
    proto.odds.MarketGroup.toObject, includeInstance),
    sportsList: jspb.Message.toObjectList(msg.getSportsList(),
    proto.odds.Sport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetSportsMarketGroupsResponse}
 */
proto.odds.GetSportsMarketGroupsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetSportsMarketGroupsResponse;
  return proto.odds.GetSportsMarketGroupsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetSportsMarketGroupsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetSportsMarketGroupsResponse}
 */
proto.odds.GetSportsMarketGroupsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.MarketGroup;
      reader.readMessage(value,proto.odds.MarketGroup.deserializeBinaryFromReader);
      msg.addMarketGroups(value);
      break;
    case 2:
      var value = new proto.odds.Sport;
      reader.readMessage(value,proto.odds.Sport.deserializeBinaryFromReader);
      msg.addSports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetSportsMarketGroupsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetSportsMarketGroupsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetSportsMarketGroupsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetSportsMarketGroupsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.MarketGroup.serializeBinaryToWriter
    );
  }
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.odds.Sport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MarketGroup market_groups = 1;
 * @return {!Array<!proto.odds.MarketGroup>}
 */
proto.odds.GetSportsMarketGroupsResponse.prototype.getMarketGroupsList = function() {
  return /** @type{!Array<!proto.odds.MarketGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.MarketGroup, 1));
};


/**
 * @param {!Array<!proto.odds.MarketGroup>} value
 * @return {!proto.odds.GetSportsMarketGroupsResponse} returns this
*/
proto.odds.GetSportsMarketGroupsResponse.prototype.setMarketGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.MarketGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.MarketGroup}
 */
proto.odds.GetSportsMarketGroupsResponse.prototype.addMarketGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.MarketGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetSportsMarketGroupsResponse} returns this
 */
proto.odds.GetSportsMarketGroupsResponse.prototype.clearMarketGroupsList = function() {
  return this.setMarketGroupsList([]);
};


/**
 * repeated Sport sports = 2;
 * @return {!Array<!proto.odds.Sport>}
 */
proto.odds.GetSportsMarketGroupsResponse.prototype.getSportsList = function() {
  return /** @type{!Array<!proto.odds.Sport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Sport, 2));
};


/**
 * @param {!Array<!proto.odds.Sport>} value
 * @return {!proto.odds.GetSportsMarketGroupsResponse} returns this
*/
proto.odds.GetSportsMarketGroupsResponse.prototype.setSportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.odds.Sport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Sport}
 */
proto.odds.GetSportsMarketGroupsResponse.prototype.addSports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.odds.Sport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetSportsMarketGroupsResponse} returns this
 */
proto.odds.GetSportsMarketGroupsResponse.prototype.clearSportsList = function() {
  return this.setSportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetSportsSCResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetSportsSCResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetSportsSCResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetSportsSCResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetSportsSCResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportsList: jspb.Message.toObjectList(msg.getSportsList(),
    proto.odds.SportSC.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetSportsSCResponse}
 */
proto.odds.GetSportsSCResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetSportsSCResponse;
  return proto.odds.GetSportsSCResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetSportsSCResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetSportsSCResponse}
 */
proto.odds.GetSportsSCResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.SportSC;
      reader.readMessage(value,proto.odds.SportSC.deserializeBinaryFromReader);
      msg.addSports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetSportsSCResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetSportsSCResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetSportsSCResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetSportsSCResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.SportSC.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SportSC sports = 1;
 * @return {!Array<!proto.odds.SportSC>}
 */
proto.odds.GetSportsSCResponse.prototype.getSportsList = function() {
  return /** @type{!Array<!proto.odds.SportSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.SportSC, 1));
};


/**
 * @param {!Array<!proto.odds.SportSC>} value
 * @return {!proto.odds.GetSportsSCResponse} returns this
*/
proto.odds.GetSportsSCResponse.prototype.setSportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.SportSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.SportSC}
 */
proto.odds.GetSportsSCResponse.prototype.addSports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.SportSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetSportsSCResponse} returns this
 */
proto.odds.GetSportsSCResponse.prototype.clearSportsList = function() {
  return this.setSportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetLocationsSCResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetLocationsSCResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetLocationsSCResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetLocationsSCResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetLocationsSCResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto.odds.LocationSC.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetLocationsSCResponse}
 */
proto.odds.GetLocationsSCResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetLocationsSCResponse;
  return proto.odds.GetLocationsSCResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetLocationsSCResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetLocationsSCResponse}
 */
proto.odds.GetLocationsSCResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.LocationSC;
      reader.readMessage(value,proto.odds.LocationSC.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetLocationsSCResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetLocationsSCResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetLocationsSCResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetLocationsSCResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.LocationSC.serializeBinaryToWriter
    );
  }
};


/**
 * repeated LocationSC locations = 1;
 * @return {!Array<!proto.odds.LocationSC>}
 */
proto.odds.GetLocationsSCResponse.prototype.getLocationsList = function() {
  return /** @type{!Array<!proto.odds.LocationSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.LocationSC, 1));
};


/**
 * @param {!Array<!proto.odds.LocationSC>} value
 * @return {!proto.odds.GetLocationsSCResponse} returns this
*/
proto.odds.GetLocationsSCResponse.prototype.setLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.LocationSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.LocationSC}
 */
proto.odds.GetLocationsSCResponse.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.LocationSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetLocationsSCResponse} returns this
 */
proto.odds.GetLocationsSCResponse.prototype.clearLocationsList = function() {
  return this.setLocationsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetCompetitionsSCResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetCompetitionsSCResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetCompetitionsSCResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetCompetitionsSCResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetCompetitionsSCResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionsList: jspb.Message.toObjectList(msg.getCompetitionsList(),
    proto.odds.CompetitionSC.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetCompetitionsSCResponse}
 */
proto.odds.GetCompetitionsSCResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetCompetitionsSCResponse;
  return proto.odds.GetCompetitionsSCResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetCompetitionsSCResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetCompetitionsSCResponse}
 */
proto.odds.GetCompetitionsSCResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.CompetitionSC;
      reader.readMessage(value,proto.odds.CompetitionSC.deserializeBinaryFromReader);
      msg.addCompetitions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetCompetitionsSCResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetCompetitionsSCResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetCompetitionsSCResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetCompetitionsSCResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.CompetitionSC.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CompetitionSC competitions = 1;
 * @return {!Array<!proto.odds.CompetitionSC>}
 */
proto.odds.GetCompetitionsSCResponse.prototype.getCompetitionsList = function() {
  return /** @type{!Array<!proto.odds.CompetitionSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.CompetitionSC, 1));
};


/**
 * @param {!Array<!proto.odds.CompetitionSC>} value
 * @return {!proto.odds.GetCompetitionsSCResponse} returns this
*/
proto.odds.GetCompetitionsSCResponse.prototype.setCompetitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.CompetitionSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.CompetitionSC}
 */
proto.odds.GetCompetitionsSCResponse.prototype.addCompetitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.CompetitionSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetCompetitionsSCResponse} returns this
 */
proto.odds.GetCompetitionsSCResponse.prototype.clearCompetitionsList = function() {
  return this.setCompetitionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetSportsMarketSCResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetSportsMarketSCResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetSportsMarketSCResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetSportsMarketSCResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetSportsMarketSCResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportsList: jspb.Message.toObjectList(msg.getSportsList(),
    proto.odds.SportMarketSC.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetSportsMarketSCResponse}
 */
proto.odds.GetSportsMarketSCResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetSportsMarketSCResponse;
  return proto.odds.GetSportsMarketSCResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetSportsMarketSCResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetSportsMarketSCResponse}
 */
proto.odds.GetSportsMarketSCResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.SportMarketSC;
      reader.readMessage(value,proto.odds.SportMarketSC.deserializeBinaryFromReader);
      msg.addSports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetSportsMarketSCResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetSportsMarketSCResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetSportsMarketSCResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetSportsMarketSCResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.SportMarketSC.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SportMarketSC sports = 1;
 * @return {!Array<!proto.odds.SportMarketSC>}
 */
proto.odds.GetSportsMarketSCResponse.prototype.getSportsList = function() {
  return /** @type{!Array<!proto.odds.SportMarketSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.SportMarketSC, 1));
};


/**
 * @param {!Array<!proto.odds.SportMarketSC>} value
 * @return {!proto.odds.GetSportsMarketSCResponse} returns this
*/
proto.odds.GetSportsMarketSCResponse.prototype.setSportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.SportMarketSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.SportMarketSC}
 */
proto.odds.GetSportsMarketSCResponse.prototype.addSports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.SportMarketSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetSportsMarketSCResponse} returns this
 */
proto.odds.GetSportsMarketSCResponse.prototype.clearSportsList = function() {
  return this.setSportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetPlayerSportsMarketSCResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetPlayerSportsMarketSCResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetPlayerSportsMarketSCResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetPlayerSportsMarketSCResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPlayerSportsMarketSCResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportsList: jspb.Message.toObjectList(msg.getSportsList(),
    proto.odds.PlayerSportMarketSC.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetPlayerSportsMarketSCResponse}
 */
proto.odds.GetPlayerSportsMarketSCResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetPlayerSportsMarketSCResponse;
  return proto.odds.GetPlayerSportsMarketSCResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetPlayerSportsMarketSCResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetPlayerSportsMarketSCResponse}
 */
proto.odds.GetPlayerSportsMarketSCResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.PlayerSportMarketSC;
      reader.readMessage(value,proto.odds.PlayerSportMarketSC.deserializeBinaryFromReader);
      msg.addSports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetPlayerSportsMarketSCResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetPlayerSportsMarketSCResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetPlayerSportsMarketSCResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPlayerSportsMarketSCResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.PlayerSportMarketSC.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PlayerSportMarketSC sports = 1;
 * @return {!Array<!proto.odds.PlayerSportMarketSC>}
 */
proto.odds.GetPlayerSportsMarketSCResponse.prototype.getSportsList = function() {
  return /** @type{!Array<!proto.odds.PlayerSportMarketSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.PlayerSportMarketSC, 1));
};


/**
 * @param {!Array<!proto.odds.PlayerSportMarketSC>} value
 * @return {!proto.odds.GetPlayerSportsMarketSCResponse} returns this
*/
proto.odds.GetPlayerSportsMarketSCResponse.prototype.setSportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.PlayerSportMarketSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.PlayerSportMarketSC}
 */
proto.odds.GetPlayerSportsMarketSCResponse.prototype.addSports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.PlayerSportMarketSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetPlayerSportsMarketSCResponse} returns this
 */
proto.odds.GetPlayerSportsMarketSCResponse.prototype.clearSportsList = function() {
  return this.setSportsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetOutcomesSCResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetOutcomesSCResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetOutcomesSCResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetOutcomesSCResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetOutcomesSCResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    outcomesList: jspb.Message.toObjectList(msg.getOutcomesList(),
    proto.odds.OutcomeSC.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetOutcomesSCResponse}
 */
proto.odds.GetOutcomesSCResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetOutcomesSCResponse;
  return proto.odds.GetOutcomesSCResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetOutcomesSCResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetOutcomesSCResponse}
 */
proto.odds.GetOutcomesSCResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.OutcomeSC;
      reader.readMessage(value,proto.odds.OutcomeSC.deserializeBinaryFromReader);
      msg.addOutcomes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetOutcomesSCResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetOutcomesSCResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetOutcomesSCResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetOutcomesSCResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutcomesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.OutcomeSC.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OutcomeSC outcomes = 1;
 * @return {!Array<!proto.odds.OutcomeSC>}
 */
proto.odds.GetOutcomesSCResponse.prototype.getOutcomesList = function() {
  return /** @type{!Array<!proto.odds.OutcomeSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.OutcomeSC, 1));
};


/**
 * @param {!Array<!proto.odds.OutcomeSC>} value
 * @return {!proto.odds.GetOutcomesSCResponse} returns this
*/
proto.odds.GetOutcomesSCResponse.prototype.setOutcomesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.OutcomeSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.OutcomeSC}
 */
proto.odds.GetOutcomesSCResponse.prototype.addOutcomes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.OutcomeSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetOutcomesSCResponse} returns this
 */
proto.odds.GetOutcomesSCResponse.prototype.clearOutcomesList = function() {
  return this.setOutcomesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetPlayerOutcomesSCResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetPlayerOutcomesSCResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetPlayerOutcomesSCResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetPlayerOutcomesSCResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPlayerOutcomesSCResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    outcomesList: jspb.Message.toObjectList(msg.getOutcomesList(),
    proto.odds.PlayerOutcomeSC.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetPlayerOutcomesSCResponse}
 */
proto.odds.GetPlayerOutcomesSCResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetPlayerOutcomesSCResponse;
  return proto.odds.GetPlayerOutcomesSCResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetPlayerOutcomesSCResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetPlayerOutcomesSCResponse}
 */
proto.odds.GetPlayerOutcomesSCResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.PlayerOutcomeSC;
      reader.readMessage(value,proto.odds.PlayerOutcomeSC.deserializeBinaryFromReader);
      msg.addOutcomes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetPlayerOutcomesSCResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetPlayerOutcomesSCResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetPlayerOutcomesSCResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPlayerOutcomesSCResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutcomesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.PlayerOutcomeSC.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PlayerOutcomeSC outcomes = 1;
 * @return {!Array<!proto.odds.PlayerOutcomeSC>}
 */
proto.odds.GetPlayerOutcomesSCResponse.prototype.getOutcomesList = function() {
  return /** @type{!Array<!proto.odds.PlayerOutcomeSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.PlayerOutcomeSC, 1));
};


/**
 * @param {!Array<!proto.odds.PlayerOutcomeSC>} value
 * @return {!proto.odds.GetPlayerOutcomesSCResponse} returns this
*/
proto.odds.GetPlayerOutcomesSCResponse.prototype.setOutcomesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.PlayerOutcomeSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.PlayerOutcomeSC}
 */
proto.odds.GetPlayerOutcomesSCResponse.prototype.addOutcomes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.PlayerOutcomeSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetPlayerOutcomesSCResponse} returns this
 */
proto.odds.GetPlayerOutcomesSCResponse.prototype.clearOutcomesList = function() {
  return this.setOutcomesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetPlayerSportsMarketResponse.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetPlayerSportsMarketResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetPlayerSportsMarketResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetPlayerSportsMarketResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPlayerSportsMarketResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sportsList: jspb.Message.toObjectList(msg.getSportsList(),
    proto.odds.PlayerSportMarket.toObject, includeInstance),
    antepostMarketsList: jspb.Message.toObjectList(msg.getAntepostMarketsList(),
    proto.odds.AntepostMarket.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetPlayerSportsMarketResponse}
 */
proto.odds.GetPlayerSportsMarketResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetPlayerSportsMarketResponse;
  return proto.odds.GetPlayerSportsMarketResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetPlayerSportsMarketResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetPlayerSportsMarketResponse}
 */
proto.odds.GetPlayerSportsMarketResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.PlayerSportMarket;
      reader.readMessage(value,proto.odds.PlayerSportMarket.deserializeBinaryFromReader);
      msg.addSports(value);
      break;
    case 2:
      var value = new proto.odds.AntepostMarket;
      reader.readMessage(value,proto.odds.AntepostMarket.deserializeBinaryFromReader);
      msg.addAntepostMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetPlayerSportsMarketResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetPlayerSportsMarketResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetPlayerSportsMarketResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetPlayerSportsMarketResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.PlayerSportMarket.serializeBinaryToWriter
    );
  }
  f = message.getAntepostMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.odds.AntepostMarket.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PlayerSportMarket sports = 1;
 * @return {!Array<!proto.odds.PlayerSportMarket>}
 */
proto.odds.GetPlayerSportsMarketResponse.prototype.getSportsList = function() {
  return /** @type{!Array<!proto.odds.PlayerSportMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.PlayerSportMarket, 1));
};


/**
 * @param {!Array<!proto.odds.PlayerSportMarket>} value
 * @return {!proto.odds.GetPlayerSportsMarketResponse} returns this
*/
proto.odds.GetPlayerSportsMarketResponse.prototype.setSportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.PlayerSportMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.PlayerSportMarket}
 */
proto.odds.GetPlayerSportsMarketResponse.prototype.addSports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.PlayerSportMarket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetPlayerSportsMarketResponse} returns this
 */
proto.odds.GetPlayerSportsMarketResponse.prototype.clearSportsList = function() {
  return this.setSportsList([]);
};


/**
 * repeated AntepostMarket antepost_markets = 2;
 * @return {!Array<!proto.odds.AntepostMarket>}
 */
proto.odds.GetPlayerSportsMarketResponse.prototype.getAntepostMarketsList = function() {
  return /** @type{!Array<!proto.odds.AntepostMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.AntepostMarket, 2));
};


/**
 * @param {!Array<!proto.odds.AntepostMarket>} value
 * @return {!proto.odds.GetPlayerSportsMarketResponse} returns this
*/
proto.odds.GetPlayerSportsMarketResponse.prototype.setAntepostMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.odds.AntepostMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.AntepostMarket}
 */
proto.odds.GetPlayerSportsMarketResponse.prototype.addAntepostMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.odds.AntepostMarket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetPlayerSportsMarketResponse} returns this
 */
proto.odds.GetPlayerSportsMarketResponse.prototype.clearAntepostMarketsList = function() {
  return this.setAntepostMarketsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.GetAntepostMarketsBOResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.GetAntepostMarketsBOResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.GetAntepostMarketsBOResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.GetAntepostMarketsBOResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetAntepostMarketsBOResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    antepostMarketsList: jspb.Message.toObjectList(msg.getAntepostMarketsList(),
    proto.odds.AntepostMarketBO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.GetAntepostMarketsBOResponse}
 */
proto.odds.GetAntepostMarketsBOResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.GetAntepostMarketsBOResponse;
  return proto.odds.GetAntepostMarketsBOResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.GetAntepostMarketsBOResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.GetAntepostMarketsBOResponse}
 */
proto.odds.GetAntepostMarketsBOResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.AntepostMarketBO;
      reader.readMessage(value,proto.odds.AntepostMarketBO.deserializeBinaryFromReader);
      msg.addAntepostMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.GetAntepostMarketsBOResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.GetAntepostMarketsBOResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.GetAntepostMarketsBOResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.GetAntepostMarketsBOResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAntepostMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.AntepostMarketBO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AntepostMarketBO antepost_markets = 1;
 * @return {!Array<!proto.odds.AntepostMarketBO>}
 */
proto.odds.GetAntepostMarketsBOResponse.prototype.getAntepostMarketsList = function() {
  return /** @type{!Array<!proto.odds.AntepostMarketBO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.AntepostMarketBO, 1));
};


/**
 * @param {!Array<!proto.odds.AntepostMarketBO>} value
 * @return {!proto.odds.GetAntepostMarketsBOResponse} returns this
*/
proto.odds.GetAntepostMarketsBOResponse.prototype.setAntepostMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.AntepostMarketBO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.AntepostMarketBO}
 */
proto.odds.GetAntepostMarketsBOResponse.prototype.addAntepostMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.AntepostMarketBO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.GetAntepostMarketsBOResponse} returns this
 */
proto.odds.GetAntepostMarketsBOResponse.prototype.clearAntepostMarketsList = function() {
  return this.setAntepostMarketsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.BaseMarketGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.BaseMarketGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.BaseMarketGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.BaseMarketGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.BaseMarketGroup}
 */
proto.odds.BaseMarketGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.BaseMarketGroup;
  return proto.odds.BaseMarketGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.BaseMarketGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.BaseMarketGroup}
 */
proto.odds.BaseMarketGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.BaseMarketGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.BaseMarketGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.BaseMarketGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.BaseMarketGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.BaseMarketGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.BaseMarketGroup} returns this
 */
proto.odds.BaseMarketGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.BaseMarketGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.BaseMarketGroup} returns this
 */
proto.odds.BaseMarketGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.odds.BaseMarketGroup.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.BaseMarketGroup} returns this
 */
proto.odds.BaseMarketGroup.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.MarketGroup.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.MarketGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.MarketGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.MarketGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MarketGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    marketsList: jspb.Message.toObjectList(msg.getMarketsList(),
    proto.odds.Market.toObject, includeInstance),
    isLive: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.MarketGroup}
 */
proto.odds.MarketGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.MarketGroup;
  return proto.odds.MarketGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.MarketGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.MarketGroup}
 */
proto.odds.MarketGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = new proto.odds.Market;
      reader.readMessage(value,proto.odds.Market.deserializeBinaryFromReader);
      msg.addMarkets(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.MarketGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.MarketGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.MarketGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MarketGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.odds.Market.serializeBinaryToWriter
    );
  }
  f = message.getIsLive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.MarketGroup.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.MarketGroup} returns this
 */
proto.odds.MarketGroup.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.MarketGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.MarketGroup} returns this
 */
proto.odds.MarketGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.odds.MarketGroup.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.MarketGroup} returns this
 */
proto.odds.MarketGroup.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Market markets = 4;
 * @return {!Array<!proto.odds.Market>}
 */
proto.odds.MarketGroup.prototype.getMarketsList = function() {
  return /** @type{!Array<!proto.odds.Market>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Market, 4));
};


/**
 * @param {!Array<!proto.odds.Market>} value
 * @return {!proto.odds.MarketGroup} returns this
*/
proto.odds.MarketGroup.prototype.setMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.odds.Market=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Market}
 */
proto.odds.MarketGroup.prototype.addMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.odds.Market, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.MarketGroup} returns this
 */
proto.odds.MarketGroup.prototype.clearMarketsList = function() {
  return this.setMarketsList([]);
};


/**
 * optional bool is_live = 5;
 * @return {boolean}
 */
proto.odds.MarketGroup.prototype.getIsLive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.MarketGroup} returns this
 */
proto.odds.MarketGroup.prototype.setIsLive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.Market.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Market.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Market.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Market} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Market.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    outcomesList: jspb.Message.toObjectList(msg.getOutcomesList(),
    proto.odds.Outcome.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Market}
 */
proto.odds.Market.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Market;
  return proto.odds.Market.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Market} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Market}
 */
proto.odds.Market.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 4:
      var value = new proto.odds.Outcome;
      reader.readMessage(value,proto.odds.Outcome.deserializeBinaryFromReader);
      msg.addOutcomes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Market.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Market.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Market} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Market.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOutcomesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.odds.Outcome.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.Market.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Market} returns this
 */
proto.odds.Market.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.Market.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Market} returns this
 */
proto.odds.Market.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 sport_id = 3;
 * @return {number}
 */
proto.odds.Market.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Market} returns this
 */
proto.odds.Market.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Outcome outcomes = 4;
 * @return {!Array<!proto.odds.Outcome>}
 */
proto.odds.Market.prototype.getOutcomesList = function() {
  return /** @type{!Array<!proto.odds.Outcome>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Outcome, 4));
};


/**
 * @param {!Array<!proto.odds.Outcome>} value
 * @return {!proto.odds.Market} returns this
*/
proto.odds.Market.prototype.setOutcomesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.odds.Outcome=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Outcome}
 */
proto.odds.Market.prototype.addOutcomes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.odds.Outcome, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Market} returns this
 */
proto.odds.Market.prototype.clearOutcomesList = function() {
  return this.setOutcomesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.MarketSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.MarketSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.MarketSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MarketSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.MarketSC}
 */
proto.odds.MarketSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.MarketSC;
  return proto.odds.MarketSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.MarketSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.MarketSC}
 */
proto.odds.MarketSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.MarketSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.MarketSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.MarketSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MarketSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.MarketSC.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.MarketSC} returns this
 */
proto.odds.MarketSC.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.MarketSC.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.MarketSC} returns this
 */
proto.odds.MarketSC.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.PlayerMarketSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.PlayerMarketSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.PlayerMarketSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PlayerMarketSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.PlayerMarketSC}
 */
proto.odds.PlayerMarketSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.PlayerMarketSC;
  return proto.odds.PlayerMarketSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.PlayerMarketSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.PlayerMarketSC}
 */
proto.odds.PlayerMarketSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.PlayerMarketSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.PlayerMarketSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.PlayerMarketSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PlayerMarketSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.PlayerMarketSC.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.PlayerMarketSC} returns this
 */
proto.odds.PlayerMarketSC.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.PlayerMarketSC.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.PlayerMarketSC} returns this
 */
proto.odds.PlayerMarketSC.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.PlayerMarket.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.PlayerMarket.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.PlayerMarket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.PlayerMarket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PlayerMarket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    outcomesList: jspb.Message.toObjectList(msg.getOutcomesList(),
    proto.odds.OutcomePlayer.toObject, includeInstance),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.PlayerMarket}
 */
proto.odds.PlayerMarket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.PlayerMarket;
  return proto.odds.PlayerMarket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.PlayerMarket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.PlayerMarket}
 */
proto.odds.PlayerMarket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.odds.OutcomePlayer;
      reader.readMessage(value,proto.odds.OutcomePlayer.deserializeBinaryFromReader);
      msg.addOutcomes(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.PlayerMarket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.PlayerMarket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.PlayerMarket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PlayerMarket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOutcomesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.odds.OutcomePlayer.serializeBinaryToWriter
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.PlayerMarket.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.PlayerMarket} returns this
 */
proto.odds.PlayerMarket.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.PlayerMarket.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.PlayerMarket} returns this
 */
proto.odds.PlayerMarket.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated OutcomePlayer outcomes = 3;
 * @return {!Array<!proto.odds.OutcomePlayer>}
 */
proto.odds.PlayerMarket.prototype.getOutcomesList = function() {
  return /** @type{!Array<!proto.odds.OutcomePlayer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.OutcomePlayer, 3));
};


/**
 * @param {!Array<!proto.odds.OutcomePlayer>} value
 * @return {!proto.odds.PlayerMarket} returns this
*/
proto.odds.PlayerMarket.prototype.setOutcomesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.odds.OutcomePlayer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.OutcomePlayer}
 */
proto.odds.PlayerMarket.prototype.addOutcomes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.odds.OutcomePlayer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.PlayerMarket} returns this
 */
proto.odds.PlayerMarket.prototype.clearOutcomesList = function() {
  return this.setOutcomesList([]);
};


/**
 * optional bool is_suspended = 4;
 * @return {boolean}
 */
proto.odds.PlayerMarket.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.PlayerMarket} returns this
 */
proto.odds.PlayerMarket.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.PlayerMarket.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.PlayerMarket} returns this
 */
proto.odds.PlayerMarket.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.OutcomePlayer.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.OutcomePlayer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.OutcomePlayer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OutcomePlayer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    printCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    typeCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    desc: jspb.Message.getFieldWithDefault(msg, 5, ""),
    hasLimit: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.OutcomePlayer}
 */
proto.odds.OutcomePlayer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.OutcomePlayer;
  return proto.odds.OutcomePlayer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.OutcomePlayer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.OutcomePlayer}
 */
proto.odds.OutcomePlayer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrintCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDesc(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.OutcomePlayer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.OutcomePlayer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.OutcomePlayer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OutcomePlayer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrintCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTypeCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDesc();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHasLimit();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.OutcomePlayer.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OutcomePlayer} returns this
 */
proto.odds.OutcomePlayer.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.OutcomePlayer.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OutcomePlayer} returns this
 */
proto.odds.OutcomePlayer.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string print_code = 3;
 * @return {string}
 */
proto.odds.OutcomePlayer.prototype.getPrintCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OutcomePlayer} returns this
 */
proto.odds.OutcomePlayer.prototype.setPrintCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type_code = 4;
 * @return {string}
 */
proto.odds.OutcomePlayer.prototype.getTypeCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OutcomePlayer} returns this
 */
proto.odds.OutcomePlayer.prototype.setTypeCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string desc = 5;
 * @return {string}
 */
proto.odds.OutcomePlayer.prototype.getDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OutcomePlayer} returns this
 */
proto.odds.OutcomePlayer.prototype.setDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool has_limit = 6;
 * @return {boolean}
 */
proto.odds.OutcomePlayer.prototype.getHasLimit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.OutcomePlayer} returns this
 */
proto.odds.OutcomePlayer.prototype.setHasLimit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Outcome.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Outcome.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Outcome} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Outcome.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    printCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    typeCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    shortDesc: jspb.Message.getFieldWithDefault(msg, 5, ""),
    hasLimit: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Outcome}
 */
proto.odds.Outcome.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Outcome;
  return proto.odds.Outcome.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Outcome} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Outcome}
 */
proto.odds.Outcome.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrintCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortDesc(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Outcome.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Outcome.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Outcome} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Outcome.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrintCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTypeCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getShortDesc();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHasLimit();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.Outcome.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.Outcome.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string print_code = 3;
 * @return {string}
 */
proto.odds.Outcome.prototype.getPrintCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setPrintCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type_code = 4;
 * @return {string}
 */
proto.odds.Outcome.prototype.getTypeCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setTypeCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string short_desc = 5;
 * @return {string}
 */
proto.odds.Outcome.prototype.getShortDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setShortDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool has_limit = 6;
 * @return {boolean}
 */
proto.odds.Outcome.prototype.getHasLimit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Outcome} returns this
 */
proto.odds.Outcome.prototype.setHasLimit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.OutcomeSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.OutcomeSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.OutcomeSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OutcomeSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    printCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    typeCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    shortDesc: jspb.Message.getFieldWithDefault(msg, 5, ""),
    submarketId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    marginType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    hasLimit: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    marketId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    isPrematch: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    isLive: jspb.Message.getBooleanFieldWithDefault(msg, 12, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.OutcomeSC}
 */
proto.odds.OutcomeSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.OutcomeSC;
  return proto.odds.OutcomeSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.OutcomeSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.OutcomeSC}
 */
proto.odds.OutcomeSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrintCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortDesc(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSubmarketId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginType(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasLimit(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsPrematch(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.OutcomeSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.OutcomeSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.OutcomeSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OutcomeSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrintCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTypeCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getShortDesc();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSubmarketId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getMarginType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getHasLimit();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getMarketId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getIsPrematch();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getIsLive();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.OutcomeSC.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OutcomeSC} returns this
 */
proto.odds.OutcomeSC.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.OutcomeSC.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OutcomeSC} returns this
 */
proto.odds.OutcomeSC.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string print_code = 3;
 * @return {string}
 */
proto.odds.OutcomeSC.prototype.getPrintCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OutcomeSC} returns this
 */
proto.odds.OutcomeSC.prototype.setPrintCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type_code = 4;
 * @return {string}
 */
proto.odds.OutcomeSC.prototype.getTypeCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OutcomeSC} returns this
 */
proto.odds.OutcomeSC.prototype.setTypeCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string short_desc = 5;
 * @return {string}
 */
proto.odds.OutcomeSC.prototype.getShortDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OutcomeSC} returns this
 */
proto.odds.OutcomeSC.prototype.setShortDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int32 submarket_id = 6;
 * @return {number}
 */
proto.odds.OutcomeSC.prototype.getSubmarketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OutcomeSC} returns this
 */
proto.odds.OutcomeSC.prototype.setSubmarketId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional string margin_type = 7;
 * @return {string}
 */
proto.odds.OutcomeSC.prototype.getMarginType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OutcomeSC} returns this
 */
proto.odds.OutcomeSC.prototype.setMarginType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool has_limit = 8;
 * @return {boolean}
 */
proto.odds.OutcomeSC.prototype.getHasLimit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.OutcomeSC} returns this
 */
proto.odds.OutcomeSC.prototype.setHasLimit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional int32 market_id = 9;
 * @return {number}
 */
proto.odds.OutcomeSC.prototype.getMarketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OutcomeSC} returns this
 */
proto.odds.OutcomeSC.prototype.setMarketId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 sport_id = 10;
 * @return {number}
 */
proto.odds.OutcomeSC.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OutcomeSC} returns this
 */
proto.odds.OutcomeSC.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool is_prematch = 11;
 * @return {boolean}
 */
proto.odds.OutcomeSC.prototype.getIsPrematch = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.OutcomeSC} returns this
 */
proto.odds.OutcomeSC.prototype.setIsPrematch = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional bool is_live = 12;
 * @return {boolean}
 */
proto.odds.OutcomeSC.prototype.getIsLive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.OutcomeSC} returns this
 */
proto.odds.OutcomeSC.prototype.setIsLive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.PlayerOutcomeSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.PlayerOutcomeSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.PlayerOutcomeSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PlayerOutcomeSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    printCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    typeCode: jspb.Message.getFieldWithDefault(msg, 4, ""),
    shortDesc: jspb.Message.getFieldWithDefault(msg, 5, ""),
    marginType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    marketId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    hasLimit: jspb.Message.getBooleanFieldWithDefault(msg, 11, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.PlayerOutcomeSC}
 */
proto.odds.PlayerOutcomeSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.PlayerOutcomeSC;
  return proto.odds.PlayerOutcomeSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.PlayerOutcomeSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.PlayerOutcomeSC}
 */
proto.odds.PlayerOutcomeSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrintCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTypeCode(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortDesc(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginType(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHasLimit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.PlayerOutcomeSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.PlayerOutcomeSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.PlayerOutcomeSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PlayerOutcomeSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPrintCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTypeCode();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getShortDesc();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMarginType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getMarketId();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getHasLimit();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.PlayerOutcomeSC.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.PlayerOutcomeSC} returns this
 */
proto.odds.PlayerOutcomeSC.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.PlayerOutcomeSC.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.PlayerOutcomeSC} returns this
 */
proto.odds.PlayerOutcomeSC.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string print_code = 3;
 * @return {string}
 */
proto.odds.PlayerOutcomeSC.prototype.getPrintCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.PlayerOutcomeSC} returns this
 */
proto.odds.PlayerOutcomeSC.prototype.setPrintCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string type_code = 4;
 * @return {string}
 */
proto.odds.PlayerOutcomeSC.prototype.getTypeCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.PlayerOutcomeSC} returns this
 */
proto.odds.PlayerOutcomeSC.prototype.setTypeCode = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string short_desc = 5;
 * @return {string}
 */
proto.odds.PlayerOutcomeSC.prototype.getShortDesc = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.PlayerOutcomeSC} returns this
 */
proto.odds.PlayerOutcomeSC.prototype.setShortDesc = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string margin_type = 7;
 * @return {string}
 */
proto.odds.PlayerOutcomeSC.prototype.getMarginType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.PlayerOutcomeSC} returns this
 */
proto.odds.PlayerOutcomeSC.prototype.setMarginType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional int32 market_id = 9;
 * @return {number}
 */
proto.odds.PlayerOutcomeSC.prototype.getMarketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.PlayerOutcomeSC} returns this
 */
proto.odds.PlayerOutcomeSC.prototype.setMarketId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional int32 sport_id = 10;
 * @return {number}
 */
proto.odds.PlayerOutcomeSC.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.PlayerOutcomeSC} returns this
 */
proto.odds.PlayerOutcomeSC.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bool has_limit = 11;
 * @return {boolean}
 */
proto.odds.PlayerOutcomeSC.prototype.getHasLimit = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.PlayerOutcomeSC} returns this
 */
proto.odds.PlayerOutcomeSC.prototype.setHasLimit = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.Sport.repeatedFields_ = [4,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Sport.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Sport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Sport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Sport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    code: jspb.Message.getFieldWithDefault(msg, 3, ""),
    locationsList: jspb.Message.toObjectList(msg.getLocationsList(),
    proto.odds.Location.toObject, includeInstance),
    periodList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    basicOfferLive: (f = msg.getBasicOfferLive()) && proto.odds.InitialBasicOffer.toObject(includeInstance, f),
    basicOfferPrematch: (f = msg.getBasicOfferPrematch()) && proto.odds.InitialBasicOffer.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Sport}
 */
proto.odds.Sport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Sport;
  return proto.odds.Sport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Sport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Sport}
 */
proto.odds.Sport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 4:
      var value = new proto.odds.Location;
      reader.readMessage(value,proto.odds.Location.deserializeBinaryFromReader);
      msg.addLocations(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addPeriod(value);
      break;
    case 6:
      var value = new proto.odds.InitialBasicOffer;
      reader.readMessage(value,proto.odds.InitialBasicOffer.deserializeBinaryFromReader);
      msg.setBasicOfferLive(value);
      break;
    case 7:
      var value = new proto.odds.InitialBasicOffer;
      reader.readMessage(value,proto.odds.InitialBasicOffer.deserializeBinaryFromReader);
      msg.setBasicOfferPrematch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Sport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Sport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Sport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Sport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLocationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.odds.Location.serializeBinaryToWriter
    );
  }
  f = message.getPeriodList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
  f = message.getBasicOfferLive();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.odds.InitialBasicOffer.serializeBinaryToWriter
    );
  }
  f = message.getBasicOfferPrematch();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.odds.InitialBasicOffer.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.Sport.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.Sport.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string code = 3;
 * @return {string}
 */
proto.odds.Sport.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Location locations = 4;
 * @return {!Array<!proto.odds.Location>}
 */
proto.odds.Sport.prototype.getLocationsList = function() {
  return /** @type{!Array<!proto.odds.Location>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Location, 4));
};


/**
 * @param {!Array<!proto.odds.Location>} value
 * @return {!proto.odds.Sport} returns this
*/
proto.odds.Sport.prototype.setLocationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.odds.Location=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Location}
 */
proto.odds.Sport.prototype.addLocations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.odds.Location, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.clearLocationsList = function() {
  return this.setLocationsList([]);
};


/**
 * repeated string period = 5;
 * @return {!Array<string>}
 */
proto.odds.Sport.prototype.getPeriodList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.setPeriodList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.addPeriod = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.clearPeriodList = function() {
  return this.setPeriodList([]);
};


/**
 * optional InitialBasicOffer basic_offer_live = 6;
 * @return {?proto.odds.InitialBasicOffer}
 */
proto.odds.Sport.prototype.getBasicOfferLive = function() {
  return /** @type{?proto.odds.InitialBasicOffer} */ (
    jspb.Message.getWrapperField(this, proto.odds.InitialBasicOffer, 6));
};


/**
 * @param {?proto.odds.InitialBasicOffer|undefined} value
 * @return {!proto.odds.Sport} returns this
*/
proto.odds.Sport.prototype.setBasicOfferLive = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.clearBasicOfferLive = function() {
  return this.setBasicOfferLive(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Sport.prototype.hasBasicOfferLive = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional InitialBasicOffer basic_offer_prematch = 7;
 * @return {?proto.odds.InitialBasicOffer}
 */
proto.odds.Sport.prototype.getBasicOfferPrematch = function() {
  return /** @type{?proto.odds.InitialBasicOffer} */ (
    jspb.Message.getWrapperField(this, proto.odds.InitialBasicOffer, 7));
};


/**
 * @param {?proto.odds.InitialBasicOffer|undefined} value
 * @return {!proto.odds.Sport} returns this
*/
proto.odds.Sport.prototype.setBasicOfferPrematch = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Sport} returns this
 */
proto.odds.Sport.prototype.clearBasicOfferPrematch = function() {
  return this.setBasicOfferPrematch(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Sport.prototype.hasBasicOfferPrematch = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.AntepostSportBO.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.AntepostSportBO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.AntepostSportBO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AntepostSportBO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    code: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.AntepostSportBO}
 */
proto.odds.AntepostSportBO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.AntepostSportBO;
  return proto.odds.AntepostSportBO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.AntepostSportBO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.AntepostSportBO}
 */
proto.odds.AntepostSportBO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCode(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.AntepostSportBO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.AntepostSportBO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.AntepostSportBO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AntepostSportBO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getCode();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.AntepostSportBO.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostSportBO} returns this
 */
proto.odds.AntepostSportBO.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string code = 2;
 * @return {string}
 */
proto.odds.AntepostSportBO.prototype.getCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AntepostSportBO} returns this
 */
proto.odds.AntepostSportBO.prototype.setCode = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.odds.AntepostSportBO.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AntepostSportBO} returns this
 */
proto.odds.AntepostSportBO.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.InitialBasicOffer.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.InitialBasicOffer.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.InitialBasicOffer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.InitialBasicOffer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.InitialBasicOffer.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketsList: jspb.Message.toObjectList(msg.getMarketsList(),
    proto.odds.InitialBasicOfferMarket.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.InitialBasicOffer}
 */
proto.odds.InitialBasicOffer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.InitialBasicOffer;
  return proto.odds.InitialBasicOffer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.InitialBasicOffer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.InitialBasicOffer}
 */
proto.odds.InitialBasicOffer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.odds.InitialBasicOfferMarket;
      reader.readMessage(value,proto.odds.InitialBasicOfferMarket.deserializeBinaryFromReader);
      msg.addMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.InitialBasicOffer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.InitialBasicOffer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.InitialBasicOffer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.InitialBasicOffer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.odds.InitialBasicOfferMarket.serializeBinaryToWriter
    );
  }
};


/**
 * repeated InitialBasicOfferMarket markets = 1;
 * @return {!Array<!proto.odds.InitialBasicOfferMarket>}
 */
proto.odds.InitialBasicOffer.prototype.getMarketsList = function() {
  return /** @type{!Array<!proto.odds.InitialBasicOfferMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.InitialBasicOfferMarket, 1));
};


/**
 * @param {!Array<!proto.odds.InitialBasicOfferMarket>} value
 * @return {!proto.odds.InitialBasicOffer} returns this
*/
proto.odds.InitialBasicOffer.prototype.setMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.odds.InitialBasicOfferMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.InitialBasicOfferMarket}
 */
proto.odds.InitialBasicOffer.prototype.addMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.odds.InitialBasicOfferMarket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.InitialBasicOffer} returns this
 */
proto.odds.InitialBasicOffer.prototype.clearMarketsList = function() {
  return this.setMarketsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.InitialBasicOfferMarket.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.InitialBasicOfferMarket.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.InitialBasicOfferMarket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.InitialBasicOfferMarket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.InitialBasicOfferMarket.toObject = function(includeInstance, msg) {
  var f, obj = {
    marketId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    outcomeIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.InitialBasicOfferMarket}
 */
proto.odds.InitialBasicOfferMarket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.InitialBasicOfferMarket;
  return proto.odds.InitialBasicOfferMarket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.InitialBasicOfferMarket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.InitialBasicOfferMarket}
 */
proto.odds.InitialBasicOfferMarket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setMarketId(value);
      break;
    case 2:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setOutcomeIdsList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.InitialBasicOfferMarket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.InitialBasicOfferMarket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.InitialBasicOfferMarket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.InitialBasicOfferMarket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarketId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOutcomeIdsList();
  if (f.length > 0) {
    writer.writePackedInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 market_id = 1;
 * @return {number}
 */
proto.odds.InitialBasicOfferMarket.prototype.getMarketId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.InitialBasicOfferMarket} returns this
 */
proto.odds.InitialBasicOfferMarket.prototype.setMarketId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated int32 outcome_ids = 2;
 * @return {!Array<number>}
 */
proto.odds.InitialBasicOfferMarket.prototype.getOutcomeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.InitialBasicOfferMarket} returns this
 */
proto.odds.InitialBasicOfferMarket.prototype.setOutcomeIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.InitialBasicOfferMarket} returns this
 */
proto.odds.InitialBasicOfferMarket.prototype.addOutcomeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.InitialBasicOfferMarket} returns this
 */
proto.odds.InitialBasicOfferMarket.prototype.clearOutcomeIdsList = function() {
  return this.setOutcomeIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.SportSC.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SportSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SportSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SportSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SportSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    periodList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SportSC}
 */
proto.odds.SportSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SportSC;
  return proto.odds.SportSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SportSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SportSC}
 */
proto.odds.SportSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addPeriod(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SportSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SportSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SportSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SportSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPeriodList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.SportSC.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.SportSC} returns this
 */
proto.odds.SportSC.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.SportSC.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.SportSC} returns this
 */
proto.odds.SportSC.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string period = 3;
 * @return {!Array<string>}
 */
proto.odds.SportSC.prototype.getPeriodList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.odds.SportSC} returns this
 */
proto.odds.SportSC.prototype.setPeriodList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.odds.SportSC} returns this
 */
proto.odds.SportSC.prototype.addPeriod = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SportSC} returns this
 */
proto.odds.SportSC.prototype.clearPeriodList = function() {
  return this.setPeriodList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.LocationSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.LocationSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.LocationSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LocationSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.LocationSC}
 */
proto.odds.LocationSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.LocationSC;
  return proto.odds.LocationSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.LocationSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.LocationSC}
 */
proto.odds.LocationSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.LocationSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.LocationSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.LocationSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.LocationSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.LocationSC.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.LocationSC} returns this
 */
proto.odds.LocationSC.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.LocationSC.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.LocationSC} returns this
 */
proto.odds.LocationSC.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.CompetitionSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.CompetitionSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.CompetitionSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CompetitionSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.CompetitionSC}
 */
proto.odds.CompetitionSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.CompetitionSC;
  return proto.odds.CompetitionSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.CompetitionSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.CompetitionSC}
 */
proto.odds.CompetitionSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.CompetitionSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.CompetitionSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.CompetitionSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CompetitionSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.CompetitionSC.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.CompetitionSC} returns this
 */
proto.odds.CompetitionSC.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.CompetitionSC.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CompetitionSC} returns this
 */
proto.odds.CompetitionSC.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.MarketGroupSC.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.MarketGroupSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.MarketGroupSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.MarketGroupSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MarketGroupSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    order: jspb.Message.getFieldWithDefault(msg, 3, 0),
    marketsList: jspb.Message.toObjectList(msg.getMarketsList(),
    proto.odds.MarketSC.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.MarketGroupSC}
 */
proto.odds.MarketGroupSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.MarketGroupSC;
  return proto.odds.MarketGroupSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.MarketGroupSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.MarketGroupSC}
 */
proto.odds.MarketGroupSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 4:
      var value = new proto.odds.MarketSC;
      reader.readMessage(value,proto.odds.MarketSC.deserializeBinaryFromReader);
      msg.addMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.MarketGroupSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.MarketGroupSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.MarketGroupSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.MarketGroupSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.odds.MarketSC.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.MarketGroupSC.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.MarketGroupSC} returns this
 */
proto.odds.MarketGroupSC.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.MarketGroupSC.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.MarketGroupSC} returns this
 */
proto.odds.MarketGroupSC.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 order = 3;
 * @return {number}
 */
proto.odds.MarketGroupSC.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.MarketGroupSC} returns this
 */
proto.odds.MarketGroupSC.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated MarketSC markets = 4;
 * @return {!Array<!proto.odds.MarketSC>}
 */
proto.odds.MarketGroupSC.prototype.getMarketsList = function() {
  return /** @type{!Array<!proto.odds.MarketSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.MarketSC, 4));
};


/**
 * @param {!Array<!proto.odds.MarketSC>} value
 * @return {!proto.odds.MarketGroupSC} returns this
*/
proto.odds.MarketGroupSC.prototype.setMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.odds.MarketSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.MarketSC}
 */
proto.odds.MarketGroupSC.prototype.addMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.odds.MarketSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.MarketGroupSC} returns this
 */
proto.odds.MarketGroupSC.prototype.clearMarketsList = function() {
  return this.setMarketsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.SportMarketSC.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.SportMarketSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.SportMarketSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.SportMarketSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SportMarketSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    marketGroupsList: jspb.Message.toObjectList(msg.getMarketGroupsList(),
    proto.odds.MarketGroupSC.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.SportMarketSC}
 */
proto.odds.SportMarketSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.SportMarketSC;
  return proto.odds.SportMarketSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.SportMarketSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.SportMarketSC}
 */
proto.odds.SportMarketSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.odds.MarketGroupSC;
      reader.readMessage(value,proto.odds.MarketGroupSC.deserializeBinaryFromReader);
      msg.addMarketGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.SportMarketSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.SportMarketSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.SportMarketSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.SportMarketSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMarketGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.odds.MarketGroupSC.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.SportMarketSC.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.SportMarketSC} returns this
 */
proto.odds.SportMarketSC.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.SportMarketSC.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.SportMarketSC} returns this
 */
proto.odds.SportMarketSC.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated MarketGroupSC market_groups = 3;
 * @return {!Array<!proto.odds.MarketGroupSC>}
 */
proto.odds.SportMarketSC.prototype.getMarketGroupsList = function() {
  return /** @type{!Array<!proto.odds.MarketGroupSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.MarketGroupSC, 3));
};


/**
 * @param {!Array<!proto.odds.MarketGroupSC>} value
 * @return {!proto.odds.SportMarketSC} returns this
*/
proto.odds.SportMarketSC.prototype.setMarketGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.odds.MarketGroupSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.MarketGroupSC}
 */
proto.odds.SportMarketSC.prototype.addMarketGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.odds.MarketGroupSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.SportMarketSC} returns this
 */
proto.odds.SportMarketSC.prototype.clearMarketGroupsList = function() {
  return this.setMarketGroupsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.PlayerSportMarketSC.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.PlayerSportMarketSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.PlayerSportMarketSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.PlayerSportMarketSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PlayerSportMarketSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    marketsList: jspb.Message.toObjectList(msg.getMarketsList(),
    proto.odds.PlayerMarketSC.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.PlayerSportMarketSC}
 */
proto.odds.PlayerSportMarketSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.PlayerSportMarketSC;
  return proto.odds.PlayerSportMarketSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.PlayerSportMarketSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.PlayerSportMarketSC}
 */
proto.odds.PlayerSportMarketSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.odds.PlayerMarketSC;
      reader.readMessage(value,proto.odds.PlayerMarketSC.deserializeBinaryFromReader);
      msg.addMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.PlayerSportMarketSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.PlayerSportMarketSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.PlayerSportMarketSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PlayerSportMarketSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.odds.PlayerMarketSC.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.PlayerSportMarketSC.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.PlayerSportMarketSC} returns this
 */
proto.odds.PlayerSportMarketSC.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.PlayerSportMarketSC.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.PlayerSportMarketSC} returns this
 */
proto.odds.PlayerSportMarketSC.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated PlayerMarketSC markets = 3;
 * @return {!Array<!proto.odds.PlayerMarketSC>}
 */
proto.odds.PlayerSportMarketSC.prototype.getMarketsList = function() {
  return /** @type{!Array<!proto.odds.PlayerMarketSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.PlayerMarketSC, 3));
};


/**
 * @param {!Array<!proto.odds.PlayerMarketSC>} value
 * @return {!proto.odds.PlayerSportMarketSC} returns this
*/
proto.odds.PlayerSportMarketSC.prototype.setMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.odds.PlayerMarketSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.PlayerMarketSC}
 */
proto.odds.PlayerSportMarketSC.prototype.addMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.odds.PlayerMarketSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.PlayerSportMarketSC} returns this
 */
proto.odds.PlayerSportMarketSC.prototype.clearMarketsList = function() {
  return this.setMarketsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.PlayerSportMarket.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.PlayerSportMarket.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.PlayerSportMarket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.PlayerSportMarket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PlayerSportMarket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    marketsList: jspb.Message.toObjectList(msg.getMarketsList(),
    proto.odds.PlayerMarket.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.PlayerSportMarket}
 */
proto.odds.PlayerSportMarket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.PlayerSportMarket;
  return proto.odds.PlayerSportMarket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.PlayerSportMarket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.PlayerSportMarket}
 */
proto.odds.PlayerSportMarket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new proto.odds.PlayerMarket;
      reader.readMessage(value,proto.odds.PlayerMarket.deserializeBinaryFromReader);
      msg.addMarkets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.PlayerSportMarket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.PlayerSportMarket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.PlayerSportMarket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.PlayerSportMarket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMarketsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.odds.PlayerMarket.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.PlayerSportMarket.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.PlayerSportMarket} returns this
 */
proto.odds.PlayerSportMarket.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.PlayerSportMarket.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.PlayerSportMarket} returns this
 */
proto.odds.PlayerSportMarket.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated PlayerMarket markets = 3;
 * @return {!Array<!proto.odds.PlayerMarket>}
 */
proto.odds.PlayerSportMarket.prototype.getMarketsList = function() {
  return /** @type{!Array<!proto.odds.PlayerMarket>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.PlayerMarket, 3));
};


/**
 * @param {!Array<!proto.odds.PlayerMarket>} value
 * @return {!proto.odds.PlayerSportMarket} returns this
*/
proto.odds.PlayerSportMarket.prototype.setMarketsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.odds.PlayerMarket=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.PlayerMarket}
 */
proto.odds.PlayerSportMarket.prototype.addMarkets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.odds.PlayerMarket, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.PlayerSportMarket} returns this
 */
proto.odds.PlayerSportMarket.prototype.clearMarketsList = function() {
  return this.setMarketsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.Location.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Location.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Location.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Location} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Location.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortCode: jspb.Message.getFieldWithDefault(msg, 3, ""),
    competitionsList: jspb.Message.toObjectList(msg.getCompetitionsList(),
    proto.odds.Competition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Location}
 */
proto.odds.Location.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Location;
  return proto.odds.Location.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Location} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Location}
 */
proto.odds.Location.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortCode(value);
      break;
    case 6:
      var value = new proto.odds.Competition;
      reader.readMessage(value,proto.odds.Competition.deserializeBinaryFromReader);
      msg.addCompetitions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Location.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Location.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Location} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Location.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortCode();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCompetitionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.odds.Competition.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.Location.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Location} returns this
 */
proto.odds.Location.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.Location.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Location} returns this
 */
proto.odds.Location.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_code = 3;
 * @return {string}
 */
proto.odds.Location.prototype.getShortCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Location} returns this
 */
proto.odds.Location.prototype.setShortCode = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated Competition competitions = 6;
 * @return {!Array<!proto.odds.Competition>}
 */
proto.odds.Location.prototype.getCompetitionsList = function() {
  return /** @type{!Array<!proto.odds.Competition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Competition, 6));
};


/**
 * @param {!Array<!proto.odds.Competition>} value
 * @return {!proto.odds.Location} returns this
*/
proto.odds.Location.prototype.setCompetitionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.odds.Competition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Competition}
 */
proto.odds.Location.prototype.addCompetitions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.odds.Competition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Location} returns this
 */
proto.odds.Location.prototype.clearCompetitionsList = function() {
  return this.setCompetitionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Competition.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Competition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Competition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Competition.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    shortName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isFavorite: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Competition}
 */
proto.odds.Competition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Competition;
  return proto.odds.Competition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Competition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Competition}
 */
proto.odds.Competition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsFavorite(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Competition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Competition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Competition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Competition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getShortName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsFavorite();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 1;
 * @return {number}
 */
proto.odds.Competition.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.Competition.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string short_name = 3;
 * @return {string}
 */
proto.odds.Competition.prototype.getShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_favorite = 4;
 * @return {boolean}
 */
proto.odds.Competition.prototype.getIsFavorite = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Competition} returns this
 */
proto.odds.Competition.prototype.setIsFavorite = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Odd.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Odd.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Odd} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Odd.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    oddValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    intKey: jspb.Message.getFieldWithDefault(msg, 4, 0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    automatedStatus: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Odd}
 */
proto.odds.Odd.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Odd;
  return proto.odds.Odd.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Odd} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Odd}
 */
proto.odds.Odd.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOddValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomatedStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Odd.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Odd.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Odd} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Odd.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOddValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getAutomatedStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional bool is_suspended = 1;
 * @return {boolean}
 */
proto.odds.Odd.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Odd} returns this
 */
proto.odds.Odd.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional double odd_value = 2;
 * @return {number}
 */
proto.odds.Odd.prototype.getOddValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Odd} returns this
 */
proto.odds.Odd.prototype.setOddValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 outcome_id = 3;
 * @return {number}
 */
proto.odds.Odd.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Odd} returns this
 */
proto.odds.Odd.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 int_key = 4;
 * @return {number}
 */
proto.odds.Odd.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Odd} returns this
 */
proto.odds.Odd.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double limit = 5;
 * @return {number}
 */
proto.odds.Odd.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Odd} returns this
 */
proto.odds.Odd.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string automated_status = 6;
 * @return {string}
 */
proto.odds.Odd.prototype.getAutomatedStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Odd} returns this
 */
proto.odds.Odd.prototype.setAutomatedStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.OddPlayer.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.OddPlayer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.OddPlayer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OddPlayer.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    oddValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    intKey: jspb.Message.getFieldWithDefault(msg, 4, 0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    automatedStatus: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.OddPlayer}
 */
proto.odds.OddPlayer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.OddPlayer;
  return proto.odds.OddPlayer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.OddPlayer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.OddPlayer}
 */
proto.odds.OddPlayer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOddValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomatedStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.OddPlayer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.OddPlayer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.OddPlayer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OddPlayer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOddValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getAutomatedStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional bool is_suspended = 1;
 * @return {boolean}
 */
proto.odds.OddPlayer.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.OddPlayer} returns this
 */
proto.odds.OddPlayer.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional double odd_value = 2;
 * @return {number}
 */
proto.odds.OddPlayer.prototype.getOddValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddPlayer} returns this
 */
proto.odds.OddPlayer.prototype.setOddValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 outcome_id = 3;
 * @return {number}
 */
proto.odds.OddPlayer.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddPlayer} returns this
 */
proto.odds.OddPlayer.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 int_key = 4;
 * @return {number}
 */
proto.odds.OddPlayer.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddPlayer} returns this
 */
proto.odds.OddPlayer.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double limit = 5;
 * @return {number}
 */
proto.odds.OddPlayer.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddPlayer} returns this
 */
proto.odds.OddPlayer.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string automated_status = 6;
 * @return {string}
 */
proto.odds.OddPlayer.prototype.getAutomatedStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddPlayer} returns this
 */
proto.odds.OddPlayer.prototype.setAutomatedStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.OddSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.OddSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.OddSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OddSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    oddValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    intKey: jspb.Message.getFieldWithDefault(msg, 4, 0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    automatedStatus: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.OddSC}
 */
proto.odds.OddSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.OddSC;
  return proto.odds.OddSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.OddSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.OddSC}
 */
proto.odds.OddSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOddValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomatedStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.OddSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.OddSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.OddSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OddSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOddValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getAutomatedStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional bool is_suspended = 1;
 * @return {boolean}
 */
proto.odds.OddSC.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.OddSC} returns this
 */
proto.odds.OddSC.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional double odd_value = 2;
 * @return {number}
 */
proto.odds.OddSC.prototype.getOddValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddSC} returns this
 */
proto.odds.OddSC.prototype.setOddValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 outcome_id = 3;
 * @return {number}
 */
proto.odds.OddSC.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddSC} returns this
 */
proto.odds.OddSC.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 int_key = 4;
 * @return {number}
 */
proto.odds.OddSC.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddSC} returns this
 */
proto.odds.OddSC.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double limit = 5;
 * @return {number}
 */
proto.odds.OddSC.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddSC} returns this
 */
proto.odds.OddSC.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string automated_status = 6;
 * @return {string}
 */
proto.odds.OddSC.prototype.getAutomatedStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddSC} returns this
 */
proto.odds.OddSC.prototype.setAutomatedStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.OddPlayerSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.OddPlayerSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.OddPlayerSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OddPlayerSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    oddValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    outcomeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    intKey: jspb.Message.getFieldWithDefault(msg, 4, 0),
    limit: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    automatedStatus: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.OddPlayerSC}
 */
proto.odds.OddPlayerSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.OddPlayerSC;
  return proto.odds.OddPlayerSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.OddPlayerSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.OddPlayerSC}
 */
proto.odds.OddPlayerSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOddValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOutcomeId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLimit(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAutomatedStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.OddPlayerSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.OddPlayerSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.OddPlayerSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.OddPlayerSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getOddValue();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getOutcomeId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getAutomatedStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional bool is_suspended = 1;
 * @return {boolean}
 */
proto.odds.OddPlayerSC.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.OddPlayerSC} returns this
 */
proto.odds.OddPlayerSC.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional double odd_value = 2;
 * @return {number}
 */
proto.odds.OddPlayerSC.prototype.getOddValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddPlayerSC} returns this
 */
proto.odds.OddPlayerSC.prototype.setOddValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional int32 outcome_id = 3;
 * @return {number}
 */
proto.odds.OddPlayerSC.prototype.getOutcomeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddPlayerSC} returns this
 */
proto.odds.OddPlayerSC.prototype.setOutcomeId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int32 int_key = 4;
 * @return {number}
 */
proto.odds.OddPlayerSC.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddPlayerSC} returns this
 */
proto.odds.OddPlayerSC.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional double limit = 5;
 * @return {number}
 */
proto.odds.OddPlayerSC.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.OddPlayerSC} returns this
 */
proto.odds.OddPlayerSC.prototype.setLimit = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional string automated_status = 6;
 * @return {string}
 */
proto.odds.OddPlayerSC.prototype.getAutomatedStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.OddPlayerSC} returns this
 */
proto.odds.OddPlayerSC.prototype.setAutomatedStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.TemplateData.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.TemplateData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.TemplateData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.TemplateData.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.TemplateData}
 */
proto.odds.TemplateData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.TemplateData;
  return proto.odds.TemplateData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.TemplateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.TemplateData}
 */
proto.odds.TemplateData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.TemplateData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.TemplateData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.TemplateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.TemplateData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.odds.TemplateData.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.TemplateData} returns this
 */
proto.odds.TemplateData.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.CurrentPhase.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.CurrentPhase.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.CurrentPhase} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CurrentPhase.toObject = function(includeInstance, msg) {
  var f, obj = {
    p: jspb.Message.getFieldWithDefault(msg, 1, ""),
    t: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isBreak: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.CurrentPhase}
 */
proto.odds.CurrentPhase.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.CurrentPhase;
  return proto.odds.CurrentPhase.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.CurrentPhase} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.CurrentPhase}
 */
proto.odds.CurrentPhase.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setP(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setT(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsBreak(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.CurrentPhase.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.CurrentPhase.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.CurrentPhase} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.CurrentPhase.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getP();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getT();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsBreak();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional string P = 1;
 * @return {string}
 */
proto.odds.CurrentPhase.prototype.getP = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CurrentPhase} returns this
 */
proto.odds.CurrentPhase.prototype.setP = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string T = 2;
 * @return {string}
 */
proto.odds.CurrentPhase.prototype.getT = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.CurrentPhase} returns this
 */
proto.odds.CurrentPhase.prototype.setT = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_break = 3;
 * @return {boolean}
 */
proto.odds.CurrentPhase.prototype.getIsBreak = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.CurrentPhase} returns this
 */
proto.odds.CurrentPhase.prototype.setIsBreak = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.Event.repeatedFields_ = [5,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Event.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Event.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Event} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Event.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.odds.Odd.toObject, includeInstance),
    start: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    eventCode: jspb.Message.getFieldWithDefault(msg, 9, 0),
    note: jspb.Message.getFieldWithDefault(msg, 10, ""),
    currentResultList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    currentPhase: (f = msg.getCurrentPhase()) && proto.odds.CurrentPhase.toObject(includeInstance, f),
    detailedResult: jspb.Message.getFieldWithDefault(msg, 13, ""),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    intKey: jspb.Message.getFieldWithDefault(msg, 15, 0),
    marketsMap: (f = msg.getMarketsMap()) ? f.toObject(includeInstance, undefined) : [],
    betradarId: jspb.Message.getFieldWithDefault(msg, 17, 0),
    home: jspb.Message.getFieldWithDefault(msg, 18, ""),
    away: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Event}
 */
proto.odds.Event.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Event;
  return proto.odds.Event.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Event} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Event}
 */
proto.odds.Event.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 5:
      var value = new proto.odds.Odd;
      reader.readMessage(value,proto.odds.Odd.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 11:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setCurrentResultList(value);
      break;
    case 12:
      var value = new proto.odds.CurrentPhase;
      reader.readMessage(value,proto.odds.CurrentPhase.deserializeBinaryFromReader);
      msg.setCurrentPhase(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetailedResult(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 16:
      var value = msg.getMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBetradarId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setHome(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setAway(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Event.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Event.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Event} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Event.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.odds.Odd.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getEventCode();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCurrentResultList();
  if (f.length > 0) {
    writer.writePackedInt32(
      11,
      f
    );
  }
  f = message.getCurrentPhase();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.odds.CurrentPhase.serializeBinaryToWriter
    );
  }
  f = message.getDetailedResult();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(16, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getBetradarId();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getHome();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getAway();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional int32 competition_id = 3;
 * @return {number}
 */
proto.odds.Event.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated Odd odds = 5;
 * @return {!Array<!proto.odds.Odd>}
 */
proto.odds.Event.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.odds.Odd>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.Odd, 5));
};


/**
 * @param {!Array<!proto.odds.Odd>} value
 * @return {!proto.odds.Event} returns this
*/
proto.odds.Event.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.odds.Odd=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.Odd}
 */
proto.odds.Event.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.odds.Odd, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};


/**
 * optional string start = 6;
 * @return {string}
 */
proto.odds.Event.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 sport_id = 7;
 * @return {number}
 */
proto.odds.Event.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 location_id = 8;
 * @return {number}
 */
proto.odds.Event.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 event_code = 9;
 * @return {number}
 */
proto.odds.Event.prototype.getEventCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setEventCode = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string note = 10;
 * @return {string}
 */
proto.odds.Event.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated int32 current_result = 11;
 * @return {!Array<number>}
 */
proto.odds.Event.prototype.getCurrentResultList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setCurrentResultList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.addCurrentResult = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.clearCurrentResultList = function() {
  return this.setCurrentResultList([]);
};


/**
 * optional CurrentPhase current_phase = 12;
 * @return {?proto.odds.CurrentPhase}
 */
proto.odds.Event.prototype.getCurrentPhase = function() {
  return /** @type{?proto.odds.CurrentPhase} */ (
    jspb.Message.getWrapperField(this, proto.odds.CurrentPhase, 12));
};


/**
 * @param {?proto.odds.CurrentPhase|undefined} value
 * @return {!proto.odds.Event} returns this
*/
proto.odds.Event.prototype.setCurrentPhase = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.clearCurrentPhase = function() {
  return this.setCurrentPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.Event.prototype.hasCurrentPhase = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string detailed_result = 13;
 * @return {string}
 */
proto.odds.Event.prototype.getDetailedResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setDetailedResult = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool is_suspended = 14;
 * @return {boolean}
 */
proto.odds.Event.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional int32 int_key = 15;
 * @return {number}
 */
proto.odds.Event.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * map<string, bool> markets = 16;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.odds.Event.prototype.getMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 16, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.clearMarketsMap = function() {
  this.getMarketsMap().clear();
  return this;};


/**
 * optional int32 betradar_id = 17;
 * @return {number}
 */
proto.odds.Event.prototype.getBetradarId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setBetradarId = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string home = 18;
 * @return {string}
 */
proto.odds.Event.prototype.getHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setHome = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string away = 19;
 * @return {string}
 */
proto.odds.Event.prototype.getAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Event} returns this
 */
proto.odds.Event.prototype.setAway = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.AntepostMarket.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.AntepostMarket.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.AntepostMarket} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AntepostMarket.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 6, 0),
    competitionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.AntepostMarket}
 */
proto.odds.AntepostMarket.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.AntepostMarket;
  return proto.odds.AntepostMarket.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.AntepostMarket} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.AntepostMarket}
 */
proto.odds.AntepostMarket.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.AntepostMarket.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.AntepostMarket.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.AntepostMarket} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AntepostMarket.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
};


/**
 * optional int32 id = 6;
 * @return {number}
 */
proto.odds.AntepostMarket.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostMarket} returns this
 */
proto.odds.AntepostMarket.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 competition_id = 1;
 * @return {number}
 */
proto.odds.AntepostMarket.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostMarket} returns this
 */
proto.odds.AntepostMarket.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.AntepostMarket.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostMarket} returns this
 */
proto.odds.AntepostMarket.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.odds.AntepostMarket.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AntepostMarket} returns this
 */
proto.odds.AntepostMarket.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_suspended = 4;
 * @return {boolean}
 */
proto.odds.AntepostMarket.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.AntepostMarket} returns this
 */
proto.odds.AntepostMarket.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.odds.AntepostMarket.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostMarket} returns this
 */
proto.odds.AntepostMarket.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 location_id = 7;
 * @return {number}
 */
proto.odds.AntepostMarket.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostMarket} returns this
 */
proto.odds.AntepostMarket.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.AntepostMarketBO.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.AntepostMarketBO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.AntepostMarketBO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AntepostMarketBO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 6, 0),
    competition: jspb.Message.getFieldWithDefault(msg, 1, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0),
    location: jspb.Message.getFieldWithDefault(msg, 7, 0),
    shortCode: jspb.Message.getFieldWithDefault(msg, 8, ""),
    sport: (f = msg.getSport()) && proto.odds.AntepostSportBO.toObject(includeInstance, f),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.AntepostMarketBO}
 */
proto.odds.AntepostMarketBO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.AntepostMarketBO;
  return proto.odds.AntepostMarketBO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.AntepostMarketBO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.AntepostMarketBO}
 */
proto.odds.AntepostMarketBO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetition(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocation(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortCode(value);
      break;
    case 9:
      var value = new proto.odds.AntepostSportBO;
      reader.readMessage(value,proto.odds.AntepostSportBO.deserializeBinaryFromReader);
      msg.setSport(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.AntepostMarketBO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.AntepostMarketBO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.AntepostMarketBO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AntepostMarketBO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCompetition();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getLocation();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getShortCode();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSport();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.odds.AntepostSportBO.serializeBinaryToWriter
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional int32 id = 6;
 * @return {number}
 */
proto.odds.AntepostMarketBO.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostMarketBO} returns this
 */
proto.odds.AntepostMarketBO.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 competition = 1;
 * @return {number}
 */
proto.odds.AntepostMarketBO.prototype.getCompetition = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostMarketBO} returns this
 */
proto.odds.AntepostMarketBO.prototype.setCompetition = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.odds.AntepostMarketBO.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AntepostMarketBO} returns this
 */
proto.odds.AntepostMarketBO.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.odds.AntepostMarketBO.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostMarketBO} returns this
 */
proto.odds.AntepostMarketBO.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int32 location = 7;
 * @return {number}
 */
proto.odds.AntepostMarketBO.prototype.getLocation = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostMarketBO} returns this
 */
proto.odds.AntepostMarketBO.prototype.setLocation = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string short_code = 8;
 * @return {string}
 */
proto.odds.AntepostMarketBO.prototype.getShortCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AntepostMarketBO} returns this
 */
proto.odds.AntepostMarketBO.prototype.setShortCode = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional AntepostSportBO sport = 9;
 * @return {?proto.odds.AntepostSportBO}
 */
proto.odds.AntepostMarketBO.prototype.getSport = function() {
  return /** @type{?proto.odds.AntepostSportBO} */ (
    jspb.Message.getWrapperField(this, proto.odds.AntepostSportBO, 9));
};


/**
 * @param {?proto.odds.AntepostSportBO|undefined} value
 * @return {!proto.odds.AntepostMarketBO} returns this
*/
proto.odds.AntepostMarketBO.prototype.setSport = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.AntepostMarketBO} returns this
 */
proto.odds.AntepostMarketBO.prototype.clearSport = function() {
  return this.setSport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.AntepostMarketBO.prototype.hasSport = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional bool is_suspended = 4;
 * @return {boolean}
 */
proto.odds.AntepostMarketBO.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.AntepostMarketBO} returns this
 */
proto.odds.AntepostMarketBO.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.AntepostMarketSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.AntepostMarketSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.AntepostMarketSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AntepostMarketSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 6, 0),
    competitionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sportId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    locationId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    locationName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    competitionName: jspb.Message.getFieldWithDefault(msg, 9, ""),
    order: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.AntepostMarketSC}
 */
proto.odds.AntepostMarketSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.AntepostMarketSC;
  return proto.odds.AntepostMarketSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.AntepostMarketSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.AntepostMarketSC}
 */
proto.odds.AntepostMarketSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 6:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setId(value);
      break;
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocationName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOrder(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.AntepostMarketSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.AntepostMarketSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.AntepostMarketSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AntepostMarketSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeInt32(
      6,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLocationName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOrder();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int32 id = 6;
 * @return {number}
 */
proto.odds.AntepostMarketSC.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostMarketSC} returns this
 */
proto.odds.AntepostMarketSC.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 competition_id = 1;
 * @return {number}
 */
proto.odds.AntepostMarketSC.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostMarketSC} returns this
 */
proto.odds.AntepostMarketSC.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 sport_id = 5;
 * @return {number}
 */
proto.odds.AntepostMarketSC.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostMarketSC} returns this
 */
proto.odds.AntepostMarketSC.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.odds.AntepostMarketSC.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AntepostMarketSC} returns this
 */
proto.odds.AntepostMarketSC.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool is_suspended = 4;
 * @return {boolean}
 */
proto.odds.AntepostMarketSC.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.AntepostMarketSC} returns this
 */
proto.odds.AntepostMarketSC.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional int32 location_id = 7;
 * @return {number}
 */
proto.odds.AntepostMarketSC.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostMarketSC} returns this
 */
proto.odds.AntepostMarketSC.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string location_name = 8;
 * @return {string}
 */
proto.odds.AntepostMarketSC.prototype.getLocationName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AntepostMarketSC} returns this
 */
proto.odds.AntepostMarketSC.prototype.setLocationName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string competition_name = 9;
 * @return {string}
 */
proto.odds.AntepostMarketSC.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AntepostMarketSC} returns this
 */
proto.odds.AntepostMarketSC.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional int32 order = 2;
 * @return {number}
 */
proto.odds.AntepostMarketSC.prototype.getOrder = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostMarketSC} returns this
 */
proto.odds.AntepostMarketSC.prototype.setOrder = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.Antepost.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.Antepost.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.Antepost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.Antepost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Antepost.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: jspb.Message.getFieldWithDefault(msg, 1, ""),
    end: jspb.Message.getFieldWithDefault(msg, 2, ""),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    note: jspb.Message.getFieldWithDefault(msg, 4, ""),
    intKey: jspb.Message.getFieldWithDefault(msg, 5, 0),
    antepostBetsList: jspb.Message.toObjectList(msg.getAntepostBetsList(),
    proto.odds.AntepostBet.toObject, includeInstance),
    name: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.Antepost}
 */
proto.odds.Antepost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.Antepost;
  return proto.odds.Antepost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.Antepost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.Antepost}
 */
proto.odds.Antepost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEnd(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 6:
      var value = new proto.odds.AntepostBet;
      reader.readMessage(value,proto.odds.AntepostBet.deserializeBinaryFromReader);
      msg.addAntepostBets(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.Antepost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.Antepost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.Antepost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.Antepost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEnd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
  f = message.getAntepostBetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.odds.AntepostBet.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string start = 1;
 * @return {string}
 */
proto.odds.Antepost.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Antepost} returns this
 */
proto.odds.Antepost.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string end = 2;
 * @return {string}
 */
proto.odds.Antepost.prototype.getEnd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Antepost} returns this
 */
proto.odds.Antepost.prototype.setEnd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool is_suspended = 3;
 * @return {boolean}
 */
proto.odds.Antepost.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.Antepost} returns this
 */
proto.odds.Antepost.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string note = 4;
 * @return {string}
 */
proto.odds.Antepost.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Antepost} returns this
 */
proto.odds.Antepost.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional int32 int_key = 5;
 * @return {number}
 */
proto.odds.Antepost.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.Antepost} returns this
 */
proto.odds.Antepost.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated AntepostBet antepost_bets = 6;
 * @return {!Array<!proto.odds.AntepostBet>}
 */
proto.odds.Antepost.prototype.getAntepostBetsList = function() {
  return /** @type{!Array<!proto.odds.AntepostBet>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.AntepostBet, 6));
};


/**
 * @param {!Array<!proto.odds.AntepostBet>} value
 * @return {!proto.odds.Antepost} returns this
*/
proto.odds.Antepost.prototype.setAntepostBetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.odds.AntepostBet=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.AntepostBet}
 */
proto.odds.Antepost.prototype.addAntepostBets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.odds.AntepostBet, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.Antepost} returns this
 */
proto.odds.Antepost.prototype.clearAntepostBetsList = function() {
  return this.setAntepostBetsList([]);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.odds.Antepost.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.Antepost} returns this
 */
proto.odds.Antepost.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.AntepostBet.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.AntepostBet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.AntepostBet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AntepostBet.toObject = function(includeInstance, msg) {
  var f, obj = {
    code: jspb.Message.getFieldWithDefault(msg, 1, 0),
    param: jspb.Message.getFieldWithDefault(msg, 2, ""),
    oddValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    intKey: jspb.Message.getFieldWithDefault(msg, 4, 0),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    manualStatus: jspb.Message.getFieldWithDefault(msg, 6, ""),
    name: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.AntepostBet}
 */
proto.odds.AntepostBet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.AntepostBet;
  return proto.odds.AntepostBet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.AntepostBet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.AntepostBet}
 */
proto.odds.AntepostBet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCode(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setParam(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOddValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setManualStatus(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.AntepostBet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.AntepostBet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.AntepostBet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.AntepostBet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCode();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getParam();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOddValue();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      4,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getManualStatus();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional int32 code = 1;
 * @return {number}
 */
proto.odds.AntepostBet.prototype.getCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostBet} returns this
 */
proto.odds.AntepostBet.prototype.setCode = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string param = 2;
 * @return {string}
 */
proto.odds.AntepostBet.prototype.getParam = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AntepostBet} returns this
 */
proto.odds.AntepostBet.prototype.setParam = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional double odd_value = 3;
 * @return {number}
 */
proto.odds.AntepostBet.prototype.getOddValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostBet} returns this
 */
proto.odds.AntepostBet.prototype.setOddValue = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional int32 int_key = 4;
 * @return {number}
 */
proto.odds.AntepostBet.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.AntepostBet} returns this
 */
proto.odds.AntepostBet.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bool is_suspended = 5;
 * @return {boolean}
 */
proto.odds.AntepostBet.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.AntepostBet} returns this
 */
proto.odds.AntepostBet.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string manual_status = 6;
 * @return {string}
 */
proto.odds.AntepostBet.prototype.getManualStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AntepostBet} returns this
 */
proto.odds.AntepostBet.prototype.setManualStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string name = 7;
 * @return {string}
 */
proto.odds.AntepostBet.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.AntepostBet} returns this
 */
proto.odds.AntepostBet.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.EventPlayer.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.EventPlayer.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.EventPlayer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.EventPlayer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EventPlayer.toObject = function(includeInstance, msg) {
  var f, obj = {
    playerName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.odds.OddPlayer.toObject, includeInstance),
    start: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    eventCode: jspb.Message.getFieldWithDefault(msg, 9, 0),
    note: jspb.Message.getFieldWithDefault(msg, 10, ""),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    intKey: jspb.Message.getFieldWithDefault(msg, 15, 0),
    marketsMap: (f = msg.getMarketsMap()) ? f.toObject(includeInstance, undefined) : [],
    dualIntKey: jspb.Message.getFieldWithDefault(msg, 17, 0),
    dualHome: jspb.Message.getFieldWithDefault(msg, 18, ""),
    dualAway: jspb.Message.getFieldWithDefault(msg, 19, ""),
    competitorId: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.EventPlayer}
 */
proto.odds.EventPlayer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.EventPlayer;
  return proto.odds.EventPlayer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.EventPlayer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.EventPlayer}
 */
proto.odds.EventPlayer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 5:
      var value = new proto.odds.OddPlayer;
      reader.readMessage(value,proto.odds.OddPlayer.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 16:
      var value = msg.getMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDualIntKey(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setDualHome(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDualAway(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.EventPlayer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.EventPlayer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.EventPlayer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EventPlayer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPlayerName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.odds.OddPlayer.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getEventCode();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(16, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getDualIntKey();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getDualHome();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getDualAway();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getCompetitorId();
  if (f !== 0) {
    writer.writeInt32(
      20,
      f
    );
  }
};


/**
 * optional string player_name = 2;
 * @return {string}
 */
proto.odds.EventPlayer.prototype.getPlayerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventPlayer} returns this
 */
proto.odds.EventPlayer.prototype.setPlayerName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 competition_id = 3;
 * @return {number}
 */
proto.odds.EventPlayer.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventPlayer} returns this
 */
proto.odds.EventPlayer.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated OddPlayer odds = 5;
 * @return {!Array<!proto.odds.OddPlayer>}
 */
proto.odds.EventPlayer.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.odds.OddPlayer>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.OddPlayer, 5));
};


/**
 * @param {!Array<!proto.odds.OddPlayer>} value
 * @return {!proto.odds.EventPlayer} returns this
*/
proto.odds.EventPlayer.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.odds.OddPlayer=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.OddPlayer}
 */
proto.odds.EventPlayer.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.odds.OddPlayer, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.EventPlayer} returns this
 */
proto.odds.EventPlayer.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};


/**
 * optional string start = 6;
 * @return {string}
 */
proto.odds.EventPlayer.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventPlayer} returns this
 */
proto.odds.EventPlayer.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 sport_id = 7;
 * @return {number}
 */
proto.odds.EventPlayer.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventPlayer} returns this
 */
proto.odds.EventPlayer.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 location_id = 8;
 * @return {number}
 */
proto.odds.EventPlayer.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventPlayer} returns this
 */
proto.odds.EventPlayer.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 event_code = 9;
 * @return {number}
 */
proto.odds.EventPlayer.prototype.getEventCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventPlayer} returns this
 */
proto.odds.EventPlayer.prototype.setEventCode = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string note = 10;
 * @return {string}
 */
proto.odds.EventPlayer.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventPlayer} returns this
 */
proto.odds.EventPlayer.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool is_suspended = 14;
 * @return {boolean}
 */
proto.odds.EventPlayer.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.EventPlayer} returns this
 */
proto.odds.EventPlayer.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional int32 int_key = 15;
 * @return {number}
 */
proto.odds.EventPlayer.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventPlayer} returns this
 */
proto.odds.EventPlayer.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * map<string, bool> markets = 16;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.odds.EventPlayer.prototype.getMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 16, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds.EventPlayer} returns this
 */
proto.odds.EventPlayer.prototype.clearMarketsMap = function() {
  this.getMarketsMap().clear();
  return this;};


/**
 * optional int32 dual_int_key = 17;
 * @return {number}
 */
proto.odds.EventPlayer.prototype.getDualIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventPlayer} returns this
 */
proto.odds.EventPlayer.prototype.setDualIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string dual_home = 18;
 * @return {string}
 */
proto.odds.EventPlayer.prototype.getDualHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventPlayer} returns this
 */
proto.odds.EventPlayer.prototype.setDualHome = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string dual_away = 19;
 * @return {string}
 */
proto.odds.EventPlayer.prototype.getDualAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventPlayer} returns this
 */
proto.odds.EventPlayer.prototype.setDualAway = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 competitor_id = 20;
 * @return {number}
 */
proto.odds.EventPlayer.prototype.getCompetitorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventPlayer} returns this
 */
proto.odds.EventPlayer.prototype.setCompetitorId = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.EventSC.repeatedFields_ = [5,11];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.EventSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.EventSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.EventSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EventSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionShortName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    competitionName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.odds.OddSC.toObject, includeInstance),
    start: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    eventCode: jspb.Message.getFieldWithDefault(msg, 9, 0),
    note: jspb.Message.getFieldWithDefault(msg, 10, ""),
    currentResultList: (f = jspb.Message.getRepeatedField(msg, 11)) == null ? undefined : f,
    currentPhase: (f = msg.getCurrentPhase()) && proto.odds.CurrentPhase.toObject(includeInstance, f),
    detailedResult: jspb.Message.getFieldWithDefault(msg, 13, ""),
    isSuspended: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    intKey: jspb.Message.getFieldWithDefault(msg, 15, 0),
    marketsMap: (f = msg.getMarketsMap()) ? f.toObject(includeInstance, undefined) : [],
    betradarId: jspb.Message.getFieldWithDefault(msg, 17, 0),
    eventStatistics: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.EventSC}
 */
proto.odds.EventSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.EventSC;
  return proto.odds.EventSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.EventSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.EventSC}
 */
proto.odds.EventSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionShortName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 5:
      var value = new proto.odds.OddSC;
      reader.readMessage(value,proto.odds.OddSC.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 11:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt32());
      msg.setCurrentResultList(value);
      break;
    case 12:
      var value = new proto.odds.CurrentPhase;
      reader.readMessage(value,proto.odds.CurrentPhase.deserializeBinaryFromReader);
      msg.setCurrentPhase(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetailedResult(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsSuspended(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 16:
      var value = msg.getMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setBetradarId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setEventStatistics(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.EventSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.EventSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.EventSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EventSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionShortName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.odds.OddSC.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getEventCode();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getCurrentResultList();
  if (f.length > 0) {
    writer.writePackedInt32(
      11,
      f
    );
  }
  f = message.getCurrentPhase();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      proto.odds.CurrentPhase.serializeBinaryToWriter
    );
  }
  f = message.getDetailedResult();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getIsSuspended();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(16, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getBetradarId();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getEventStatistics();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string competition_short_name = 1;
 * @return {string}
 */
proto.odds.EventSC.prototype.getCompetitionShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.setCompetitionShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.odds.EventSC.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 competition_id = 3;
 * @return {number}
 */
proto.odds.EventSC.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string competition_name = 4;
 * @return {string}
 */
proto.odds.EventSC.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated OddSC odds = 5;
 * @return {!Array<!proto.odds.OddSC>}
 */
proto.odds.EventSC.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.odds.OddSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.OddSC, 5));
};


/**
 * @param {!Array<!proto.odds.OddSC>} value
 * @return {!proto.odds.EventSC} returns this
*/
proto.odds.EventSC.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.odds.OddSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.OddSC}
 */
proto.odds.EventSC.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.odds.OddSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};


/**
 * optional string start = 6;
 * @return {string}
 */
proto.odds.EventSC.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 sport_id = 7;
 * @return {number}
 */
proto.odds.EventSC.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 location_id = 8;
 * @return {number}
 */
proto.odds.EventSC.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 event_code = 9;
 * @return {number}
 */
proto.odds.EventSC.prototype.getEventCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.setEventCode = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string note = 10;
 * @return {string}
 */
proto.odds.EventSC.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * repeated int32 current_result = 11;
 * @return {!Array<number>}
 */
proto.odds.EventSC.prototype.getCurrentResultList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 11));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.setCurrentResultList = function(value) {
  return jspb.Message.setField(this, 11, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.addCurrentResult = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 11, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.clearCurrentResultList = function() {
  return this.setCurrentResultList([]);
};


/**
 * optional CurrentPhase current_phase = 12;
 * @return {?proto.odds.CurrentPhase}
 */
proto.odds.EventSC.prototype.getCurrentPhase = function() {
  return /** @type{?proto.odds.CurrentPhase} */ (
    jspb.Message.getWrapperField(this, proto.odds.CurrentPhase, 12));
};


/**
 * @param {?proto.odds.CurrentPhase|undefined} value
 * @return {!proto.odds.EventSC} returns this
*/
proto.odds.EventSC.prototype.setCurrentPhase = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.clearCurrentPhase = function() {
  return this.setCurrentPhase(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.odds.EventSC.prototype.hasCurrentPhase = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string detailed_result = 13;
 * @return {string}
 */
proto.odds.EventSC.prototype.getDetailedResult = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.setDetailedResult = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool is_suspended = 14;
 * @return {boolean}
 */
proto.odds.EventSC.prototype.getIsSuspended = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.setIsSuspended = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional int32 int_key = 15;
 * @return {number}
 */
proto.odds.EventSC.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * map<string, bool> markets = 16;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.odds.EventSC.prototype.getMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 16, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.clearMarketsMap = function() {
  this.getMarketsMap().clear();
  return this;};


/**
 * optional int32 betradar_id = 17;
 * @return {number}
 */
proto.odds.EventSC.prototype.getBetradarId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.setBetradarId = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string event_statistics = 18;
 * @return {string}
 */
proto.odds.EventSC.prototype.getEventStatistics = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventSC} returns this
 */
proto.odds.EventSC.prototype.setEventStatistics = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.odds.EventPlayerSC.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.odds.EventPlayerSC.prototype.toObject = function(opt_includeInstance) {
  return proto.odds.EventPlayerSC.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.odds.EventPlayerSC} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EventPlayerSC.toObject = function(includeInstance, msg) {
  var f, obj = {
    competitionShortName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    playerName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    competitionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    competitionName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    oddsList: jspb.Message.toObjectList(msg.getOddsList(),
    proto.odds.OddPlayerSC.toObject, includeInstance),
    start: jspb.Message.getFieldWithDefault(msg, 6, ""),
    sportId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    locationId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    eventCode: jspb.Message.getFieldWithDefault(msg, 9, 0),
    note: jspb.Message.getFieldWithDefault(msg, 10, ""),
    intKey: jspb.Message.getFieldWithDefault(msg, 15, 0),
    marketsMap: (f = msg.getMarketsMap()) ? f.toObject(includeInstance, undefined) : [],
    dualHome: jspb.Message.getFieldWithDefault(msg, 20, ""),
    dualAway: jspb.Message.getFieldWithDefault(msg, 19, ""),
    dualIntKey: jspb.Message.getFieldWithDefault(msg, 18, 0),
    competitorId: jspb.Message.getFieldWithDefault(msg, 21, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.odds.EventPlayerSC}
 */
proto.odds.EventPlayerSC.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.odds.EventPlayerSC;
  return proto.odds.EventPlayerSC.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.odds.EventPlayerSC} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.odds.EventPlayerSC}
 */
proto.odds.EventPlayerSC.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionShortName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPlayerName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitionId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompetitionName(value);
      break;
    case 5:
      var value = new proto.odds.OddPlayerSC;
      reader.readMessage(value,proto.odds.OddPlayerSC.deserializeBinaryFromReader);
      msg.addOdds(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStart(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setSportId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLocationId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setEventCode(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNote(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setIntKey(value);
      break;
    case 16:
      var value = msg.getMarketsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readBool, null, "", false);
         });
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setDualHome(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDualAway(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDualIntKey(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setCompetitorId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.odds.EventPlayerSC.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.odds.EventPlayerSC.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.odds.EventPlayerSC} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.odds.EventPlayerSC.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCompetitionShortName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPlayerName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCompetitionId();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getCompetitionName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOddsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.odds.OddPlayerSC.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSportId();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getLocationId();
  if (f !== 0) {
    writer.writeInt32(
      8,
      f
    );
  }
  f = message.getEventCode();
  if (f !== 0) {
    writer.writeInt32(
      9,
      f
    );
  }
  f = message.getNote();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getIntKey();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getMarketsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(16, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeBool);
  }
  f = message.getDualHome();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getDualAway();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getDualIntKey();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getCompetitorId();
  if (f !== 0) {
    writer.writeInt32(
      21,
      f
    );
  }
};


/**
 * optional string competition_short_name = 1;
 * @return {string}
 */
proto.odds.EventPlayerSC.prototype.getCompetitionShortName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventPlayerSC} returns this
 */
proto.odds.EventPlayerSC.prototype.setCompetitionShortName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string player_name = 2;
 * @return {string}
 */
proto.odds.EventPlayerSC.prototype.getPlayerName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventPlayerSC} returns this
 */
proto.odds.EventPlayerSC.prototype.setPlayerName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional int32 competition_id = 3;
 * @return {number}
 */
proto.odds.EventPlayerSC.prototype.getCompetitionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventPlayerSC} returns this
 */
proto.odds.EventPlayerSC.prototype.setCompetitionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string competition_name = 4;
 * @return {string}
 */
proto.odds.EventPlayerSC.prototype.getCompetitionName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventPlayerSC} returns this
 */
proto.odds.EventPlayerSC.prototype.setCompetitionName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated OddPlayerSC odds = 5;
 * @return {!Array<!proto.odds.OddPlayerSC>}
 */
proto.odds.EventPlayerSC.prototype.getOddsList = function() {
  return /** @type{!Array<!proto.odds.OddPlayerSC>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.odds.OddPlayerSC, 5));
};


/**
 * @param {!Array<!proto.odds.OddPlayerSC>} value
 * @return {!proto.odds.EventPlayerSC} returns this
*/
proto.odds.EventPlayerSC.prototype.setOddsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.odds.OddPlayerSC=} opt_value
 * @param {number=} opt_index
 * @return {!proto.odds.OddPlayerSC}
 */
proto.odds.EventPlayerSC.prototype.addOdds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.odds.OddPlayerSC, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.odds.EventPlayerSC} returns this
 */
proto.odds.EventPlayerSC.prototype.clearOddsList = function() {
  return this.setOddsList([]);
};


/**
 * optional string start = 6;
 * @return {string}
 */
proto.odds.EventPlayerSC.prototype.getStart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventPlayerSC} returns this
 */
proto.odds.EventPlayerSC.prototype.setStart = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 sport_id = 7;
 * @return {number}
 */
proto.odds.EventPlayerSC.prototype.getSportId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventPlayerSC} returns this
 */
proto.odds.EventPlayerSC.prototype.setSportId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional int32 location_id = 8;
 * @return {number}
 */
proto.odds.EventPlayerSC.prototype.getLocationId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventPlayerSC} returns this
 */
proto.odds.EventPlayerSC.prototype.setLocationId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional int32 event_code = 9;
 * @return {number}
 */
proto.odds.EventPlayerSC.prototype.getEventCode = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventPlayerSC} returns this
 */
proto.odds.EventPlayerSC.prototype.setEventCode = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional string note = 10;
 * @return {string}
 */
proto.odds.EventPlayerSC.prototype.getNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventPlayerSC} returns this
 */
proto.odds.EventPlayerSC.prototype.setNote = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional int32 int_key = 15;
 * @return {number}
 */
proto.odds.EventPlayerSC.prototype.getIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventPlayerSC} returns this
 */
proto.odds.EventPlayerSC.prototype.setIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * map<string, bool> markets = 16;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,boolean>}
 */
proto.odds.EventPlayerSC.prototype.getMarketsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,boolean>} */ (
      jspb.Message.getMapField(this, 16, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.odds.EventPlayerSC} returns this
 */
proto.odds.EventPlayerSC.prototype.clearMarketsMap = function() {
  this.getMarketsMap().clear();
  return this;};


/**
 * optional string dual_home = 20;
 * @return {string}
 */
proto.odds.EventPlayerSC.prototype.getDualHome = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventPlayerSC} returns this
 */
proto.odds.EventPlayerSC.prototype.setDualHome = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string dual_away = 19;
 * @return {string}
 */
proto.odds.EventPlayerSC.prototype.getDualAway = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.odds.EventPlayerSC} returns this
 */
proto.odds.EventPlayerSC.prototype.setDualAway = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional int32 dual_int_key = 18;
 * @return {number}
 */
proto.odds.EventPlayerSC.prototype.getDualIntKey = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventPlayerSC} returns this
 */
proto.odds.EventPlayerSC.prototype.setDualIntKey = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int32 competitor_id = 21;
 * @return {number}
 */
proto.odds.EventPlayerSC.prototype.getCompetitorId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.odds.EventPlayerSC} returns this
 */
proto.odds.EventPlayerSC.prototype.setCompetitorId = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


goog.object.extend(exports, proto.odds);
