const getPythonGrpcPath = () => {
  return `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_PATH_GRPC}`;
};

const getGoGrpcPath = () => {
  return `${process.env.VUE_APP_API_PROTOCOL}://${process.env.VUE_APP_API_PATH_GRPC_GO}`;
};

const getApiBaseUrl = (apiType?: 'GO' | 'PYTHON') => {
  return apiType === 'GO' ? getGoGrpcPath() : getPythonGrpcPath();
};

export const getApiUrl = (apiType?: 'GO' | 'PYTHON', endpoint?: string) => {
  return `${getApiBaseUrl(apiType)}${endpoint ? `/${endpoint}` : ''}`;
};
