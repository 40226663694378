// import { replaceBadJsonString } from '@/lib/parseLiveResults';

export class SettlementEventWs {
  stage: string;
  competitionId: number;
  competitionName: string;
  currentPhase?: {
    fieldsMap: any;
  };
  currentResult?: {
    fieldsMap: any;
  };
  checkbox1530: boolean;
  intKey: number;
  id: number;
  locationId: number;
  note: string; // event mng note
  settlementNote: string;
  sportId: number;
  start: string;
  eventCode: string;
  settled15: boolean;
  settled30: boolean;
  settledM1: boolean;
  state: string;
  locationName: string;
  eventName?: string;
  currentPhaseJson: any;
  currentResultJson: any;
  assignee: number;
  isVerified: boolean;
  liveStatus: string;
  liveOffer: boolean;
  isLiveBooking: boolean;
  isSettleDisabled: boolean;
  liveProvider: string;
  constructor(event: any) {
    this.eventName = event.n;
    this.competitionId = event.c;
    this.competitionName = event.c_n;
    this.locationId = event.l;
    this.sportId = event.s; // mising
    this.eventCode = event.l_c;
    this.start = event.sd;
    this.stage = event.stg;
    this.intKey = event.i_k;
    this.id = event.i_k;
    this.state = event.st;
    this.liveStatus = event.l_s;
    this.locationName = event.l_n;
    this.settled15 = event.s_15;
    this.settled30 = event.s_30;
    this.checkbox1530 = event.c_1530;
    this.settledM1 = event.s_m1;
    this.settlementNote = event.snt;
    this.note = event.nt;
    this.assignee = event.u || 0;
    this.isSettleDisabled = true;
    this.isVerified = false;
    this.isLiveBooking = !!event.l_s?.length || false;
    this.liveOffer = event.l_o;
    this.currentPhaseJson = event.t; //replaceBadJsonString(event.c_p);
    this.currentResultJson = event.c_r; //replaceBadJsonString(event.c_r);
    this.liveProvider = '';
  }
}
