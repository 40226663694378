export type SettlementEventStatus = 'LIVE' | 'PREMATCH';

export type SettledOddStatus = 'PASSED' | 'FAILED';

export type OddTypes = 'LOST' | 'WON' | 'ODD_ONE';

// Old REST format
// export interface SettlementEvent {
//   id: string;
//   name: string;
//   sport: number;
//   start: string;
//   stage: SettlementEventStatus;
//   note: string;
//   is_settled: boolean;
//   is_verified: boolean;
//   settle_result: number;
//   assignee: number | null;
//   current_result: string | null;
//   current_period: FootballPeriod | null;
//   minute: number | null;
//   detailed_result: any;
//   settlement_note: string | null;
//   settlement_history: any;
//   is_ttx: boolean;
//   automatic_settlement_only: boolean;
//   automatic_settlement_result: SettleResults;
//   live_offer: boolean;
//   settled_15: boolean;
//   settled_30: boolean;
// }

export interface RawSettlementEvent {
  away: string;
  betStop: boolean;
  betradarId: string;
  competitionId: number;
  competitionName: string;
  currentPhase?: {
    fieldsMap: any;
  };
  currentResult?: {
    fieldsMap: any;
  };

  settlementHistory: any;
  checkbox1530: boolean;
  home: string;
  intKey: number;
  isSuspended: boolean;
  landbaseCode: number;
  locationId: number;
  liveStatus: string; // if empty string event is not booked for live PREMATCH ONLY
  note: string; // event mng note
  settlementNote: string;
  periodResult?: {
    fieldsMap: any;
  };
  sportId: number;
  start: string;
  stage: 'live' | 'prematch' | 'FINISHED';
  settled15: boolean;
  settled30: boolean;
  settledM1: boolean;
  isVerified: boolean;
  isSettled: boolean;
  state: string;
  locationName: string;
  ctrlProvider: string;
  ctrlProviders: string[];
  liveProvider: string;
  liveProviders: string[];

  // player event specifics

  eventName?: string;
  playerName?: string;
}

export interface SettlementEvent extends RawSettlementEvent {
  currentPeriod: string;
  assignee: number; // when 0 its unassigned -- id of the assignee
  currentPhaseJson: any;
  currentResultJson: any;
  periodResultJson: any;
  isSettleDisabled: boolean;
  isManualSettlement: boolean;
  eventIntKey: number;
  id: string;
  isLiveBooking: boolean;
  prematchRemoved: boolean;
  isSettleEnabled: boolean;
  sentToSettlement: boolean;
  checkboxM1: boolean;
  settledPeriodsList: string[];
  lastPlayingPeriod?: string;
  hasUnsettled: boolean;
}

export interface SettlementEventsMap {
  [key: string]: SettlementEvent;
}

export interface BulkEventsPayload {
  event_ids: string[];
  event_type: 'prematch' | 'live';
}

export interface BulkPlayerEventsPayload {
  event_ids: string[];
}

export interface AssignUserPayload {
  event_ids: string[];
  user_id: number;
  event_type: 'prematch' | 'live';
}

export interface AssignUserPlayerPayload {
  event_ids: string[];
  user_id: number;
}

export interface ActiveOutcomes {
  market_name: string;
  odd_id: number;
  odd_value: number;
  outcome_name: string;
  stage: SettlementEventStatus;
  odd_status: string;
  odd_note: string[] | null;
}

export interface LivePlayerActiveOutcomes extends ActiveOutcomes {
  score: string;
  int_key: number;
  limit: number;
  outcome_id: number;
}

export interface SettledOutcome {
  odd_id: number;
  outcome_name: string;
  odd_value: number;
  market_name: string;
}

export interface SettleResults {
  PASSED: SettledOutcome[];
  FAILED: SettledOutcome[];
}

export type FootballPeriod =
  | FootballPeriods.FIRST_HALF
  | FootballPeriods.FULL_TIME
  | FootballPeriods.HALF_TIME
  | FootballPeriods.NOT_STARTED
  | FootballPeriods.OVER_TIME
  | FootballPeriods.SECOND_HALF;

export enum FootballPeriods {
  NOT_STARTED = 'NS',
  FIRST_HALF = 'FH',
  HALF_TIME = 'HT',
  SECOND_HALF = 'SH',
  FULL_TIME = 'FT',
  OVER_TIME = 'OT',
}

export enum CommonSportPeriods {
  NOT_STARTED = 'NS',
  HALF_TIME = 'HT',
  FULL_TIME = 'FT',
  OVER_TIME = 'OT',
}

export interface SettleOddsPayload {
  odds: { WON: number[]; LOST: number[]; ODD_ONE: number[] };
}

export interface ActiveOutcomesMap {
  [key: string]: ActiveOutcomes;
}

export enum OddStatus {
  UNRESOLVED = 'N',
  WON = 'P',
  LOST = 'F',
  CANCELLED = 'C',
}

export type PeriodChange = Record<string, { home: number; away: number }>;

export interface SettlementHistory {
  cards?: PeriodChange[];
  corners?: PeriodChange[];
  periods?: PeriodChange[];
  scores: PeriodChange[];
  live_ids: number[];
}

export type GoalScorer = {
  home: number;
  away: number;
  goal_scorer: string;
};
export interface LivePlayerSettlementHistory {
  scores: GoalScorer[];
}

export interface MillionTicketsCount {
  million_tickets_count: number;
}
export interface BackofficeUser {
  created_at: string;
  email: string;
  external_id: string;
  first_name: string;
  id: number;
  is_active: boolean;
  is_admin: boolean;
  last_name: string;
  modified_at: string;
  role: number;
  user_type: string;
  username: string;
}

export interface NextGoalData {
  home: number;
  away: number;
  goal_scorer: string;
  goal_nr: number;
  eventIntKey: number;
  score: string;
}

export interface EventScores {
  [key: string]: { home: number; away: number; goal_scorer: string };
}
