import { AuthStore } from '@/lib/internal';
import to from 'await-to-js';
import { EventState } from '../event';
import { sportProtoApi } from '../grpc/sport.api';
import { settlementRepo } from './settlement.repo';
import {
  AssignUserPayload,
  SettlementEvent,
  ActiveOutcomes,
  ActiveOutcomesMap,
  FootballPeriods,
  FootballPeriod,
  BulkEventsPayload,
  SettleOddsPayload,
  BulkPlayerEventsPayload,
  AssignUserPlayerPayload,
  BackofficeUser,
  EventScores,
  LivePlayerActiveOutcomes,
} from './settlement.types';
import has from 'lodash/has';
class SettlementService {
  getSettlementEvents(
    streamParams:
      | string
      | {
          date: string;
          setParamFunc: string;
          value: boolean;
        }
      | null = null
  ) {
    return sportProtoApi.fetchSettlementEvents(streamParams);
  }

  getSettlementCtrlProviderEvents(streamParams: string) {
    return sportProtoApi.getSettlementCtrlProviderEvents(streamParams);
  }

  assignToUser(payload: AssignUserPayload) {
    return settlementRepo.assignToUser(payload);
  }

  unassignEvents(eventIds: string[], eventType: 'prematch' | 'live') {
    return settlementRepo.unassignEvents({ event_ids: eventIds, event_type: eventType });
  }

  settleAll = (eventId: string, payload: any) => {
    return settlementRepo.settleAll(eventId, { ...payload, manual: true }); //for be to differentiate is it manual settlement action
  };

  // toggleTTX = (eventIds: string[]) => {
  //   return settlementRepo.toggleTTX({ event_ids: eventIds });
  // };

  autoSettlement = (eventIds: string[]) => {
    return settlementRepo.autoSettlement({ event_ids: eventIds });
  };

  verifySettlement(eventId: string) {
    return settlementRepo.verifySettlement(eventId);
  }

  getActiveOutcomes(eventId: string) {
    return settlementRepo.getActiveOutcomes(eventId);
  }

  getActiveOutcomesLivePlayer(eventId: number) {
    return settlementRepo.getActiveOutcomesLivePlayer(eventId);
  }

  setOddToOne(oddId: string, eventId: number) {
    return settlementRepo.setOddToOne(oddId, eventId);
  }

  setEventTerminated(eventId: string) {
    return settlementRepo.setEventTerminated(eventId);
  }

  setPostponed(eventId: string) {
    return settlementRepo.setPostponed(eventId);
  }

  setResult(eventId: number, payload: any) {
    return settlementRepo.setResult(eventId, payload);
  }

  setPeriodResult(eventId: number, payload: any) {
    return settlementRepo.setPeriodResult(eventId, payload);
  }

  clearResult(eventId: string, payload: any) {
    return settlementRepo.clearResult(eventId, payload);
  }

  updateSettlementNote(eventId: string, payload: any) {
    return settlementRepo.updateSettlementNote(eventId, payload);
  }

  getUnsettledEvents(payload: any, urlParams: any) {
    return settlementRepo.getUnsettledEvents(payload, urlParams);
  }

  getResultHistory(eventId: number) {
    return settlementRepo.getResultHistory(eventId);
  }

  getResultLivePlayerHistory(eventId: number) {
    return settlementRepo.getResultLivePlayerHistory(eventId);
  }

  resetAllOdds(eventId: string) {
    return settlementRepo.resetAllOdds(eventId);
  }

  settleOdds(payload: any) {
    return settlementRepo.settleOdds(payload);
  }

  resetOdd(oddId: string, eventId: number) {
    return settlementRepo.resetOdd(oddId, eventId);
  }

  // these are in service since they do not update store
  async resetOddsByDateTime(eventId: string, params: any) {
    const [err] = await to(settlementRepo.resetOddsByDateTime(eventId, params));
    if (err) return Promise.reject(err);
    return Promise.resolve();
  }

  async resetOddsByOutcomeAndTime(eventId: string, params: any) {
    const [err] = await to(settlementRepo.resetOddsByOutcomeAndTime(eventId, params));
    if (err) return Promise.reject(err);
    return Promise.resolve();
  }

  async setAllOddsToOne(eventId: string, eventType: 'prematch' | 'live') {
    const [err] = await to(settlementRepo.setAllOddsToOne(eventId, eventType));
    if (err) return Promise.reject(err);
    return Promise.resolve();
  }

  // Players

  getPlayerEvents() {
    return sportProtoApi.fetchSettlementPlayerEvents();
  }

  setPlayerResult(eventId: number, payload: any) {
    return settlementRepo.setPlayerResult(eventId, payload);
  }

  resetAllPlayerOdds(eventId: string) {
    return settlementRepo.resetAllPlayerOdds(eventId);
  }

  toggleTTXPlayers(payload: BulkEventsPayload) {
    return settlementRepo.toggleTTXPlayers(payload);
  }

  getActiveOutcomesPlayers(eventId: string) {
    return settlementRepo.getActiveOutcomesPlayer(eventId);
  }

  assignPlayerEventToUser(payload: AssignUserPlayerPayload) {
    return settlementRepo.assignPlayerEventToUser(payload);
  }

  unassignPlayerEvents(payload: BulkEventsPayload) {
    return settlementRepo.unassignPlayerEvents(payload);
  }

  assignAllDualEventPlayerEvents(payload: AssignUserPlayerPayload) {
    return settlementRepo.assignAllDualEventsPlayerEvents(payload);
  }

  unassignAllDualEventPlayerEvents(payload: BulkPlayerEventsPayload) {
    return settlementRepo.unassignAllDualEventsPlayerEvents(payload);
  }

  getPlayerResultHistory(eventId: string) {
    return settlementRepo.getPlayerResultHistory(eventId);
  }

  verifyPlayerSettlement(eventId: string) {
    return settlementRepo.verifyPlayerSettlement(eventId);
  }

  updatePlayerSettlementNote(eventId: string, payload: any) {
    return settlementRepo.updatePlayerSettlementNote(eventId, payload);
  }

  settlePlayerOdds(payload: SettleOddsPayload) {
    return settlementRepo.settlePlayerOdds(payload);
  }

  setPlayerOddToOne(oddId: string) {
    return settlementRepo.setPlayerOddToOne(oddId);
  }

  resetPlayerOdd(oddId: string) {
    return settlementRepo.resetPlayerOdd(oddId);
  }

  settleAllPlayer(eventId: string) {
    return settlementRepo.settleAllPlayer(eventId);
  }

  async setAllPlayerOddsToOne(eventId: string) {
    const [err] = await to(settlementRepo.setAllPlayerOddsToOne(eventId));
    if (err) return Promise.reject(err);
    return Promise.resolve();
  }

  async resetPlayerOddsByOutcomeAndTime(eventId: string, params: any) {
    const [err] = await to(settlementRepo.resetPlayerOddsByOutcomeAndTime(eventId, params));
    if (err) return Promise.reject(err);
    return Promise.resolve();
  }

  async resetPlayerOddsByDateTime(eventId: string, params: any) {
    const [err] = await to(settlementRepo.resetPlayerOddsByDateTime(eventId, params));
    if (err) return Promise.reject(err);
    return Promise.resolve();
  }
  // Dual services

  findEvent(eventId: string, events: SettlementEvent[]): SettlementEvent {
    return events.find(event => event.id === eventId) as any;
  }

  findOdd(oddId: string, odds: any[]) {
    return odds.find(odd => odd.odd_id === oddId);
  }

  filterEvents(events: SettlementEvent[], eventIds: string[]) {
    return events.filter((event: SettlementEvent) => !eventIds.includes(event.id));
  }

  getEventClass(event: SettlementEvent): string {
    if (!event) return '';
    if (event.state === 'PUBLISHED') return 'primary-row';
    if (event.state === 'STARTED') return 'primary-row';
    if (event.state === 'HIDDEN') return 'purple-row';
    if (event.state === 'COMPLETED') return 'yellow-row';
    if (event.liveStatus === 'SETTLE') return 'primary-row';
    return '';
  }

  getControlEventClass(event: any): string {
    if (!event) return '';
    if (event.liveProvider || event.prematchProvider) return 'disabled-event-row';
    return '';
  }

  buildFilterPayload(payload: any) {
    if (payload.is_assigned === 'assigned') {
      payload.is_assigned = true;
    }
    if (payload.is_assigned === 'assignedToMe') {
      payload.is_assigned = true;
      payload.user = AuthStore.userId;
    }
    if (payload.is_assigned === 'unAssigned') payload.is_assigned = false;
    return payload;
  }

  getNextPeriod(currentPeriod: string) {
    if (currentPeriod === 'not_started') return 'first_half';
    if (currentPeriod === 'first_half') return 'HT';
    if (currentPeriod === 'ht' || currentPeriod === 'HT') return 'second_half';
    if (currentPeriod === 'second_half') return 'FT';
    if (currentPeriod === 'ft' || currentPeriod === 'FT') return 'not_started';
  }

  mapActiveOutcomes(activeOutcomes: ActiveOutcomes[], storeArray: ActiveOutcomesMap) {
    activeOutcomes.forEach(outcome => {
      storeArray[outcome.odd_id] = outcome;
    });
  }

  findNonSelectedActiveOutcomes(
    activeOutcomes: ActiveOutcomesMap,
    wonOdds: number[],
    lostOdds: number[],
    oddOne: number[]
  ) {
    const nonSelectedOutcomes: number[] = [];
    const activeOutcomeIds = Object.keys(activeOutcomes);
    activeOutcomeIds.forEach(outcomeId => {
      if (
        !wonOdds.includes(Number(outcomeId)) &&
        !lostOdds.includes(Number(outcomeId)) &&
        !oddOne.includes(Number(outcomeId))
      ) {
        nonSelectedOutcomes.push(Number(outcomeId));
      }
    });
    return nonSelectedOutcomes;
  }

  isPublishUnpublishButtonDisabled(eventState: EventState) {
    return eventState !== 'PUBLISHED' && eventState !== 'HIDDEN' && eventState !== 'VALIDATED';
  }

  canEventBePublished(eventState: EventState) {
    return eventState === 'HIDDEN' || eventState === 'VALIDATED';
  }

  private resultReducer(minute: any) {
    return (acc: any, val: any) => {
      const scoreMinute = Number(val.minute[0] + val.minute[1]);

      if (scoreMinute <= minute) {
        return {
          home: val.home,
          away: val.away,
          period_name: `${minute}`,
        };
      }
      return acc;
    };
  }

  private mapResultEntries(resultEntries: any) {
    const minutes = Object.keys(resultEntries);
    const results = minutes.map((minute: any) => {
      return { ...resultEntries[minute], minute: minute };
    });
    return results;
  }

  private canExctract45(eventPeriod: FootballPeriod) {
    return (
      eventPeriod === FootballPeriods.HALF_TIME ||
      eventPeriod === FootballPeriods.SECOND_HALF ||
      eventPeriod === FootballPeriods.FULL_TIME
    );
  }

  private canExctract90(eventPeriod: FootballPeriod) {
    return eventPeriod === FootballPeriods.FULL_TIME;
  }

  //Football only
  getImportantPeriodResults(results: any, eventPeriod: any, eventTime: any) {
    const resultEntries = this.mapResultEntries(results);

    const importantResults: any = {};
    if (eventPeriod === FootballPeriods.NOT_STARTED || eventTime <= 15) return importantResults;
    if (eventTime > 15) {
      importantResults['15'] = resultEntries.reduce(this.resultReducer(15));
    }
    if (eventTime > 30) {
      importantResults['30'] = resultEntries.reduce(this.resultReducer(30));
    }
    if (this.canExctract45(eventPeriod)) {
      importantResults['45'] = resultEntries.reduce(this.resultReducer(45));
    }
    if (this.canExctract90(eventPeriod)) {
      importantResults['90'] = resultEntries.reduce(this.resultReducer(90));
    }
    return importantResults;
  }

  getBackofficeUsers(payload: { role: number }) {
    return settlementRepo.getBackofficeUsers(payload);
  }

  usersForAssigneDropdown(users: BackofficeUser[]) {
    return users.reduce((acc: any, user) => {
      const userForDropdown = {
        label: `${user.first_name}  ${user.last_name}`,
        value: user.id,
      };
      return [...acc, userForDropdown];
    }, []);
  }

  mapLivePlayerSettlementHistory(scores: EventScores) {
    const resultsByTime = Object.values(scores);
    resultsByTime.forEach(result => {
      if (!has(result, 'goal_scorer')) {
        result.goal_scorer = '';
      }
    });
    return Object.values(resultsByTime);
  }

  mapLivePlayersFromBets(outcomes: LivePlayerActiveOutcomes[]) {
    const allPlayers: string[] = outcomes.reduce(
      (playerArr: string[], outcome: LivePlayerActiveOutcomes) => {
        if (!playerArr.includes(outcome.outcome_name)) {
          playerArr.push(outcome.outcome_name);
        }
        return playerArr;
      },
      []
    );
    return allPlayers.map((player: string) => {
      return { label: player, value: player };
    });
  }
}

export const settlementService = new SettlementService();
