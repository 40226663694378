export class EventPublished {
  betStop: boolean;
  competitionId: number;
  competitionName: string;
  locationId: number;
  eventCode: number;
  name: string;
  baseLiveMarkets: any;
  basePrematchMarkets: any;
  start: string;
  sportId: number;
  stage: string;
  intKey: number;
  away: string;
  home: string;
  liveStatus: string;
  state: string;
  liveOutcomeTemplateId: number;
  liveApprovalTemplateId: number;
  liveMarketTemplateId: number;
  prematchApprovalTemplateId: number;
  prematchMarketTemplateId: number;
  prematchOutcomeTemplateId: number;
  liveProvider: string;
  operatorConfirmed: boolean;
  operatorNotesList: any;
  manualChanged: boolean;
  settlementLiveStop: boolean;
  hasSlip: boolean;
  competitionConfirmed: boolean;
  competitorsConfirmed: boolean;
  canPublish: boolean;
  liveOffer: boolean;
  currentPhaseJson: any;
  currentResult: boolean;
  currentResultJson: any;
  periodResultJson: any;
  prematchOffer: boolean;
  numOfProdOddPrematch: number;
  templateOddsNum: number;
  marketsMap: any[][];
  constructor(event: any) {
    this.betStop = event.bs;
    this.name = event.n;
    this.competitionId = event.c_i;
    this.competitionName = event.c_n;
    this.locationId = event.l_i;
    this.sportId = event.s_i;
    this.eventCode = event.e_c;
    this.start = event.sta;
    this.stage = event.stg;
    this.intKey = event.i_k;
    this.away = event.a;
    this.home = event.h;
    this.liveStatus = event.l_s;
    this.state = 'PUBLISHED';
    this.liveApprovalTemplateId = event.lati;
    this.liveOutcomeTemplateId = event.loti;
    this.liveMarketTemplateId = event.lmti;
    this.prematchApprovalTemplateId = event.pati;
    this.prematchMarketTemplateId = event.pmti;
    this.prematchOutcomeTemplateId = event.poti;
    this.numOfProdOddPrematch = event.n_o_p;
    this.templateOddsNum = event.n_o_t;
    this.liveProvider = event.lp;
    this.operatorConfirmed = event.oc;
    this.manualChanged = event.mc;
    this.settlementLiveStop = event.sls;
    this.hasSlip = event.hs;
    this.competitionConfirmed = event.cc;
    this.currentPhaseJson = event.c_p;
    this.periodResultJson = event.d_r;
    this.currentResult = true;
    this.currentResultJson = event.c_r;
    this.competitorsConfirmed = event.csc;
    this.canPublish = event.cp;
    this.liveOffer = event.lo;
    this.prematchOffer = event.po;
    this.marketsMap = event.mm;
    this.baseLiveMarkets =
      event.stg.toLowerCase() === 'live'
        ? event.o.map((odd: any) => {
            return {
              intKey: odd.i_k,
              limit: odd.lim,
              marketId: odd.m_i,
              marketName: odd.m_n,
              oddValue: odd.o_v,
              outcomeId: odd.o_i,
              outcomeName: odd.o_n,
              isSuspended: odd.i_s,
              oddRealValue: odd.r_v,
            };
          })
        : [];
    this.basePrematchMarkets =
      event.stg.toLowerCase() === 'prematch'
        ? event.o.map((odd: any) => {
            return {
              intKey: odd.i_k,
              limit: odd.lim,
              marketId: odd.m_i,
              marketName: odd.m_n,
              oddValue: odd.o_v,
              outcomeId: odd.o_i,
              outcomeName: odd.o_n,
              isSuspended: odd.i_s,
              oddRealValue: odd.r_v,
            };
          })
        : [];
  }
}
